import React from "react"
import { Button, Grid, Tooltip } from "@material-ui/core"

import useStyles, { BUTTON_MESSAGE_WIDTH } from "@/components/Messages/ButtonMessage/styles"
import useCustomStyles from "@/styles/custom"

import Divider from "@/components/Divider"

import { getMessageColor } from "@/utils/message"
import { isLastItem } from "@/utils/array"

import { IMessageExtraData } from "@/protocols/channel"

type ButtonMessageProps = {
	extraData: IMessageExtraData
	sentByCustomer: boolean
}

type ButtonItemProps = {
	text: string
	sentByCustomer: boolean
	style?: React.CSSProperties
}

const ButtonItem: React.FC<ButtonItemProps> = (props) => {
	const {
		sentByCustomer,
		text,
		style
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<Tooltip
			title="Somente seu destinatário pode clicar no botão."
		>
			<Grid>
				<Button
					variant="contained"
					fullWidth
					className={classes.button}
					style={{
						backgroundColor: getMessageColor(sentByCustomer),
						...style
					}}
					classes={{
						label: customClasses.inlineText
					}}
				>
					{text}
				</Button>
			</Grid>
		</Tooltip>
	)
}

export const ButtonMessageContainerFooter: React.FC<ButtonMessageProps> = (props) => {
	const {
		extraData,
		sentByCustomer
	} = props

	const buttons = extraData?.button?.buttons
	const totalButtons = buttons?.length as number

	const canShowDualButton = () => {
		const DUAL_BUTTON_AMOUNT = 2
		const MAX_WORD_SIZE_PER_BUTTON = 25

		const isExpectedDualButton = totalButtons === DUAL_BUTTON_AMOUNT

		const firstButtonWordSize = buttons?.[0]?.text?.length as number
		const secondButtonWordSize = buttons?.[1]?.text?.length as number

		const isFirstButtonWordOversized = firstButtonWordSize > MAX_WORD_SIZE_PER_BUTTON
		const isSecondButtonWordOversized = secondButtonWordSize > MAX_WORD_SIZE_PER_BUTTON

		return (
			isExpectedDualButton &&
			!isFirstButtonWordOversized &&
			!isSecondButtonWordOversized
		)
	}

	if (canShowDualButton()) {
		return (
			<Grid
				container
				style={{
					width: BUTTON_MESSAGE_WIDTH
				}}
				justify="space-between"
			>
				{buttons?.map((button, index) => (
					<Grid
						key={index}
						item
						style={{
							width: (BUTTON_MESSAGE_WIDTH / totalButtons) - 2
						}}
					>
						<ButtonItem
							sentByCustomer={sentByCustomer}
							text={button.text}
							key={button.id}
						/>
					</Grid>
				))}
			</Grid>
		)
	} else {
		return (
			<>
				{buttons?.map((button, index) => (
					<>
						<ButtonItem
							sentByCustomer={sentByCustomer}
							text={button.text}
							key={button.id}
							style={{
								width: BUTTON_MESSAGE_WIDTH
							}}
						/>

						{!isLastItem(index, totalButtons) && (
							<Divider orientation="horizontal" size={0.5} />
						)}
					</>
				))}
			</>
		)
	}
}

export const ButtonMessageContentBoxStyle: React.CSSProperties = {
	width: BUTTON_MESSAGE_WIDTH
}
