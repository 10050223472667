import React, { useState } from "react"
import {
	Grid,
	Divider as MuiDivider,
	Chip
} from "@material-ui/core"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleCategory
} from "@/protocols/chatBot"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"

import { PopConfirm } from "@/components"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyEditMessageItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyEditMessageItem"
import BlockConditionEditor, { ValidationSlugInput } from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor"
import CreateConditionIconButton from "@/pages/Admin/ChatBot/ChatBotConstructor/components/CreateConditionIconButton"
import NoConditionText from "@/pages/Admin/ChatBot/ChatBotConstructor/components/NoConditionText"

import InputLocationEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SaveInputBlock/InputLocationEditor"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SaveInputBlock/styles"

import { generateUUID } from "@/utils/id"
import { getNextChatBotRuleDescription, getCurrentSaveInputLocationOption } from "@/utils/chatBot"

const AVAILABLE_SLUG_INPUTS: ValidationSlugInput[] = ["no-interaction-timeout"]

const ConditionalTextBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		nextChatBotFlowBlockRules,
		changeNextChatBotFlowBlockRule,
		toggleNextChatBotFlowBlock,
		constructionResources,
		flowBlock,
		deleteChatBotFlowBlock
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")

	const classes = useStyles()

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const handleEditNextChatBotFlowBlockRule = (nextChatBotFlowBlockRule: ChatBotFlowBlockRule) => {
		BlockConditionEditor.open({
			onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("UPDATE", chatBotFlowBlockRule),
			nextChatBotFlowBlockRule: nextChatBotFlowBlockRule,
			constructionResources,
			chatBotFlowBlockType: "save-input",
			availableSlugInputs: AVAILABLE_SLUG_INPUTS
		})
	}

	const handleCreateNextChatBotFlowBlockRule = (selectedCategory: ChatBotFlowBlockRuleCategory) => {
		const block = constructionResources.blocks.find(({ type }) => type === "save-input")

		const nextChatBotFlowBlockRule = block?.available_next_block_flow_rules?.find(({ category }) => category === selectedCategory)

		if (nextChatBotFlowBlockRule) {
			BlockConditionEditor.open({
				onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("CREATE", chatBotFlowBlockRule),
				nextChatBotFlowBlockRule: {
					...nextChatBotFlowBlockRule,
					id: generateUUID(),
					next_chat_bot_flow_block_id: null,
					validations: nextChatBotFlowBlockRule?.validations || []
				},
				constructionResources,
				chatBotFlowBlockType: "save-input",
				availableSlugInputs: AVAILABLE_SLUG_INPUTS
			})
		}
	}

	const handleDeleteNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		PopConfirm.open({
			title: "EXCLUIR CONDIÇÃO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => changeNextChatBotFlowBlockRule("DELETE", nextFlowBlockRule),
			confirmButtonText: "EXCLUIR"
		})
	}

	const handleChangeInputLocation = () => {
		InputLocationEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources
		})
	}

	const getInputLocationElement = () => {
		const option = getCurrentSaveInputLocationOption(constructionResources, chatBotFlowBlockContent)

		if (option) {
			return (
				<Chip
					className={classes.inputVariableChip}
					label={`{{ ${option.variableName} }}`}
					size="small"
				/>
			)
		} else {
			return ""
		}
	}

	const continueConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "continue-condition")
	const exitConditionRules = nextChatBotFlowBlockRules.filter(({ category }) => category === "exit-condition")

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyEditMessageItem
					constructionResources={constructionResources}
					title="Mensagem"
					onSave={(buildedMessages) => changeChatBotFlowContent({ messagesToSend: buildedMessages })}
					messages={chatBotFlowBlockContent.messagesToSend}
				/>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Onde salvar a resposta?"
					/>

					<BlockBodyItemInput
						text={getInputLocationElement() || ""}
						type="action"
						placeholder="Selecione um local para salvar a resposta"
						onEdit={handleChangeInputLocation}
					/>
				</BlockBodyItem>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Condições para sair"
						endIcon={(
							<CreateConditionIconButton
								onClick={() => handleCreateNextChatBotFlowBlockRule("exit-condition")}
							/>
						)}
					/>

					{exitConditionRules?.length ? (
						exitConditionRules.map((nextChatBotFlowBlockRule) => (
							<BlockBodyItemInput
								key={nextChatBotFlowBlockRule.id}
								onClick={() => handleSelectNextFlowBlockRule(nextChatBotFlowBlockRule)}
								text={getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
								type="keep-going-condition"
								onEdit={() => handleEditNextChatBotFlowBlockRule(nextChatBotFlowBlockRule)}
								onDelete={() => handleDeleteNextFlowBlockRule(nextChatBotFlowBlockRule)}
								selectable={nextChatBotFlowBlockRule.id !== selectedNextFlowBlockRuleId}
								selected={nextChatBotFlowBlockRule.id === selectedNextFlowBlockRuleId}
							/>
						))
					) : (
						<NoConditionText />
					)}
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{continueConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(continueConditionRule)}
							text="O que fazer após salvar os dados?"
							type="finish-flow-condition"
							selectable={continueConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={continueConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default ConditionalTextBlock
