import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	portletInfoContainer: {
		flex: 1
	},
	portletLimitedSize: {
		maxWidth: 300,
		paddingRight: theme.spacing(2)
	},
	children: {
		flex: 1
	},
	copyWebhookUrlContainer: {
		maxWidth: 400
	},
	copyWebhookUrlText: {
		paddingRight: theme.spacing(2),
		wordBreak: "break-all"
	},
	copyWebhookUrlChip: {
		backgroundColor: "#FBEECC",
		color: colors.grayScale[4],
		fontSize: 14,
		height: 20
	},
	testWebhookUrlChip: {
		backgroundColor: "#cccffb",
		color: colors.grayScale[4],
		fontSize: 14,
		height: 20
	}
}))

export default useStyles
