class CookieService {
	private readonly defaultKey = "@bume-ti"

	set (key: string, value: string): void {
		const cookieName = `${this.defaultKey}/${key}`

		document.cookie = `${cookieName}=${value}`
	}

	get (key: string): string | null {
		const documentCookie = `; ${document.cookie}`
		const cookieName = `${this.defaultKey}/${key}`

		const extractKeyInCookie = documentCookie.split(`; ${cookieName}=`)

		if (extractKeyInCookie.length === 2) {
			const data = extractKeyInCookie.pop()?.split(";")?.shift() || null

			return data
		}

		return null
	}

	delete (key: string): void {
		const cookieName = `${this.defaultKey}/${key}`

		document.cookie = `${cookieName}=`
	}
}

export default new CookieService()
