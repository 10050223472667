import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	iconContainer: {
		width: 60,
		height: 60,
		borderRadius: "100%"
	},
	dataContainer: {
		minHeight: 160
	},
	count: {
		fontWeight: "bold",
		fontSize: 36
	}
})

export default useStyles
