import React from "react"
import {
	Typography,
	Grid,
	IconButton,
	CircularProgress
} from "@material-ui/core"
import {
	Description as FileIcon,
	GetApp as DownloadIcon
} from "@material-ui/icons"

import { FancyBox, Divider } from "@/components"

import useStyles, { MESSAGE_MEDIA_MAX_WIDTH } from "@/components/Messages/MediaMessage/styles"
import useCustomStyles from "@/styles/custom"

import AudioPlayer from "@/components/Messages/MediaMessage/AudioPlayer"
import VideoPlayer from "@/components/Messages/MediaMessage/VideoPlayer"
import Image from "@/components/Messages/MediaMessage/Image"

import { MessageType } from "@/protocols/channel"

type MediaMessageProps = {
	uploadingMedia?: boolean
	type: MessageType
	caption: string
	content: string
	fancyBoxSlug?: string
}

export const MediaMessageContent: React.FC<MediaMessageProps> = (props) => {
	const {
		uploadingMedia,
		content,
		type,
		caption,
		fancyBoxSlug
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<>
			{(type === "picture" || type === "video") && (
				<Grid
					container
					direction="column"
					className={classes.pictureVideoContainer}
				>
					<FancyBox
						options={{
							slug: fancyBoxSlug,
							enableHashChange: true,
							backFocus: false
						}}
						caption={caption}
						src={content}
						id={String(Date.now())}
					>
						{uploadingMedia && (
							<Grid
								container
								alignItems="center"
								justify="center"
								className={classes.loadingContainer}
							>
								<CircularProgress />
							</Grid>
						)}

						{type === "picture" && (
							<Image
								src={content}
								classes={{
									afterLoad: classes.messageImage,
									beforeLoad: classes.unloadedMessageImage
								}}
								alt="mensagem"
							/>
						)}

						{type === "video" && (
							<VideoPlayer
								src={content}
								classes={{
									afterLoad: classes.messageVideo,
									beforeLoad: classes.unloadedMessageVideo
								}}
							/>
						)}
					</FancyBox>

					<Typography
						variant="overline"
						className={classes.messageCaption}
						color="textPrimary"
						style={{
							maxWidth: MESSAGE_MEDIA_MAX_WIDTH
						}}
					>
						{caption}
					</Typography>
				</Grid>
			)}

			{type === "audio" && (
				<AudioPlayer
					src={content}
				/>
			)}

			{type === "file" && (
				<Grid
					container
					alignItems="center"
					justify="space-between"
					className={classes.fileContainer}
				>
					<Grid
						container
						alignItems="center"
						className={classes.fileLeftSideContainer}
					>
						<FileIcon />

						<Divider orientation="vertical" size={1} />

						<Typography
							color="textPrimary"
							className={`${classes.fileName} ${customClasses.inlineText}`}
						>
							{caption}
						</Typography>
					</Grid>

					{uploadingMedia ? (
						<CircularProgress />
					) : (
						<IconButton
							component="a"
							download={caption}
							target="_blank"
							href={content}
						>
							<DownloadIcon />
						</IconButton>
					)}
				</Grid>
			)}
		</>
	)
}
