import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	optionChildContainer: {
		borderLeft: "4px solid #CBCBCB",
		padding: theme.spacing(2, 3),
		marginLeft: theme.spacing(1)
	}
}))

export default useStyles
