import React from "react"
import { Grid } from "@material-ui/core"

const BlockBodyItem: React.FC = (props) => {
	return (
		<Grid item>
			<Grid
				container
				spacing={1}
				direction="column"
			>
				{React.Children.map(props.children, child => (
					<Grid
						item
						xs={12}
					>
						<Grid
							container
						>
							{child}
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	)
}

export default BlockBodyItem
