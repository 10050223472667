import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
const useStyles = makeStyles({
	buttonPrimaryColorContained: {
		backgroundColor: colors.palette.primary,
		fontWeight: 500,
		color: colors.grayScale[11],
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: colors.palette.primary
		}
	},
	buttonPrimaryColorOutLined: {
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: colors.palette.primary,
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		}
	}

})

export default useStyles
