import React from "react"
import { Typography, TypographyProps } from "@material-ui/core"

type ClientNameProps = TypographyProps & {
	name: string
}

const ClientName: React.FC<ClientNameProps> = (props) => {
	const { className, name, ...rest } = props

	return (
		<Typography
			variant="caption"
			color="textPrimary"
			{...rest}
			className={`${className}`}
		>
			~{name}
		</Typography>
	)
}

export default ClientName
