import { MessageType } from "@/protocols/channel"

export type Media = {
	data: File
	type: MessageType
	url?: string
	key?: string
}

type BuildMediaByUrlInput = {
	name: string
	url: string
	key?: string
	type: MessageType
}

class MediaService {
	adaptFile (file: File): Media {
		const adaptedMimeType = this.adaptMimeType(file.type)

		return {
			data: file,
			type: adaptedMimeType
		}
	}

	getUrlFromBlob (file: File): string {
		const url = URL.createObjectURL(file)

		return url
	}

	turnBlobIntoFile (blob: Blob, fileName: string): File {
		const file = new File([blob], fileName, {
			type: blob.type,
			lastModified: Date.now()
		})

		return file
	}

	turnBase64IntoFile (base64Data: string, fileName: string): File {
		const byteCharacters = base64Data
		const byteArrays = []
		const sliceSize = 512
		const mimeType = this.getMimeTypeFromBase64Data(base64Data)

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize)

			const byteNumbers = new Array(slice.length)
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i)
			}

			const byteArray = new Uint8Array(byteNumbers)
			byteArrays.push(byteArray)
		}

		const file = new File(byteArrays, fileName, {
			type: mimeType
		})

		return file
	}

	getMimeTypeFromBase64Data (base64Data: string): string {
		const mimeType = base64Data
			?.split(";base64")
			?.shift()
			?.replace("data:", "")
			?.trim()

		return mimeType || ""
	}

	async turnFileIntoBase64 (file: File): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader()

			reader.readAsDataURL(file)

			reader.onload = () => resolve(reader.result as string)
			reader.onerror = error => reject(error)
		})
	}

	buildMediaByUrl (input: BuildMediaByUrlInput): Media {
		const {
			name,
			type,
			url,
			key
		} = input

		return {
			data: new File([], name),
			url,
			type,
			key
		}
	}

	private adaptMimeType (mimeType: string): MessageType {
		const mainType = mimeType.split?.("/")?.[0]

		const adaptedMimeTypeMap: { [key: string]: MessageType } = {
			video: "video",
			image: "picture",
			audio: "audio",
			application: "file",
			default: "file"
		}

		const adaptedMimeType = adaptedMimeTypeMap[mainType] || adaptedMimeTypeMap.default

		return adaptedMimeType
	}
}

export default new MediaService()
