import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	textareaLineNumbers: {
		backgroundColor: colors.grayScale[9],
		"& > div": {
			paddingLeft: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5)
		},
		"& textarea": {
			textAlign: "center",
			color: colors.grayScale[1]
		},
		"& fieldset": {
			border: 0
		}
	}
}))

export default useStyles
