import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { Tag } from "@/protocols/tag"
import { ErrorType } from "@/hooks/useValidation"

class TagService {
	async getTags (): Promise<Tag[] | null> {
		try {
			const { data } = await ApiService.get("/tag")

			return data.tags
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
			return null
		}
	}
}

export default new TagService()
