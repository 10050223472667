import React from "react"
import { FixedSizeList, ListChildComponentProps } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

import { consolidateAutoSizerHeight } from "@/utils/virtualizedList"

export type VirtualizedListItemProps = ListChildComponentProps

export type VirtualizedListProps = {
	itemSize: number
	itemCount: number
	itemRender: React.ComponentType<ListChildComponentProps> & React.ReactNode
	className: string
	/**
	 * Container width and height are useful in the cases the children does not work well
	 * when dealing with AutoSizer (per ex when using virtualized lists inside drawers).
	 */
	containerWidth?: number
	containerHeight?: number
}

const VirtualizedList: React.FC<VirtualizedListProps> = (props) => {
	const {
		className,
		itemCount,
		itemSize,
		itemRender,
		containerWidth,
		containerHeight
	} = props

	if (containerWidth && containerHeight) {
		return (
			<FixedSizeList
				className={className}
				itemSize={itemSize}
				itemCount={itemCount}
				width={containerWidth}
				height={containerHeight}
			>
				{itemRender}
			</FixedSizeList>
		)
	} else {
		return (
			<AutoSizer>
				{({ width, height }) => (
					<FixedSizeList
						className={className}
						itemSize={itemSize}
						itemCount={itemCount}
						width={width}
						height={consolidateAutoSizerHeight(height)}
					>
						{itemRender}
					</FixedSizeList>
				)}
			</AutoSizer>
		)
	}
}

export default VirtualizedList
