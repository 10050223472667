import React, { ReactNode, useState } from "react"
import {
	Box,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@material-ui/core"
import { InfoOutlined as InfoIcon } from "@material-ui/icons"
import useStyles from "@/pages/Admin/CustomFields/ManageCustomField/styles"
import { ActionDialog, Divider } from "@/components"
import ApiService from "@/services/Api"
import alert_signal from "@/assets/images/illustrations/alert_signal.svg"
import vertical_yellow_bar from "@/assets/images/illustrations/vertical_yellow_bar.svg"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useCustomStyles from "@/styles/custom"
import { CustomFieldGroupList } from "@/protocols/customField"
import slugify from "slugify"

type ActualCustomFieldValue = {
	display_name: string
	variable: string
	group_name: string
	custom_field_group_id: number
	id: number
}

type ManageCustomFieldDialogProps = {
	children: ReactNode
	customFieldGroupList?: CustomFieldGroupList[]
	onDataChange: () => void
	edit?: boolean
	actualCustomFieldInfos?: ActualCustomFieldValue
	groupDisplayName?: string
}

const ManageCustomField: React.FC<ManageCustomFieldDialogProps> = ({ children, customFieldGroupList, onDataChange, edit, actualCustomFieldInfos }) => {
	const [openDialog, setOpen] = useState(false)
	const [customFieldData, setNewCustomField] = useState<ActualCustomFieldValue>({} as ActualCustomFieldValue)
	const { triggerValidation, validation, clearValidation } = useValidation()
	const handleOpenDialog = () => {
		setOpen(true)
		if (!edit) {
			customFieldData.display_name = ""
		}
		setNewCustomField(lastState => ({
			...lastState,
			...actualCustomFieldInfos
		}))
	}

	const handleCloseDialog = () => {
		setOpen(false)
	}

	const handleChangeNewClientField = async (value: string | number, field: keyof ActualCustomFieldValue) => {
		clearValidation(field)
		setNewCustomField(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const treatmentVariableFormatedValue = (variable = "") => slugify(variable, {
		remove: /[^a-zA-Z_ ]/g,
		replacement: "_"
	})

	const onCustomFieldSave = async () => {
		const { display_name, id, custom_field_group_id, variable } = customFieldData
		const treatedCustomFieldVariable = treatmentVariableFormatedValue(display_name).toLowerCase()
		try {
			if (edit) {
				await ApiService.put(`/custom-fields/${id}`, {
					display_name: display_name,
					custom_field_group_id: custom_field_group_id,
					variable: variable,
					type: "text"

				})
			} else {
				await ApiService.post("/custom-fields", {
					display_name: display_name,
					custom_field_group_id: custom_field_group_id,
					variable: treatedCustomFieldVariable,
					type: "text"
				})
			}
			handleCloseDialog()
			onDataChange()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}
	const handleRenderAlert = () => {
		if (edit && actualCustomFieldInfos && actualCustomFieldInfos.variable !== customFieldData.variable) {
			return true
		}
		return false
	}
	const customStyle = useStyles()
	const customClasses = useCustomStyles()

	return (
		<>
			<div
				onClick={handleOpenDialog}
			>
				{children}
			</div>
			<ActionDialog
				onClose={handleCloseDialog}
				openDialog={openDialog}
				title={"ADICIONAR UM CAMPO PERSONALIZADO"}
				fullWidth
				cancelText="CANCELAR"
				onSave={onCustomFieldSave}
			>
				<Grid container direction="column" spacing={4}>
					<Grid item>
						<InputLabel className={customClasses.inputLabel}>Nome do campo:</InputLabel>
						<Divider size={2} orientation="horizontal" />
						<TextField
							name="display_name"
							variant="outlined"
							defaultValue={edit && customFieldData.display_name}
							placeholder="Nome"
							onChange={
								(({ target }) => {
									handleChangeNewClientField(target.value, "display_name")
								}
								)
							}
							fullWidth
							helperText={validation.display_name}
							error={!!validation.display_name}
						/>
					</Grid>
					<Grid item>

						<InputLabel className={customClasses.inputLabel}>Grupo:</InputLabel>
						<Divider size={2} orientation="horizontal" />
						<FormControl variant="outlined" fullWidth>
							<Select
								labelId="group_field"
								id="group_field"
								fullWidth
								displayEmpty
								defaultValue={actualCustomFieldInfos ? actualCustomFieldInfos?.custom_field_group_id : undefined}
								onChange={(({ target }) => {
									handleChangeNewClientField(target.value as number, "custom_field_group_id")
								})}
								renderValue={(selected) => {
									if (selected === undefined) {
										return <p className={customStyle.selectedGroupPlaceholder}>Selecione um grupo</p>
									}
									const customField = customFieldGroupList?.filter((customField) => customField.id === selected)
									if (customField) {
										return customField[0].display_name
									}
								}}
								error={!!validation.custom_field_group_id}
							>
								{customFieldGroupList && customFieldGroupList.map((customFieldGroup) => {
									return <MenuItem key={customFieldGroup.id} value={customFieldGroup.id}>{customFieldGroup.display_name}</MenuItem>
								})}
							</Select>
							{validation.custom_field_group_id && <FormHelperText error >{validation.custom_field_group_id}</FormHelperText>}
						</FormControl>
					</Grid>
					<Grid item>
						<Divider size={2} orientation="horizontal" />
						<Box className={customStyle.variableBox} sx={{ display: "flex", flexDirection: "row" }}>
							<InputLabel className={customClasses.inputLabel} >Variável:</InputLabel>
							<InfoIcon />
						</Box>
						<Box
							component="div"
							sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
						>
							<TextField className={customStyle.inputResetPadding}
								name="variable"
								variant="outlined"
								defaultValue={edit && customFieldData.variable}
								value={edit === true ? customFieldData.variable : treatmentVariableFormatedValue(customFieldData.display_name).toLowerCase()}
								onChange={(({ target }) => {
									handleChangeNewClientField(treatmentVariableFormatedValue(target.value), "variable")
								})}
								placeholder="nome"
								fullWidth
								InputProps={{
									startAdornment: (
										<span className={customStyle.bracketLeft}>
											&#123;&#123;
										</span>
									),
									endAdornment: (
										<span className={customStyle.bracketRight}>
											&#125;&#125;
										</span>
									)
								}}
								helperText={validation.variable}
								error={!!validation.variable}
							/>
						</Box>
					</Grid>
					{handleRenderAlert() && <Grid item>
						<Grid container direction="row" spacing={2}>
							<Grid item className={customStyle.alterationAlert} xs={2}>
								<img className="alert-content" src={vertical_yellow_bar} alt="alert-bar" style={{ width: 8 }} />
								<img src={alert_signal} alt="alert" style={{ marginRight: 5 }} />
							</Grid>
							<Grid item xs={10}>
								<InputLabel className={customClasses.inputLabel}>ATENÇÃO</InputLabel>
								<Typography style={{ fontSize: 14 }}>
									Essa variável está sendo utilizada em outras áreas do sistema,
									portanto, se for feita qualquer alteração, ela não funcionará corretamente nessas áreas.
								</Typography>
								<Typography style={{ fontSize: 14, fontWeight: 500, marginTop: 16 }}>
									Se realmente deseja fazer a alteração, atente-se em alterar o nome da variável nas áreas onde ela está sendo utilizada.
								</Typography>
							</Grid>

						</Grid>
					</Grid>}
				</Grid>
			</ActionDialog>
		</>
	)
}

export default ManageCustomField
