import React, { useState } from "react"
import {
	Typography
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import { ActionDialog } from "@/components"

type PopConfirmProps = {
	description?: string
	confirmButtonText: string
	cancelButtonText?: string
	onConfirm: () => Promise<void> | void
	title?: string
}

type PopConfirmType = {
	open: (props: PopConfirmProps) => void
}

const PopConfirm: PopConfirmType & React.FC<PopConfirmProps> = (props) => {
	const { description, onConfirm, confirmButtonText, title, cancelButtonText } = props

	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleClose = () => {
		setOpened(false)
	}

	const handleOk = async () => {
		setLoading(true)

		await onConfirm()

		setLoading(false)

		handleClose()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	return (
		<ActionDialog
			title={title || ""}
			onSave={handleOk}
			saveText={confirmButtonText}
			cancelText={cancelButtonText}
			onClose={handleClose}
			openDialog={opened}
			loading={loading}
			fullWidth
		>
			<Typography>
				{description || ""}
			</Typography>
		</ActionDialog>
	)
}

PopConfirm.open = (props: PopConfirmProps) => {
	renderComponent(
		"pop-confirm",
		<PopConfirm
			{...props}
		/>
	)
}

export default PopConfirm
