import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	attendantManagerCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	filterInput: {
		backgroundColor: colors.grayScale[11],
		"& fieldset": {
			borderRadius: 3
		}
	},
	attendantManagerCardTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1)
	},
	attendantManagerCardContent: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	}
}))

export default useStyles
