import React from "react"

import {
	MessageContentBox
} from "@/components"

import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"

import { getMessageColor } from "@/utils/message"

type MessageBlockProps = {
	message?: string
}

const MessageBlock: React.FC<MessageBlockProps> = (props) => {
	const { message } = props

	const convertToHtml = () => {
		if (!message) {
			return "A pré visualização da sua mensagem aparecerá aqui..."
		}

		return translateWhatsAppSyntaxToHTML(message)
	}

	return (
		<MessageContentBox
			style={{
				backgroundColor: getMessageColor(true)
			}}
		>
			{convertToHtml()}
		</MessageContentBox>
	)
}

export default MessageBlock
