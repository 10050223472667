import React from "react"

import { IntegrationType } from "@/protocols/integration"

import ActiveCampaignLogo from "@/assets/images/integrations/activecampaign.png"
import DirectIntegrationLogo from "@/assets/images/integrations/letalk.png"
import YampiLogo from "@/assets/images/integrations/yampi.png"
import HotmartLogo from "@/assets/images/integrations/hotmart.png"
import HotmartV2Logo from "@/assets/images/integrations/hotmartv2.png"
import RDStationLogo from "@/assets/images/integrations/rd-station.png"
import HubSpotLogo from "@/assets/images/integrations/hubspot.png"

type IntegrationInfo = {
	[key in IntegrationType]: {
		logo: string
		description: string
		tutorialUrl: string
		title: string
		customStyles?: React.CSSProperties,
	}
}

export const integrationInfo: IntegrationInfo = {
	ActiveCampaign: {
		logo: ActiveCampaignLogo,
		description: "Integração com o ActiveCampaign",
		title: "ActiveCampaign",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=ES8Pj7PioOQ"
	},
	DirectIntegration: {
		logo: DirectIntegrationLogo,
		description: "Integração direta com a Letalk",
		title: "Integração direta com a Letalk",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=WVfjkS1hMKs",
		customStyles: {
			padding: "0px 15px 0px 15px",
			height: "35px"
		}
	},
	Yampi: {
		logo: YampiLogo,
		description: "Integração com a Yampi",
		title: "Yampi",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=54NtADi2ftU"

	},
	Hotmart: {
		logo: HotmartLogo,
		description: "Integração com a Hotmart",
		title: "Hotmart V1",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=5TUvlIhGB6M"
	},
	RDStation: {
		logo: RDStationLogo,
		description: "Integração com a RD Station",
		title: "RD Station",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=5TUvlIhGB6M"

	},
	HotmartV2: {
		logo: HotmartV2Logo,
		description: "Integração com a Hotmart V2",
		title: "Hotmart V2",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=5TUvlIhGB6M"
	},
	HubSpot: {
		logo: HubSpotLogo,
		description: "Integração com a HubSpot",
		title: "HubSpot",
		tutorialUrl: ""
	}
}
