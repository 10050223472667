import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	headerTitle: {
		fontWeight: "bolder",
		color: colors.grayScale[11],
		textTransform: "uppercase"
	},
	header: {
		background: colors.palette.button1
	}
})

export default useStyles
