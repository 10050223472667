import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	notesCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	notesCardTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1)
	},
	note: {
		padding: "0px",
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(3)
	},
	noteContent: {
		fontSize: "15px",
		padding: "0px",
		marginTop: "0px"
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "340px",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 3,
		wordWrap: "break-word"
	},
	button: {
		color: colors.grayScale[1]
	},
	iconButton: {
		padding: "0",
		margin: "0",
		minHeight: "20px"
	}
}))

export default useStyles
