import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	searchInputContainer: {
		backgroundColor: "#F6F6F6",
		padding: theme.spacing(2)
	},
	input: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	},
	list: {
		overflowY: "scroll",
		height: "100%",
		maxHeight: "calc(100vh - 120px)" // 120px is the sum of the drawerHeader and inputContainer heights
	},
	badgeContainer: {
		width: 22,
		height: 22,
		backgroundColor: colors.grayScale[11],
		borderRadius: "100%",
		border: `3px solid ${colors.grayScale[11]}`
	},
	chatDescription: {},
	addContactAvatar: {
		backgroundColor: colors.palette.confirm
	},
	iconButton: {
		padding: "6px"
	}
}))

export default useStyles
