import React from "react"
import { Grid, Typography } from "@material-ui/core"
import howlerAudioMp3 from "@/assets/sounds/howler_audio_test.mp3"
import howlerAudioOgg from "@/assets/sounds/howler_audio_test.ogg"
import letalkAudioOgg from "@/assets/sounds/audioLetalk.ogg"
import letalkAudioMp3 from "@/assets/sounds/audioLetalk.mp3"
import useStyles from "@/components/Messages/MediaMessage/AudioPlayer/styles"

const AudioTest: React.FC = () => {
	const classes = useStyles()

	return (
		<Grid container>

			<Grid
				item
			>
				<Typography>
					Howler ogg AWS
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={"https://inbox-data.s3.us-west-2.amazonaws.com/whatsapp/89dce420-8664-487f-ae96-749abb4dac0b/test/howler_audio_test.ogg"}
						type="audio/ogg"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Howler mp3 AWS
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={"https://inbox-data.s3.us-west-2.amazonaws.com/whatsapp/89dce420-8664-487f-ae96-749abb4dac0b/test/howler_audio_test.mp3"}
						type="audio/mp3"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Letalk ogg AWS
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={"https://inbox-data.s3.us-west-2.amazonaws.com/whatsapp/89dce420-8664-487f-ae96-749abb4dac0b/test/audioLetalk.ogg"}
						type="audio/ogg"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Letalk mp3 AWS
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={"https://inbox-data.s3.us-west-2.amazonaws.com/whatsapp/89dce420-8664-487f-ae96-749abb4dac0b/test/audioLetalk.mp3"}
						type="audio/mp3"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Howler ogg local
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={howlerAudioOgg}
						type="audio/ogg"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Howler mp3 local
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={howlerAudioMp3}
						type="audio/mp3"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Letalk ogg local
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={letalkAudioOgg}
						type="audio/ogg"
					/>
				</audio>
			</Grid>

			<Grid
				item
			>
				<Typography>
					Letalk mp3 local
				</Typography>
				<audio
					controls
					preload="metadata"
					className={classes.audioPlayer}
				>
					<source
						src={letalkAudioMp3}
						type="audio/mp3"
					/>
				</audio>
			</Grid>

		</Grid>
	)
}

export default AudioTest
