import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	messageDeletedContainer: {
		"& > *": {
			color: "rgba(0, 0, 0, 0.5)"
		}
	},
	messageDeletedText: {
		fontStyle: "italic"
	}
})

export default useStyles
