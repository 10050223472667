
import colors from "@/styles/colors"
import { formatDateInBrazilianDate, getBrazilianWeekDay, isToday, isYesterday } from "@/utils/time"
import { Divider, Portlet } from "@/components"
import { Grid, Typography, Divider as MuiDivider, Box } from "@material-ui/core"
import React, { useEffect, useState, useRef } from "react"
import useStyles from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/DailyAttendancesChart/styles"
import { chartColors } from "@/pages/Admin/Dashboard/Metrics/styles"
import { AttendancesCount } from "@/protocols/metrics"
import {
	XAxis,
	VerticalBarSeries,
	Hint,
	VerticalBarSeriesPoint,
	YAxis,
	HorizontalGridLines,
	FlexibleWidthXYPlot
} from "react-vis"
import useCustomStyles from "@/styles/custom"

type AttendancesHistogram = Array<{
	day: Date
	attendancesCount: AttendancesCount
}>

type ChartData = {
	enqueued: VerticalBarSeriesPoint[]
	onGoing: VerticalBarSeriesPoint[]
	finished: VerticalBarSeriesPoint[]
}

type ChartHoverData = {
	pointData: VerticalBarSeriesPoint,
	fullData: {
		enqueued: VerticalBarSeriesPoint
		onGoing: VerticalBarSeriesPoint
		finished: VerticalBarSeriesPoint
		total: number
		date: string
	}
} | null

type DailyAttendancesChartProps = {
	attendancesHistogram?: AttendancesHistogram
}

const DailyAttendancesChart: React.FC<DailyAttendancesChartProps> = ({ attendancesHistogram }) => {
	const [chartData, setChartData] = useState<ChartData>({
		enqueued: [],
		onGoing: [],
		finished: []
	})
	const [chartHoverData, setChartHoverData] = useState<ChartHoverData>()

	const classes = useStyles()
	const customClasses = useCustomStyles()

	// eslint-disable-next-line
	const updateChartData = useRef((histogramData?: AttendancesHistogram) => { })
	updateChartData.current = (histogramData?: AttendancesHistogram) => {
		const data: ChartData = {
			enqueued: [],
			onGoing: [],
			finished: []
		}

		histogramData?.forEach(dailyAttendances => {
			const day = new Date(dailyAttendances.day)
			let x: string

			if (isToday(day)) {
				x = "Hoje"
			} else if (isYesterday(day)) {
				x = "Ontem"
			} else {
				x = getBrazilianWeekDay(day)
			}

			data.enqueued.push({
				x,
				y: dailyAttendances.attendancesCount.enqueued,
				date: day
			})

			data.onGoing.push({
				x,
				y: dailyAttendances.attendancesCount.onGoing,
				date: day
			})

			data.finished.push({
				x,
				y: dailyAttendances.attendancesCount.finished,
				date: day
			})
		})

		setChartData(data)
	}

	const handleChangeChartHoverData = (pointData: VerticalBarSeriesPoint | null) => {
		if (!pointData) {
			setChartHoverData(null)
			return
		}

		const x = pointData?.x

		const fullData = {
			enqueued: chartData.enqueued.find((value) => value.x === x) as VerticalBarSeriesPoint,
			onGoing: chartData.onGoing.find((value) => value.x === x) as VerticalBarSeriesPoint,
			finished: chartData.finished.find((value) => value.x === x) as VerticalBarSeriesPoint
		}

		const total = (fullData?.enqueued?.y || 0) +
			(fullData?.onGoing?.y || 0) +
			(fullData?.finished?.y || 0)
		const date = formatDateInBrazilianDate(pointData.date)

		setChartHoverData({ pointData, fullData: { ...fullData, total, date } })
	}

	const getSeriesDataWithOpacity = (data: VerticalBarSeriesPoint[]) => {
		if (chartHoverData?.pointData) {
			const x = chartHoverData.pointData.x

			return data.map((point) => {
				if (point.x !== x) {
					return {
						...point,
						opacity: 0.65
					}
				}
				return point
			})
		}

		return data
	}

	useEffect(() => {
		updateChartData.current(attendancesHistogram)
	}, [attendancesHistogram, updateChartData])

	return (
		<Portlet style={{ height: "100%", minHeight: "300px" }}>
			<Grid container spacing={2} direction="column" justify="space-between">
				<Grid item>
					<Typography variant="h2" className={customClasses.uppercase}>
						Diário
					</Typography>
				</Grid>

				<Grid item >
					<FlexibleWidthXYPlot
						height={350}
						xType="ordinal"
						stackBy="y"
						onMouseLeave={() => handleChangeChartHoverData(null)}
						animation
					>
						<XAxis
							hideLine
							style={{
								text: { fill: colors.grayScale[5], fontSize: "14px" },
								ticks: { stroke: "transparent" }
							}}
						/>
						<YAxis style={{ text: { fill: colors.grayScale[5], fontSize: "14px" } }} />
						<HorizontalGridLines />

						<VerticalBarSeries
							barWidth={0.8}
							color={chartColors.finished}
							data={getSeriesDataWithOpacity(chartData.finished)}
							onNearestX={handleChangeChartHoverData}
						/>
						<VerticalBarSeries
							barWidth={0.8}
							color={chartColors.onGoing}
							data={getSeriesDataWithOpacity(chartData.onGoing)}
						/>
						<VerticalBarSeries
							barWidth={0.8}
							color={chartColors.enqueued}
							data={getSeriesDataWithOpacity(chartData.enqueued)}
						/>

						{chartHoverData?.pointData && (
							<Hint
								value={chartHoverData.pointData}
							>
								<Grid
									container
									direction="column"
									alignItems="stretch"
									justify="center"
									className={classes.plotHint}
								>
									<Grid item style={{ alignSelf: "center" }}>
										<Typography variant="h4" color="textPrimary">
											{chartHoverData.fullData.date}
										</Typography>
									</Grid>

									<Divider orientation="horizontal" size={1.5} />

									<MuiDivider />

									<Divider orientation="horizontal" size={1.5} />

									<Grid item>
										<Box display="flex" alignItems="center">
											<Box
												width={14}
												height={14}
												bgcolor={chartColors.enqueued}
												mr={1}
												borderRadius="50%"
											/>

											<Typography variant="overline" color="textPrimary">
												<b>Na fila:</b> {chartHoverData.fullData.enqueued.y}
											</Typography>
										</Box>
									</Grid>

									<Grid item>
										<Box display="flex" alignItems="center">
											<Box
												width={14}
												height={14}
												bgcolor={chartColors.onGoing}
												mr={1}
												borderRadius="50%"
											/>

											<Typography variant="overline" color="textPrimary">
												<b>Em andamento:</b> {chartHoverData.fullData.onGoing.y}
											</Typography>
										</Box>
									</Grid>

									<Grid item>
										<Box display="flex" alignItems="center">
											<Box
												width={14}
												height={14}
												bgcolor={chartColors.finished}
												mr={1}
												borderRadius="50%"
											/>

											<Typography variant="overline" color="textPrimary">
												<b>Finalizados:</b> {chartHoverData.fullData.finished.y}
											</Typography>
										</Box>
									</Grid>

									<Divider orientation="horizontal" size={1.5} />

									<MuiDivider />

									<Divider orientation="horizontal" size={1} />

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Total:</b> {chartHoverData.fullData.total} atendimento{chartHoverData.fullData.total > 1 ? "s" : ""}
										</Typography>
									</Grid>
								</Grid>
							</Hint>
						)}
					</FlexibleWidthXYPlot>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default DailyAttendancesChart
