import React, { useState } from "react"

import {
	Grid,
	Avatar,
	Typography,
	IconButton,
	Button,
	CircularProgress,
	Tooltip,
	Popover,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core"

import {
	Forum as InitializeAttendanceIcon,
	ArchiveOutlined as FinishAttendanceIcon,
	ArrowBackIosOutlined as CloseChatIcon,
	MoreVert as MoreOptionsIcon,
	Link as LinkIcon,
	Sync as SyncIcon
} from "@material-ui/icons"

import {
	Divider, InfoDialog, Notification, TextCopy
} from "@/components"

import { AttendanceStatus } from "@/protocols/channel"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/styles"
import useCustomStyles from "@/styles/custom"
import useCustomMemo from "@/hooks/useCustomMemo"

import { isSmallScreen } from "@/utils/checkDevice"

import { keepOnlyDigits } from "@/utils/phone"

import useSocket from "@/hooks/useSocket"

import { formatPhoneNumber } from "@/utils/mask"

import AssignAttendance from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/AssignAttendance"
import { Alert } from "@material-ui/lab"

type ConversationHeaderProps = {
	onClose: () => void
	onClick: () => void
}

const ConversationHeader: React.FC<ConversationHeaderProps> = (props) => {
	const { onClick, onClose } = props

	const chatGlobalStateStore = useChatGlobalStateStore()
	const socket = useSocket()
	const customClasses = useCustomStyles()
	const classes = useStyles()
	const [isSyncChatMessages, setIsSyncChatMessages] = useState<boolean>(false)

	const client = chatGlobalStateStore.client.current
	const whatsappContactPhoneNumber = chatGlobalStateStore.client.getWhatsappPhoneNumber(client)

	const currentChatAttendanceStatus = chatGlobalStateStore.chat.current?.attendance?.status as AttendanceStatus
	const isAbleToFinishAttendance = currentChatAttendanceStatus === "active"
	const isAbleToTakeAttendance = currentChatAttendanceStatus !== "active"
	const isAbleToAssignAttendance = (["active", "waiting"] as AttendanceStatus[]).includes(currentChatAttendanceStatus) && chatGlobalStateStore.chat.current?.type !== "group"

	const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const moreOptionsMenuIsOpen = Boolean(moreOptionsMenuAnchorEl)

	const handleMoreOptionsMenuClose = () => {
		setMoreOptionsMenuAnchorEl(null)
	}

	const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreOptionsMenuAnchorEl(event.currentTarget)
	}

	const handleTakeChatAttendance = async () => {
		await chatGlobalStateStore.attendance.takeOnCurrentChat()
	}

	const handleFinishChatAttendance = async () => {
		try {
			await chatGlobalStateStore.attendance.finishOnCurrentChat()
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}
	}

	const handleSyncCurrentChatMessages = async () => {
		handleMoreOptionsMenuClose()
		const afterDate = new Date()
		afterDate.setDate(afterDate.getDate() - 2)

		const chat = chatGlobalStateStore.chat.current

		setIsSyncChatMessages(true)

		try {
			if (chat) {
				const { channelType, id, inboxChannelId } = chat

				const socketResponse = await socket.scheduleChatMessagesSync({
					channelType,
					inboxChannelChatId: id,
					inboxChannelId,
					afterDate: Number(afterDate)
				})

				if (socketResponse) {
					Notification.success({ message: "As mensagens dessa conversa foram sincronizadas." })
				} else {
					Notification.error({ message: "Não foi possível sincronizar." })
				}
			} else {
				Notification.info({ message: "Conversa não encontrada." })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}

		setIsSyncChatMessages(false)
	}

	const handleShareChatLink = async () => {
		handleMoreOptionsMenuClose()
		const clientContactPhone = client?.contacts?.find(contact => contact.data.type === "user")
		InfoDialog.open({
			title: "COMPARTILHAR LINK",
			openDialog: true,
			children: (
				<Grid
					container
					alignContent="center"
				>
					<Grid item xs={12}>
						<Alert severity="warning" icon={false}>
							Para que o atendente consiga abrir o link e visualizar a conversa, ele precisa estar logado no sistema da Letalk, pois é um <b>link privado</b>.
						</Alert>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<TextCopy
						text={`${window.location.origin}/attendance/chat?phone=${clientContactPhone?.data?.phone}`}
						onSuccessMessage="Link copiado com sucesso!"
					/>
				</Grid>
			)
		})
	}

	const showTitleName = (keepOnlyDigits(client?.nickname || "") === whatsappContactPhoneNumber) && (chatGlobalStateStore.chat.current?.type !== "group")

	return useCustomMemo(() => (
		<>
			{chatGlobalStateStore.chat.current && (
				<>
					{isSmallScreen && (
						<>
							<IconButton
								onClick={() => onClose()}
							>
								<CloseChatIcon />
							</IconButton>

							<Divider orientation="vertical" size={2} />
						</>
					)}

					<Grid item xs>
						<Grid
							container
							spacing={2}
							alignItems="center"
							style={{ cursor: "pointer" }}
							onClick={() => onClick()}
						>

							<Grid item>
								<Avatar src={client?.picture_url} />
							</Grid>

							<Grid item xs>
								<Grid
									container
									direction="column"
									alignItems="flex-start"
								>
									<Typography
										variant="h2"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{client?.nickname}

										{
											showTitleName && (
												<Typography
													variant="caption"
													color="textPrimary"
													className={classes.inlineTextName}
												>
													~{client?.name}
												</Typography>
											)
										}
									</Typography>

									<Typography
										variant="caption"
										color="textPrimary"
										className={customClasses.inlineText}
									>
										{formatPhoneNumber(whatsappContactPhoneNumber)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{isAbleToTakeAttendance && (
						<>
							<Divider orientation="vertical" size={2} />

							{isSmallScreen ? (
								<IconButton
									onClick={handleTakeChatAttendance}
									className={`${classes.attendanceIconButton} ${classes.takeAttendanceButton}`}
								>
									<InitializeAttendanceIcon />
								</IconButton>
							) : (
								<Button
									color="primary"
									variant="contained"
									disableElevation
									onClick={handleTakeChatAttendance}
									className={classes.takeAttendanceButton}
								>
									{currentChatAttendanceStatus === "waiting" ? "ASSUMIR" : "INICIAR"}
									{" "}
									ATENDIMENTO
								</Button>
							)}
						</>
					)}

					{isAbleToFinishAttendance && (
						<>
							<Divider orientation="vertical" size={2} />

							{isSmallScreen ? (
								<IconButton
									onClick={handleFinishChatAttendance}
									className={`${classes.attendanceIconButton} ${classes.finishAttendanceButton}`}
								>
									<FinishAttendanceIcon />
								</IconButton>
							) : (
								<Button
									color="default"
									variant="contained"
									disableElevation
									onClick={handleFinishChatAttendance}
									className={classes.finishAttendanceButton}
								>
									FINALIZAR ATENDIMENTO
								</Button>
							)}
						</>
					)}

					<Divider orientation="vertical" size={2} />

					<Grid>

						{isAbleToAssignAttendance && <AssignAttendance/>}
					</Grid>

					<Grid item>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							onClick={(event) => handleMoreOptionsMenuClick(event)}
						>
							<MoreOptionsIcon />
						</IconButton>

						<Popover
							open={moreOptionsMenuIsOpen}
							anchorEl={moreOptionsMenuAnchorEl}
							onClose={handleMoreOptionsMenuClose}
							anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
							transformOrigin={{ vertical: "bottom", horizontal: "left" }}
						>
							<List>
								<ListItem
									button
									onClick={handleShareChatLink}
									disabled={isSyncChatMessages}
								>
									<LinkIcon/>
									<Divider orientation="vertical" size={1}/>
									<ListItemText primary="Compartilhar (Link)"/>
								</ListItem>
								<Tooltip
									title="Sincronizar mensagens das últimas 48 horas. Antes de sincronizar, certifique-se de que a mensagem esteja no Whatsapp web."
								>
									<ListItem
										button
										onClick={handleSyncCurrentChatMessages}
										disabled={isSyncChatMessages}
									>
										{isSyncChatMessages ? (
											<CircularProgress />
										) : (
											<SyncIcon/>
										)}
										<Divider orientation="vertical" size={1}/>
										<ListItemText primary="Sincronizar Chat"/>
									</ListItem>
								</Tooltip>
							</List>
						</Popover>
					</Grid>

				</>
			)}
		</>
	), [
		moreOptionsMenuIsOpen,
		isSyncChatMessages,
		currentChatAttendanceStatus,
		chatGlobalStateStore.chat.current?.status,
		chatGlobalStateStore.chat.current?.title,
		chatGlobalStateStore.client.current?.picture_key
	])
}

export default ConversationHeader
