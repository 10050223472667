import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: 500
	},
	helpLink: {
		marginTop: theme.spacing(0.5)
	},
	menuList: {
		paddingTop: 0
	}
}))

export default useStyles
