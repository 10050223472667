import React, { useRef } from "react"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"

import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"
import useDidMount from "@/hooks/useDidMount"
import useAsyncState from "@/hooks/useAsyncState"

import {
	Notification
} from "@/components"

import QRCodeWaitingDialog, { QRCodeWaitingDialogHandler } from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeWaitingDialog"

const EXPECTED_CONNECTION_TIME_IN_SECONDS = 300

type QRCodeScannedProps = ConnectionFlowComponentDefaultProps

const QRCodeScanned: React.FC<QRCodeScannedProps> = (props) => {
	const {
		resetSteps,
		goToNextStep
	} = props

	const whatsappConnection = useWhatsappConnection()
	const qrCodeScannedDialogRef = useRef<QRCodeWaitingDialogHandler>(null)

	const [whatsappAuthenticated, setWhatsappAuthenticated] = useAsyncState(false)

	const handleGoToNextStep = async () => {
		await qrCodeScannedDialogRef.current?.finish?.()

		goToNextStep()
	}

	const onQRCodeWaitingTimeout = () => {
		Notification.warning({
			message: "O tempo limite para conexão chegou ao fim. Por favor gere um novo QR Code."
		})

		resetSteps()
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onWhatsappAuthenticated(async () => {
			await setWhatsappAuthenticated(() => true)

			if (whatsappAuthenticated.current) {
				handleGoToNextStep()
			}
		})
	}

	const onQRCodeScannedDialogClose = () => {
		resetSteps()
	}

	useDidMount(() => {
		addWhatsappEventListeners()
	})

	return (
		<QRCodeWaitingDialog
			title="Conectando"
			onClose={onQRCodeScannedDialogClose}
			onTimeout={onQRCodeWaitingTimeout}
			waitingTimeInSeconds={EXPECTED_CONNECTION_TIME_IN_SECONDS}
			ref={qrCodeScannedDialogRef}
			opened
		/>
	)
}

export default QRCodeScanned
