import React, { ReactNode, useState } from "react"
import {
	Grid,
	DialogContent,
	Button
} from "@material-ui/core"
import {
	Add as PlusSignal
} from "@material-ui/icons"
import ManageCustomField from "@/pages/Admin/CustomFields/ManageCustomField"
import useStyles from "@/pages/Admin/CustomFields/styles"
import { ActionDialog, Loading } from "@/components"
import ApiService from "@/services/Api"
import GroupListVariable from "@/pages/Admin/CustomFields/GroupListVariable"
import CustomFieldIntroduction from "@/pages/Admin/CustomFields/CustomFieldIntroduction"
import { CustomFieldGroupList } from "@/protocols/customField"
import { ErrorType } from "@/hooks/useValidation"
import useCustomStyles from "@/styles/custom"
import ErrorHandlerService from "@/services/ErrorHandler"
import CustomFieldGroupsSkeleton from "@/skeletons/Admin/CustomFieldSkeletons"
import ManageCustomFieldGroup from "./ManageCustomFieldGroup"

export type CustomFieldDialog = {
	children: ReactNode
}
const CustomFieldsDialog: React.FC<CustomFieldDialog> = ({ children }) => {
	const [openCustomFieldPanel, setOpenCustomFieldPanel] = useState(false)
	const [loading, setLoading] = useState(false)
	const [customFieldGroupList, setCustomFiedlGroupList] = useState<CustomFieldGroupList[]>()

	const handleCustomFieldCloseDialog = () => {
		setOpenCustomFieldPanel(false)
	}

	const handleGetCustomFieldsGroupData = async () => {
		try {
			const { data } = await ApiService.get("/custom-fields/group")
			setCustomFiedlGroupList(data.customFieldsGroups)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const onDataChange = async () => {
		await handleGetCustomFieldsGroupData()
		setLoading(false)
	}

	const handleCustomFieldOpenPanelDialog = () => {
		setLoading(true)
		onDataChange()
		setOpenCustomFieldPanel(true)
	}

	const customStyles = useStyles()
	const customClasses = useCustomStyles()
	return (
		<>
			<div
				onClick={handleCustomFieldOpenPanelDialog}
			>
				{children}
			</div>
			<ActionDialog
				onClose={handleCustomFieldCloseDialog}
				openDialog={openCustomFieldPanel}
				title={"PAINEL DE CAMPOS PERSONALIZADOS"}
				fullWidth
				maxWidth="lg"
				hideCloseButton={true}
			>
				<DialogContent style={{ height: 655 }}>
					<Grid container alignItems="center" spacing={2}>
						<Grid item>
							<ManageCustomField onDataChange={onDataChange} customFieldGroupList={customFieldGroupList}>
								<Button variant="contained" className={customClasses.buttonAction} startIcon={<PlusSignal />}>ADICIONAR CAMPO PERSONALIZADO</Button>
							</ManageCustomField>
						</Grid>
						<Grid item>
							<ManageCustomFieldGroup onDataChange={onDataChange}>
								<Button className={customStyles.buttonAction} startIcon={<PlusSignal />}>ADICIONAR UM NOVO GRUPO</Button>
							</ManageCustomFieldGroup>
						</Grid>
					</Grid>
					<Loading loading={loading} customLoadingElement={<CustomFieldGroupsSkeleton />}>
						{!loading && <Grid container direction="column" justifyContent="center">
							{customFieldGroupList && customFieldGroupList.length > 0 ? <GroupListVariable groupList={customFieldGroupList} onDataChange={onDataChange} /> : <CustomFieldIntroduction />}
						</Grid>}
					</Loading>

				</DialogContent>
			</ActionDialog>
		</>
	)
}

export default CustomFieldsDialog
