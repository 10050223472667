import React, { useState } from "react"
import {
	Grid,
	Typography,
	Button
} from "@material-ui/core"
import {
	ArrowDownward as ArrowDownIcon,
	CropFreeOutlined as GenerateQRCodeIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider,
	ActionDialog
} from "@/components"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"

import QRCodeInstruction from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeInstruction"

import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/GenerateQRCode/styles"

import { useGlobalStateStore } from "@/store/GlobalState"

type GenerateQRCodeProps = ConnectionFlowComponentDefaultProps & {
	startQRCodeGeneration: () => Promise<boolean>
}

const GenerateQRCode: React.FC<GenerateQRCodeProps> = (props) => {
	const {
		startQRCodeGeneration,
		goToNextStep
	} = props

	const globalStateStore = useGlobalStateStore()
	const classes = useStyles()
	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const [openDeactivatedAccountDialog, setOpenDeactivatedAccountDialog] = useState(false)

	const handleOpenDeactivatedAccountDialog = () => {
		setOpenDeactivatedAccountDialog(true)
	}

	const handleCloseDeactivatedAccountDialog = () => {
		setOpenDeactivatedAccountDialog(false)
	}

	const handleGenerateQRCode = () => {
		const isSubscriptionExpired = globalStateStore.instance?.subscriptionData?.tolerance_date && new Date(globalStateStore.instance?.subscriptionData?.tolerance_date) < new Date()

		if (isSubscriptionExpired) {
			handleOpenDeactivatedAccountDialog()
		} else {
			startQRCodeGeneration()
			goToNextStep()
		}
	}

	return (
		<Portlet
			className={whatsappConnectionFlowClasses.qrCodePortlet}
		>
			<ActionDialog
				title="Conta desativada"
				openDialog={openDeactivatedAccountDialog}
				onClose={handleCloseDeactivatedAccountDialog}
				fullWidth
			>
				<Typography style={{ alignItems: "center", verticalAlign: "baseline" }}>
					A sua conta está desativada, procure nosso financeiro para maiores detalhes.
				</Typography>
			</ActionDialog>
			<QRCodeInstruction>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					className={classes.generateQRCodeContent}
				>
					<Typography
						variant="h2"
						align="center"
						className={classes.generateQRCodeText}
					>
						GERE O QR CODE PARA CONECTAR O SEU WHATSAPP
					</Typography>

					<Divider orientation="horizontal" size={1} />

					<ArrowDownIcon
						className={classes.arrowDownIcon}
					/>

					<Divider orientation="horizontal" size={1} />

					<Button
						startIcon={(
							<GenerateQRCodeIcon />
						)}
						variant="contained"
						color="primary"
						onClick={handleGenerateQRCode}
					>
						GERAR QR CODE
					</Button>
				</Grid>
			</QRCodeInstruction>
		</Portlet>
	)
}

export default GenerateQRCode
