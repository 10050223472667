import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	description: {
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4],
		fontWeight: 400,
		fontSize: "20px",
		lineHeight: "30px"
	},
	eventTrigger: {
		fontFamily: "IBM Plex Sans",
		color: colors.palette.primary,
		fontWeight: 500
	},
	descriptionTrigger: {
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4],
		fontWeight: 600,
		fontSize: "24px"
	},
	title: {
		fontFamily: "IBM Plex Sans",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "32px",
		lineHeight: "24px",
		color: colors.grayScale[4]
	}
}))

export default useStyles
