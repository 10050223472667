import React, { ReactNode } from "react"
import {
	Grid,
	IconButton,
	Typography
} from "@material-ui/core"

import {
	ArrowBackIosOutlined as ArrowBackIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Attendance/Chat/HeaderDrawer/styles"

type HeaderDrawerProps = {
	title: string
	icon?: ReactNode
	onClose: () => void
}

const HeaderDrawer = (props: HeaderDrawerProps) => {
	const { title, icon, onClose } = props

	const classes = useStyles()

	const handleCloseDrawer = () => {
		onClose()
	}

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.drawerHeader}
		>
			<IconButton
				className={classes.closeButton}
				onClick={handleCloseDrawer}
			>
				{
					icon || <ArrowBackIcon />
				}
			</IconButton>

			<Grid item>
				<Typography
					variant="h2"
					color="textPrimary"
					style={{ fontSize: "15px" }}
				>
					{title}
				</Typography>
			</Grid>

		</Grid>
	)
}

export default HeaderDrawer
