import React from "react"
import { Redirect, Route } from "react-router-dom"

import Integration from "@/pages/Auth/Integration"
import SignUp from "@/pages/Auth/SignUp"
import SignIn from "@/pages/Auth/SignIn"
import RequestRecoveryPassword from "@/pages/Auth/RecoveryPassword/Request"
import ResetRecoveryPassword from "@/pages/Auth/RecoveryPassword/Reset"
import UserRedirect from "@/pages/Auth/UserRedirect"
import Logout from "@/pages/Auth/Logout"
import Optout from "@/pages/Optout"

const PublicRoutes = [
	<Route
		exact
		key="home"
		path="/"
		render={() => (
			<Redirect to="user-redirect" />
		)}
	/>,
	<Route
		exact
		key="user-redirect"
		path="/user-redirect"
		component={UserRedirect}
	/>,
	<Route
		exact
		key="user-signin"
		path="/signin"
		component={SignIn}
	/>,
	<Route
		exact
		key="user-signup"
		path="/signup"
		component={SignUp}
	/>,
	<Route
		exact
		key="recovery-request"
		path="/recovery/request"
		component={RequestRecoveryPassword} />,
	<Route
		exact
		key="recovery-reset"
		path="/recovery/reset"
		component={ResetRecoveryPassword} />,
	<Route
		key="account-auth"
		path="/login/account-integration/:account_instance_id"
		component={Integration}
	/>,
	<Route
		exact
		key="instance-invite"
		path="/invite/:invitation_code"
		render={(props) => (
			<Redirect to={`/signup?invitation_code=${props.match.params.invitation_code}`} />
		)}
	/>,
	<Route
		exact
		key="optout"
		path="/optout/:hash"
		component={Optout}
	/>,
	<Route
		exact
		key="logout"
		path="/logout"
		component={Logout}
	/>
]

export default PublicRoutes
