import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	copyButton: {
		height: "100%",
		width: "100%",
		fontSize: "14px",
		lineHeight: "24px",
		borderRadius: "0px 6px 6px 0px",
		marginLeft: "-3px"
	},
	copyInput: {
		borderRadius: "6px 0px 0px 6px"
	}
})

export default useStyles
