import React, { useState } from "react"
import {
	Drawer as MaterialDrawer,
	DrawerProps as MaterialDrawerProps
} from "@material-ui/core"

import { useAccessibleDrawerStateStore, OnMobileCloseFunction } from "@/store/AccessibleDrawerState"
import useCustomMemo from "@/hooks/useCustomMemo"

import { isSmallScreen } from "@/utils/checkDevice"

type AccessibleDrawerProps = MaterialDrawerProps & {
	onMobileBackButtonPress: OnMobileCloseFunction
	withoutTriggerHistoryBackEvent?: boolean
}

/**
 * That drawer is capable of being closed by back button press
 * when opened by mobile devices.
 */
const AccessibleDrawer: React.FC<AccessibleDrawerProps> = (props) => {
	const { withoutTriggerHistoryBackEvent, onMobileBackButtonPress, ...rest } = props

	const drawerStateStore = useAccessibleDrawerStateStore()

	const [drawerId] = useState(String(Date.now()))

	const handleMobileClose = () => {
		drawerStateStore.onMobileClose(drawerId, !withoutTriggerHistoryBackEvent)
	}

	const handleMobileOpen = () => {
		drawerStateStore.onMobileOpen(drawerId, onMobileBackButtonPress)
	}

	useCustomMemo(() => {
		if (isSmallScreen) {
			if (props.open) {
				handleMobileOpen()
			} else {
				handleMobileClose()
			}
		}
	}, [
		props.open
	])

	return (
		<MaterialDrawer
			{...rest}
		/>
	)
}

export default AccessibleDrawer
