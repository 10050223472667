import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	todayMetricsBar: {
		filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
		"& g": {
			transform: "translate(0, 0)"
		}
	},
	todayMetricBarPart: {
		"& rect": {
			width: "100%"
		}
	},
	todayMetricsDescriptionItemContainer: {
		width: "auto",
		paddingRight: theme.spacing(4),
		[theme.breakpoints.down("sm")]: {
			paddingBottom: theme.spacing(2)
		}
	},
	todayMetricsDescriptionIconContainer: {
		width: 32,
		height: 32
	},
	hint: {
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(1, 2),
		boxShadow: theme.shadows[1],
		borderRadius: theme.spacing(0.5)
	}
}))

export default useStyles
