import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	Grid,
	Avatar,
	Typography,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Select,
	Divider as MuiDivider
} from "@material-ui/core"
import {
	ExitToApp as LogoutIcon,
	MoreVert as OptionsIcon,
	ImportantDevices as AdminIcon,
	Sync as SyncIcon,
	Settings,
	AccountCircleOutlined as ProfileIcon
} from "@material-ui/icons"

import NewChatButton from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/NewChatButton"
import ProfileInfo from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/ProfileInfo"

import { useGlobalStateStore } from "@/store/GlobalState"

import AuthService from "@/services/Auth"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/styles"

import { deviceIsMobile } from "@/utils/checkDevice"
import { getAvatarSymbol } from "@/utils/string"
import { openUrlOnlyOnce } from "@/utils/url"

import { inboxAppUrl } from "@/config/url"
import UserSettings from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/UserSettings"
import { ActionDialog, Divider, EditableText, Notification } from "@/components"
import { Alert } from "@material-ui/lab"
import useSocket from "@/hooks/useSocket"

type SyncChatsDialogValuesType = {
	loading: boolean,
	open: boolean,
	syncHour: number
}

const ChatListHeader: React.FC = () => {
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const socket = useSocket()

	const [profileInfoOpen, setProfileInfoOpen] = useState<boolean>(false)

	const [userSettingsOpen, setUserSettingsOpen] = useState<boolean>(false)

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const [syncChatsDialogValues, setSyncChatsDialogValues] = useState<SyncChatsDialogValuesType>({
		loading: false,
		open: false,
		syncHour: 0
	})

	const menuOpened = Boolean(anchorEl)

	const userStore = globalStateStore.user
	const instanceStore = globalStateStore.instance

	const handleProfileInfoOpen = () => {
		setProfileInfoOpen(true)
	}

	const handleProfileInfoClose = () => {
		setProfileInfoOpen(false)
	}

	const handleUserSettingsOpen = () => {
		setUserSettingsOpen(true)
	}

	const handleUserSettingsClose = () => {
		setUserSettingsOpen(false)
	}

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleProfileInfo = () => {
		handleCloseMenu()
		handleProfileInfoOpen()
	}

	const handleUserSettingsInfo = () => {
		handleCloseMenu()
		handleUserSettingsOpen()
	}

	const handleSyncChatsMenuOption = () => {
		handleCloseMenu()
		setSyncChatsDialogValues({ ...syncChatsDialogValues, open: true })
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout(userStore.extra_data?.is_account_user)
	}

	const handleRunSyncChats = async () => {
		setSyncChatsDialogValues({ ...syncChatsDialogValues, loading: true })

		try {
			if (syncChatsDialogValues.syncHour && globalStateStore.whatsappChannel) {
				const actDate = new Date()
				actDate.setHours(actDate.getHours() - syncChatsDialogValues.syncHour)

				await socket.scheduleManyChatMessagesSync({
					channelType: globalStateStore.whatsappChannel.channelType,
					inboxChannelId: globalStateStore.whatsappChannel.id,
					afterDate: Number(actDate)
				})

				Notification.success({ message: "O processo de sincronização foi iniciado." })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro ao tentar sincronizar as conversas." })
		}

		setSyncChatsDialogValues({ ...syncChatsDialogValues, loading: false })
	}

	const goToBlankPage = (
		e: React.MouseEvent<HTMLElement>,
		to: string,
		targetName?: string
	) => {
		if (!deviceIsMobile()) {
			openUrlOnlyOnce(e, to, targetName)
		}
	}

	return (
		<>
			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<Grid item xs>
					<Grid
						container
						className={classes.rightContainer}
						wrap="nowrap"
						alignItems="center"
						spacing={2}
					>
						<Grid item>
							<Avatar
								aria-label="more"
								aria-controls="header-menu"
								aria-haspopup="true"
								className={classes.profile}
							>
								{getAvatarSymbol(userStore?.name)}
							</Avatar>
						</Grid>

						<Grid item>
							<Grid
								container
								direction="column"
								alignItems="flex-start"
							>
								<Typography
									variant="h2"
									color="textPrimary"
									className={classes.nameText}
								>
									{userStore?.name}
								</Typography>

								<Typography
									variant="caption"
									color="textPrimary"
									className={classes.emailText}
								>
									{userStore?.email}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item>
					<Grid container>
						<Grid item>
							<NewChatButton />
						</Grid>

						<Grid item>
							<IconButton
								onClick={handleClickMenu}
								className={classes.iconButton}
							>
								<OptionsIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Menu
				id="header-menu"
				anchorEl={anchorEl}
				keepMounted
				open={menuOpened}
				onClose={handleCloseMenu}
				autoFocus={false}
				PaperProps={{
					style: {
						marginTop: 40
					}
				}}
			>
				{ globalStateStore.isBaileysWhatsappChannel &&
					<MenuItem
						onClick={handleSyncChatsMenuOption}
					>
						<ListItemIcon>
							<SyncIcon fontSize="small"/>
						</ListItemIcon>
						<ListItemText primary="Sincronizar Conversas" />
					</MenuItem>
				}

				<MenuItem
					onClick={handleUserSettingsInfo}
				>
					<ListItemIcon>
						<Settings fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Configurações" />
				</MenuItem>

				<MuiDivider />

				{
					instanceStore?.user_in_instance_role?.code === "admin" && (
						<MenuItem
							component={Link}
							to="/admin"
							target={deviceIsMobile() ? "_SELF" : "_BLANK"}
							onClick={(e: React.MouseEvent<HTMLElement>) =>
								goToBlankPage(
									e,
									`${inboxAppUrl.cpURL}/admin`,
									"inbox_admin_page")
							}
						>
							<ListItemIcon>
								<AdminIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Painel do Gestor" />
						</MenuItem>
					)
				}

				<MenuItem
					onClick={handleProfileInfo}
				>
					<ListItemIcon>
						<ProfileIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Meus dados" />
				</MenuItem>

				<MenuItem
					component="a"
					href="#logout"
					onClick={handleLogout}
				>
					<ListItemIcon>
						<LogoutIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Sair" />
				</MenuItem>
			</Menu>

			<ProfileInfo
				openDrawer={profileInfoOpen}
				onClose={handleProfileInfoClose}
			/>

			<UserSettings
				openDrawer={userSettingsOpen}
				onClose={handleUserSettingsClose}
			/>

			<ActionDialog
				title="Sincronização de conversas"
				openDialog={syncChatsDialogValues.open}
				fullWidth
				saveText="SINCRONIZAR"
				onSave={handleRunSyncChats}
				loading={syncChatsDialogValues.loading}
				onClose={ () => setSyncChatsDialogValues({ ...syncChatsDialogValues, open: false })}
				saveButtonDisabled={!syncChatsDialogValues.syncHour}
			>
				<Grid container spacing={4}>
					<Grid item>
						<Alert color="warning" icon={false} style={{ borderRadius: "6px" }}>
							<Typography
								code="attendance_chat_sync_chats_dialog_text"
								skeletonWidth={530}
								skeletonHeight={200}
								component={EditableText}
								errorText="A sincronização é um processo demorado e pode levar vários minutos. Antes de<br/>
								executar, tenha certeza de que é realmente necessário.<br/><br/>

								Indicamos em casos que você tenha recebido a mensagem no celular mas não<br/>
								tenha recebido na Letalk. Apesar de raro, é possível que aconteça. <br/><br/>

								Caso após executar esse processo a mensagem não aparecer, certifique-se de que<br/>
								a mensagem esteja aparecendo no Whatsapp Web. Em alguns casos, a<br/>
								mensagem aparece no celular mas não aparece no Whatsapp Web, o que acarreta<br/>
								nesse problema."
							/>
						</Alert>
					</Grid>
					<Grid item xs={8}>
						<Typography variant="body1">
							<b> Sincronizar as últimas: </b>
						</Typography>

						<Divider size={1} orientation="horizontal" />
						<Select
							value={syncChatsDialogValues.syncHour}
							onChange={(event) => setSyncChatsDialogValues({ ...syncChatsDialogValues, syncHour: event.target.value as number })}
							variant="outlined"
							fullWidth
						>
							<MenuItem value={0} disabled hidden>
								Selecione
							</MenuItem>
							<MenuItem value={12}>
								12 Horas
							</MenuItem>

							<MenuItem value={24}>
								24 Horas
							</MenuItem>

							<MenuItem value={36}>
								36 Horas
							</MenuItem>
						</Select>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default ChatListHeader
