import React, { useEffect, useState } from "react"
import {
	Grid,
	Typography,
	IconButton,
	Box,
	TextField,
	Table,
	TableRow,
	Button,
	TableBody,
	styled,
	TableCell,
	Card,
	Fade
} from "@material-ui/core"
import {
	AddCircleOutline as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"
import {
	Divider,
	Loading,
	ActionDialog,
	Notification,
	PopConfirm,
	InfoDialog
} from "@/components"
import { fullDatetime } from "@/utils/time"
import ErrorHandler from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import ClientNotesSkeleton from "@/skeletons/ClientNotes"
import { useGlobalStateStore } from "@/store/GlobalState"
import useStyles from "@/pages/Admin/NewClientCatalog/ClientProfileInfo/Notes/styles"
import translateWhatsAppSyntaxToHTML from "@/utils/whatsappTranslator"
import { ErrorType } from "@/hooks/useValidation"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover
	},
	"&:last-child td, &:last-child th": {
		border: 0
	}
}))

interface ClientNotesTableProps {
	isExpandedNotes: boolean,
	clientId: number,
	loading: boolean,
	inboxChannelChat?: number
}

export type ClientNoteDataProps = {
	id: number
	content: string,
	user: { id: number, name: string },
	created_at: string,
}

export type ClientNoteDialog = {
	open: boolean,
	status: "create" | "edit",
	selectedNoteId?: number
}

const ClientNotesTable = (props: ClientNotesTableProps) => {
	const {
		clientId,
		loading,
		inboxChannelChat
	} = props

	const globalStateStore = useGlobalStateStore()

	const classes = useStyles()

	const [notes, setNotes] = useState<ClientNoteDataProps[]>([])
	const [allNotes, setAllNotes] = useState<ClientNoteDataProps[]>([])
	const [loadingNotes, setLoadingNotes] = useState(false)
	const [clientNoteDialog, setClientNoteDialog] = useState<ClientNoteDialog>({ open: false, status: "create" })
	const [clientNoteContent, setClientNoteContent] = useState("")
	const [allClientNotesDialog, setAllClientNotesDialog] = useState(false)
	const [isExpandedNotes, setIsExpandedNotes] = useState(props.isExpandedNotes)
	const [showOptionsIconsArray, setShowOptionsIconsArray] = useState<boolean[]>([])

	const handleShowOptionsIcon = (index: number) => {
		const oldShowOptionsIconsArray = showOptionsIconsArray
		oldShowOptionsIconsArray[index] = true
		setShowOptionsIconsArray([...oldShowOptionsIconsArray])
	}

	const handleHideOptionsIcon = (index: number) => {
		const oldShowOptionsIconsArray = showOptionsIconsArray
		oldShowOptionsIconsArray[index] = false
		setShowOptionsIconsArray([...oldShowOptionsIconsArray])
	}

	const getLimitedClientNotes = async () => {
		setLoadingNotes(true)
		try {
			const { data } = await ApiService.get(`/client-notes/${clientId}`, {
				params: {
					rows: 7
				}
			})
			setShowOptionsIconsArray(Array(...Array(data.notes.length)))

			setNotes(data.notes)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
		setLoadingNotes(false)
	}

	const getAllClientNotes = async () => {
		try {
			const { data } = await ApiService.get(`/client-notes/${clientId}`)

			setShowOptionsIconsArray(Array(...Array(data.notes.length)))

			setAllNotes(data.notes)
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
		}
	}

	const deleteClientNoteByIdWithConfirm = async (noteId: number) => {
		PopConfirm.open(
			{
				onConfirm: async () => {
					setLoadingNotes(true)
					try {
						await ApiService.delete(`/client-notes/${noteId}`)

						await getLimitedClientNotes()
						if (isExpandedNotes) {
							await getAllClientNotes()
						}
					} catch (error) {
						ErrorHandler.handle(error as ErrorType)
					}
					setLoadingNotes(false)
				},
				confirmButtonText: "APAGAR",
				title: "Você realmente deseja apagar essa nota?",
				description: ""
			}
		)
	}

	const handleNoteFormSubmit = async () => {
		try {
			await ApiService.post("/client-notes", {
				content: clientNoteContent,
				clientId,
				inboxChannelChatId: inboxChannelChat
			})
			Notification.success({ message: "Nota criada com sucesso." })
			await getLimitedClientNotes()
			if (isExpandedNotes) {
				await getAllClientNotes()
			}
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Houve um erro no processo de criação da nota." })
		}
	}

	const handleNoteUpdate = async (noteId: number) => {
		try {
			await ApiService.put(`/client-notes/${noteId}`, {
				content: clientNoteContent
			})
			Notification.success({ message: "Nota atualizada com sucesso." })
			await getLimitedClientNotes()
			if (isExpandedNotes) {
				await getAllClientNotes()
			}
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Houve um erro." })
		}
	}

	const handleNoteFormOk = async (noteId?: number) => {
		if (clientNoteDialog.status === "create") {
			await handleNoteFormSubmit()
			setClientNoteDialog({
				...clientNoteDialog,
				open: false
			})
		}
		if (clientNoteDialog.status === "edit" && noteId) {
			await handleNoteUpdate(noteId)
			setClientNoteDialog({
				...clientNoteDialog,
				open: false
			})
		}
	}

	const getClientNoteDialogTitle = () => {
		if (clientNoteDialog.status === "create") {
			return "Criar Nota"
		} else if (clientNoteDialog.status === "edit") {
			return "Editar Nota"
		}

		return "Criar Nota"
	}

	const handleCreateNoteDialog = () => {
		setClientNoteContent("")
		setClientNoteDialog({
			open: true,
			status: "create"
		})
	}

	const openAllClientNotesDialog = async () => {
		setIsExpandedNotes(true)
		setAllClientNotesDialog(true)
		await getAllClientNotes()
	}

	const isNoteOwnerOrAdmin = (userId: number) => {
		return globalStateStore.user.id === userId || globalStateStore.instance.user_in_instance_role.code === "admin"
	}

	useEffect(() => {
		getLimitedClientNotes()
		getAllClientNotes()
		// eslint-disable-next-line
	}, [clientId])

	const renderNotesTable = (notes: ClientNoteDataProps[], isExpandedNotesLocal: boolean) => {
		return <Table style={{ maxHeight: 551 }}>
			<TableBody>
				{notes ? notes.map((note, index) =>
					<StyledTableRow key={note.id}>
						<TableCell
							onMouseEnter={() => handleShowOptionsIcon(index)}
							onMouseLeave={() => handleHideOptionsIcon(index)}
						>
							<Grid container justify="flex-start" className={classes.note}>
								<Grid item xs>
									<Typography
										variant="h2"
										color="textPrimary"
										style={{ fontSize: "13px" }}
									>
										{fullDatetime(new Date(note.created_at))} Por: {note.user.name}
									</Typography>
								</Grid>

								{isNoteOwnerOrAdmin(note.user.id)
									? <Grid item>
										<IconButton
											disabled={loadingNotes}
											className={classes.iconButton}
											style={{ marginRight: "5px" }}
											onClick={() => { deleteClientNoteByIdWithConfirm(note.id) }}
										>
											{
												isExpandedNotes ? isExpandedNotesLocal ? <DeleteIcon fontSize="small" /> : <Grid></Grid> : <Fade in={showOptionsIconsArray[index]}>
													<DeleteIcon fontSize="small" />
												</Fade>
											}
										</IconButton>

										<IconButton
											disabled={loadingNotes}
											className={classes.iconButton}
											onClick={() => {
												setClientNoteContent(note.content)
												setClientNoteDialog({
													status: "edit",
													open: true,
													selectedNoteId: note.id
												})
											}}
										>
											{
												isExpandedNotes ? isExpandedNotesLocal ? <EditIcon fontSize="small" /> : <Grid></Grid> : <Fade in={showOptionsIconsArray[index]}>
													<EditIcon fontSize="small" />
												</Fade>
											}
										</IconButton>
									</Grid> : <Grid item> </Grid>
								}
								<Divider orientation="horizontal" size={0.1} />

								<Grid item>
									<Box
										component={Typography}
										className={`${classes.noteContent} ${isExpandedNotes ? undefined : classes.inlineText}`}
										{...({
											variant: "body1",
											color: "textPrimary"
										})}

									>
										{translateWhatsAppSyntaxToHTML(note.content)}
									</Box>
								</Grid>

							</Grid>
						</TableCell>
					</StyledTableRow>
				)
					: <Grid container justify="center">
						<Grid item>
							<Typography variant="body2" >Nenhuma nota foi adicionada ainda</Typography>
						</Grid>
					</Grid>
				}
			</TableBody>
			<ActionDialog
				title={getClientNoteDialogTitle()}
				onSave={async () => {
					if (clientNoteDialog.status === "create") {
						await handleNoteFormOk()
					} else if (clientNoteDialog.status === "edit") {
						await handleNoteFormOk(clientNoteDialog.selectedNoteId)
					}
				}}
				saveText="PRONTO"
				onClose={() => setClientNoteDialog({
					...clientNoteDialog,
					open: false
				})}
				openDialog={clientNoteDialog.open}
				fullWidth
				loading={loadingNotes}
			>
				<Grid item xs={12}>
					<TextField
						value={clientNoteContent}
						onChange={
							({ target }) => setClientNoteContent(target.value)
						}
						variant="outlined"
						color="primary"
						rows={8}
						multiline
						fullWidth
					/>
				</Grid>
			</ActionDialog>
		</Table>
	}

	return <Grid item style={{ width: "100%" }} >
		<Card className={classes.notesCard}>
			<Loading loading={loading} customLoadingElement={<ClientNotesSkeleton />}>
				<Grid container alignItems="center" justify="space-between">
					<Grid item style={{ width: "100%" }}>
						<Grid className={classes.notesCardTitle} container alignItems="center" justify="space-between">
							<Grid item xs>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: 16, fontWeight: 500 }}
								>
									Adicionar Notas
								</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={handleCreateNoteDialog}>
									<AddIcon />
								</IconButton>

							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={0.5} />
					<Grid item style={{ width: "100%" }}>
						<Grid container spacing={2} justify="center">

							{renderNotesTable(notes, false)}
							<Grid item style={{ display: "flex", height: 50 }} justifyContent="center" alignItems="flex-end">
								<Button
									size="small"
									variant="text"
									color="primary"
									className={classes.button}
									onClick={openAllClientNotesDialog}
								>
									TODAS AS NOTAS
								</Button>
								<InfoDialog
									title={"Todas as Notas"}
									openDialog={allClientNotesDialog}
									onClose={() => {
										setIsExpandedNotes(false)
										setAllClientNotesDialog(false)
									}}
								>
									{renderNotesTable(allNotes, true)}
								</InfoDialog>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Loading>
		</Card>
	</Grid>
}

export default ClientNotesTable
