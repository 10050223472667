import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	titleText: {
		textTransform: "uppercase"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		paddingLeft: theme.spacing(1)
	},
	nameText: {
		fontWeight: "normal"
	}
}))

export default useStyles
