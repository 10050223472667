import CookieService from "@/services/Cookie"
import StorageService from "@/services/Storage"

import authConfig from "@/config/auth"
import ApiService from "@/services/Api"
import { Notification } from "@/components"
import UserService from "@/services/User"

import ErrorHandlerService from "@/services/ErrorHandler"

class AuthService {
	get authTokenKey (): string {
		return authConfig.authTokenKey
	}

	get authToken (): string | null {
		const authToken = CookieService.get(this.authTokenKey) || StorageService.get("account-token") as string

		return authToken
	}

	async isLoggedIn (): Promise<boolean> {
		const isLoggedIn = await UserService.isLoggedIn()

		return isLoggedIn
	}

	async login (): Promise<void> {
		try {
			const userInfo = await UserService.getInfo()

			if (!userInfo) window.location.replace("/signin")

			if (userInfo?.userInInstanceData.user_role_code === "admin") {
				window.location.replace("/admin")
			} else {
				window.location.replace("/attendance")
			}
		} catch (error) {
			ErrorHandlerService.handle(error)

			window.location.replace("/signin")
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async logout (is_account_user: boolean): Promise<void> {
		try {
			await ApiService.delete("/auth/logout")

			window.location.replace("/signin")

			// if (is_account_user) {
			// 	window.location.replace(`${accountAppUrl.cpURL}/signin`)
			// } else {
			// 	window.location.replace("/signin")
			// }
		} catch (error) {
			Notification.error({ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		}
	}

	// This functionality will be temporary,
	// just to fix the problem of retroactive users (BUME 1.0)
	async containInMailingOldBume (email: string | null): Promise<boolean | void> {
		try {
			const { data: containInMailingOldBume } = await ApiService
				.get("/mailing-old-bume/check", {
					params: {
						email
					}
				})

			if (containInMailingOldBume) {
				return true
			} else {
				return false
			}
		} catch (error) {
			Notification
				.error({
					message: "Algo deu errado e não conseguimos identificar. Entre em contato."
				})
		}
	}
}

export default new AuthService()
