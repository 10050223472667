import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	trapContainer: {
		height: "100%",
		outline: "none"
	}
})

export default useStyles
