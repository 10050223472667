import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	titleText: {
		textTransform: "uppercase"
	},
	messageContainer: {
		order: 1,
		[theme.breakpoints.down("sm")]: {
			order: 2
		}
	},
	previewContainer: {
		order: 2,
		[theme.breakpoints.down("sm")]: {
			order: 1
		}
	}
}))

export default useStyles
