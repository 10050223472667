import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	indicatorContainer: {
		height: "100%",
		minHeight: "200px"
	},
	largeText: {
		fontSize: "44px",
		fontWeight: 600
	}
}))

export default useStyles
