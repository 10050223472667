import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(2, 2, 2, 0)
	},
	offlineContainer: {
		backgroundColor: "#FE9E59",
		"& svg": {
			color: "#FE9E59"
		}
	},
	disconnectedContainer: {
		backgroundColor: "#FA685E",
		"& svg": {
			color: "#FA685E"
		}
	},
	unpairedContainer: {
		backgroundColor: "#FBBF27",
		"& svg": {
			color: "#FBBF27"
		}
	},
	messageContainer: {
		flex: 1
	},
	iconContainer: {
		width: 80
	},
	linkText: {
		cursor: "pointer"
	},
	fab: {
		boxShadow: "none",
		width: 40,
		height: 40
	},
	statusDescriptionText: {
		color: colors.grayScale[11],
		fontSize: 14,
		lineHeight: "20px"
	}
}))

export default useStyles
