import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	TableBody,
	TableCell,
	TableRow,
	Avatar,
	Grid,
	Paper,
	TableContainer,
	TableHead,
	Table
} from "@material-ui/core"
import { Divider } from "@/components"

const SkeletonTableRow = () => (
	<TableRow>
		<TableCell>
			<Grid container alignItems="center" >
				<Grid item>
					<Avatar style={{ backgroundColor: "transparent" }}>
						<Skeleton
							width={40}
							height={40}
							variant="circle"
						/>
					</Avatar>
				</Grid>

				<Divider size={2.5} orientation="vertical" />

				<Grid item>
					<Skeleton width={280} height={32} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Skeleton width={120} height={32} />
		</TableCell>

		<TableCell>
			<Grid container spacing={1}>
				<Grid item>
					<Skeleton width={56} height={48} />
				</Grid>

				<Grid item>
					<Skeleton width={56} height={48} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Grid container spacing={1}>
				<Grid item>
					<Skeleton width={32} height={56} />
				</Grid>

				<Grid item>
					<Skeleton width={32} height={56} />
				</Grid>

				<Grid item>
					<Skeleton width={32} height={56} />
				</Grid>
			</Grid>
		</TableCell>
	</TableRow>
)

const ClientCatalogTableSkeleton = () => {
	return (
		<Paper style={{ width: "100%" }}>
			<TableContainer>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								width="400px"
							>
								Cliente
							</TableCell>

							<TableCell
								width="190px"
							>
								WhatsApp
							</TableCell>

							<TableCell
								width="180px"
							>
								Tags
							</TableCell>

							<TableCell
								width="185px"
							/>
						</TableRow>
					</TableHead>
					<TableBody>
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default ClientCatalogTableSkeleton
