import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	loading: {
		color: colors.grayScale[11]
	},
	closeIcon: {
		position: "absolute",
		top: 20,
		right: 10
	},
	button: {
		height: "40px",
		padding: "0px 8px",
		fontSize: "14px"
	}
}))

export default useStyles
