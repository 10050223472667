import React, { Dispatch, SetStateAction, useState } from "react"
import {
	Button,
	IconButton,
	TextField,
	Grid,
	InputAdornment,
	Chip,
	Tooltip
} from "@material-ui/core"
import {
	Search as SearchIcon,
	FilterList as FilterListIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	Divider
} from "@/components"

import { Tag } from "@/protocols/tag"
import { ClientCatalogPagination } from "@/protocols/clientCatalog"
import HardCodedService from "@/services/HardCoded"
import useValidation from "@/hooks/useValidation"
import ContactFilters, { ContactsFiltersType } from "@/pages/Admin/NewClientCatalog/ClientManageFilters/ContactsFilters"
import CreateOrEditContactFilter from "@/pages/Admin/NewClientCatalog/ClientManageFilters/CreateOrEditFilters"
import { ClientCatalogWhereData } from "@/pages/Admin/NewClientCatalog"
import TagService from "@/services/Tag"
import useDidMount from "@/hooks/useDidMount"
import useDebounce from "@/hooks/useDebounce"
type ClientManageContactsProps = {
	setSelectedContactsFilter: Dispatch<SetStateAction<ContactsFiltersType>>
	selectedContactsFilter: ContactsFiltersType
	setClientCatalog: Dispatch<SetStateAction<ClientCatalogPagination>>
	getAndUpdateClientCatalogData: (newWhereData?: Partial<ClientCatalogWhereData>) => Promise<void>
	whereData: ClientCatalogWhereData
	setWhereData: Dispatch<SetStateAction<ClientCatalogWhereData>>
}
const ClientManageContacts: React.FC<ClientManageContactsProps> = ({ selectedContactsFilter, setSelectedContactsFilter, getAndUpdateClientCatalogData, whereData, setWhereData }) => {
	const [openContactFiltersDialog, setOpenContactFiltersDialog] = useState(false)
	const [openEditContactFiltersDialog, setOpenEditContactFiltersDialog] = useState(false)
	const [tags, setTags] = useState<Tag[]>([] as Tag[])
	const { validation, clearValidation } = useValidation()

	const isSelectedContactsFilterNotEmpty = Object.keys(selectedContactsFilter).length !== 0

	const getTags = async () => {
		const data = await TagService.getTags()

		if (data) {
			setTags(data)
		}
	}

	const handleWhereDataChange = (newData: Partial<ClientCatalogWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handleSearchChange = async (searchData: string) => {
		clearValidation("search")
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleOpenFilterDialog = () => {
		setOpenContactFiltersDialog(!openContactFiltersDialog)
	}

	const handleRemoveSelectedContactFilters = async () => {
		setSelectedContactsFilter({} as ContactsFiltersType)

		await getAndUpdateClientCatalogData({ contactsFilters: undefined })
	}
	useDidMount(() => {
		getTags()
	})

	useDebounce(
		async () => getAndUpdateClientCatalogData({ search: whereData?.search }),
		whereData.search,
		2250
	)

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<Grid container spacing={2} alignItems="center" justify="flex-end">
						<Grid item xs>
							{
								isSelectedContactsFilterNotEmpty && selectedContactsFilter?.id === undefined ? <Button
									style={{ padding: "11px" }}
									variant="contained"
									startIcon={<FilterListIcon />}
									color="primary"
									onClick={() => {
										setSelectedContactsFilter(selectedContactsFilter)
										setOpenEditContactFiltersDialog(true)
									}}
								>
									EDITAR FILTRO
								</Button> : <Button
									style={{ padding: "11px" }}
									variant="contained"
									startIcon={<FilterListIcon />}
									color="primary"
									onClick={handleOpenFilterDialog}
								>
									FILTRAR
								</Button>
							}
						</Grid>
						<Grid item xs={4}>
							<Tooltip title={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch") ? "Pesquisa desabillitada temporariamente, identificamos que sua conta possui muitos contatos e isso acabou gerando algumas instabilidades. Em breve essa solução será reabilitada e com maior estabilidade. Pedimos desculpas pelo transtorno e agradecemos a compreensão!" : ""} >
								<TextField
									value={whereData.search}
									onChange={({ target }) =>
										handleSearchChange(target.value)
									}
									placeholder="Pesquisar"
									variant="outlined"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: whereData.search && (
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon
													fontSize="small"
												/>
											</IconButton>
										)
									}}
									fullWidth
									size="small"
									disabled={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch")}
									helperText={validation.search}
									error={!!validation.search}
								/>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				{isSelectedContactsFilterNotEmpty && <Grid container alignItems="center" spacing={1}>
					<Grid item>
						<b>Filtro Selecionado: </b>
					</Grid>
					<Grid item xs>
						<Chip
							label={selectedContactsFilter.title}
							style={{
								backgroundColor: "#FBEECC"
							}}
							onDelete={handleRemoveSelectedContactFilters}
						/>
					</Grid>
				</Grid>
				}

				<Divider size={3} orientation="horizontal" />

			</Grid>
			<ContactFilters
				openDialog={openContactFiltersDialog}
				setOpenDialog={setOpenContactFiltersDialog}
				setSelectedFilter={setSelectedContactsFilter}
				getDataWithFilters={getAndUpdateClientCatalogData}
				contactsTags={tags}
			/>

			<CreateOrEditContactFilter
				openDialog={openEditContactFiltersDialog}
				setOpenDialog={setOpenEditContactFiltersDialog}
				selectedFilter={selectedContactsFilter}
				setSelectedFilter={setSelectedContactsFilter}
				getDataWithFilters={getAndUpdateClientCatalogData}
				contactsTags={tags}
			/>
		</>
	)
}

export default ClientManageContacts
