import React from "react"
import { Typography, IconButton, Grid, Chip, Icon } from "@material-ui/core"
import {
	ArrowForwardIos as RArrowIcon,
	AccessTime as ClockIcon
} from "@material-ui/icons"

import NoTriggersIcon from "@/assets/icons/no_triggers.svg"
import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"
import AttendanceIcon from "@/assets/icons/attendance.svg"

import { Portlet } from "@/components"

import useStyles from "@/pages/Admin/ChatBot/ChatBotList/ChatBotListItem/styles"
import { Link } from "react-router-dom"
import { fullDatetime } from "@/utils/time"
import { ChatBotCategory } from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"
import { webhookTemplatesInfo } from "@/utils/webhook"

type ChatBotListItemProps = {
	chatBoFlowtId: number
	title?: string
	isActive: boolean
	onEdit?: () => void
	category: ChatBotCategory | undefined
	updated_at: Date
	webhookTemplateType?: WebhookTemplateType | undefined
}

const STATUS_MAP: Record<"enabled" | "disabled", {
	statusLabel: string
	chipBackgroundColor: string
}> = {
	disabled: {
		statusLabel: "Desativado",
		chipBackgroundColor: "#F8DAD3"
	},
	enabled: {
		statusLabel: "Ativado",
		chipBackgroundColor: "#D4F8D3"
	}
}

const ChatBotListItem: React.FC<ChatBotListItemProps> = (props) => {
	const { chatBoFlowtId, title, isActive, onEdit, category, updated_at, webhookTemplateType } = props

	const classes = useStyles()

	const handleEdit = () => {
		onEdit && onEdit()
	}

	const renderChatBotTriggerIcon = (botTriggerType: ChatBotCategory | undefined, botWebhookTemplateType: WebhookTemplateType | "custom") => {
		const webhookTemplateInfo = webhookTemplatesInfo[botWebhookTemplateType as WebhookTemplateType]

		const iconsMap: Record<string, string> = {
			"no-triggers": NoTriggersIcon,
			custom: CustomWebhookIcon,
			"attendance-started-by-client": AttendanceIcon
		}

		let icon: string | undefined = webhookTemplateInfo?.svgIcon || iconsMap[botWebhookTemplateType]

		if (botTriggerType && botTriggerType !== "webhook-triggered") {
			icon = iconsMap[botTriggerType]
		}

		return <Icon classes={{ root: classes.iconRoot }}>
			<img src={icon} className={classes.icon} style={{ width: "40px" }} alt="trigger-icon"/>
		</Icon>
	}

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
				className={classes.listItem}
			>
				<Grid item xs={1}>
					{renderChatBotTriggerIcon(category, webhookTemplateType || "custom")}
				</Grid>

				<Grid item xs={4} >
					<Typography
						variant="h4"
						className={classes.listItemTitle}
					>
						{title}
					</Typography>
				</Grid>

				<Grid item xs={2} container justifyContent="flex-end">
					<Chip
						label={STATUS_MAP[isActive ? "enabled" : "disabled"]?.statusLabel}
						style={{
							backgroundColor: STATUS_MAP[isActive ? "enabled" : "disabled"]?.chipBackgroundColor
						}}
						className={classes.chip}
					/>
				</Grid>

				<Grid item xs={4} container alignItems="center" justifyContent="flex-end">
					<ClockIcon className={classes.clockIcon} />
					<Typography
						variant="body1"
						className={classes.listItemData}
						onClick={handleEdit}
					>
						Última alteração: {fullDatetime(new Date(updated_at))}
					</Typography>
				</Grid>

				<Grid item xs={1}>
					<Grid container justifyContent="flex-end">
						<Grid item >
							<Link to={`/admin/bots/${chatBoFlowtId}`} style={{ textDecoration: "none" }}>
								<IconButton onClick={handleEdit} className={classes.listItemButton}>
									<RArrowIcon className={classes.editIcon}/>
								</IconButton>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default ChatBotListItem
