import React from "react"

import { MessageType } from "@/protocols/channel"

import BaseMessage from "@/components/ChatMessageBuilder/MessageItem/BaseMessage"

import { Messages } from "@/components"

type MessageItemProps = {
	type: MessageType
	content?: string
	mediaName?: string
	className?: string
	style?: React.CSSProperties
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
	const { type, content, mediaName, className, style } = props

	return (
		<BaseMessage
			className={className}
			style={style}
			childContent={(
				Messages.getChildContent(type, {
					caption: type === "file" ? (mediaName as string) : "",
					copySpecial: true,
					translateVariables: true,
					content: type === "text" ? (content || "O conteúdo da mensagem aparecerá aqui...") : (content || "")
				})
			)}
		/>
	)
}

export default MessageItem
