import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	flowConfigBlockHeader: {
		background: "#7E0DD6"
	},
	flowConfigBlockHeaderTitle: {
		fontWeight: "bolder",
		color: "#FFFFFF",
		fontSize: "16px",
		padding: theme.spacing(1.5, 0, 1.5, 1.5)
	},
	flowTriggerInfo: {
		padding: theme.spacing(0)
	},
	blockHeaderTitle: {
		fontWeight: "bolder",
		color: colors.grayScale[11]
	},
	blockHeader: {
		background: colors.palette.button1
	},
	createBlockText: {
		color: "rgba(73, 80, 87, 0.7)"
	},
	createBlockIcon: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		color: "rgba(73, 80, 87, 0.7)"
	},
	createBlockContainer: {
		padding: theme.spacing(3),
		border: "1px dashed #BFBFBF",
		borderRadius: 6
	},
	flowContent: {
		height: "calc(100% - 64px)",
		width: "100%",
		overflow: "hidden",
		backgroundColor: "rgba(245, 245, 245, 1)",
		padding: 16
	},
	flowContainer: {
		overflow: "hidden",
		height: "calc(100% - 64px)",
		alignContent: "flex-start"
	},
	flowZoomContainer: {
		cursor: "auto !important",
		width: "100%"
	},
	flowZoomContent: {
		flexWrap: "nowrap",
		overflowX: "hidden"
	},
	createTriggerContainer: {
		backgroundColor: "#F8F9FA"
	},
	webhookInfoUrlBlock: {
		backgroundColor: "#F8F9FA",
		borderRadius: theme.shape.borderRadius
	},
	saveButton: {
		fontSize: "14px",
		color: colors.grayScale[11],
		padding: theme.spacing(1, 2)
	},
	actionButton: {
		width: theme.spacing(4),
		height: theme.spacing(4)
	},
	listItemDataTime: {
		opacity: "0.5",
		fontSize: "12px",
		fontWeight: 400,
		color: colors.grayScale[4],
		marginRight: theme.spacing(4)
	},
	clockIcon: {
		width: theme.spacing(2),
		height: "16px",
		color: colors.grayScale[4],
		opacity: "0.5",
		marginRight: theme.spacing(1.25),
		marginTop: theme.spacing(0.25)
	},
	chatBotName: {
		fontSize: "18px",
		fontWeight: 600,
		lineHeight: "24px",
		color: colors.grayScale[4]
	},
	saveArea: {
		display: "flex",
		justifyContent: "flex-end"
	},
	dataTime: {
		display: "flex",
		alignItems: "center"
	},
	chatBotData: {
		display: "flex",
		alignItems: "center"
	},
	blockActiveChatBotFlowButton: {
		position: "absolute",
		zIndex: 9,
		left: "-2px",
		top: "-5px"
	},
	chatBotBlockIdentification: {
		position: "absolute",
		zIndex: 9,
		right: "24px",
		bottom: "12px"
	},
	blockItemContainer: {
		position: "relative"
	}
}))

export default useStyles
