import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Avatar,
	Grid,
	makeStyles
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 18,
		height: 18,
		backgroundColor: "transparent"
	},
	skeleton: {
		transform: "scale(1)"
	},
	title: {
		marginTop: theme.spacing(1)
	},
	tags: {
		marginTop: theme.spacing(1)
	},
	tag: {
		transform: "scale(1)",
		marginTop: "10px",
		width: "100%"
	}
}))

const ClientInfoSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Grid container alignItems="center" justify="space-between" className={classes.title}>
				<Grid item xs>
					<Skeleton className={classes.skeleton} width={60} height={18} />
				</Grid>
				<Grid item>
					<Avatar className={classes.avatar}>
						<Skeleton width={18} height={18} variant="circle" />
					</Avatar>
				</Grid>
			</Grid>
			<Grid container alignItems="center" className={classes.tags}>
				<Skeleton className={classes.tag} height={18} />
				<Skeleton className={classes.tag} height={18} />
				<Skeleton className={classes.tag} height={18} />
			</Grid>
		</>
	)
}

export default ClientInfoSkeleton
