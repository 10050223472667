import {
	Grid,
	Typography,
	TableContainer,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Select,
	MenuItem,
	Button,
	CircularProgress
} from "@material-ui/core"

import {
	CheckCircle,
	Block
} from "@material-ui/icons"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"

import {
	transformHourAndMinutesToDate,
	transformDateIntoHourAndMinutes
} from "@/utils/time"

import { Divider, Loading, Notification, Portlet } from "@/components"

import React, { useState } from "react"
import useStyles from "@/pages/Admin/Settings/InboxChannel/SendMessagesTimeLimits/styles"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { useParams } from "react-router-dom"
import useDidMount from "@/hooks/useDidMount"
import InboxChannelSettings, { BaseInboxChannelSettings } from "@/services/InboxChannelSettings"

export type UseParamsType = {
	inboxChannelId: string
}
const SendMessagesTimeLimits = () => {
	const [sendMessagesTimeLimits, setSendMessagesTimeLimits] = useState<BaseInboxChannelSettings<"send_messages_time_limits">>()
	const params = useParams<UseParamsType>()
	const [loading, setLoading] = useState<boolean>(false)
	const [sendMessagesTimeLimitsHasChanged, setSendMessagesTimeLimitsHasChanged] = useState<boolean>(false)
	const [sendMessagesTimeLimitsUpdating, setSendMessagesTimeLimitsUpdating] = useState<boolean>(false)
	const [hasErrors, setHasErrors] = useState<boolean>(false)

	const classes = useStyles()

	const handleGetSendMessagesTimeLimitSettings = async () => {
		setLoading(true)
		const data = await InboxChannelSettings.get("send_messages_time_limits", params.inboxChannelId)
		setSendMessagesTimeLimits(data)
		setLoading(false)
	}

	useDidMount(() => {
		handleGetSendMessagesTimeLimitSettings()
	})

	const handleUpdateSendMessagesTimeLimitsValue = async (fieldName: string, newValue: string, index: number) => {
		if (sendMessagesTimeLimits?.value) {
			const allValues = sendMessagesTimeLimits.value

			const oldValue = allValues?.[index]

			allValues[index] = { ...oldValue, [fieldName]: newValue }

			setSendMessagesTimeLimitsHasChanged(true)

			setSendMessagesTimeLimits({ ...sendMessagesTimeLimits, value: [...allValues] })
		}
	}

	const handleSubmitSendMessagesTimeLimitSettings = async () => {
		setSendMessagesTimeLimitsUpdating(true)
		if (!hasErrors) {
			InboxChannelSettings.update(sendMessagesTimeLimits?.value, params.inboxChannelId, sendMessagesTimeLimits?.id, sendMessagesTimeLimits?.key)
			setSendMessagesTimeLimitsHasChanged(false)
			Notification.success({ message: "Os horários dos envios foram atualizados." })
		} else {
			Notification.error({ message: "Corrija os dados antes de enviar." })
		}
		setSendMessagesTimeLimitsUpdating(false)
	}

	const translatedDays: Record<number, string> = {
		1: "Segunda-Feira",
		2: "Terça-Feira",
		3: "Quarta-Feira",
		4: "Quinta-Feira",
		5: "Sexta-Feira",
		6: "Sábado",
		7: "Domingo"
	}

	const handleDateChange = (newValue: Date | null, fieldName: string, index: number) => {
		const hourAndMinutes = transformDateIntoHourAndMinutes(newValue as Date)

		handleUpdateSendMessagesTimeLimitsValue(fieldName, hourAndMinutes, index)
	}

	return (
		<Loading
			loading={loading}
		>
			<Portlet>
				<TableContainer>
					<Table
						stickyHeader={false}
						size="small"
						style={{ borderRadius: "8px" }}
					>
						<TableHead>
							<TableRow>
								<TableCell width={150}>
									DIA
								</TableCell>

								<TableCell>
									OPÇÕES DE HORÁRIOS
								</TableCell>

								<TableCell>
									INÍCIO
								</TableCell>

								<TableCell>
									TÉRMINO
								</TableCell>

								<TableCell> </TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{sendMessagesTimeLimits?.value?.map((limit, index) => (
								<TableRow
									key={limit.day_code}
									tabIndex={-1}
								>
									<TableCell
									>
										<Typography variant="body1">
											{translatedDays[limit.day_code]}
										</Typography>
									</TableCell>

									<TableCell>
										<Select
											style={{
												height: "40px",
												margin: "3px 0px",
												textTransform: "none"
											}}
											fullWidth
											value={limit.option}
											variant="outlined"
											onChange={(event) => {
												if (event.target.value) {
													handleUpdateSendMessagesTimeLimitsValue("option", String(event.target.value), index)
												}
											}}
										>
											<MenuItem value="all_day">O dia todo</MenuItem>
											<MenuItem value="customize">Personalizado</MenuItem>
											<MenuItem value="no_send">Não enviar</MenuItem>
										</Select>
									</TableCell>

									<TableCell>
										<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
											<Grid>
												<KeyboardTimePicker
													okLabel="Pronto"
													clearLabel="Limpar"
													cancelLabel="Cancelar"
													disabled={limit.option === "no_send" || limit.option === "all_day"}
													id="from-hour-time-picker"
													ampm={false}
													value={transformHourAndMinutesToDate(limit.from_hour)}
													invalidDateMessage="Valores Incorretos"
													inputVariant="outlined"
													size="small"
													onError={(_error) => {
														if (_error) {
															setHasErrors(true)
														}
													}}
													onChange={(newValue) => {
														handleDateChange(newValue, "from_hour", index)
														setHasErrors(false)
													}}
													KeyboardButtonProps={{
														"aria-label": "change time"
													}}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
									</TableCell>

									<TableCell>
										<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
											<Grid>
												<KeyboardTimePicker
													okLabel="Pronto"
													clearLabel="Limpar"
													cancelLabel="Cancelar"
													disabled={limit.option === "no_send" || limit.option === "all_day"}
													id="to-hour-time-picker"
													ampm={false}
													value={transformHourAndMinutesToDate(limit.to_hour)}
													inputVariant="outlined"
													invalidDateMessage="Valores Incorretos"
													size="small"
													onError={(_error) => {
														if (_error) {
															setHasErrors(true)
														}
													}}
													onChange={(newValue) => {
														handleDateChange(newValue, "to_hour", index)
														setHasErrors(false)
													}}
													KeyboardButtonProps={{
														"aria-label": "change time"
													}}
												/>
											</Grid>
										</MuiPickersUtilsProvider>
									</TableCell>

									<TableCell
										style={{
											textAlign: "center"
										}}
									>
										{
											limit.option === "no_send" ? <Block className={classes.disableIcon} /> : <CheckCircle className={classes.enableIcon} />
										}
									</TableCell>
								</TableRow>
							))}
						</TableBody>

					</Table>
				</TableContainer>

				<Divider orientation="horizontal" size={2} />

				<Grid container justify="flex-end">
					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSubmitSendMessagesTimeLimitSettings}
							disabled={sendMessagesTimeLimitsUpdating || !sendMessagesTimeLimitsHasChanged}
						>
							{sendMessagesTimeLimitsUpdating ? <CircularProgress size={15} /> : "Salvar"}
						</Button>
					</Grid>
				</Grid>
			</Portlet>
		</Loading>

	)
}

export default SendMessagesTimeLimits
