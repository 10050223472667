import React, { useState } from "react"

import { Switch, Route, useHistory } from "react-router-dom"

import PrivateRoutes from "@/routes/private"
import PublicRoutes from "@/routes/public"
import DevelopmentRoutes from "@/routes/development"

import { Loading } from "@/components"

import useDidMount from "@/hooks/useDidMount"
import useSocket from "@/hooks/useSocket"

import ErrorHandlerService from "@/services/ErrorHandler"

import UserService from "@/services/User"

import { useGlobalStateStore } from "@/store/GlobalState"
import AuthService from "@/services/Auth"
import SocketService from "@/services/Socket"
import ChannelService from "@/services/Channel"
import StorageService from "@/services/Storage"
import { isDevEnv } from "@/utils/environment"

import { addDataLayerVariables, initGoogleTagManager, triggerGoogleAnalyticsPageView } from "@/services/Google"
import ApiService from "@/services/Api"

import { wootricAccountToken } from "@/config/wootric"

const PrivateComponent = () => {
	const [loading, setLoading] = useState(true)

	const globalStateStore = useGlobalStateStore()
	const history = useHistory()
	const socket = useSocket()

	const setupChannelListeners = () => {
		socket.onChannelChanged(updatedChannel => {
			globalStateStore.setChannelsData(lastState => {
				const channelExists = lastState.some(channel => channel.id === updatedChannel.id)

				let updatedState = [...lastState]

				/**
				 * We need to validate since a channel can not exist in state
				 * in case it was a created channels, since sometimes channels
				 * are updated and other times created.
				 */
				if (channelExists) {
					updatedState = lastState.map(channel => {
						if (channel.id === updatedChannel.id) {
							return {
								...channel,
								...updatedChannel
							}
						}

						return channel
					})
				} else {
					updatedState.push(updatedChannel)
				}

				return updatedState
			})
		})

		socket.onSocketReady(async () => {
			const user = await UserService.getInfo()

			const channels = await ChannelService.setupChannels(user?.userInInstanceData?.instance_id as number)

			globalStateStore.setChannelsData(channels || [])
		})
	}

	const runWootric = (email: string, createdAt: Date) => {
		const created_at = Math.round(createdAt.getTime() / 1000)

		window.wootricSettings = {
			email, // TODO: Required to uniquely identify a user. Email is recommended but this can be any unique identifier.
			created_at, // TODO: The current logged in user's sign-up date as a Unix timestamp.
			account_token: wootricAccountToken
		}

		// Request a survey
		window.wootric("run")
	}

	const getInitialData = async () => {
		const isLoggedIn = await AuthService.isLoggedIn()

		if (!isLoggedIn) {
			history.push("/signin")
			return
		}

		try {
			const user = await UserService.getInfo()

			if (user) {
				const {
					userData,
					userInInstanceData,
					subscriptionData
				} = user

				const instanceId = userInInstanceData.instance_id

				StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, instanceId)

				const { data: instanceTeams } = await ApiService.get("/team")

				const [channels] = await Promise.all([
					ChannelService.setupChannels(instanceId),
					SocketService.setup(String(instanceId), user.authToken)
				])

				globalStateStore.setUserData({
					id: userData.id,
					name: userData.name,
					email: userData.email,
					phone_number: userData.phone_number,
					extra_data: userData.extra_data,
					user_teams: userInInstanceData.user_teams,
					valid_teams: userInInstanceData.user_teams.map(team => team.id),
					is_instance_owner: userInInstanceData.is_instance_owner
				})

				globalStateStore.setInstanceData({
					instance_id: userInInstanceData.instance_id,
					instance_created_at: userInInstanceData.instance_created_at,
					user_in_instance_id: userInInstanceData.id,
					user_in_instance_role: {
						code: userInInstanceData.user_role_code,
						name: userInInstanceData.user_role_name
					},
					subscriptionData,
					teams: instanceTeams.teams
				})

				globalStateStore.setChannelsData(channels || [])

				setupChannelListeners()

				addDataLayerVariables({
					is_admin: Boolean(userData?.extra_data?.is_admin_mode),
					is_tester: Boolean(userData?.extra_data?.is_tester),
					is_first_access: Boolean(userData?.extra_data?.is_first_access)
				})

				if (
					userData?.extra_data?.is_first_access &&
					!userData?.extra_data?.is_admin_mode
				) {
					window.onunload = await ApiService.put("/user/revoke/extra-data/first-access")
				}

				runWootric(userData.email, new Date(userData.created_at))
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	useDidMount(() => {
		getInitialData()
	})

	return (
		<Loading loading={loading}>
			<PrivateRoutes />
		</Loading>
	)
}

const Routes = () => {
	const history = useHistory()

	history.listen(({ pathname }) => {
		triggerGoogleAnalyticsPageView(pathname)
	})

	useDidMount(() => {
		initGoogleTagManager()
	})

	return (
		<Switch>
			{isDevEnv && DevelopmentRoutes}

			{PublicRoutes}

			<Route path="/" component={PrivateComponent} />
			<Route path="*" component={() => <h1>Page Not Found</h1>} />
		</Switch>
	)
}

export default Routes
