import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

type UseStylesProps = {
	backgroundHexColor?: string
	colorText?: string
}

export const navbarHeight = "56px"

const useStyles = makeStyles(theme => ({
	navbarContainer: {
		backgroundColor: (props: UseStylesProps) => props
			.backgroundHexColor,
		height: navbarHeight,
		color: (props: UseStylesProps) => props
			.colorText
	},
	titleText: {
		fontSize: "15px"
	},
	leftContainer: {
		width: "auto"
	},
	rightContainer: {
		width: "auto"
	},
	nameText: {
		textTransform: "uppercase",
		color: colors.grayScale[11]
	},
	emailText: {
		fontWeight: "normal"
	},
	profile: {
		cursor: "pointer"
	},
	iconButton: {
		padding: "6px"
	},
	menuIcon: {
		fontSize: theme.spacing(4)
	},
	iconNavGroup: {
		marginLeft: "36px"
	},
	logoImg: {
		width: 87
	}
}))

export default useStyles
