import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	List,
	ListItem,
	ListItemAvatar,
	ListItemText
} from "@material-ui/core"

const ChatListSkeleton = () => {
	const defaultListItem = (
		<ListItem>
			<ListItemAvatar>
				<Skeleton
					width={40}
					height={40}
					variant="circle"
				/>
			</ListItemAvatar>

			<ListItemText
				primary={(
					<Skeleton
						width="100%"
						height="20px"
					/>
				)}
				secondary={(
					<Skeleton
						width="100%"
						height="20px"
					/>
				)}
			/>
		</ListItem>
	)

	return (
		<List>
			{defaultListItem}
			{defaultListItem}
			{defaultListItem}
			{defaultListItem}
			{defaultListItem}
		</List>
	)
}

export default ChatListSkeleton
