import React from "react"
import {
	Grid,
	Typography,
	Chip,
	IconButton,
	useMediaQuery,
	useTheme
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	Block as BlockIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Attendant/styles"

type TeamDataProps = {
	id: number
	instanceId?: number
	name: string
}

type UserInInstanceCardProps = {
	name?: string
	email?: string,
	status?: string,

	role_code?: string

	teams?: TeamDataProps[]

	onClickEditButton?: () => void
	onClickDeleteButton?: () => void
	onClickBlockButton?: () => void
}

const UserInInstanceCard: React.FC<UserInInstanceCardProps> = (props) => {
	const { name, email, status, role_code, teams, onClickEditButton, onClickDeleteButton, onClickBlockButton } = props

	const MuiTheme = useTheme()
	const classes = useStyles()

	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center">
				<Grid
					item xs={12} sm={4}
					className={classes.textContainer}
				>
					<Typography
						color="textPrimary"
						variant="h2"
						className={classes.nameText}
					>
						{name}
					</Typography>

					<Typography
						color="textPrimary"
						variant="caption"
					>
						{email}
					</Typography>

					<Divider size={0} orientation="horizontal" />

					<Chip
						variant="outlined" className={classes.roleChip}
						label={role_code === "admin" ? "Gestor" : "Atendente"}
						style={{ background: role_code === "admin" ? "#FBEECC" : "#E1D3F8" }}
					/>
				</Grid>

				{
					teams?.length ? isSmall && <Divider size={2} orientation="horizontal" /> : <></>
				}

				<Grid
					item xs={12} sm={5}
					className={classes.textContainer}
				>
					{
						teams?.length
							? teams.map((team, index) => (
								<Chip label={team?.name} key={index} variant="outlined" className={classes.teamChip}/>
							))
							: ""
					}
				</Grid>

				{
					isSmall &&
							<Divider size={2} orientation="horizontal" />
				}

				{/* <Grid item xs={12} sm={3}>
					<Grid container justify="flex-end">
						<Grid item xs={12} sm={8}>
							<TextField
								id="input-user-type"
								name="role_code"
								select
								value={role_code}
								variant="outlined"
								disabled
								fullWidth
							>
								<MenuItem value={"admin"}>
								Gestor
								</MenuItem>

								<MenuItem value={"attendant"}>
								Atendente
								</MenuItem>
							</TextField>
						</Grid>
					</Grid>
				</Grid> */}

				{
					isSmall && <Divider size={1} orientation='horizontal'/>
				}

				<Grid item xs={12} sm={3}>
					<Grid container justify="flex-end">
						<IconButton onClick={onClickBlockButton}>
							<BlockIcon style={{ color: status === "blocked" ? "red" : "" }} />
						</IconButton>
						<IconButton onClick={onClickEditButton}>
							<EditIcon />
						</IconButton>
						<IconButton onClick={onClickDeleteButton}>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default UserInInstanceCard
