import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		position: "relative"
	},
	circularProgressContainer: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		zIndex: 9
	}
})

export default useStyles
