import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	breadcrumbLink: {
		textTransform: "uppercase",
		textDecoration: "none",
		color: colors.grayScale[4],
		"&:hover": {
			textDecoration: "underline"
		}
	}
})

export default useStyles
