import React from "react"
import { Typography } from "@material-ui/core"

import TypographyListGroup from "@/components/TypographyListGroup"
import ClientName from "@/components/ClientIdentification/ClientName"
import ClientNickname from "@/components/ClientIdentification/ClientNickname"

import useStyles from "@/components/ProductReportClient/styles"
import useCustomStyles from "@/styles/custom"

import PhoneNumberRedirect from "@/components/PhoneNumberRedirect"

export type ProductReportClientProps = {
	client?: {
		nickname?: string
		name?: string
		phoneNumber?: string
	}
}

const ProductReportClient: React.FC<ProductReportClientProps> = (props) => {
	const { client } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<TypographyListGroup>
			{client?.nickname ? (
				<div >
					<ClientNickname>
						{client?.nickname}
					</ClientNickname>

					{client?.name && (
						<ClientName
							className={customClasses.italicText}
							name={client.name}
						/>
					)}
				</div>
			) : (
				<Typography
					className={classes.unknown}
				>
					Desconhecido
				</Typography>
			)}

			{client?.phoneNumber && (
				<PhoneNumberRedirect phoneNumber={client.phoneNumber} />
			)}
		</TypographyListGroup>
	)
}

export default ProductReportClient
