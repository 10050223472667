import React, { useState } from "react"
import {
	Grid,
	Divider as MuiDivider,
	Chip
} from "@material-ui/core"

import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockRule } from "@/protocols/chatBot"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"

import TagsEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ManageClientTagsBlock/TagsEditor"

import {
	getNextChatBotRuleDescription,
	getCurrentTagsOptions
} from "@/utils/chatBot"
import { convertRGBAObjectToString } from "@/utils/color"

import colors from "@/styles/colors"

const ManageClientTagsBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		nextChatBotFlowBlockRules,
		constructionResources,
		flowBlock,
		deleteChatBotFlowBlock,
		toggleNextChatBotFlowBlock
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const handleAddTags = () => {
		TagsEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources,
			action: "add"
		})
	}
	const handleRemoveTags = () => {
		TagsEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources,
			action: "remove"
		})
	}

	const currentTagsOptions = getCurrentTagsOptions(constructionResources, chatBotFlowBlockContent)

	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Adicionar tags"
					/>

					<BlockBodyItemInput
						text={currentTagsOptions.tagsToAdd.length > 0 ? (
							currentTagsOptions.tagsToAdd.map(tagOption => (
								<Chip
									key={tagOption.id}
									label={tagOption.name}
									style={{
										color: colors.grayScale[11],
										backgroundColor: convertRGBAObjectToString(tagOption.color),
										marginRight: 8
									}}
									size="small"
								/>
							))
						) : ""}
						type="action"
						placeholder="Selecione as tags que serão adicionadas"
						onEdit={handleAddTags}
					/>
				</BlockBodyItem>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Remover tags"
					/>

					<BlockBodyItemInput
						text={currentTagsOptions.tagsToRemove.length > 0 ? (
							currentTagsOptions.tagsToRemove.map(tagOption => (
								<Chip
									key={tagOption.id}
									label={tagOption.name}
									style={{
										color: colors.grayScale[11],
										backgroundColor: convertRGBAObjectToString(tagOption.color),
										marginRight: 8
									}}
									size="small"
								/>
							))
						) : ""}
						type="action"
						placeholder="Selecione as tags que serão removidas"
						onEdit={handleRemoveTags}
					/>
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default ManageClientTagsBlock
