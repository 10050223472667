import React from "react"

import {
	MoreVert as MoreOptionsIcon
} from "@material-ui/icons"

import { ReactComponent as AttendanceStartByClientIcon } from "@/assets/icons/attendance.svg"

import {
	Grid,
	IconButton,
	ListItemText,
	Menu,
	MenuItem, Typography
} from "@material-ui/core"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/styles"
import { Divider, SvgIcon } from "@/components"
import { TriggerItemProps } from "@/protocols/chatBotConstructor"

const defaultViewBox = "0 0 32 32"

const AttendanceTriggerItem = (props: TriggerItemProps) => {
	const {
		onDelete
	} = props

	const classes = useStyles()

	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	return <Grid container direction="column" className={classes.triggerBlock}>
		<Grid item xs>
			<Grid container direction="column" >
				<Grid item>
					<Grid container justifyContent="flex-start" alignItems="center">
						<Grid item xs={3} className={classes.triggerIconContainer}>
							<SvgIcon icon={AttendanceStartByClientIcon} style={{ width: "32px", height: "32px", fill: "none" }} viewBox={defaultViewBox}/>
						</Grid>

						<Grid item xs={9} className={classes.triggerDescriptionContainer}>
							<Typography className={classes.triggerTitle}>
                                Atendimento
							</Typography>

							<Divider orientation="horizontal" size={0.5}/>

							<Typography className={classes.triggerDescription}>
                                Chatbot acionado quando o atendimento é iniciado pelo cliente
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid item container justifyContent="flex-end">

					<IconButton
						onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
					>
						<MoreOptionsIcon />
					</IconButton>
				</Grid>

				<Menu
					anchorEl={optionsMenuAnchorElement}
					keepMounted
					open={Boolean(optionsMenuAnchorElement)}
					onClose={handleCloseOptionsDialog}
					autoFocus={false}
					PaperProps={{
						style: {
							marginTop: 40
						}
					}}
				>
					{onDelete && (
						<MenuItem
							onClick={() => {
								onDelete()
								handleCloseOptionsDialog()
							}}
						>
							<ListItemText primary="Excluir" />
						</MenuItem>
					)}
				</Menu>
			</Grid>
		</Grid>

	</Grid>
}

export default AttendanceTriggerItem
