import ReactDOM from "react-dom"

type VoidFunction = () => Promise<void> | void

const RENDER_PATH_NAME_ATTRIBUTE_NAME = "data-pathname"
const RENDER_DEFAULT_ELEMENT_TYPE = "div"

/**
 * Since when we render a component using 'renderComponent' it is not controlled
 * by an external state, it keeps opened in case the page pathname changes
 * due to some history navigation. Being minded about it, we need to force a close on
 * these components to prevent some UX bugs (like a modal kept opened after changing
 * the page route).
 */
window.addEventListener("popstate", () => {
	const currentPathname = window.location.pathname

	const renderedElements = document.querySelectorAll(`${RENDER_DEFAULT_ELEMENT_TYPE}[${RENDER_PATH_NAME_ATTRIBUTE_NAME}]`)

	renderedElements.forEach(renderedElement => {
		const elementPathname = renderedElement.getAttribute(RENDER_PATH_NAME_ATTRIBUTE_NAME)

		const needToUnmountComponent = elementPathname !== currentPathname

		if (needToUnmountComponent) {
			ReactDOM.unmountComponentAtNode(renderedElement)
		}
	})
})

export const renderComponent = (id: string, component: JSX.Element, unmountOnPagePathnameChange = true) => {
	let node = document.getElementById(id)

	if (node) {
		ReactDOM.unmountComponentAtNode(node)
	} else {
		node = document.createElement(RENDER_DEFAULT_ELEMENT_TYPE)

		node.setAttribute("id", id)

		if (unmountOnPagePathnameChange) {
			node.setAttribute(RENDER_PATH_NAME_ATTRIBUTE_NAME, window.location.pathname)
		}

		document.body.appendChild(node)
	}

	const where = document.getElementById("root") || document.body

	const expectedRendering = ReactDOM.createPortal(component, where)

	ReactDOM.render(expectedRendering, node)
}

export const unmountComponent = (id: string) => {
	const node = document.getElementById(id)

	if (node) {
		ReactDOM.unmountComponentAtNode(node)
	}
}

/**
 * We use a workaround to run the selected function after
 * any react action (hooks, render, etc), since react make everything in a synchronous way
 * and the 'setTimeout' is asynchronous, so it runs after everything
 * that is synchronous.
 */
export const runAfterReactRender = (fn: VoidFunction) => {
	setTimeout(() => {
		fn()
	}, 0)
}

export const getPageFavicon = (): HTMLLinkElement | undefined => {
	const favicon = document.querySelector("link[rel~='icon']") as HTMLLinkElement

	return favicon
}

export const changePageFavicon = (src: string) => {
	let favicon = getPageFavicon()

	if (!favicon) {
		favicon = document.createElement("link")
		favicon.rel = "icon"

		document.head.appendChild(favicon)
	}

	favicon.href = src
}

/**
 * Sometimes we want to force a component to re-mount when a value changed
 * (per example to avoid problems such as an old value got inside the component
 * being shown instead of the current one, when we are dealing with concurrent requests).
 *
 * Being minded about it, we can use React 'key' property to force a component to be re-mounted
 * as soon as the key changes.
 *
 * It means that every time the 'uniqueId' and 'context' arguments of that function changes,
 * the component will be re-mounted.
 */
export const forceRemountComponentProps = (uniqueId: number | string, context: string) => ({
	key: `${context}-${uniqueId}`
})
