import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	largeText: {
		fontSize: "44px",
		fontWeight: 600
	},
	legendBox: {
		color: colors.grayScale[11],
		height: 30,
		width: 30,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		boxShadow: theme.shadows[3]
	}
}))

export default useStyles
