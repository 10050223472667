import React from "react"
import { Grid } from "@material-ui/core"

import useDidMount from "@/hooks/useDidMount"

import { Loading } from "@/components"
import AuthService from "@/services/Auth"

const Logout = () => {
	const handleLogout = async () => {
		await AuthService.logout(false)
	}

	useDidMount(() => {
		handleLogout()
	})

	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
		>
			<Grid item>
				<Loading loading />
			</Grid>
		</Grid>
	)
}

export default Logout
