import React, { useState } from "react"
import {
	Grid,
	Typography,
	TextField,
	InputAdornment,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	FormControlLabel,
	Checkbox
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	ActionDialog,
	Notification,
	Divider,
	LoadingOverlay,
	GroupValidatedName,
	GroupSyncButton
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import useDidMount from "@/hooks/useDidMount"
import useDebounce from "@/hooks/useDebounce"
import useStyles from "@/pages/Admin/GroupMessageBlast/Management/Groups/AddGroupDialog/styles"

import { MessageBlastContactToAdd } from "@/protocols/messageBlast"
import { FormattedGroup } from "@/protocols/group"

import { getRowsLabel } from "@/utils/table"
import { ErrorType } from "@/hooks/useValidation"

type CustomFormattedGroup = FormattedGroup & {
	selected?: boolean
}

type GroupsPaginatedData = {
	count: number
	rows: CustomFormattedGroup[]
}

export type GroupsWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

export type AddGroupDialogProps = {
	inboxChannelId: number
	onAdd: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	onClose: () => void
	opened: boolean
}

export const GROUPS_DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

const AddGroupDialog: React.FC<AddGroupDialogProps> = (props) => {
	const {
		onAdd,
		onClose,
		opened,
		inboxChannelId
	} = props

	const classes = useStyles()

	const [loadingCreation, setLoadingCreation] = useState(false)

	const [loadingGroups, setLoadingGroups] = useState(true)
	const [groupsWhereData, setGroupsWhereData] = useState<GroupsWhereData>(GROUPS_DEFAULT_WHERE_DATA)
	const [groupsPaginatedData, setGroupsPaginatedData] = useState({} as GroupsPaginatedData)

	const [selectedGroups, setSelectedGroups] = useState<Map<number, FormattedGroup>>(new Map())

	const handleLoadGroups = async (whereData: Partial<GroupsWhereData> = GROUPS_DEFAULT_WHERE_DATA) => {
		setLoadingGroups(true)

		try {
			const response = await ApiService.get("/groups/formatted", {
				params: {
					...groupsWhereData,
					...whereData
				}
			})

			setGroupsPaginatedData(response.data.groups)
		} catch (err) {
			Notification.error({ message: "Não foi possível carregar seus grupos!" })
			ErrorHandler.handle(err as ErrorType)
		}

		setLoadingGroups(false)
	}

	const handleClearSelectedGroups = () => {
		setSelectedGroups(lastState => {
			lastState.clear()

			return lastState
		})

		setGroupsPaginatedData(lastState => ({
			...lastState,
			rows: lastState?.rows?.map(group => ({
				...group,
				selected: false
			}))
		}))
	}

	const handleAddGroups = async () => {
		setLoadingCreation(true)

		try {
			const selectedGroupsArray = Array.from(selectedGroups.values())

			const contacts: MessageBlastContactToAdd[] = selectedGroupsArray.map(selectedGroup => ({
				client_id: selectedGroup.clientId,
				contact_id: selectedGroup.contactId
			}))

			await onAdd(contacts)

			Notification.success({ message: "Grupos adicionados com sucesso!" })

			handleClearSelectedGroups()

			onClose()
		} catch (err) {
			Notification.error({ message: "Não foi possível adicionar os grupos!" })
			ErrorHandler.handle(err as ErrorType)
		}

		setLoadingCreation(false)
	}

	const handleToggleGroupSelected = (selectedGroup: FormattedGroup, selected: boolean) => {
		if (selected) {
			setSelectedGroups(lastState => {
				lastState.set(selectedGroup.id, selectedGroup)

				return lastState
			})
		} else {
			setSelectedGroups(lastState => {
				lastState.delete(selectedGroup.id)

				return lastState
			})
		}

		setGroupsPaginatedData(lastState => ({
			...lastState,
			rows: lastState?.rows?.map(group => {
				if (group.id === selectedGroup.id) {
					group.selected = selected
				}

				return group
			})
		}))
	}

	const handleWhereDataChange = (newData: Partial<GroupsWhereData>) => {
		setGroupsWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (_: unknown, page: number) => {
		const newWhereData: GroupsWhereData = {
			...groupsWhereData,
			page
		}

		handleWhereDataChange(newWhereData)

		await handleLoadGroups(newWhereData)
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		const newWhereData: GroupsWhereData = {
			...groupsWhereData,
			rowsPerPage
		}

		handleWhereDataChange(newWhereData)

		await handleLoadGroups(newWhereData)
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	useDidMount(() => {
		handleLoadGroups()
	})

	useDebounce(
		async () => await handleLoadGroups({ search: groupsWhereData?.search }),
		groupsWhereData.search,
		1250
	)

	return (
		<ActionDialog
			title="Adicionar grupos"
			onClose={onClose}
			openDialog={opened}
			fullWidth
			saveText="ADICIONAR"
			onSave={handleAddGroups}
			loading={loadingCreation}
			maxWidth="md"
		>
			<Grid
				container
				spacing={1}
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						item
						xs
					>
						<Grid
							container
						>
							<GroupSyncButton
								inboxChannelId={inboxChannelId}
								onSuccess={handleLoadGroups}
							/>
						</Grid>
					</Grid>

					<Grid item>
						<TextField
							value={groupsWhereData.search}
							placeholder="Pesquisar"
							variant="outlined"
							onChange={({ target }) =>
								handleSearchChange(target.value)
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment: groupsWhereData.search && (
									<IconButton
										size="small"
										onClick={() => handleSearchChange("")}
									>
										<CloseIcon
											fontSize="small"
										/>
									</IconButton>
								)
							}}
							size="small"
						/>
					</Grid>

					<Divider orientation="horizontal" size={3} />

					<Grid item xs={12}>
						<LoadingOverlay
							loading={loadingGroups}
						>
							{groupsPaginatedData?.rows?.length ? (
								<>
									<TableContainer>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														Grupos
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{groupsPaginatedData?.rows?.map(group => {
													const isInvalid = !group.valid

													return (
														<TableRow
															key={group.id}
															tabIndex={-1}
														>
															<TableCell
																className={classes.tableCell}
															>
																<GroupValidatedName
																	valid={group.valid}
																	invalidReason={group.invalidReason}
																>
																	<FormControlLabel
																		control={(
																			<Checkbox
																				checked={group.selected}
																				onChange={({ target }) => handleToggleGroupSelected(group, target.checked)}
																				disabled={isInvalid}
																			/>
																		)}
																		label={group.name}
																	/>
																</GroupValidatedName>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</TableContainer>

									<TablePagination
										rowsPerPageOptions={[20, 50, 100, 200]}
										component="div"
										count={groupsPaginatedData.count}
										rowsPerPage={groupsWhereData.rowsPerPage}
										page={groupsWhereData.page}
										onPageChange={handlePageChange}
										onChangeRowsPerPage={({ target }) => {
											handlePageChange(target, 0)
											handleChangeRowsPerPage(+target.value)
										}}
										labelRowsPerPage={"Resultados por página:"}
										labelDisplayedRows={tableData => getRowsLabel(tableData, groupsWhereData.rowsPerPage)}
									/>
								</>
							) : (
								<Grid container justify="center" direction="row">
									<Typography variant="h5" className={classes.title}>
										Nenhum grupo encontrado
									</Typography>
								</Grid>
							)}
						</LoadingOverlay>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default AddGroupDialog
