import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	input: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	},
	closeChat: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	}
}))

export default useStyles
