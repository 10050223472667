import React, { useState, useEffect } from "react"
import {
	Grid
} from "@material-ui/core"

import {
	VolumeUp as VolumeUpIcon,
	RateReview as RateReviewIcon
} from "@material-ui/icons"

import {
	Divider,
	AccessibleDrawer,
	SettingsMenuItem
} from "@/components"

import useChatStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import UserNotificationSettings from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/UserSettings/UserNotificationSettings"
import SignatureSettings from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/UserSettings/SignatureSettings"

type UserSettingsProps = {
	openDrawer: boolean
	onClose: () => void
}

const UserSettings = (props: UserSettingsProps) => {
	const { openDrawer, onClose } = props

	const chatClasses = useChatStyles()

	const [userNotificationSettingsOpen, setUserNotificationSettingsOpen] = useState<boolean>(false)
	const [userSignatureSettingsOpen, setUserSignatureSettingsOpen] = useState<boolean>(false)

	const handleCloseDrawer = () => {
		// eslint-disable-next-line
        turnOnEventListenerKeydown(false)
		onClose()
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	const turnOnEventListenerKeydown = (turnOn: boolean) => {
		if (turnOn) {
			window.addEventListener(
				"keydown",
				eventKeyDownHandler,
				{ capture: true }
			)
		} else {
			window.removeEventListener(
				"keydown",
				eventKeyDownHandler,
				{ capture: true }
			)
		}
	}

	const defaultSettingOpen = () => {
		turnOnEventListenerKeydown(false)
	}

	const defaultSettingClose = () => {
		turnOnEventListenerKeydown(true)
	}

	const handleUserNotificationSettingsOpen = () => {
		setUserNotificationSettingsOpen(true)
		defaultSettingOpen()
	}

	const handleNotificationSettingsClose = () => {
		setUserNotificationSettingsOpen(false)
		defaultSettingClose()
	}

	const handleUserSignatureSettingsOpen = () => {
		setUserSignatureSettingsOpen(true)
		defaultSettingOpen()
	}

	const handleUserSignatureSettingsClose = () => {
		setUserSignatureSettingsOpen(false)
		defaultSettingClose()
	}

	useDidMount(() => {
		turnOnEventListenerKeydown(true)
	})

	useEffect(() => {
		turnOnEventListenerKeydown(false)
	})

	return (
		<>
			<AccessibleDrawer
				id="new-chat-drawer"
				anchor="left"
				open={openDrawer}
				variant="persistent"
				onClose={handleCloseDrawer}
				onMobileBackButtonPress={handleCloseDrawer}
				classes={{
					paper: chatClasses.drawerPaper
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<HeaderDrawer
							title="Configurações"
							onClose={handleCloseDrawer}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid item xs={12}>

						<SettingsMenuItem
							key="notifications-settings"
							icon={<VolumeUpIcon />}
							text={"Notificações"}
							onClick={handleUserNotificationSettingsOpen}
						/>

						<SettingsMenuItem
							key="chat-settings"
							icon={<RateReviewIcon />}
							text={"Assinatura"}
							onClick={handleUserSignatureSettingsOpen}
						/>

					</Grid>

				</Grid>
			</AccessibleDrawer>
			<UserNotificationSettings
				openDrawer={userNotificationSettingsOpen}
				onClose={handleNotificationSettingsClose}
			/>
			<SignatureSettings
				openDrawer={userSignatureSettingsOpen}
				onClose={handleUserSignatureSettingsClose}
			/>
		</>
	)
}

export default UserSettings
