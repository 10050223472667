import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	notificationMessage: {
		padding: theme.spacing(0.5, 1.5),
		maxWidth: "none",
		textAlign: "center"
	},
	blueNotification: {
		backgroundColor: "#EBFAF5"
	},
	orangeNotification: {
		backgroundColor: "#FFF0D3"
	},
	grayNotification: {
		backgroundColor: "#EFEFEF"
	},
	whiteNotification: {
		backgroundColor: "#FFFFFF"
	},
	messageText: {
		fontSize: 12
	},
	messageContent: {
		position: "relative",
		borderRadius: theme.spacing(1),
		boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
		wordBreak: "break-word"
	}
}))

export default useStyles
