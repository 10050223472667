import React, { useEffect } from "react"
import { Prompt } from "react-router-dom"

type ClosePagePromptProps = {
	activated: boolean
	message: string
}

const ClosePagePrompt: React.FC<ClosePagePromptProps> = (props) => {
	const {
		activated,
		message
	} = props

	const handleClearOnBeforeUnloadListener = () => {
		window.onbeforeunload = () => undefined
	}

	const handleSetupOnBeforeUnloadListener = () => {
		window.onbeforeunload = () => message
	}

	const handleConfirmChangeRoute = ({ pathname }: { pathname: string }) => {
		const sure = window.confirm(message)

		if (sure) {
			handleClearOnBeforeUnloadListener()
			window.location.href = pathname
		}

		return false
	}

	useEffect(() => {
		if (activated) {
			handleSetupOnBeforeUnloadListener()
		} else {
			handleClearOnBeforeUnloadListener()
		}
	// eslint-disable-next-line
	}, [activated])

	return (
		<Prompt
			when={activated}
			message={handleConfirmChangeRoute}
		/>
	)
}

export default ClosePagePrompt
