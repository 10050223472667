import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	noConditionText: {
		color: colors.grayScale[5]
	}
})

export default useStyles
