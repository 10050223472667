import { useGlobalStateStore } from "@/store/GlobalState"
import { Chat, QueueChatList, QueueChatInterfaceList } from "@/store/ChatGlobalState"

const useChat = () => {
	const globalStateStore = useGlobalStateStore()

	const createQueueChatsInterface = (organizedChats: QueueChatList) => {
		const queueChatsInterface: QueueChatInterfaceList[] = []

		Object.values(organizedChats).forEach(queue => {
			queueChatsInterface.push({
				type: "title",
				data: {
					exhibitionName: queue.exhibitionName,
					chatCount: queue.data.length
				},
				height: 45
			})

			queue.data.forEach(chat => {
				queueChatsInterface.push({
					type: "chat",
					data: chat,
					height: 87
				})
			})
		})
		return queueChatsInterface
	}

	const formatQueueChats = (chats: Chat[]) => {
		const actualUserId = globalStateStore?.user?.id
		const userTeams = globalStateStore.user.user_teams

		// Fixed values in object define exhibition order
		const queueChats: QueueChatList = {
			assignedToMe: {
				exhibitionName: "Minha fila",
				data: []
			}
		}

		userTeams.forEach(userTeam => {
			queueChats[`team_${userTeam.id}`] = {
				exhibitionName: `Fila equipe ${userTeam.name}`,
				data: []
			}
		})

		queueChats.all = {
			exhibitionName: "Fila geral",
			data: []
		}

		chats.forEach(chat => {
			let queueChatKey = null
			let exhibitionName = ""

			if (!chat?.attendance?.userId && !chat?.attendance?.assignUserId && !chat?.attendance?.assignTeamId) {
				queueChatKey = "all"
				exhibitionName = "Fila geral"
			}

			if (chat?.attendance?.assignUserId === actualUserId) {
				queueChatKey = "assignedToMe"
				exhibitionName = "Minha fila"
			}

			const isTeamChat = Boolean(chat?.attendance?.assignTeamId)
			const isTeamChatToBeQueued = Boolean(queueChats[`team_${chat?.attendance?.assignTeamId}`])

			if (isTeamChat && isTeamChatToBeQueued) {
				queueChatKey = `team_${chat.attendance.assignTeamId}`
				exhibitionName = `Fila equipe ${chat?.attendance?.assignTeamId}`
			}

			if (queueChatKey) {
				if (!queueChats[queueChatKey]) {
					queueChats[queueChatKey] = {
						exhibitionName,
						data: []
					}
				}

				queueChats[queueChatKey].data.push(chat)
			}
		})
		return createQueueChatsInterface(queueChats)
	}

	const isQueueChat = (chat: Chat): boolean => {
		const actualUserId = globalStateStore?.user?.id

		const isQueue = chat.status === "queue"
		const isGeneral = !chat.attendance.assignUserId && !chat.attendance.assignTeamId
		const isAssignedToCurrentUser = chat.attendance.assignUserId === actualUserId
		const isAssignedToUserTeam = globalStateStore?.user?.valid_teams.includes(Number(chat?.attendance?.assignTeamId))

		return isQueue && (isGeneral || isAssignedToCurrentUser || isAssignedToUserTeam)
	}

	return {
		formatQueueChats,
		isQueueChat
	}
}

export default useChat
