import React from "react"
import {
	Button,
	Grid,
	TextField
} from "@material-ui/core"

import copy from "copy-to-clipboard"

import Notification from "@/components/Notification"

import useStyles from "@/components/TextCopy/styles"
import useCustomStyles from "@/styles/custom"

type TextCopyProps = {
	text: string
	onSuccessMessage: string
}

const TextCopy: React.FC<TextCopyProps> = (props) => {
	const {
		text,
		onSuccessMessage
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleCopy = () => {
		copy(text)

		Notification.success({
			message: onSuccessMessage
		})
	}

	return (
		<Grid
			container
		>
			<Grid item xs={9} sm={9} md={10}>
				<TextField
					id="invitation_link_input"
					defaultValue={text}
					InputProps={{
						readOnly: true
					}}
					variant="outlined"
					fullWidth
					disabled
					className={classes.copyInput}
				/>
			</Grid>

			<Grid item xs={3} sm={3} md={2}>
				<Button
					onClick={handleCopy}
					variant="contained"
					className={`${customClasses.buttonAction} ${classes.copyButton}`}
					disableFocusRipple
					disableElevation
				>
					COPIAR
				</Button>
			</Grid>
		</Grid>
	)
}

export default TextCopy
