import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { Button, CircularProgress, Container, Grid, TextField, Typography } from "@material-ui/core"

import { Divider, Notification, Portlet } from "@/components"

import ApiService from "@/services/Api"
import useValidation from "@/hooks/useValidation"
import { formatEmail } from "@/utils/mask"

import useStyles from "@/pages/Auth/styles"

import colors from "@/styles/colors"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"

type RequestRecoveryDataType = {
	email: string
}

const RecoveryRequest = () => {
	const [requestRecoveryData, setRequestRecoveryData] = useState<RequestRecoveryDataType>({} as RequestRecoveryDataType)
	const [loadingRequestRecovery, setLoadingRequestRecovery] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	const classes = useStyles()
	const history = useHistory()

	const handleRequestDataChange = (type: string, value: string) => {
		setRequestRecoveryData({ ...requestRecoveryData, [type]: value })

		clearValidation(type)
	}

	const handleRequest = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingRequestRecovery(true)

		try {
			await ApiService
				.post("/auth/password/recovery", requestRecoveryData)

			Notification.success({
				message: "Email de redefinição de senha enviado com sucesso!"
			})
			history.push("/signin")
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingRequestRecovery(false)
	}

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet elevation={1} className={classes.portlet} style={
								{
									backgroundColor: colors.palette.primary
								}
							}>
								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />
								<form onSubmit={handleRequest}>
									<TextField
										id="input-email"
										name="email"
										value={requestRecoveryData.email}
										onChange={
											({ target }) => handleRequestDataChange("email", formatEmail(target.value))
										}
										variant="outlined"
										label="Email"
										fullWidth
										helperText={validation.email}
										error={Boolean(validation.email)}
										InputProps={{
											className: classes.whiteInput
										}}
										InputLabelProps={{
											className: classes.whiteLabelInput
										}}
									/>

									<Divider orientation="horizontal" size={1}/>

									<Typography
										variant="body2"
										className={classes.whiteText}
									>
									Nesse e-mail você receberá uma mensagem com as instruções de recuperação de senha.
									</Typography>

									<Divider orientation="horizontal" size={4}/>

									<Button
										color="primary"
										variant="contained"
										type='submit'
										disabled={loadingRequestRecovery}
										endIcon={loadingRequestRecovery && (
											<CircularProgress size={20} color="inherit" />
										)}
										className={classes.whiteButton}
										fullWidth
									>
										RECUPERAR SENHA
									</Button>
								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default RecoveryRequest
