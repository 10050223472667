import React, { useState } from "react"
import { Grid } from "@material-ui/core"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleCategory
} from "@/protocols/chatBot"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"

import { PopConfirm } from "@/components"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyEditMessageItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyEditMessageItem"
import BlockConditionEditor, { ValidationSlugInput } from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor"
import CreateConditionIconButton from "@/pages/Admin/ChatBot/ChatBotConstructor/components/CreateConditionIconButton"
import NoConditionText from "@/pages/Admin/ChatBot/ChatBotConstructor/components/NoConditionText"
import OptionInput from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/ConditionalMenuBlock/OptionInput"

import { generateUUID } from "@/utils/id"
import { getNextChatBotRuleDescription, getMessageButtonText } from "@/utils/chatBot"

const EXIT_CONDITION_VALIDATION_SLUG_INPUTS: ValidationSlugInput[] = ["no-interaction-timeout", "max-invalid-responses"]

const ConditionalButtonBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		nextChatBotFlowBlockRules,
		changeNextChatBotFlowBlockRule,
		toggleNextChatBotFlowBlock,
		constructionResources,
		flowBlock,
		deleteChatBotFlowBlock
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const handleEditNextChatBotFlowBlockRule = (nextChatBotFlowBlockRule: ChatBotFlowBlockRule) => {
		if (nextChatBotFlowBlockRule.category === "continue-condition") {
			OptionInput.open({
				onSave: (chatBotFlowBlockRule) => {
					changeNextChatBotFlowBlockRule("UPDATE", chatBotFlowBlockRule)
				},
				nextChatBotFlowBlockRule
			})
		} else {
			BlockConditionEditor.open({
				onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("UPDATE", chatBotFlowBlockRule),
				nextChatBotFlowBlockRule: nextChatBotFlowBlockRule,
				constructionResources,
				chatBotFlowBlockType: "conditional-menu",
				availableSlugInputs: EXIT_CONDITION_VALIDATION_SLUG_INPUTS
			})
		}
	}

	const handleCreateNextChatBotFlowBlockRule = (selectedCategory: ChatBotFlowBlockRuleCategory) => {
		const block = constructionResources.blocks.find(({ type }) => type === "conditional-menu")

		const nextChatBotFlowBlockRule = block?.available_next_block_flow_rules?.find(({ category }) => category === selectedCategory)

		if (nextChatBotFlowBlockRule) {
			if (selectedCategory === "continue-condition") {
				OptionInput.open({
					onSave: (chatBotFlowBlockRule) => {
						changeNextChatBotFlowBlockRule("CREATE", chatBotFlowBlockRule)
					},
					nextChatBotFlowBlockRule: {
						...nextChatBotFlowBlockRule,
						id: generateUUID(),
						next_chat_bot_flow_block_id: null,
						validations: nextChatBotFlowBlockRule?.validations || []
					}
				})
			} else {
				BlockConditionEditor.open({
					onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("CREATE", chatBotFlowBlockRule),
					nextChatBotFlowBlockRule: {
						...nextChatBotFlowBlockRule,
						id: generateUUID(),
						next_chat_bot_flow_block_id: null,
						validations: nextChatBotFlowBlockRule?.validations || []
					},
					constructionResources,
					chatBotFlowBlockType: "conditional-menu",
					availableSlugInputs: EXIT_CONDITION_VALIDATION_SLUG_INPUTS
				})
			}
		}
	}

	const handleDeleteNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		PopConfirm.open({
			title: "EXCLUIR CONDIÇÃO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => {
				changeNextChatBotFlowBlockRule("DELETE", nextFlowBlockRule)
			},
			confirmButtonText: "EXCLUIR"
		})
	}

	const continueConditionRules = nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")
	const exitConditionRules = nextChatBotFlowBlockRules.filter(({ category }) => category === "exit-condition")

	const buttonMessage = chatBotFlowBlockContent?.messagesToSend?.[0]

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyEditMessageItem
					constructionResources={constructionResources}
					disabledInputs={["file", "voice"]}
					title="Mensagem"
					/**
					 * WARNING:
					 * - Make sure to get always the first message, since 'ConditionalButton' block only support
					 * a single message.
					 */
					onSave={(buildedMessages) => {
						const buildedMessage = buildedMessages[0]

						changeChatBotFlowContent({ messagesToSend: [buildedMessage] })
					}}
					messages={buttonMessage?.content ? [{
						...buttonMessage,
						/**
						 * Workaround to avoid breaking layout when we add button messages
						 * on 'ChatMessageBuilder'.
						 */
						type: "text"
					}] : []}
				/>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Opções"
						endIcon={(
							<Grid>
								<CreateConditionIconButton
									onClick={() => handleCreateNextChatBotFlowBlockRule("continue-condition")}
								/>
							</Grid>
						)}
					/>

					{continueConditionRules?.length ? (
						continueConditionRules.map((nextChatBotFlowBlockRule) => (
							<BlockBodyItemInput
								onClick={() => handleSelectNextFlowBlockRule(nextChatBotFlowBlockRule)}
								key={nextChatBotFlowBlockRule.id}
								text={getMessageButtonText(nextChatBotFlowBlockRule)}
								type="text"
								onEdit={() => handleEditNextChatBotFlowBlockRule(nextChatBotFlowBlockRule)}
								onDelete={() => handleDeleteNextFlowBlockRule(nextChatBotFlowBlockRule)}
								selectable={nextChatBotFlowBlockRule.id !== selectedNextFlowBlockRuleId}
								selected={nextChatBotFlowBlockRule.id === selectedNextFlowBlockRuleId}
							/>
						))
					) : (
						<NoConditionText text="Nenhum botão" />
					)}
				</BlockBodyItem>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Condições"
						endIcon={(
							<CreateConditionIconButton
								onClick={() => handleCreateNextChatBotFlowBlockRule("exit-condition")}
							/>
						)}
					/>

					{exitConditionRules?.length ? (
						exitConditionRules.map((nextChatBotFlowBlockRule) => (
							<BlockBodyItemInput
								key={nextChatBotFlowBlockRule.id}
								onClick={() => handleSelectNextFlowBlockRule(nextChatBotFlowBlockRule)}
								text={getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
								type="keep-going-condition"
								onEdit={() => handleEditNextChatBotFlowBlockRule(nextChatBotFlowBlockRule)}
								onDelete={() => handleDeleteNextFlowBlockRule(nextChatBotFlowBlockRule)}
								selectable={nextChatBotFlowBlockRule.id !== selectedNextFlowBlockRuleId}
								selected={nextChatBotFlowBlockRule.id === selectedNextFlowBlockRuleId}
							/>
						))
					) : (
						<NoConditionText text="Nenhuma condição" />
					)}
				</BlockBodyItem>

				<BlockBodyEditMessageItem
					constructionResources={constructionResources}
					title="Mensagem quando a resposta não entrar em nenhuma condição"
					onSave={(buildedMessages) => changeChatBotFlowContent({ invalidInputResponseMessages: buildedMessages })}
					messages={chatBotFlowBlockContent.invalidInputResponseMessages}
				/>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default ConditionalButtonBlock
