import React, { useState } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
	Grid,
	Typography,
	IconButton,
	Tooltip,
	TextField,
	Chip
} from "@material-ui/core"

import {
	Reply as AssignAttendanceIcon
} from "@material-ui/icons"

import {
	Divider, ActionDialog, Notification
} from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import useCustomMemo from "@/hooks/useCustomMemo"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader/AssignAttendance/styles"
import useCustomStyles from "@/styles/custom"

import { AssignAttendanceData } from "@/protocols/channel"

type AttendanceFilterOption = {
	id?: number
	userId?: number
	teamId?: number
	name: string
	assignment_queue_type: AssignAttendanceData["assignmentQueueType"]
	category: "Atendentes" | "Equipes"
	disableOptions: {
		usersInTeamCount: {
			willValidate: boolean
			usersNumber: number | null
		}
	}
}

const AssignAttendance = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()

	const [showEditAssignAttendanceDialog, setShowEditAssignAttendanceDialog] = useState<boolean>(false)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)
	const [assignAttendanceData, setAssignAttendanceData] = useState<AssignAttendanceData>({} as AssignAttendanceData)

	const getTeamNameWithAttendants = (teamName: string, teamAttendantsCount: number): string => {
		const zeroAttendantsText = teamAttendantsCount === 0 ? ". Não é possível transferir" : ""
		const attendantsNumber = teamAttendantsCount === 1 ? (
			`${teamAttendantsCount} Atendente`
		) : (
			`${teamAttendantsCount} Atendentes${zeroAttendantsText}`
		)
		return `${teamName} (${attendantsNumber})`
	}

	const attendantFilterOptions: AttendanceFilterOption[] = chatGlobalStateStore.attendant.listOrderedAlphabetically.map(attendant => ({
		id: attendant.id,
		userId: attendant.id,
		name: attendant.name,
		assignment_queue_type: "User",
		category: "Atendentes",
		disableOptions: {
			usersInTeamCount: {
				willValidate: false,
				usersNumber: null
			}
		}
	}))
	const instanceTeams: AttendanceFilterOption[] = globalStateStore.instance.teams.map(team => ({
		id: team.id,
		teamId: team.id,
		name: getTeamNameWithAttendants(team.name, team.usersInTeamCount),
		assignment_queue_type: "Team",
		category: "Equipes",
		disableOptions: {
			usersInTeamCount: {
				willValidate: Boolean(team.usersInTeamCount === 0),
				usersNumber: team.usersInTeamCount
			}
		}
	}))
	const alphabeticallyOrderedInstanceTeams = instanceTeams.sort((oldTeam, newTeam) => oldTeam.name.localeCompare(newTeam.name))

	const allAssignOptions = [...attendantFilterOptions, ...alphabeticallyOrderedInstanceTeams]

	const handleCloseEditAssignChatAttendance = () => {
		setShowEditAssignAttendanceDialog(false)
		setAssignAttendanceData({} as AssignAttendanceData)
	}

	const handleOpenEditAssignChatAttendance = () => {
		setShowEditAssignAttendanceDialog(true)
	}

	const handleChoosaAttendantToAssign = async (attendant: AttendanceFilterOption) => {
		setAssignAttendanceData({
			...assignAttendanceData,
			assignedUserId: attendant?.userId,
			assignedTeamId: attendant?.teamId,
			assignmentQueueType: attendant?.assignment_queue_type
		})
	}

	const handleChangeAssignmentObservation = async (assignmentObservation: string) => {
		setAssignAttendanceData({
			...assignAttendanceData,
			assignmentObservation: assignmentObservation
		})
	}

	const handleAssignChatAttendance = async () => {
		setActionDialogLoading(true)
		try {
			if (assignAttendanceData.assignedUserId || assignAttendanceData.assignedTeamId) {
				await chatGlobalStateStore.attendance.assignOnCurrentChat({
					...assignAttendanceData
				})
				handleCloseEditAssignChatAttendance()
			} else {
				Notification.error({ message: "Preencha o atendente que irá receber a transferência primeiro." })
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}
		setActionDialogLoading(false)
	}

	return useCustomMemo(() => (
		<>
			<Tooltip
				title="Transferir atendimento"
			>
				<IconButton
					onClick={handleOpenEditAssignChatAttendance}
				>
					<AssignAttendanceIcon
						className={`${classes.assignAttendanceIcon}`}
					/>
				</IconButton>
			</Tooltip>

			<ActionDialog
				title="TRANSFERIR ATENDIMENTO"
				onSave={handleAssignChatAttendance}
				saveText="TRANSFERIR"
				onClose={handleCloseEditAssignChatAttendance}
				loading={actionDialogLoading}
				openDialog={showEditAssignAttendanceDialog}
				fullWidth
				maxWidth="md"
			>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							color="textPrimary"
							className={customClasses.boldText}
						>
                        Escolha o atendente ou equipe responsável
						</Typography>
						<Autocomplete
							options={allAssignOptions}
							getOptionSelected={(option, value) => option.id === value.id}
							getOptionLabel={(option) => option.name}
							getOptionDisabled={(option) => option.disableOptions.usersInTeamCount.willValidate}
							groupBy={option => option.category}
							fullWidth
							filterSelectedOptions
							onChange={(_, attendants) => handleChoosaAttendantToAssign(attendants as AttendanceFilterOption)}
							renderInput={(params) => {
								return (
									<TextField
										className={classes.filterInput}
										variant="outlined"
										placeholder="Selecionar o atendente"
										{...({
											...params,
											InputProps: {
												...params.InputProps
											}
										})}
									/>
								)
							}}
							renderTags={(value, getTagProps) => {
								return value.map((attendant, index) => (
									<Chip
										key={index}
										label={attendant.name}
										size="small"
										{...getTagProps({ index })}
									/>
								))
							}
							}
							size="small"
						/>
					</Grid>
				</Grid>

				<Divider
					orientation="horizontal"
					size={2}
				/>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography
							color="textPrimary"
							className={customClasses.boldText}
						>
                            Deseja inserir uma observação?
						</Typography>
						<TextField
							value={assignAttendanceData.assignmentObservation}
							onChange={ ({ target }) => { handleChangeAssignmentObservation(target.value) } }
							className={classes.textareaLineNumbers}
							variant="outlined"
							color="primary"
							multiline
							fullWidth
							rows={6}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	), [
		showEditAssignAttendanceDialog,
		JSON.stringify(assignAttendanceData),
		chatGlobalStateStore.chat.current?.type,
		chatGlobalStateStore.chatListPanel.chatStatusFilter.current
	])
}

export default AssignAttendance
