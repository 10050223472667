import React, { useEffect, useCallback } from "react"
import { Route, Switch, Redirect, useLocation, useHistory } from "react-router-dom"
import { Box, Grid, Typography } from "@material-ui/core"

import Admin from "@/pages/Admin"
import AdminAttendant from "@/pages/Admin/Attendant"
import AdminNewClientCatalogPage from "@/pages/Admin/NewClientCatalog"
import AdminClientCatalogPage from "@/pages/Admin/ClientCatalog"
import AdminQuickReplyPage from "@/pages/Admin/QuickReply"
import AdminTeam from "@/pages/Admin/Team"
import AdminTag from "@/pages/Admin/Tag"
import AdminIntegration from "@/pages/Admin/Integration"
import AdminIntegrationManagement from "@/pages/Admin/Integration/Management"
import MessageBlast from "@/pages/Admin/MessageBlast"
import MessageBlastManagement from "@/pages/Admin/MessageBlast/Management"
import GroupMessageBlast from "@/pages/Admin/GroupMessageBlast"
import GroupMessageBlastManagement from "@/pages/Admin/GroupMessageBlast/Management"
import Group from "@/pages/Admin/Group"
import ChatBotConstructor from "@/pages/Admin/ChatBot/ChatBotConstructor"
import AudioTest from "@/routes/private/components/AudioTest"

import Attendance from "@/pages/Attendance"
import ChatPage from "@/pages/Attendance/Chat"
import Metrics from "@/pages/Admin/Metrics"
import Dashboard from "@/pages/Admin/Dashboard"
import NotificationSettings from "@/pages/Admin/NotificationSettings"

import IsTrialEndedPage from "@/pages/IsTrialEnded"

import { Profile } from "@/components"

import useStyles from "@/routes/styles"
import { useGlobalStateStore } from "@/store/GlobalState"
import InboxChannelSettings from "@/pages/Admin/Settings/InboxChannel"
import ImportContacts from "@/pages/Admin/NewClientCatalog/ImportContacts"
import ActiveCampaignPlugin from "@/pages/Admin/ActiveCampaignPlugin"
import ChatBotList from "@/pages/Admin/ChatBot/ChatBotList"

export const AdminRoutes = () => (
	<Switch>
		<Route
			exact
			path="/admin"
			render={() => <Redirect to={"/admin/dashboard"} />}
		/>
		NewClientCatalogPage
		<Route exact path="/admin/attendant" component={AdminAttendant} />
		<Route exact path="/admin/client-catalog" component={AdminClientCatalogPage} />
		<Route exact path="/admin/client-catalog-new" component={AdminNewClientCatalogPage} />
		<Route exact path="/admin/client-catalog/import-contacts" component={ImportContacts} />
		<Route exact path="/admin/groups" component={Group} />
		<Route exact path="/admin/quick-reply" component={AdminQuickReplyPage} />
		<Route exact path="/admin/teams" component={AdminTeam} />
		<Route exact path="/admin/tags" component={AdminTag} />
		<Route exact path="/admin/metrics" component={Metrics} />
		<Route exact path="/admin/profile" component={Profile} />
		<Route exact path="/admin/dashboard" component={Dashboard} />
		<Route exact path="/admin/notifications" component={NotificationSettings} />
		<Route exact path="/admin/integration" component={AdminIntegration} />
		<Route exact path="/admin/integration/:integrationId" component={AdminIntegrationManagement} />
		<Route exact path="/admin/plugins/active-campaign" component={ActiveCampaignPlugin} />
		<Route exact path="/admin/message-blast" component={MessageBlast} />
		<Route exact path="/admin/message-blast/:messageBlastId" component={MessageBlastManagement} />
		<Route exact path="/admin/group-message-blast" component={GroupMessageBlast} />
		<Route exact path="/admin/group-message-blast/:messageBlastId" component={GroupMessageBlastManagement} />
		<Route exact path="/admin/settings/inbox-channel/:inboxChannelId" component={InboxChannelSettings} />
		<Route exact path="/admin/bots/:chatBotFlowId" component={ChatBotConstructor} />
		<Route exact path="/admin/bots" component={ChatBotList} />
		<Route exact path="/admin/audio-test" component={AudioTest} />
	</Switch>
)

export const AttendanceRoutes = () => (
	<Switch>
		<Route
			exact
			path="/attendance"
			render={() => <Redirect to={"/attendance/chat"} />}
		/>
		<Route exact path="/attendance/chat" component={ChatPage} />
	</Switch>
)

const PrivateRoutes = () => {
	const globalStateStore = useGlobalStateStore()
	const location = useLocation()
	const history = useHistory()
	const classes = useStyles()

	const userStore = globalStateStore.user

	const pathsWithPageRaw = ["/trial-ended"]

	const checkIfIsTrialEnded = useCallback(() => {
		const {
			subscriptionData
		} = globalStateStore.instance

		if (subscriptionData?.isTrial) {
			const trialIsEnded = subscriptionData?.isTrial &&
				new Date(subscriptionData?.tolerance_date) < new Date()

			if (location.pathname !== "/trial-ended" && trialIsEnded) {
				history.push("/trial-ended")
			}
		}
	}, [globalStateStore.instance, location.pathname, history])

	const checkIfIsAdmin = useCallback(() => {
		if (location.pathname.split("/")[1] === "admin") {
			const userInInstanceData = globalStateStore.instance.user_in_instance_role
			const possibleUserRoleCode = userInInstanceData?.code

			if (possibleUserRoleCode !== "admin") {
				history.push("/attendance")
			}
		}
	}, [globalStateStore.instance, location.pathname, history])

	useEffect(() => {
		checkIfIsTrialEnded()
		checkIfIsAdmin()
	}, [location.pathname, checkIfIsTrialEnded, checkIfIsAdmin])

	return (
		<>
			{
				pathsWithPageRaw.includes(location.pathname)
					? <Switch>
						<Route path="/trial-ended" component={IsTrialEndedPage} />
					</Switch>
					: (
						<>
							<Switch>
								<Route path="/admin" component={Admin} />
								<Route path="/attendance" component={Attendance} />
							</Switch>

							{
								userStore?.extra_data?.is_admin_mode &&
								<Box
									component="span"
									className={classes.isAdminMode}
								>
									<Grid
										container
										justify="center"
										alignItems="center"
										style={{
											height: "100%"
										}}
									>
										<Grid item>
											<Typography
												variant="h2"
												align="center"
												color="inherit"
											>
												Modo administrador
											</Typography>
										</Grid>
									</Grid>

								</Box>
							}
						</>)
			}
		</>
	)
}

export default PrivateRoutes
