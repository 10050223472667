import { ActionDialog } from "@/components"
import ApiService from "@/services/Api"
import { Grid, TextField } from "@material-ui/core"
import React, { ReactNode, useState } from "react"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { CustomFieldGroupList } from "@/protocols/customField"
type ManageCustomFieldGroupProps = {
	children: ReactNode
	onDataChange: () => void
	edit?: boolean
	groupData?: CustomFieldGroupList
}
const ManageCustomFieldGroup: React.FC<ManageCustomFieldGroupProps> = ({ children, onDataChange, edit, groupData }) => {
	const [customFieldGroupManage, setCustomFieldGroupManage] = useState(false)
	const { validation, triggerValidation } = useValidation()
	const [group, setGroup] = useState<CustomFieldGroupList>(groupData as CustomFieldGroupList || {} as CustomFieldGroupList)

	const handleDialogOpen = () => {
		setCustomFieldGroupManage(true)
	}
	const handleDialogClose = () => {
		setCustomFieldGroupManage(false)
	}
	const onCustomFieldGroupSave = async () => {
		try {
			if (edit) {
				await ApiService.put(`/custom-fields/group/${group.id}`, {
					display_name: group.display_name
				})
			} else {
				await ApiService.post("/custom-fields/group", {
					display_name: group.display_name
				})
			}
			setCustomFieldGroupManage(false)
			onDataChange()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}
	const handleChangeGroupData = async (value: string | number, field: keyof CustomFieldGroupList) => {
		setGroup(lastState => ({
			...lastState,
			[field]: value
		}))
	}
	return (<>
		<div
			onClick={handleDialogOpen}
		>
			{children}
		</div>

		<ActionDialog
			onClose={handleDialogClose}
			openDialog={customFieldGroupManage}
			title={edit === true ? "ALTERAR GRUPO" : "ADICIONAR GRUPO"}
			fullWidth
			saveText={"SALVAR"}
			cancelText="CANCELAR"
			onSave={onCustomFieldGroupSave}
		>
			<Grid container>
				<Grid xs={12}>
					<Grid
						item
						xs
					>
						<TextField
							name="group_display_name"
							variant="outlined"
							placeholder={edit === true ? "Dados Pessoais" : "Digite o novo nome do grupo"}
							fullWidth
							helperText={validation.custom_field_group_display_name}
							error={!!validation.custom_field_group_display_name}
							defaultValue={edit && group.id}
							value={edit && group.display_name}
							onChange={({ target }) => handleChangeGroupData(target.value, "display_name")}
						/>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	</>
	)
}

export default ManageCustomFieldGroup
