import React, { useState } from "react"
import {
	Grid,
	Typography,
	Link,
	TextField,
	Chip
} from "@material-ui/core"

import {
	Divider,
	ActionDialog,
	Loading,
	Notification
} from "@/components"

import colors from "@/styles/colors"

import { renderComponent } from "@/utils/node"
import { isCustomWebhook } from "@/utils/webhook"

import useStyles from "@/pages/Admin/Webhooks/WebhookFieldAssociationEditorDialog/styles"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import HardCodedService from "@/services/HardCoded"

import { Webhook, WebhookCustomFieldAssociation, WebhookDefaultFields, WebhookType } from "@/protocols/webhook"
import { CustomField } from "@/protocols/customField"

import WebhookFieldAssociationInput from "@/pages/Admin/Webhooks/WebhookFieldAssociationInput"
import WebhookCustomFieldAssociationInput from "@/pages/Admin/Webhooks/WebhookCustomFieldAssociationInput"

import WebhookFieldAssociationEditorDialogSkeleton from "@/skeletons/Admin/WebhookFieldAssociationEditorDialogSkeleton"

type WebhookFieldAssociationEditorDialogProps = {
	webhookId: number
	webhookType: WebhookType
	saveText?: string
	onSave?: () => void
	onClose?: () => void
}

type WebhookFieldAssociationEditorDialogType = {
	open: (props: WebhookFieldAssociationEditorDialogProps) => void
}

type DefaultFieldsAssociationInput = {
	key: keyof WebhookDefaultFields
	label: React.ReactNode
	placeholder?: string
}

const defaultFieldsAssociationInputs: DefaultFieldsAssociationInput[] = [
	{
		key: "firstName",
		label: "Nome",
		placeholder: "contacts.name"
	},
	{
		key: "email",
		label: "E-mail",
		placeholder: "contacts.email"
	},
	{
		key: "phone",
		label: (
			<>
				Telefone
				<Chip
					size="small"
					label="Obrigatório"
					style={{
						backgroundColor: colors.palette.tag3,
						marginLeft: "8px",
						color: "#3E3E3E"
					}}
				/>
			</>
		),
		placeholder: "contacts.phone"
	}
]

const WebhookFieldAssociationEditorDialog: WebhookFieldAssociationEditorDialogType & React.FC<WebhookFieldAssociationEditorDialogProps> = (props) => {
	const {
		webhookId,
		webhookType,
		onSave,
		onClose,
		saveText
	} = props

	const [opened, setOpened] = useState(true)
	const [saving, setSaving] = useState(false)
	const [loading, setLoading] = useState(true)

	const [defaultFieldsAssociation, setDefaultFieldsAssociation] = useState({} as WebhookDefaultFields)
	const [customFieldsAssociation, setCustomFieldsAssociation] = useState<WebhookCustomFieldAssociation[]>([])
	const [customFields, setCustomFields] = useState<CustomField[]>([])

	const classes = useStyles()
	const { triggerValidation, clearValidation, validation } = useValidation()

	const handleChangeCustomFieldsAssociation = (data: WebhookCustomFieldAssociation[]) => {
		setCustomFieldsAssociation(data)
	}

	const handleChangeDefaultFieldsAssociation = (key: keyof WebhookDefaultFields, value: string) => {
		setDefaultFieldsAssociation(lastState => ({
			...lastState,
			[key]: value
		}))

		clearValidation(key)
	}

	const handleClose = () => {
		setOpened(false)

		onClose?.()
	}

	const handleSave = async () => {
		setSaving(true)

		try {
			await ApiService.put(`/webhooks/${webhookId}/fields-association`, {
				...(isCustomWebhook(webhookType) ? {
					defaultFieldsAssociation,
					customFieldsAssociation
				} : {
					customFieldsAssociation
				})
			})

			Notification.success({
				message: "Os dados de associação do webhook foram alterados com sucesso!"
			})

			setOpened(false)
			onSave?.()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setSaving(false)
	}

	const loadAssociations = async () => {
		try {
			const result = await ApiService.get("/webhooks", {
				params: {
					webhookId
				}
			})
			const webhook: Webhook = result.data

			setDefaultFieldsAssociation((webhook?.default_fields_association || {}) as WebhookDefaultFields)
			setCustomFieldsAssociation(webhook?.custom_fields_association || [])
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const loadCustomFields = async () => {
		try {
			const result = await ApiService.get("/custom-fields")

			const customFields: CustomField[] = result.data.customFields

			if (customFields) {
				setCustomFields(customFields)
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	useDidMount(async () => {
		await Promise.all([
			loadAssociations(),
			loadCustomFields()
		])

		setLoading(false)
	})

	return (
		<ActionDialog
			title={isCustomWebhook(webhookType) ? "WEBHOOK CUSTOMIZÁVEL" : "WEBHOOK - SALVAR INFORMAÇÕES NO CONTATO"}
			openDialog={opened}
			saveText={saveText || "FECHAR"}
			fullWidth={true}
			onSave={handleSave}
			onClose={handleClose}
			loading={saving}
			hideCloseButton={true}
			maxWidth="md"
		>
			<Grid
				container
				alignContent="center"
			>
				<Grid
					item
					xs={12}
				>
					<Typography
						variant="body1"
						color="textPrimary"
					>
						{isCustomWebhook(webhookType) ? (
							"Associe os campos da Letalk com os campos da estrutura de dados recebidos via Webhook."
						) : (
							"Para salvar informações recebidas via Webhook, adicione associações dos campos da estrutura de dados recebidos via Wehook com os campos personalizados criados na Letalk."
						)}
					</Typography>

					<Link
						href="https://letalk.crunch.help/chatbot/criando-bot-de-integracao-customizada"
						target="_blank"
					>
						Veja mais sobre como associar o pacote do Webhook com as informações da Letalk
					</Link>
				</Grid>

				<Divider size={5} orientation="horizontal" />

				<Grid
					item
					xs={12}
				>
					<Loading
						loading={loading}
						customLoadingElement={<WebhookFieldAssociationEditorDialogSkeleton />}
					>
						<Grid
							container
							spacing={6}
						>
							{isCustomWebhook(webhookType) && (
								<Grid
									item
									xs={12}
								>
									<WebhookFieldAssociationInput
										label={{
											leftSide: "Campos na Letalk",
											rightSide: "Dados que recebemos no pacote do Webhook"
										}}
										items={(
											defaultFieldsAssociationInputs.map(input => ({
												leftSide: (
													<Grid
														container
														alignItems="center"
														className={classes.associationDescription}
													>
														{input.label}
													</Grid>
												),
												rightSide: (
													<TextField
														placeholder={input.placeholder}
														variant="outlined"
														value={defaultFieldsAssociation[input.key]}
														onChange={({ target }) => handleChangeDefaultFieldsAssociation(input.key, target.value)}
														helperText={validation[input.key]}
														error={!!validation[input.key]}
														fullWidth
													/>
												)
											}))
										)}
									/>
								</Grid>
							)}

							{HardCodedService.checkFeatureFlagByInstanceId("webhookCustomFields") && (
								<Grid
									item
									xs={12}
								>
									<WebhookCustomFieldAssociationInput
										customFieldsAssociaton={customFieldsAssociation}
										customFields={customFields}
										onChange={handleChangeCustomFieldsAssociation}
										validation={validation}
										clearValidation={clearValidation}
									/>
								</Grid>
							)}
						</Grid>
					</Loading>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

WebhookFieldAssociationEditorDialog.open = (props: WebhookFieldAssociationEditorDialogProps) => {
	renderComponent(
		"webhook-field-association-editor-dialog",
		<WebhookFieldAssociationEditorDialog
			{...props}
		/>
	)
}

export default WebhookFieldAssociationEditorDialog
