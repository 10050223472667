import { IconButton, List, ListItem, ListItemIcon, ListItemText, Popover } from "@material-ui/core"
import { MoreVert as MoreVertIcon } from "@material-ui/icons"
import React from "react"
import useStyles from "@/components/MoreOptionsMenu/styles"

type MoreOptionsMenuOptionData = {
	title: string,
	icon?: JSX.Element,
	showIcon?: boolean,
	color?: "danger"
	onClick?: () => Promise<void>
}

interface MoreOptionsMenu {
	options: MoreOptionsMenuOptionData[]
	iconSize?: "small" | "medium"
	enableBackground?: boolean
}

const MoreOptionsMenu = (props: MoreOptionsMenu) => {
	const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const moreOptionsMenuIsOpen = Boolean(moreOptionsMenuAnchorEl)

	const { options, iconSize, enableBackground } = props

	const classes = useStyles()

	const handleMoreOptionsMenuClose = () => {
		setMoreOptionsMenuAnchorEl(null)
	}

	const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreOptionsMenuAnchorEl(event.currentTarget)
	}

	const customStyleByColor = {
		danger: classes.danger
	}

	return <>
		<IconButton
			aria-label="more"
			aria-controls="long-menu"
			aria-haspopup="true"
			size={iconSize || "medium"}
			style={enableBackground ? {
				borderRadius: 3,
				backgroundColor: "#4950570D"
			} : undefined}
			onClick={handleMoreOptionsMenuClick}
		>
			<MoreVertIcon />
		</IconButton>
		<Popover
			open={moreOptionsMenuIsOpen}
			anchorEl={moreOptionsMenuAnchorEl}
			onClose={handleMoreOptionsMenuClose}
			anchorOrigin={{ vertical: "top", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
		>
			<List>
				{options.map(option => (<ListItem
					button
					onClick={option.onClick}
					key={option.title}
				>
					{
						option.showIcon && <ListItemIcon
							style={{ minWidth: "30px" }}
							className={option.color && customStyleByColor[option.color]}>
							{option.icon}
						</ListItemIcon>
					}
					<ListItemText
						primary={option.title}
						classes={{
							primary: option.color && customStyleByColor[option.color]
						}}
					/>
				</ListItem>))}
			</List>
		</Popover>
	</>
}

export default MoreOptionsMenu
