import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	cancelIcon: {
		color: "#FF5656"
	},
	finishIcon: {
		color: "#47ED65"
	},
	recodingAnimatedIcon: {
		width: 12,
		height: 12,
		backgroundColor: "#FF1919"
	},
	recordingInfoContainer: {
		flex: 1
	}
})

export default useStyles
