import React, { useState } from "react"
import {
	IconButton,
	CircularProgress,
	Grid,
	Tooltip
} from "@material-ui/core"
import { ReactComponent as SyncIcon } from "@/assets/icons/drive_folder_upload.svg"

import {
	Notification,
	SvgIcon
} from "@/components"

import useSocket from "@/hooks/useSocket"
import { useGlobalStateStore } from "@/store/GlobalState"
import useCustomStyles from "@/styles/custom"

type GroupSyncButtonProps = {
	inboxChannelId: number
	onSuccess?: () => Promise<void>
}

const GroupSyncButton: React.FC<GroupSyncButtonProps> = (props) => {
	const { inboxChannelId, onSuccess } = props

	const socket = useSocket()
	const globalStateStore = useGlobalStateStore()
	const customClasses = useCustomStyles()

	const [syncingGroups, setSyncingGroups] = useState(false)

	const inboxChannel = globalStateStore.channels?.find(({ id }) => id === inboxChannelId)

	const canNotSyncGroups = !globalStateStore.isChannelConnected(inboxChannel)

	const handleSyncGroups = async () => {
		setSyncingGroups(true)

		if (inboxChannel) {
			const success = await socket.syncGroups({
				channelType: inboxChannel.channelType,
				inboxChannelId: inboxChannel.id
			})

			if (success) {
				Notification.success({ message: "Seus grupos foram importados e sincronizados com sucesso!" })

				onSuccess?.()
			} else {
				Notification.error({ message: "Ocorreu um erro ao importar e sincronizar seus grupos!" })
			}
		}

		setSyncingGroups(false)
	}

	return (
		<Tooltip
			title={canNotSyncGroups ? (
				"Você precisa estar com o Whatsapp conectado para importar e sincronizar seus grupos"
			) : (
				"Importar e sincronizar grupos"
			)}
		>
			<Grid
				item
			>
				<IconButton
					onClick={() => handleSyncGroups()}
					className={customClasses.tableActionButton}
					disabled={canNotSyncGroups}
				>
					{syncingGroups ? (
						<CircularProgress
							size={24}
						/>
					) : (
						<SvgIcon
							icon={SyncIcon}
						/>
					)}
				</IconButton>
			</Grid>
		</Tooltip>
	)
}

export default GroupSyncButton
