import React, { useState } from "react"
import {
	Grid,
	Typography,
	Button
} from "@material-ui/core"

import { Divider } from "@/components"

import useManagementStyles from "@/pages/Admin/Integration/Management/styles"
import useCustomStyles from "@/styles/custom"

import {
	IntegrationCustomWebhook,
	IntegrationPlatformVariable,
	IntegrationData,
	IntegrationPlatformEvent,
	IntegrationCustomWebhookTriggerType,
	WebhookFormData
} from "@/protocols/integration"
import { BuildedMessage } from "@/protocols/messages"

import MessageListItem from "@/pages/Admin/Integration/Management/Messages/MessageListItem"
import MessageDialog from "@/pages/Admin/Integration/Management/Messages/MessageDialog"

export type NewWebhookData = {
	title: string
	integrationPlatformEventId?: number
	triggerType: IntegrationCustomWebhookTriggerType
	messages?: BuildedMessage[]
}

type MessagesProps = {
	webhooks: IntegrationCustomWebhook[]
	platformVariables: IntegrationPlatformVariable[]
	platformEvents: IntegrationPlatformEvent[]
	onWebhookChange: (webhookId: number, webhook: IntegrationCustomWebhook) => Promise<void>
	onWebhookCreate: (newWebhookData: NewWebhookData) => Promise<IntegrationCustomWebhook | null>
	onWebhookDelete: (webhookId: number) => Promise<void>
	integration: IntegrationData
}

const Messages: React.FC<MessagesProps> = (props) => {
	const {
		webhooks,
		onWebhookChange,
		integration,
		onWebhookCreate,
		onWebhookDelete,
		platformVariables,
		platformEvents
	} = props

	const customClasses = useCustomStyles()
	const managementClasses = useManagementStyles()

	const [messageDialogOpened, setMessageDialogOpened] = useState(false)
	const [currentWebhookBeingEdited, setCurrentWebhookBeingEdited] = useState<IntegrationCustomWebhook | undefined>({} as IntegrationCustomWebhook)

	const handleOpenMessageDialog = (webhook?: IntegrationCustomWebhook) => {
		setMessageDialogOpened(true)

		setCurrentWebhookBeingEdited(webhook || undefined)
	}

	const handleCloseMessageDialog = () => {
		setMessageDialogOpened(false)

		setCurrentWebhookBeingEdited(undefined)
	}

	const handleChangeWebhook = async (webhookId: number, data: Partial<IntegrationCustomWebhook>): Promise<void> => {
		let webhook = webhooks.find(wh => wh.id === webhookId)

		if (webhook) {
			webhook = {
				...webhook,
				...data
			}

			await onWebhookChange(webhookId, webhook)
		}
	}

	const handleToggleEventActive = async (webhookId: number, isActive: boolean) => {
		await handleChangeWebhook(webhookId, { isActive })
	}

	const handleChangeCustomWebhookData = async (webhookId: number, data: WebhookFormData) => {
		await handleChangeWebhook(webhookId, {
			title: data.title,
			triggerType: data.triggerType,
			integrationPlatformEventId: data.integrationPlatformEventId,
			content: {
				messages: data.messages
			}
		})
	}

	const handleCreateCustomWebhook = async (data: WebhookFormData) => {
		const integrationCustomWebhook = await onWebhookCreate(data)

		/**
		 * In case we create a new webhook, we need to assign it to the current
		 * webhook being edited in order to avoid creating duplicated webhooks
		 * when clicking on saving multiple times after creating a webhook.
		 */
		if (integrationCustomWebhook) {
			handleOpenMessageDialog(integrationCustomWebhook)
		}
	}

	const handleDeleteWebhook = async (webhookId: number) => {
		await onWebhookDelete(webhookId)
	}

	return (
		<>
			<Grid
				container
				direction="column"
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Button
						variant="contained"
						className={customClasses.buttonAction}
						onClick={() => handleOpenMessageDialog()}
					>
						NOVA MENSAGEM
					</Button>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid
					item
					xs={12}
				>
					{webhooks.length > 0 ? (
						<Grid
							container
							direction="column"
						>
							<Grid
								container
								direction="column"
								spacing={2}
							>
								{webhooks.map(webhook => (
									<Grid
										item
										xs={12}
										key={webhook.id}
									>
										<MessageListItem
											onEdit={() => handleOpenMessageDialog(webhook)}
											integrationType={integration.type}
											onToggleActive={async (active) => await handleToggleEventActive(webhook.id, active)}
											onDelete={async () => await handleDeleteWebhook(webhook.id)}
											platformVariables={platformVariables}
											platformEvents={platformEvents}
											webhook={webhook}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>
					) : (
						<Typography
							variant="body1"
							className={managementClasses.categoryTitle}
						>
							Você ainda não tem nenhuma mensagem criada
						</Typography>
					)}
				</Grid>
			</Grid>

			<MessageDialog
				opened={messageDialogOpened}
				onClose={handleCloseMessageDialog}
				integrationType={integration.type}
				title="CRIAR MENSAGEM"
				platformVariables={platformVariables}
				platformEvents={platformEvents}
				webhook={currentWebhookBeingEdited}
				onSave={async (data) => {
					if (currentWebhookBeingEdited) {
						await handleChangeCustomWebhookData(currentWebhookBeingEdited.id, data)
					} else {
						await handleCreateCustomWebhook(data)
					}
				}}
				hasCustomUrlWebhook={integration.hasCustomUrlWebhook}
			/>
		</>
	)
}

export default Messages
