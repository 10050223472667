import StorageService from "@/services/Storage"

type InstanceFeatureFlagType =
	"mpegAudioTester" |
	"webhookCustomFields" |
	"blockedSearch" |
	"scheduleMessageBlast" |
	"delayMessageSettings" |
	"activeChatBotFlowReport"

class HardCodedService {
	checkFeatureFlagByInstanceId (type: InstanceFeatureFlagType): boolean {
		const featureFlagInstanceIdsMap: Record<InstanceFeatureFlagType, number[]> = {
			blockedSearch: [],
			delayMessageSettings: [
				3048,
				2230,
				1
			],
			mpegAudioTester: [
				2245,
				2518,
				2397,
				3170,
				3252,
				3192,
				2761
			],
			webhookCustomFields: [
				2230,
				1,
				3090,
				3098,
				1712,
				2487
			],
			scheduleMessageBlast: [
				2230
			],
			activeChatBotFlowReport: [2230, 1, 1712]
		}

		const instanceIds = featureFlagInstanceIdsMap[type] || []

		return instanceIds.includes(this.instanceId)
	}

	private get instanceId (): number {
		const instanceId = StorageService.get(StorageService.reservedKeys.INBOX_INSTANCE_ID) as string

		return Number(instanceId)
	}
}

export default new HardCodedService()
