import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const drawerWidth = 260

const useStyles = makeStyles({
	drawer: {
		height: "calc(100%)",
		background: colors.palette.background,
		width: drawerWidth
	},
	paper: {
		position: "relative",
		borderRight: "1px solid #E0E6ED"
	},
	goToAttendanceButton: {
		fontSize: "14px"
	},
	goToInboxButton: {
		marginTop: "24.7px",
		marginBottom: "16px",
		marginLeft: "4.5%",
		boxShadow: "0px 5px 7px rgba(0, 0, 0, 0.12)"
	},
	logo: {
		width: 115,
		display: "flex"
	},
	closeIcon: {
		color: colors.grayScale[1]
	},
	topInfoContainer: {
		paddingLeft: 13,
		paddingRight: 13
	},
	container: {
		maxHeight: "calc(100vh - 60px)"
	}
})

export default useStyles
