export const openUrlOnlyOnce = (event: unknown, url: string, targetName?: string): Window | null => {
	(event as Event).preventDefault()

	let targetRef = targetName

	if (!targetRef) {
		const newUrl = new URL(url)

		targetRef = `${newUrl.hostname}_${newUrl.port}${newUrl.pathname.split("/")[1]}`
	}

	// open a blank "target" window
	// or get the reference to the existing "target" window
	const winref = window.open("", targetRef, "", true)

	// if the "target" window was just opened, change its url
	try {
		if (winref?.location?.href === "about:blank") {
			winref.location.href = url
		}
	} catch (error) {
		winref?.focus()
	}

	return winref
}
