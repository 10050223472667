import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	list: {
		width: "95%",
		margin: "0 auto"
	},
	listItem: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "6px",
		boxShadow: "3px 3px 16px rgba(0, 0, 0, 0.06)"
	},
	listItemText: {
		fontWeight: "normal"
	},
	nextIcon: {
		color: colors.grayScale[5],
		minWidth: "0px"
	},
	nextIconDisabled: {
		opacity: "0.4"
	},
	drawerHeader: {
		backgroundColor: colors.grayScale[10],
		height: 56
	},
	closeButton: {
		position: "absolute",
		left: theme.spacing(1)
	}
}))

export default useStyles
