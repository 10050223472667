import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	customInputFile: {
		border: "1px solid #d9d9d9",
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(2.5, 1.5, 2.5, 1.5)
	},
	customInputFileError: {
		border: `1px solid ${theme.palette.error.main}`,
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(2.5, 1.5, 2.5, 1.5)
	},
	customInputFileLabel: {
		marginRight: 10,
		whiteSpace: "nowrap",
		maxWidth: "400px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "inline-block"
	}
}))

export default useStyles
