import React from "react"
import { Skeleton } from "@material-ui/lab"

const ChatListFilterSkeleton = () => (
	<Skeleton
		width="100%"
		height={48}
		variant="rect"
	/>
)

export default ChatListFilterSkeleton
