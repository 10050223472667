import React from "react"
import {
	Grid,
	IconButton,
	Link
} from "@material-ui/core"

import {
	Send as SendIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import {
	Divider
} from "@/components"

import { useChatGlobalStateStore, Message } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/styles"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"
import useCustomStyles from "@/styles/custom"
import useFileUpload from "@/hooks/useFileUpload"

import ReplyMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/ReplyMessage"
import MediaPreview from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview"
import VoiceInput from "@/pages/Attendance/Chat/ConversationPanel/Input/VoiceInput"
import FileInput from "@/pages/Attendance/Chat/ConversationPanel/Input/FileInput"
import InputBlockMessage from "@/pages/Attendance/Chat/ConversationPanel/Input/InputBlockMessage"
import TextInput from "@/pages/Attendance/Chat/ConversationPanel/Input/TextInput"
import EmojiPicker from "@/pages/Attendance/Chat/ConversationPanel/Input/EmojiPicker"
import QuickReply from "@/pages/Attendance/Chat/ConversationPanel/Input/QuickReply"

import { ISendableMessage, MessageType } from "@/protocols/channel"
import { BuildedMessage } from "@/protocols/messages"

import MediaService, { Media } from "@/services/Media"

import { isMediaMessage } from "@/utils/message"

type MessageMediaData = {
	name: string
	url: string
	key: string
	file: null | File
}

type BuildMessageInput = {
	inboxChannelId: number
	inboxChannelChatId: number
	type: MessageType
}

const Input: React.FC = () => {
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()

	const classes = useStyles()
	const socket = useSocket()
	const customClasses = useCustomStyles()
	const fileUpload = useFileUpload({ requestPath: "/inbox/channel/chats/media/upload" })

	const currentChatAttendanceStatus = chatGlobalStateStore.chat.current?.attendance?.status
	const isInputBlocked = currentChatAttendanceStatus !== "active"

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			if (chatGlobalStateStore.conversationPanel.media.current.length > 0) {
				chatGlobalStateStore.conversationPanel.media.clear()
			}

			if (chatGlobalStateStore.conversationPanel.replyMessage.current) {
				chatGlobalStateStore.conversationPanel.replyMessage.clear()
			}
		}
	}

	window.addEventListener(
		"keydown",
		eventKeyDownHandler
	)

	const onMessageSent = (sendableMessage: ISendableMessage, sentMessage: Message) => {
		/**
		 * Since we created a temporary message, we remove it
		 * in order to add the correct one that has its data
		 * synced with database.
		 */
		chatGlobalStateStore.message.removeById([sendableMessage.id])

		chatGlobalStateStore.message.add(sentMessage)
	}

	const buildMessage = (input: BuildMessageInput): ISendableMessage => {
		const {
			inboxChannelChatId,
			inboxChannelId,
			type
		} = input

		const builtMessage: ISendableMessage = {
			content: "",
			sequentialId: Date.now(),
			type,
			senderName: "",
			createdAt: String(new Date()),
			id: String(Date.now()),
			inboxChannelChatId,
			inboxChannelId,
			sentByCustomer: true,
			sentByExternalPlatform: false,
			sentBySystem: false,
			read: true,
			deletedAt: null,
			caption: "",
			status: "created",
			feature: "attendance"
		}

		const replyMessage = chatGlobalStateStore.conversationPanel.replyMessage.current

		if (replyMessage) {
			builtMessage.replyMessage = {
				content: replyMessage.content,
				createdAt: replyMessage.createdAt,
				id: replyMessage.id,
				senderName: replyMessage.senderName,
				sentByCustomer: replyMessage.sentByCustomer,
				type: replyMessage.type,
				deletedAt: replyMessage.deletedAt,
				caption: replyMessage.caption
			}
		}

		return builtMessage
	}

	const handleSendMediaMessage = async (media: Media) => {
		if (!chatGlobalStateStore.chat.current || !media || chatGlobalStateStore.conversationPanel.inputBlocked) {
			return
		}

		const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current

		const newMessage = buildMessage({
			inboxChannelChatId: id,
			inboxChannelId,
			type: media.type
		})

		const mediaData: MessageMediaData = {
			name: "",
			url: "",
			key: "",
			file: null
		}

		if (newMessage.type === "file") {
			newMessage.caption = media.data.name
		}

		mediaData.name = media.data.name

		if (media.url) {
			mediaData.url = media.url
			mediaData.key = media.key as string
			newMessage.content = media.url
		} else {
			mediaData.file = media.data
			newMessage.content = URL.createObjectURL(media.data)
		}

		chatGlobalStateStore.message.add({
			...newMessage,
			uploadingMedia: true
		})

		if (mediaData.file) {
			const result = await fileUpload.uploadFile(mediaData.file, "media", {
				inboxChannelChatId: id,
				mediaType: newMessage.type
			})

			if (result) {
				mediaData.url = result.url as string
				mediaData.key = result.key as string
				newMessage.content = result.url
			}
		}

		socket.sendMessage({
			mediaName: mediaData.name,
			mediaUrl: mediaData.url,
			mediaKey: mediaData.key,
			channelType,
			inboxChannelId,
			inboxChannelChatId: id,
			content: "",
			type: newMessage.type,
			replyMessageId: newMessage.replyMessage?.id
		}).then(message => {
			if (message) {
				onMessageSent(newMessage, {
					...newMessage,
					...message,
					uploadingMedia: false,
					/**
					 * We only add caption data to file messages,
					 * since it is the way whatsapp use to handle filename.
					 */
					...(newMessage.type !== "file" && { caption: "" })
				})
			}
		})
	}

	const getActiveChannelIndex = function () {
		const activeChannelId = Number(globalStateStore?.whatsappChannel?.id)
		const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)
		return activeChannelIndex
	}

	const handleSendTextMessage = async (text: string) => {
		if (!chatGlobalStateStore.chat.current || !text || chatGlobalStateStore.conversationPanel.inputBlocked) {
			return
		}

		const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current
		const activeChannelIndex = getActiveChannelIndex()
		const actualChatSettings = globalStateStore?.channels?.[activeChannelIndex]?.settings?.["chat"]
		const signatureSettings = actualChatSettings?.signature
		const signatureText = chatGlobalStateStore.chat.current.type === "user" ? signatureSettings?.individual_signature_text : signatureSettings?.group_signature_text

		const newMessage = buildMessage({
			inboxChannelChatId: id,
			inboxChannelId,
			type: "text"
		})

		newMessage.content = text

		socket.sendMessage({
			channelType,
			inboxChannelId,
			inboxChannelChatId: id,
			content: newMessage.content,
			type: newMessage.type,
			replyMessageId: newMessage.replyMessage?.id
		}).then(message => {
			if (message) {
				onMessageSent(newMessage, {
					...newMessage,
					...message
				})
			}
		})

		chatGlobalStateStore.message.add({
			...newMessage,
			content: `${signatureText}${newMessage.content}`
		})
	}

	const handleSendBatchMessages = () => {
		chatGlobalStateStore.conversationPanel.scrolledBottomList.enableAutoScrollBottom()

		const [media] = chatGlobalStateStore.conversationPanel.media.current
		const text = chatGlobalStateStore.conversationPanel.textInput.value

		if (media) {
			handleSendMediaMessage(media)
		}

		if (text) {
			handleSendTextMessage(text)
		}

		chatGlobalStateStore.conversationPanel.media.clear()
		chatGlobalStateStore.conversationPanel.textInput.clear()
		chatGlobalStateStore.conversationPanel.replyMessage.clear()
	}

	const handleTakeChatAttendance = async () => {
		await chatGlobalStateStore.attendance.takeOnCurrentChat()
	}

	const onQuickReplySelect = (message: BuildedMessage) => {
		if (isMediaMessage(message.type)) {
			const media = MediaService.buildMediaByUrl({
				name: message.mediaName as string,
				type: message.type,
				url: message.content,
				key: message.mediaKey
			})

			chatGlobalStateStore.conversationPanel.media.add([media])
		} else {
			chatGlobalStateStore.conversationPanel.textInput.addText(message.content)
		}
	}

	const handleAddMedia = (medias: Media[]) => {
		chatGlobalStateStore.conversationPanel.media.add(medias)
		chatGlobalStateStore.conversationPanel.textInput.focus()
	}

	return useCustomMemo(() => (
		<Grid
			container
			alignItems="center"
			className={customClasses.chatInputContainer}
		>
			{chatGlobalStateStore.chat.current && (
				<>
					<InputBlockMessage
						blocked={chatGlobalStateStore.conversationPanel.inputBlocked}
					>
						Houve uma queda na conexão e por isso o chat está desativado
						{" "}
						até que a conexão seja reestabelecida.
					</InputBlockMessage>

					<InputBlockMessage
						blocked={isInputBlocked}
					>
						Para iniciar uma conversa, você precisa
						{" "}
						{currentChatAttendanceStatus === "waiting" ? "assumir" : "iniciar"}
						{" "}
						o atendimento.
						{" "}
						<Link
							onClick={handleTakeChatAttendance}
							className={classes.takeAttendanceText}
						>
							{currentChatAttendanceStatus === "waiting" ? (
								"Assumir o atendimento desse cliente"
							) : (
								"Iniciar um atendimento com esse cliente"
							)}
						</Link>
					</InputBlockMessage>

					{chatGlobalStateStore.conversationPanel.replyMessage.current && (
						<>
							<Grid
								container
								alignItems="center"
								justify="center"
							>
								<Grid
									container
									className={classes.inputItemContainer}
								>
									<ReplyMessage
										id={chatGlobalStateStore.conversationPanel.replyMessage.current.id}
										senderName={chatGlobalStateStore.client.current?.nickname || ""}
										content={chatGlobalStateStore.conversationPanel.replyMessage.current.content}
										sentByCustomer={chatGlobalStateStore.conversationPanel.replyMessage.current.sentByCustomer}
										type={chatGlobalStateStore.conversationPanel.replyMessage.current.type}
										caption={chatGlobalStateStore.conversationPanel.replyMessage.current.caption}
										extraData={chatGlobalStateStore.conversationPanel.replyMessage.current.extraData}
									/>
								</Grid>

								<IconButton
									onClick={() => chatGlobalStateStore.conversationPanel.replyMessage.clear()}
								>
									<CloseIcon />
								</IconButton>
							</Grid>

							<Divider orientation="horizontal" size={1} />
						</>
					)}

					<MediaPreview
						medias={chatGlobalStateStore.conversationPanel.media.current}
						onClear={() => chatGlobalStateStore.conversationPanel.media.clear()}
					/>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						alignItems="center"
						justify="center"
					>
						<Grid
							container
						>
							<TextInput
								onSubmit={handleSendBatchMessages}
								ref={chatGlobalStateStore.conversationPanel.textInput.ref}
								onMedia={medias => chatGlobalStateStore.conversationPanel.media.add(medias)}
							/>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1} />

					<Grid
						container
						alignItems="center"
						justify="flex-end"
					>
						<QuickReply
							quickReplies={chatGlobalStateStore.quickReply.list}
							onSelect={onQuickReplySelect}
							onClose={chatGlobalStateStore.conversationPanel.textInput.focus}
							shortcutEnabled={Boolean(chatGlobalStateStore.chat.current)}
							substituteVariables={true}
							withoutTriggerHistoryBackEvent={true}
						/>

						<VoiceInput
							onMedia={audio => chatGlobalStateStore.conversationPanel.media.add([audio])}
						/>

						<EmojiPicker
							onEmoji={(emoji) => chatGlobalStateStore.conversationPanel.textInput.ref.current?.addTextByCursor(emoji)}
						/>

						<FileInput
							onMedia={handleAddMedia}
						/>

						<IconButton
							onClick={handleSendBatchMessages}
						>
							<SendIcon />
						</IconButton>
					</Grid>
				</>
			)}
		</Grid>
	), [
		currentChatAttendanceStatus,
		chatGlobalStateStore.chat.current?.id,
		chatGlobalStateStore.chat.current?.status,
		chatGlobalStateStore.conversationPanel.media.current.length,
		chatGlobalStateStore.conversationPanel?.replyMessage?.current?.id
	])
}

export default Input
