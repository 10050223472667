import React from "react"
import { Grid, CircularProgress } from "@material-ui/core"

import useStyles from "@/components/LoadingOverlay/styles"

export type LoadingOverlayProps = {
	loading: boolean
}

const LoadingOverlay: React.FC<LoadingOverlayProps> = (props) => {
	const { loading, children } = props

	const classes = useStyles()

	return (
		<Grid
			className={classes.container}
		>
			{loading && (
				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.circularProgressContainer}
				>
					<CircularProgress
						color="secondary"
					/>
				</Grid>
			)}

			{children}
		</Grid>
	)
}

export default LoadingOverlay
