import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%"
	},
	adminChip: {
		backgroundColor: "#FBEECC",
		color: colors.grayScale[4],
		height: theme.spacing(3)
	}
}))

export default useStyles
