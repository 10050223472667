import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Avatar,
	Grid
} from "@material-ui/core"

const ClientProfileInfoSkeleton = () => (
	<Grid
		container
		spacing={2}
		style={{ maxWidth: 1200, padding: "0px 10px", height: 745 }}
		xs={12}
	>

		<Grid item xs={4}>
			<Grid item alignItems="center" style={{ padding: "0px 32px 32px 32px" }}>
				<Grid container direction="row" alignItems="center" justifyContent="space-between">
					<Grid item style={{ width: 80, height: 80, fontSize: "64px" }}>
						<Avatar style={{ backgroundColor: "transparent", width: 80, height: 80, fontSize: "64px" }}>
							<Skeleton
								width={80}
								height={80}
								variant="circle"
							/>
						</Avatar>
					</Grid>
					<Grid item>
						<Grid container direction="column">
							<Skeleton width={140} height={50} />
							<Skeleton width={140} height={50} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ padding: "32px 32px 0px 32px" }}>
				<Grid container direction="column" justifyContent="center">
					<Grid item>
						<Skeleton width={220} height={60} />
					</Grid>
					<Grid item>
						<Skeleton width={160} height={30} />
					</Grid>
					<Grid item>
						<Skeleton width={130} height={50} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ padding: 32 }}>
				<Grid container direction="column" justifyContent="center">
					<Grid item>
						<Skeleton width={"70%"} height={60} />
					</Grid>
					<Grid item>
						<Skeleton width={200} height={90} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ padding: "32px 32px 0px 32px" }}>
				<Grid item>
					<Skeleton width={"100%"} height={60} />
				</Grid>
				<Grid item>
					<Skeleton width={"90%"} height={30} />
				</Grid>
				<Grid item>
					<Skeleton width={"90%"} height={30} />
				</Grid>
				<Grid item>
					<Skeleton width={"90%"} height={30} />
				</Grid>
			</Grid>
		</Grid>
		<Grid xs={8} >
			<Grid item>
				<Grid container direction="row">
					<Grid item style={{ marginRight: 20 }}>
						<Skeleton width={150} height={50} />
					</Grid>
					<Grid item>
						<Skeleton width={150} height={50} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ marginTop: 15 }} >
				<Grid container xs={9} direction="row">
					<Grid item xs={5}>
						<Skeleton width={180} height={38} />
					</Grid>
					<Grid item xs={4}>
						<Grid container direction="column">
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ marginTop: 15 }} >
				<Grid container xs={9} direction="row">
					<Grid item xs={5}>
						<Skeleton width={180} height={38} />
					</Grid>
					<Grid item xs={4}>
						<Grid container direction="column">
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid item style={{ marginTop: 15 }} >
				<Grid container xs={9} direction="row">
					<Grid item xs={5}>
						<Skeleton width={180} height={38} />
					</Grid>
					<Grid item xs={4}>
						<Grid container direction="column">
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
							<Skeleton width={120} height={32} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
)
export default ClientProfileInfoSkeleton
