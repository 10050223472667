import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	list: {
		margin: "0 auto"
	},
	listItem: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "6px",
		boxShadow: "3px 3px 16px rgba(0, 0, 0, 0.06)"
	},
	listItemText: {
		fontWeight: "normal"
	},
	nextIcon: {
		color: colors.grayScale[5],
		minWidth: "0px"
	},
	nextIconDisabled: {
		opacity: "0.4"
	}
})

export default useStyles
