import React, { useState } from "react"
import {
	Select,
	MenuItem,
	Typography
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"

import { ActionDialog, Divider } from "@/components"

import { ConstructionResources, ShortChatBotTrigger } from "@/protocols/chatBotConstructor"
import { ChatBotFlowTriggerType } from "@/protocols/chatBot"

import CreateWebhook from "@/pages/Admin/Webhooks/CreateWebhook"

type TriggerCreatorProps = {
	onSave: (flowTrigger: ShortChatBotTrigger) => void
	constructionResources: ConstructionResources
	loadContructionResources: () => Promise<ConstructionResources | null>
}

type TriggerCreatorType = {
	open: (props: TriggerCreatorProps) => void
}

const TriggerCreator: TriggerCreatorType & React.FC<TriggerCreatorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		loadContructionResources
	} = props

	const [opened, setOpened] = useState(true)

	const [triggerSelectedValue, setTriggerSelectedValue] = useState<ChatBotFlowTriggerType>("attendance-started-by-client")

	const handleClose = () => {
		setOpened(false)
	}

	const handleSelectTriggerChange = (triggerType: ChatBotFlowTriggerType) => {
		setTriggerSelectedValue(triggerType)
	}

	const handleSave = () => {
		if (triggerSelectedValue === "webhook-triggered") {
			CreateWebhook.open({
				onSave: async (data) => {
					await loadContructionResources()

					const flowTrigger = constructionResources?.triggers?.find(trigger => trigger.type === "webhook-triggered")

					onSave({
						id: flowTrigger?.id as number,
						type: "webhook-triggered",
						webhook_id: data.id
					})
				}
			})
		} else {
			const flowTrigger = constructionResources.triggers.find(trigger => trigger.type === triggerSelectedValue)

			if (flowTrigger) {
				onSave(flowTrigger)
			}
		}

		handleClose()
	}

	return (
		<ActionDialog
			title="GATILHOS"
			hideCloseButton
			onClose={handleClose}
			openDialog={opened}
			saveText={ triggerSelectedValue === "webhook-triggered" ? "CRIAR UM WEBHOOK" : "SALVAR" }
			onSave={handleSave}
			maxWidth="md"
			fullWidth
		>
			<Typography variant="body1" color="textPrimary">
				Selecione o gatilho para acionar o Chatbot
			</Typography>

			<Divider size={2} orientation="horizontal" />

			<Select
				value={triggerSelectedValue}
				defaultValue={triggerSelectedValue}
				variant="outlined"
				fullWidth
				onChange={(event) => handleSelectTriggerChange(event.target.value as ChatBotFlowTriggerType)}
			>
				{constructionResources.triggers.map((trigger) => (
					<MenuItem
						key={trigger.type}
						value={trigger.type}
					>
						{trigger.description}
					</MenuItem>
				))}
			</Select>

			<Divider size={2} orientation="horizontal" />

		</ActionDialog>
	)
}

TriggerCreator.open = (props: TriggerCreatorProps) => {
	renderComponent(
		"trigger-creator",
		<TriggerCreator
			{...props}
		/>
	)
}

export default TriggerCreator
