import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	qrCodePortlet: {
		borderLeft: "6px solid #0DD682",
		padding: "32px 16px"
	},
	sessionPortlet: {
		padding: "32px 24px"
	},
	boldText: {
		fontWeight: "bold"
	},
	actionButton: {
		width: 220,
		display: "flex",
		justifyContent: "flex-start",

		color: colors.palette.primary,
		background: colors.grayScale[11],
		fontSize: "14px",
		fontWeight: 500
	},
	connectWppText: {
		color: colors.grayScale[4],
		fontWeight: 300
	}
})

export default useStyles
