import React from "react"
import { Typography } from "@material-ui/core"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInputDescription/styles"

type BlockBodyItemInputDescriptionProps = {
	text: string | React.ReactNode | React.ReactNodeArray
	className?: string
}

const BlockBodyItemInputDescription: React.FC<BlockBodyItemInputDescriptionProps> = (props) => {
	const {
		text,
		className
	} = props

	const classes = useStyles()

	return (
		<Typography
			variant="overline"
			color="textPrimary"
			align="left"
			className={`${classes.text} ${className}`}
		>
			{text}
		</Typography>
	)
}

export default BlockBodyItemInputDescription
