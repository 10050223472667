import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

import { Divider, TextCopy, ActionDialog } from "@/components"

import { renderComponent } from "@/utils/node"
import { isCustomWebhook, webhookTemplatesInfo } from "@/utils/webhook"

import { WebhookTemplateType, WebhookType } from "@/protocols/webhook"

type WebhookUrlDialogProps = {
	webhookUrl: string
	saveText?: string
	callback?: () => void,
	webhookType: WebhookType
	webhookTemplateType?: WebhookTemplateType
}

type WebhookUrlDialogType = {
	open: (props: WebhookUrlDialogProps) => void
}

const WebhookUrlDialog: WebhookUrlDialogType & React.FC<WebhookUrlDialogProps> = (props) => {
	const {
		webhookUrl,
		callback,
		saveText,
		webhookType,
		webhookTemplateType
	} = props

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)

		callback?.()
	}

	return (
		<ActionDialog
			title="WEBHOOK"
			openDialog={opened}
			saveText={saveText || "FECHAR"}
			fullWidth={true}
			onSave={handleClose}
			onClose={handleClose}
			hideCloseButton={true}
			maxWidth="md"
		>
			<Grid
				container
				alignContent="center"
			>
				<Grid item xs={12}>
					<Alert severity="warning" icon={false}>
						{isCustomWebhook(webhookType) ? (
							"Esse é o link que deverá ser disparado para iniciar o bot"
						) : (
							`Esse é o link que deverá ser cadastrado no ${webhookTemplateType && webhookTemplatesInfo[webhookTemplateType]?.title} para iniciar o bot`
						)}
					</Alert>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Grid item xs={12}>
					<Grid container direction="row" justifyContent="space-between">
						<Grid item xs={12}>
							<TextCopy
								text={webhookUrl}
								onSuccessMessage="Link copiado com sucesso!"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

WebhookUrlDialog.open = (props: WebhookUrlDialogProps) => {
	renderComponent(
		"webhook-url-dialog",
		<WebhookUrlDialog
			{...props}
		/>
	)
}

export default WebhookUrlDialog
