import OpusRecorder from "opus-recorder"

class AudioService {
	private recorder: OpusRecorder

	constructor () {
		this.recorder = new OpusRecorder({
			/**
			 * It is recommended to use waveWorker since it
			 * has the best performance among all the others. Besides,
			 * we can avoid audio crackling/stuttering due to React
			 * state updates.
			 */
			encoderPath: "/libs/waveWorker.min.js"
		})
	}

	async startRecording (): Promise<void> {
		this.recorder.start()
	}

	async finishRecording (): Promise<Blob> {
		const audio = await new Promise<Blob>(resolve => {
			this.recorder.ondataavailable = arrayBuffer => {
				const blob = new Blob([arrayBuffer], {
					type: "audio/ogg"
				})

				resolve(blob)
			}

			this.recorder.stop()
		})

		return audio
	}
}

export default new AudioService()
