import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"

import ErrorHandlerService from "@/services/ErrorHandler"
import { Notification } from "@/components"
import AuthService from "@/services/Auth"

type InstanceInfo = {
	instance_id: number
}

class InstanceService {
	async getInstances (): Promise<InstanceInfo[] | null> {
		try {
			const { data } = await ApiService
				.get("/user-in-instances/instances")

			return data.userInInstances
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}

	async redirectToInstance (): Promise<void> {
		try {
			const instances = await this.getInstances()

			const instance = instances && instances[0]

			if (instance?.instance_id) {
				StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, instance.instance_id)

				await AuthService.login()

				return
			}

			Notification.warning({
				message: "Você não possui convites. Solicite um para o gestor da organização!"
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}
}

export default new InstanceService()
