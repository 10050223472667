import { MessageType } from "@/protocols/channel"

import colors from "@/styles/colors"

export const isTextMessage = (messageType?: MessageType): boolean => {
	return messageType === "text"
}

export const isMediaMessage = (messageType?: MessageType): boolean => {
	if (!messageType) {
		return false
	}

	const mediaTypes: MessageType[] = [
		"audio",
		"file",
		"picture",
		"video"
	]

	const isMediaMessage = mediaTypes.includes(messageType)

	return isMediaMessage
}

export const hasVariable = (message: string): boolean => {
	const regex = /\{\{\s*\w+\s*\}\}/
	return regex.test(message)
}

export const getMessageColor = (sentByCustomer: boolean): string => {
	return sentByCustomer ? colors.palette.whatsappSentMessage : "#FFFFFF"
}
