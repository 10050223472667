import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	authContainer: {
		height: "100%"
	},
	authContent: {
		height: "100%"
	},
	authWrapper: {
		margin: "auto 0"
	},
	portlet: {
		padding: `${theme.spacing(5)}px 10%`
	},
	logoImg: {
		display: "flex",
		margin: "0 auto",
		height: 50
	},
	tabs: {
		backgroundColor: colors.grayScale[11],
		color: colors.grayScale[4],
		borderRadius: "6px 6px 0px 0px"
	},
	tab: {
		fontSize: "14px",
		fontWeight: "normal"
	},
	signInFormHelperText: {
		marginTop: theme.spacing(1),
		textAlign: "center"
	},
	whiteText: {
		color: colors.grayScale[11]
	},
	whiteInput: {
		backgroundColor: colors.grayScale[11],
		borderWidth: 0
	},
	whiteLabelInput: {
		background: colors.grayScale[11],
		padding: "0 6px",
		borderRadius: "9px"
	},
	whiteBackground: {
		backgroundColor: colors.grayScale[11],
		color: colors.palette.primary
	},
	whiteButton: {
		backgroundColor: colors.grayScale[11],
		color: colors.palette.primary,
		"&:hover": {
			backgroundColor: colors.grayScale[11],
			opacity: 0.9
		}
	}
}))

export default useStyles
