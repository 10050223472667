import React, { useState } from "react"

import { ConnectionFlowSteps } from "@/@integrations/Whatsapp/protocols/connectionFlow"
import { IChannel } from "@/protocols/channel"

import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"
import useDidMount from "@/hooks/useDidMount"
import { useGlobalStateStore } from "@/store/GlobalState"

import GenerateQRCode from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/GenerateQRCode"
import GeneratingQRCode from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/GeneratingQRCode"
import QRCodeGenerated from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeGenerated"
import QRCodeScanned from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeScanned"
import SessionCardItem from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SessionCardItem"

const WhatsappConnectionFlow: React.FC = () => {
	const whatsappConnection = useWhatsappConnection()
	const globalStateStore = useGlobalStateStore()

	const [currentStep, setCurrentStep] = useState<ConnectionFlowSteps>("GenerateQRCode")
	const [lastStep, setLastStep] = useState<ConnectionFlowSteps>("GenerateQRCode")
	const [qrCodeImage, setQrCodeImage] = useState("")

	const whatsappChannelStatus = globalStateStore.whatsappChannel?.status
	const whatsappPhoneNumber = globalStateStore.whatsappChannel?.extraData?.user?.phoneNumber

	const getStepByStatus = (): ConnectionFlowSteps | null => {
		if (whatsappChannelStatus === "authenticating") {
			return "QRCodeGenerated"
		} else if (whatsappChannelStatus === "no-session" && !whatsappPhoneNumber) {
			return "GenerateQRCode"
		} else {
			return "Session"
		}
	}

	const refreshCurrentStepWithStatus = () => {
		const step = getStepByStatus()

		if (step) {
			setCurrentStep(step)
		}
	}

	const handleStartSession = async (): Promise<boolean> => {
		return await whatsappConnection.startSession()
	}

	const handleClearSession = async (): Promise<boolean> => {
		const integrationId = globalStateStore.whatsappChannel?.integrationId

		if (integrationId) {
			return await whatsappConnection.clearSession(integrationId)
		}

		return false
	}

	const handleChangeCurrentStep = (newStep: ConnectionFlowSteps) => {
		setCurrentStep(oldStep => {
			setLastStep(oldStep)

			return newStep
		})
	}

	const resetSteps = async () => {
		handleChangeCurrentStep("GenerateQRCode")

		await handleClearSession()
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onQRCodeGenerated(qrCode => {
			setQrCodeImage(qrCode)
		})
	}

	useDidMount(() => {
		addWhatsappEventListeners()
		refreshCurrentStepWithStatus()
	})

	const isQRCodeStep = currentStep.includes("QRCode")

	if (isQRCodeStep) {
		return (
			<>
				{(currentStep === "GenerateQRCode" || currentStep === "GeneratingQRCode") && (
					<GenerateQRCode
						goToNextStep={() => handleChangeCurrentStep("GeneratingQRCode")}
						resetSteps={resetSteps}
						startQRCodeGeneration={handleStartSession}
						currentStep={currentStep}
						lastStep={lastStep}
					/>
				)}

				{currentStep === "GeneratingQRCode" && (
					<GeneratingQRCode
						goToNextStep={() => handleChangeCurrentStep("QRCodeGenerated")}
						resetSteps={resetSteps}
						currentStep={currentStep}
						lastStep={lastStep}
					/>
				)}

				{(currentStep === "QRCodeGenerated" || currentStep === "QRCodeScanned") && (
					<QRCodeGenerated
						qrCodeImage={qrCodeImage}
						goToNextStep={() => handleChangeCurrentStep("QRCodeScanned")}
						resetSteps={resetSteps}
						currentStep={currentStep}
						lastStep={lastStep}
					/>
				)}

				{(currentStep === "QRCodeScanned") && (
					<QRCodeScanned
						goToNextStep={() => handleChangeCurrentStep("Session")}
						resetSteps={resetSteps}
						currentStep={currentStep}
						lastStep={lastStep}
					/>
				)}
			</>
		)
	} else {
		return (
			<SessionCardItem
				goToNextStep={() => handleChangeCurrentStep("Session")}
				resetSteps={resetSteps}
				currentStep={currentStep}
				lastStep={lastStep}
				inboxChannel={globalStateStore.whatsappChannel as IChannel}
			/>
		)
	}
}

export default WhatsappConnectionFlow
