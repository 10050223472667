import React from "react"
import {
	RadioGroup,
	FormControlLabel,
	Radio,
	Grid,
	Fade
} from "@material-ui/core"

import useStyles from "@/components/RadioSelect/styles"
import useCustomStyles from "@/styles/custom"

export type RadioSelectProps = {
	value: unknown
	onChange: (value: unknown) => void
	options: Array<{
		title: string
		value: unknown
		child?: React.ReactNode
	}>
}

const RadioSelect: React.FC<RadioSelectProps> = (props) => {
	const { options, value, onChange } = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleChange = (newValue: unknown) => {
		onChange(newValue)
	}

	return (
		<RadioGroup
			value={value}
			onChange={({ target }) => handleChange(target.value)}
		>
			{options.map((option, index) => {
				const OptionComponent = (
					<FormControlLabel
						key={index}
						value={option.value}
						control={<Radio />}
						label={option.title}
						classes={{
							label: customClasses.formText
						}}
					/>
				)

				const isOptionSelected = option.value === value

				return (
					<>
						{(option.child && isOptionSelected) ? (
							<Grid>
								{OptionComponent}

								<Fade in>
									<Grid
										className={classes.optionChildContainer}
									>
										{option.child}
									</Grid>
								</Fade>
							</Grid>
						) : (
							OptionComponent
						)}
					</>
				)
			})}
		</RadioGroup>
	)
}

export default RadioSelect
