import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	editButton: {
		display: "none"
	},
	customField: {
		"&:hover $editButton": {
			display: "flex",
			minWidth: 18,
			padding: 0,
			height: 18,
			color: "#757575"
		}
	},
	editCustomFieldIcon: {
		minWidth: 18,
		padding: 0,
		height: 18
	},
	editCustomFieldButton: {
		height: 24,
		padding: 0,
		width: 24,
		paddingTop: 1
	},
	customFieldContainer: {
		padding: "32px 0px"
	},
	customFieldGroupNameSizeContainer: {
		width: 250
	},
	customFieldValueContainer: {
		width: 300
	},
	customFieldValueName: {
		paddingRight: 19
	}
})

export default useStyles
