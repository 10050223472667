import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	nonSelected: {
		"&:before": {
			border: "0.5px solid #EDEDED"
		}
	},
	selected: {
		"&:before": {
			border: `2px solid ${colors.palette.confirm}`
		}
	},
	webhookItemContainer: {
		width: 200,
		height: 75,
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
		borderRadius: "5px",
		position: "relative",
		"&:before": {
			position: "absolute",
			content: "close-quote",
			width: "100%",
			height: "calc(100% - 4px)",
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			borderRadius: "5px"
		}
	},
	webhookItemImg: {
		maxHeight: "100%",
		maxWidth: "100%"
	}
}))

export default useStyles
