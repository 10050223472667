import React from "react"
import {
	Grid,
	Tooltip
} from "@material-ui/core"

import {
	DeviceHub as IntegrationAPIIcon,
	Send as MessageBlastIcon
} from "@material-ui/icons"

import {
	SvgIcon
} from "@/components"

import {
	ReactComponent as ChatBotIcon
} from "@/assets/icons/chat_bot.svg"

import useCommonMessageStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/CommonMessage/styles"

import { Message } from "@/store/ChatGlobalState"

type MessageFeatureProps = {
	message?: Message
	style?: React.CSSProperties
}

const MessageFeature: React.FC<MessageFeatureProps> = (props) => {
	const {
		style,
		message
	} = props

	const commonMessageClasses = useCommonMessageStyles()

	return (
		<>
			{message?.feature === "integration" && (
				<Grid
					style={style}
				>
					<Tooltip
						title={
							message?.integration ? (
								`Essa mensagem foi enviada pela Integração "${message?.integration.title}"`
							) : (
								"Essa mensagem foi enviada pela API de Integração."
							)
						}
					>
						<IntegrationAPIIcon
							fontSize="small"
							className={commonMessageClasses.infoIcon}
						/>
					</Tooltip>
				</Grid>
			)}

			{message?.feature === "message-blast" && (
				<Grid
					style={style}
				>
					<Tooltip
						title="Essa mensagem foi enviada pelo Envio em Massa."
					>
						<MessageBlastIcon
							fontSize="small"
							className={commonMessageClasses.infoIcon}
						/>
					</Tooltip>
				</Grid>
			)}

			{message?.feature === "chat-bot" && (
				<Grid
					style={style}
				>
					<Tooltip
						title={
							message?.chatBot?.chatBotFlowName ? (
								`Essa mensagem foi enviada pelo Bot "${message?.chatBot?.chatBotFlowName}"`
							) : (
								"Essa mensagem foi enviada pelo Bot."
							)
						}
					>
						<Grid item>
							<SvgIcon
								fontSize="small"
								className={commonMessageClasses.infoIcon}
								icon={ChatBotIcon}
							/>
						</Grid>
					</Tooltip>
				</Grid>
			)}
		</>
	)
}

export default MessageFeature
