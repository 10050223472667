import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	qrCodeInstructionContainer: {
		flex: 1
	},
	qrCodeInstructionContent: {
		order: 1,
		[theme.breakpoints.down("sm")]: {
			order: 2
		}
	}
}))

export default useStyles
