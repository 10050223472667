import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	container: {
		backgroundColor: "#F6F6F6",
		boxShadow: "none",
		margin: "0px !important"
	},
	box: {},
	filterInput: {
		backgroundColor: colors.grayScale[11],
		"& fieldset": {
			borderColor: "transparent",
			borderRadius: 3
		}
	},
	grayIcon: {
		color: colors.grayScale[6]
	},
	dateContainer: {
		flex: 1,
		"& input": {
			fontSize: "14px",
			height: "19px",
			marginLeft: "4px"
		},
		"& button": {
			width: "40px",
			height: "40px"
		},
		"& div": {
			paddingLeft: "0px",
			marginRight: "0px"
		}
	}
})

export default useStyles
