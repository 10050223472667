import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	statusColorRed: {
		backgroundColor: "#F8DAD3"
	},
	statusColorGreen: {
		backgroundColor: "#D4F8D3"
	},
	statusColorGrey: {
		backgroundColor: "#E0E6ED"
	},
	statusColorYellow: {
		backgroundColor: "#FBEECC"
	},
	webhookBodyIcon: {
		width: 24,
		height: 24,
		overflow: "overlay",
		position: "absolute",
		top: 8,
		left: 10,
		color: colors.grayScale[1]
	},
	webhookBodyIconButton: {
		height: 54,
		width: 54,
		position: "relative",
		padding: 0
	}
})

export default useStyles
