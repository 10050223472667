import {
	useMediaQuery,
	useTheme
} from "@material-ui/core"

import {
	Breakpoint
} from "@material-ui/core/styles/createBreakpoints"

type UseBreakpointProps = {
	type: "down" | "up"
	breakpoint: Breakpoint
}

const useBreakpoint = (props: UseBreakpointProps) => {
	const MuiTheme = useTheme()

	const result = useMediaQuery(MuiTheme.breakpoints[props.type](props.breakpoint))

	return result
}

export default useBreakpoint
