import React from "react"

import { WebhookTemplateType, WebhookType } from "@/protocols/webhook"

import ActiveCampaignLogo from "@/assets/images/integrations/activecampaign.png"
import RDStationLogo from "@/assets/images/integrations/rd-station.png"
import HubSpotLogo from "@/assets/images/integrations/hubspot.png"

import ActiveCampaignIcon from "@/assets/icons/active_campaign.svg"
import RDStationIcon from "@/assets/icons/rd_station.svg"

type WebhookTemplates = {
	[key in WebhookTemplateType]: {
		logo: string
		description: string
		tutorialUrl: string
		title: string
		customStyles?: React.CSSProperties
		svgIcon?: string
	}
}

export const webhookTemplatesInfo: WebhookTemplates = {
	"active-campaign": {
		logo: ActiveCampaignLogo,
		description: "Integração com o ActiveCampaign",
		title: "Active Campaign",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=ES8Pj7PioOQ",
		svgIcon: ActiveCampaignIcon
	},
	"rd-station": {
		logo: RDStationLogo,
		description: "Integração com a RD Station",
		title: "RD Station",
		tutorialUrl: "https://www.youtube.com/embed/watch?v=5TUvlIhGB6M",
		svgIcon: RDStationIcon
	},
	hubspot: {
		logo: HubSpotLogo,
		description: "Integração com a HubSpot",
		title: "HubSpot",
		tutorialUrl: ""
	}
}

export const isCustomWebhook = (webhookType?: WebhookType) => {
	return webhookType === "custom"
}
