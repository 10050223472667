import React, { useState } from "react"
import {
	Grid,
	TextField,
	Chip
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { renderComponent } from "@/utils/node"
import { getCurrentGoToFlowOption } from "@/utils/chatBot"

import { ActionDialog } from "@/components"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/GoToFlowBlock/FlowEditor/styles"

import { ChatBotFlowBlockContent } from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"
import { ExtractArrayElementType } from "@/protocols/utility"

type FlowEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

type FlowEditorType = {
	open: (props: FlowEditorProps) => void
}

const FlowEditor: FlowEditorType & React.FC<FlowEditorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		chatBotFlowBlockContent
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)

		handleClose()
	}

	const handleChangeContent = (content: Partial<ChatBotFlowBlockContent>) => {
		setBlockContent(lastState => ({
			...lastState,
			...content
		}))
	}

	const getOptionName = (option: ExtractArrayElementType<ConstructionResources["goToFlowOptions"]>) => {
		const status = option.active ? "Ativo" : "Inativo"
		const activationInfo = option.active ? "" : ". Não é possível direcionar"

		const optionName = `${option.name} (${status})${activationInfo}`

		return optionName
	}

	return (
		<ActionDialog
			title="DIRECIONAMENTO PARA BOT"
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Autocomplete
						defaultValue={getCurrentGoToFlowOption(constructionResources, chatBotFlowBlockContent)}
						options={constructionResources.goToFlowOptions}
						getOptionSelected={(option, value) => option.chatBotFlowId === value.chatBotFlowId}
						getOptionLabel={(option) => getOptionName(option)}
						getOptionDisabled={(option) => !option.active}
						fullWidth
						filterSelectedOptions
						onChange={(_, option) => {
							if (option) {
								handleChangeContent({
									goToChatBotFlowId: option.chatBotFlowId
								})
							}
						}}
						renderInput={(params) => {
							return (
								<TextField
									className={classes.filterInput}
									variant="outlined"
									placeholder="Selecione um Bot"
									{...({
										...params,
										InputProps: {
											...params.InputProps
										}
									})}
								/>
							)
						}}
						renderTags={(value, getTagProps) => {
							return value.map((attendant, index) => (
								<Chip
									key={index}
									label={attendant.name}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

FlowEditor.open = (props: FlowEditorProps) => {
	renderComponent(
		"flow-editor",
		<FlowEditor
			{...props}
		/>
	)
}

export default FlowEditor
