import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const customColors = colors
const useStyles = makeStyles(() => ({
	boxIntroductionOrganize: {
		flexDirection: "column",
		justifyContent: "inherit",
		display: "flex",
		maxWidth: 524
	},
	fieldExplanation: {
		fontSize: 20,
		fontWeight: 400,
		paddingLeft: 0,
		color: customColors.grayScale[4]
	},
	customFieldName: {
		fontSize: 32,
		marginBottom: 10,
		fontWeight: 700,
		lineHeight: 1.8
	}
}))

export default useStyles
