import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	takeAttendanceText: {
		textDecoration: "underline",
		cursor: "pointer"
	},
	inputItemContainer: {
		flex: 1,
		paddingRight: theme.spacing(2),
		position: "relative"
	}
}))

export default useStyles
