import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	Typography,
	Grid
} from "@material-ui/core"
import {
	Add as NewIntegrationIcon
} from "@material-ui/icons"

import {
	Divider,
	Loading,
	Portlet
} from "@/components"

import { IntegrationData, IntegrationPlatform, IntegrationType } from "@/protocols/integration"

import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Integration/styles"

import IntegrationListItem from "@/pages/Admin/Integration/IntegrationListItem"
import NewIntegrationDialog from "@/pages/Admin/Integration/NewIntegrationDialog"

import IntegrationSkeleton from "@/skeletons/Admin/Integration"

import exampleImg from "@/assets/images/integrations/example.png"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

const Integration: React.FC = () => {
	const customClasses = useCustomStyles()
	const classes = useStyles()
	const history = useHistory()

	const [loading, setLoading] = useState(true)

	const [integrations, setIntegrations] = useState<IntegrationData[]>([])
	const [integrationPlatforms, setIntegrationPlatforms] = useState<IntegrationPlatform[]>([])
	const [newIntegrationDialogOpened, setNewIntegrationDialogOpened] = useState(false)

	const handleOpenNewIntegrationDialog = () => {
		setNewIntegrationDialogOpened(true)
	}

	const handleCloseNewIntegrationDialog = () => {
		setNewIntegrationDialogOpened(false)
	}

	const loadIntegrations = async () => {
		try {
			const response = await ApiService.get("/integrations")
			const formattedIntegrations = response.data.integrations.map((integ: {
				id: number
				title: string
				integration_platform: { type: IntegrationType }
				webhook_hash: string
			}) => ({
				id: integ.id,
				title: integ.title,
				type: integ.integration_platform.type
			}))

			setIntegrations(formattedIntegrations)
		} catch (err) {
			ErrorHandler.handle(err)
		}
	}

	const loadIntegrationPlatforms = async () => {
		try {
			const response = await ApiService.get("/integrations/platforms")

			const formattedPlatforms = response.data.integrationPlatforms.map((platform: {
				id: number
				title: string
				type: IntegrationType
			}) => ({
				id: platform.id,
				title: platform.title,
				type: platform.type
			}))

			setIntegrationPlatforms(formattedPlatforms)
		} catch (err) {
			ErrorHandler.handle(err)
		}
	}

	const handleOpenIntegrationManagement = (integrationId: number) => {
		history.push(`/admin/integration/${integrationId}`)
	}

	const setup = async () => {
		await Promise.all([
			loadIntegrations(),
			loadIntegrationPlatforms()
		])

		setLoading(false)
	}

	useDidMount(() => {
		setup()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<IntegrationSkeleton />}
		>
			<Grid
				container
				direction="column"
			>
				{integrations.length > 0 ? (
					<>
						<Grid
							item
						>
							<Button
								variant="contained"
								className={customClasses.buttonAction}
								onClick={handleOpenNewIntegrationDialog}
							>
								NOVA INTEGRAÇÃO
							</Button>
						</Grid>

						<Divider size={3} orientation="horizontal" />

						<Typography
							variant="h4"
							color="textPrimary"
						>
							INTEGRAÇÕES CADASTRADAS
						</Typography>

						<Divider size={3} orientation="horizontal" />

						<Grid
							container
						>
							<Grid
								container
								spacing={2}
							>
								{integrations.map((integration) => (
									<Grid
										item
										xs={12}
										key={integration.id}
									>
										<IntegrationListItem
											title={integration.title}
											type={integration.type}
											integrationId={integration.id}
										/>
									</Grid>
								))}
							</Grid>
						</Grid>
					</>
				) : (
					<Portlet
						elevation={1}
					>
						<Grid
							container
							alignItems="center"
							justify="space-between"
							className={classes.noIntegrationContainer}
						>
							<Grid
								container
								direction="column"
								className={classes.noIntegrationTextContainer}
							>
								<Typography
									variant="h3"
									className={classes.noIntegrationText}
								>
									VOCÊ AINDA NÃO FEZ NENHUMA INTEGRAÇÃO.
									<br />
									CRIE A SUA AGORA MESMO!
								</Typography>

								<Divider orientation="horizontal" size={3} />

								<Button
									variant="contained"
									color="primary"
									startIcon={<NewIntegrationIcon />}
									className={classes.noIntegrationButton}
									onClick={handleOpenNewIntegrationDialog}
								>
									CRIAR UMA INTEGRAÇÃO
								</Button>
							</Grid>

							<img
								src={exampleImg}
								alt="Exemplo de integrações"
								className={classes.noIntegrationImage}
							/>
						</Grid>
					</Portlet>
				)}

				<NewIntegrationDialog
					integrationPlatforms={integrationPlatforms}
					onCreate={(integrationId) => handleOpenIntegrationManagement(integrationId)}
					opened={newIntegrationDialogOpened}
					onClose={handleCloseNewIntegrationDialog}
				/>
			</Grid>
		</Loading>
	)
}

export default Integration
