import React from "react"
import {
	Grid,
	InputAdornment,
	OutlinedInput
} from "@material-ui/core"

import { BlockConditionEditorInputDefaultProps } from "@/protocols/chatBotConstructor"

const MaxInvalidResponsesInput: React.FC<BlockConditionEditorInputDefaultProps> = (props) => {
	const {
		changeValidation,
		validation
	} = props

	const value = validation.second_param

	return (
		<>
			<Grid
				item
				xs={6}
			>
				<OutlinedInput
					type="number"
					placeholder="3"
					fullWidth
					value={value}
					onChange={(event) => changeValidation({ second_param: event.target.value })}
					endAdornment={(
						<InputAdornment
							position="end"
						>
							mensagens
						</InputAdornment>
					)}
				/>
			</Grid>
		</>
	)
}

export default MaxInvalidResponsesInput
