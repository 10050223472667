import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	emojiContainer: {
		"& .emoji-mart-bar:last-child": {
			display: "none"
		},
		"& .emoji-mart-scroll button": {
			outline: "none"
		},
		"& .emoji-mart-search": {
			display: "none"
		}
	}
})

export default useStyles
