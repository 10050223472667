import { makeStyles } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"

export const CHAT_LEFT_SIDE_WIDTH = "418px"

const useStyles = makeStyles(theme => ({
	chatDrawer: {
		width: "100%",
		height: "100%",
		display: "flex",
		flex: 1
	},
	chatDrawerPaper: {
		position: isSmallScreen ? "absolute" : "relative",
		width: "100%"
	},
	chatRightSideContainer: {
		flex: 1,
		backgroundColor: "#E5E0DB",
		maxHeight: "100%",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	fixedHeightContainer: {
		height: "100%",
		flex: 1,
		position: "relative"
	},
	chatHeader: {
		backgroundColor: "#EDEDED",
		padding: theme.spacing(1, 2)
	}
}))

export default useStyles
