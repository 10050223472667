import React from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"

import Divider from "@/components/Divider"

import useStyles from "@/components/ProductReportHeader/styles"

type ProductReportHeaderProps = {
	title: string | React.ReactElement
	subTitle?: string | React.ReactElement
	description?: string | React.ReactElement
}

const ProductReportHeader: React.FC<ProductReportHeaderProps> = (props) => {
	const {
		title,
		description,
		subTitle
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
		>
			<Typography
				className={classes.title}
				variant="button"
				color="textPrimary"
			>
				{title}
			</Typography>

			{subTitle && (
				<>
					<Divider orientation="horizontal" size={2} />

					<Typography
						className={classes.subTitle}
						variant="h4"
						color="textPrimary"
					>
						{subTitle}
					</Typography>
				</>
			)}

			{description && (
				<>
					<Divider orientation="horizontal" size={2} />

					<Typography
						className={classes.description}
						variant="overline"
						color="textPrimary"
					>
						{description}
					</Typography>
				</>
			)}
		</Grid>
	)
}

export default ProductReportHeader
