import React from "react"
import {
	Button,
	Grid,
	Typography,
	Chip,
	Tooltip
} from "@material-ui/core"
import {
	Forum as ChatIcon,
	Wifi as ConnectedIcon,
	WhatsApp as WhatsAppIcon,
	Settings as SettingsIcon
} from "@material-ui/icons"
import { Link as LinkRouter } from "react-router-dom"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"
import { IChannel } from "@/protocols/channel"

import { deviceIsMobile } from "@/utils/checkDevice"
import { formatPhoneNumber } from "@/utils/mask"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import {
	ComputedStatus,
	getComputedStatus,
	getStatusColor,
	getStatusIcon,
	getStatusMessage
} from "@/utils/channelStatus"

import { Divider, Portlet, PopConfirm } from "@/components"

import colors from "@/styles/colors"

import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/SessionCardItem/styles"
import useBreakpoint from "@/hooks/useBreakpoint"
import { Alert } from "@material-ui/lab"
import { useGlobalStateStore } from "@/store/GlobalState"

export type SessionCardItemProps = ConnectionFlowComponentDefaultProps & {
	inboxChannel: IChannel
}

const SessionCardItem: React.FC<SessionCardItemProps> = (props) => {
	const {
		resetSteps,
		inboxChannel
	} = props

	const globalStateStore = useGlobalStateStore()

	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const classes = useStyles()
	const whatsappConnection = useWhatsappConnection()
	const isSmall = useBreakpoint({ type: "down", breakpoint: "sm" })

	const handleReset = () => {
		const props = {
			title: "Deseja encerrar sessão?",
			confirmButtonText: "ENCERRAR",
			onConfirm: () => resetSteps()
		}
		PopConfirm.open(props)
	}

	const reset = () => {
		resetSteps()
	}

	const getStatusChip = () => {
		const statusColor = getStatusColor(inboxChannel.status)
		const statusIcon = getStatusIcon(inboxChannel.status)

		const statusTagMap: Record<ComputedStatus, React.ReactNode> = {
			connected: (
				<Chip
					icon={statusIcon}
					className={classes.statusChip}
					label="Conectado"
					style={{ backgroundColor: statusColor }}
				/>
			),
			disconnected: (
				<Chip
					icon={statusIcon}
					className={classes.statusChip}
					label="Desconectado"
					style={{ backgroundColor: statusColor }}
				/>
			),
			reconnecting: (
				<Chip
					icon={statusIcon}
					className={classes.statusChip}
					label="Reconectando..."
					style={{ backgroundColor: statusColor }}
				/>
			)
		}

		const computedStatus = getComputedStatus(inboxChannel.status)

		return statusTagMap[computedStatus]
	}

	const getStatusMessageInfo = () => {
		const statusMessage = getStatusMessage(inboxChannel.status)

		const statusMessageInfoMap: Record<ComputedStatus, React.ReactNode> = {
			connected: statusMessage,
			disconnected: (
				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.statusMessageContainer}
					style={{ backgroundColor: "#EFC7CB" }}
				>
					<Typography
						variant="body1"
						align="center"
						className={classes.statusMessageText}
					>
						{statusMessage}
					</Typography>
				</Grid>
			),
			reconnecting: (
				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.statusMessageContainer}
					style={{ backgroundColor: "#F9D3B7" }}
				>
					<Typography
						variant="body1"
						align="center"
						className={classes.statusMessageText}
					>
						{statusMessage}
					</Typography>
				</Grid>
			)
		}

		const computedStatus = getComputedStatus(inboxChannel.status)

		return statusMessageInfoMap[computedStatus]
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onWhatsappLoggedOut(() => {
			resetSteps()
		})
	}

	const formatDate = (dateInString: string | undefined) => {
		if (!dateInString) {
			return ""
		}

		const date = new Date(dateInString)

		const formattedDate = `${formatDateInBrazilianDate(date)} às ${formatDateInHours(date)}`

		return formattedDate
	}

	useDidMount(() => {
		addWhatsappEventListeners()
	})

	const statusColor = getStatusColor(inboxChannel.status)
	const statusChip = getStatusChip()
	const statusMessageInfo = getStatusMessageInfo()
	const computedStatus = getComputedStatus(inboxChannel.status)
	const phoneNumber = inboxChannel?.extraData?.user?.phoneNumber

	return (
		<Portlet
			style={{
				borderLeft: `8px solid ${statusColor}`,
				backgroundColor: colors.grayScale[11],
				padding: 16
			}}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					container
					alignContent="center"
					alignItems="center"
					justify="space-between"
				>
					{
							inboxChannel?.extraData?.loggedInfo?.multiDevice &&
							<Grid>
								<Alert severity="error">
									Você está usando o whatsapp beta com a função de múltiplos dispositivos, nossa plataforma não oferece suporte completo para essa funcionalidade, aconselhamos desabilitá-la.
								</Alert>
								<Divider orientation="horizontal" size={2} />
							</Grid>
					}
					<Grid
						container
						direction="column"
						className={classes.equalSpacedContainer}
					>
						<Grid
							container
							alignItems="center"
						>
							<Grid
								container
								alignItems="center"
								justify="center"
								className={classes.whatsappIconContainer}
								style={{
									backgroundColor: computedStatus === "connected" ? "#5ABE66" : "#ACB5BD"
								}}
							>
								<WhatsAppIcon
									className={classes.whatsappIcon}
								/>
							</Grid>

							<Divider orientation="vertical" size={2} />

							<Typography
								variant="body1"
								className={classes.phoneNumberText}
							>
								{formatPhoneNumber(phoneNumber)}
							</Typography>
						</Grid>

						<Divider orientation="horizontal" size={1} />

						<Grid
							container
							direction="row"
						>
							<Grid
								item
							>
								<Typography
									variant="caption"
									color="textPrimary"
									style={{
										...(computedStatus === "disconnected" && { color: statusColor })
									}}
								>
									{computedStatus === "disconnected" ? (
										`Sem sessão desde ${formatDate(inboxChannel?.syncControlData?.last_authentication_revoked_date)}`
									) : (
										`Sessão ativa desde ${formatDate(inboxChannel?.syncControlData?.last_successful_connection_date)} |`
									)}
								</Typography>
							</Grid>
							<Divider orientation="vertical" size={0.5}/>
							<Grid
								item
							>
								{computedStatus !== "disconnected" && (
									<Typography
										variant="caption"
										className={classes.disconnectButton}
										onClick={handleReset}
									>
										Encerrar sessão
									</Typography>
								)
								}
							</Grid>
						</Grid>
					</Grid>

					<Grid
						container
						className={classes.equalSpacedContainer}
					>
						{statusChip}
					</Grid>

					<Grid
						container
						className={classes.equalSpacedContainer}
					>
						{computedStatus === "disconnected" && (
							<Button
								disableElevation
								startIcon={<ConnectedIcon />}
								variant="contained"
								onClick={reset}
								className={`${whatsappConnectionFlowClasses.actionButton} ${classes.connectButton}`}
							>
								CONECTAR
							</Button>
						)}

						<Divider orientation={isSmall ? "horizontal" : "vertical"} size={2} />

						<Button
							disableElevation
							startIcon={<ChatIcon />}
							variant="contained"
							component={LinkRouter}
							to={"/attendance"}
							target={deviceIsMobile() ? "_SELF" : "_BLANK"}
							className={`${whatsappConnectionFlowClasses.actionButton} ${classes.openInboxButton}`}
						>
							IR PARA INBOX
						</Button>
						<Tooltip
							title="Configurações do canal"
						>
							<Button
								disableRipple
								disableElevation
								component={LinkRouter}
								variant="contained"
								to={`/admin/settings/inbox-channel/${globalStateStore.whatsappChannel?.id}`}
								className={classes.channelSettingsButton}
							>
								<SettingsIcon />
							</Button>
						</Tooltip>
					</Grid>
				</Grid>

				{statusMessageInfo && (
					<>
						<Divider orientation="horizontal" size={2} />

						<Grid>
							{statusMessageInfo}
						</Grid>
					</>
				)}
			</Grid>
		</Portlet>
	)
}

export default SessionCardItem
