import React, { useState } from "react"
import {
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Button,
	CircularProgress,
	Typography,
	Avatar
} from "@material-ui/core"

import { Close as CloseIcon } from "@material-ui/icons"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader/NewChatButton/StartChatDialog/styles"
import { Client } from "@/protocols/clientCatalog"
import { Divider } from "@/components"
import { keepOnlyDigits } from "@/utils/phone"
import { formatPhoneNumber } from "@/utils/mask"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

type StartChatDialogProps = {
	open: boolean
	onClose: () => void
	client: Client
	onStartChat?: (client: Client) => Promise<null | string>
}

const StartChatDialog: React.FC<StartChatDialogProps> = ({
	open,
	onClose,
	onStartChat = () => null,
	client
}) => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<null | string>(null)
	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()

	const whatsappContactPhoneNumber = chatGlobalStateStore.client.getWhatsappPhoneNumber(client)

	const innerOnStartChat = async (client: Client) => {
		setLoading(true)

		const errorMessage = await onStartChat(client)

		setError(errorMessage)

		setLoading(false)
	}

	const getClientName = () => {
		if (!client) {
			return ""
		}

		if (keepOnlyDigits(client.nickname) === whatsappContactPhoneNumber) {
			return client.name || client.nickname
		}

		return client.nickname
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
		>
			<IconButton onClick={onClose} className={classes.closeIcon}>
				<CloseIcon />
			</IconButton>

			<DialogTitle>
				Iniciar atendimento
			</DialogTitle>

			<DialogContent>
				<Grid container direction="column" alignItems="center">
					<Grid item className={classes.avatarContainer}>
						<Avatar className={classes.avatar} src={client?.picture_url} />
					</Grid>

					<Divider orientation="horizontal" size={3} />

					<Grid item >
						<Typography
							variant="h2"
							color="textPrimary"
							style={{ fontSize: "15px" }}
						>
							{getClientName()}
						</Typography>
					</Grid>

					{
						whatsappContactPhoneNumber && (
							<>
								<Divider orientation="horizontal" size={0.5} />

								<Grid item>
									<Typography color="textSecondary" variant="body2">
										{formatPhoneNumber(whatsappContactPhoneNumber)}
									</Typography>
								</Grid>
							</>
						)
					}

					<Divider orientation="horizontal" size={2} />

					<Grid item>
						<Button
							variant="contained"
							color="primary"
							onClick={() => { innerOnStartChat(client) }}
							disabled={loading}
							endIcon={loading && <CircularProgress size={20} />}
						>
							INICIAR ATENDIMENTO
						</Button>
					</Grid>

					<Grid item>
						{error && (
							<>
								<Divider orientation="horizontal" size={1} />

								<Typography align="center" color="error" variant="body2">
									{error}
								</Typography>
							</>
						)}
					</Grid>

					<Divider orientation="horizontal" size={1} />
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default StartChatDialog
