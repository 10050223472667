import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	routesContainer: {
		height: "100%",
		flex: 1
	},
	routesContent: {
		height: "100%"
	},
	attendanceContainer: {
		height: "100%"
	}
})

export default useStyles
