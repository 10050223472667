import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	header: {
		minHeight: theme.spacing(5),
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(3, 2)
	},
	headerChildren: {
		flex: 1
	},
	deleteIcon: {
		color: colors.grayScale[11],
		width: theme.spacing(2),
		height: theme.spacing(2),
		backgroundColor: "rgba(73, 80, 87, 0.2)",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: "rgba(73, 80, 87, 0.1)"
		},
		"& svg": {
			width: theme.spacing(2),
			height: theme.spacing(2)
		}
	}
}))

export default useStyles
