import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	listItemLogo: {
		maxHeight: 75,
		marginRight: theme.spacing(1)
	},
	listItemTitle: {
		flex: 1,
		fontFamily: "IBM Plex Sans",
		color: colors.grayScale[4],
		fontSize: "16px",
		fontWeight: 600,
		textTransform: "capitalize",
		lineHeight: "19px"
	},
	listItemDescription: {
		flex: 1
	},
	listItemDescription2: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	},
	listItem: {
		paddingLeft: theme.spacing(2)
	},
	listItemIcon: {
		display: "flex",
		justifyContent: "flex-start",
		paddingLeft: theme.spacing(0.25)
	},
	editIcon: {
		color: "#495057",
		fontSize: "20px"
	},
	listItemData: {
		opacity: "0.5",
		fontSize: "14px"
	},
	clockIcon: {
		width: "16px",
		height: "16px",
		color: colors.grayScale[4],
		opacity: "0.5",
		marginRight: "10px"
	},
	listItemButton: {
		background: "#E0E6ED",
		opacity: "0.7",
		width: "64px",
		height: "64px",
		borderRadius: "8px",
		gap: "8px",
		"&:hover": {
			backgroundColor: "#D5D5D5"
		}
	},
	chip: {
		color: colors.grayScale[4],
		fontSize: 14,
		padding: "4px 16px"
	},
	iconRoot: {
		textAlign: "center",
		fontSize: "0px"
	},
	icon: {
		height: "34px"
	}
}))

export default useStyles
