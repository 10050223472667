import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	chatAttendantName: {
		fontSize: 10
	},
	chatAttendantIcon: {
		fontSize: 14
	},
	chatTitle: {},
	chatTitleName: {
		paddingLeft: theme.spacing(0.5)
	},
	badgeContainer: {
		width: 22,
		height: 22,
		backgroundColor: colors.grayScale[11],
		borderRadius: "100%",
		border: `3px solid ${colors.grayScale[11]}`
	},
	selectedChatListItem: {
		backgroundColor: "#EBEBEB"
	},
	chatListItem: {
		width: "100%"
	},
	chatDescriptionContainer: {
		flex: 1,
		maxHeight: 16,
		height: 16,
		"& svg": {
			width: 16,
			height: 16
		},
		"& span": {
			fontSize: 12,
			lineHeight: "16px"
		}
	},
	chatDescription: {
		color: colors.grayScale[4]
	},
	messageStatus: {},
	chatOptionsButton: {
		minHeight: 24,
		height: 24,
		width: 24,
		boxShadow: "none",
		backgroundColor: "transparent"
	},
	chatInfoContainer: {
		width: "auto",
		marginLeft: theme.spacing(1),
		"& > *": {
			cursor: "pointer"
		}
	},
	chatUnreadMessagesText: {
		fontSize: 10,
		marginBottom: theme.spacing(0.5)
	},
	chatListItemContainer: {
		listStyleType: "none"
	},
	chatClientTag: {
		width: 5,
		height: 5,
		borderRadius: "100%"
	}
}))

export default useStyles
