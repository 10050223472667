import SocketService from "@/services/Socket"
import ErrorHandlerService from "@/services/ErrorHandler"

import {
	ExtraSocketClientEvents,
	ExtraSocketServerEvents,
	OnClearWhatsappSessionEventResponse,
	OnQRCodeGeneratedEventResponse,
	OnWhatsappHistorySyncProgressEventResponse,
	OnStartWhatsappSessionEventResponse
} from "@/@integrations/Whatsapp/protocols/channel"

const useWhatsappConnection = () => {
	const startSession = async () => {
		try {
			const { result } = await SocketService.emit<OnStartWhatsappSessionEventResponse, ExtraSocketServerEvents>(
				"StartWhatsappSession",
				"whatsapp",
				{}
			)

			if (result === "SessionCreated") {
				return true
			} else {
				return false
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
			return false
		}
	}

	const clearSession = async (integrationId: string) => {
		try {
			await SocketService.emit<OnClearWhatsappSessionEventResponse, ExtraSocketServerEvents>(
				"ClearWhatsappSession",
				"whatsapp",
				{
					inboxChannel: {
						integrationId
					}
				}
			)

			return true
		} catch (error) {
			ErrorHandlerService.handle(error)
			return false
		}
	}

	const forceReconnectSession = async (integrationId: string) => {
		try {
			await SocketService.emit<OnClearWhatsappSessionEventResponse, ExtraSocketServerEvents>(
				"ForceReconnectWhatsappSession",
				"whatsapp",
				{
					inboxChannel: {
						integrationId
					}
				}
			)

			return true
		} catch (error) {
			ErrorHandlerService.handle(error)
			return false
		}
	}

	const onQRCodeGenerated = (callback: (src: string) => void) => {
		SocketService.on<OnQRCodeGeneratedEventResponse, ExtraSocketClientEvents>("QRCodeGenerated", (data) => {
			const formattedBase64Image = `data:image/png;base64, ${data.QRCodeBase64Image}`

			callback(formattedBase64Image)
		})
	}

	const onWhatsappAuthenticated = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("WhatsappAuthenticated", callback)
	}

	const onQRCodeScanned = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("QRCodeScanned", callback)
	}

	const onWhatsappDisconnected = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("WhatsappDisconnected", callback)
	}

	const onWhatsappLoggedOut = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("WhatsappLoggedOut", callback)
	}

	const onQRCodeFailed = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("QRCodeFailed", callback)
	}

	const onQRCodeTimedOut = (callback: () => void) => {
		SocketService.on<null, ExtraSocketClientEvents>("QRCodeTimedOut", callback)
	}

	const onWhatsappHistorySyncProgress = (callback: (progressInPercentage: number) => void) => {
		SocketService.on<OnWhatsappHistorySyncProgressEventResponse, ExtraSocketClientEvents>("WhatsappHistorySyncProgress", (data) => {
			callback(data.historySyncProgressInPercentage)
		})
	}

	return {
		onQRCodeScanned,
		onWhatsappAuthenticated,
		onQRCodeGenerated,
		onWhatsappDisconnected,
		onWhatsappLoggedOut,
		onQRCodeFailed,
		onQRCodeTimedOut,
		onWhatsappHistorySyncProgress,
		startSession,
		clearSession,
		forceReconnectSession
	}
}

export default useWhatsappConnection
