import React, { useState } from "react"
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography
} from "@material-ui/core"
import { Person as ActiveFlowIcon } from "@material-ui/icons"

import { ActionDialog, ProductReportClient } from "@/components"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/ActiveFlowReport/styles"

import { ActiveInboxChannelChatFlow } from "@/protocols/chatBotConstructor"

type ActiveFlowReportProps = {
	activeInboxChannelChatFlows: ActiveInboxChannelChatFlow[]
	tooltipTitle?: string
	dialogTitle?: string
	buttonClassName?: string
}

const ActiveFlowReport: React.FC<ActiveFlowReportProps> = (props) => {
	const {
		activeInboxChannelChatFlows,
		tooltipTitle,
		buttonClassName,
		dialogTitle
	} = props

	const [opened, setOpened] = useState(false)

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const handleOpenDialog = () => {
		setOpened(true)
	}

	const handleCloseDialog = () => {
		setOpened(false)
	}

	return (
		<>
			<Tooltip
				title={tooltipTitle || ""}
			>
				<Button
					variant="contained"
					color="primary"
					startIcon={<ActiveFlowIcon />}
					onClick={handleOpenDialog}
					className={`${classes.button} ${buttonClassName}`}
				>
					{activeInboxChannelChatFlows.length}
				</Button>
			</Tooltip>

			<ActionDialog
				title={dialogTitle || "CONTATOS"}
				hideCloseButton
				onClose={handleCloseDialog}
				openDialog={opened}
				maxWidth="sm"
				fullWidth
			>
				{activeInboxChannelChatFlows?.length > 0 ? (
					<TableContainer>
						<Table stickyHeader size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										ID Bloco Atual
									</TableCell>

									<TableCell>
										Contato
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody
								className={customClasses.reportTableBodyText}
							>
								{activeInboxChannelChatFlows?.map(activeFlow => (
									<TableRow
										key={activeFlow.id}
										tabIndex={-1}
									>
										<TableCell>
											{activeFlow.current_chat_bot_flow_block_id}
										</TableCell>

										<TableCell>
											<ProductReportClient
												client={activeFlow.client}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Typography
						variant="body1"
					>
						Nenhum contato está aqui atualmente
					</Typography>
				)}
			</ActionDialog>
		</>
	)
}

export default ActiveFlowReport
