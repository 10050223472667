import { makeStyles } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"

import colors from "@/styles/colors"

import { CHAT_LEFT_SIDE_WIDTH } from "@/pages/Attendance/Chat/ChatListPanel/styles"

const useStyles = makeStyles(theme => ({
	pageContainer: {
		height: "100%"
	},
	chatContainer: {
		height: "100%",
		backgroundColor: colors.grayScale[11],
		boxShadow: "3px 3px 16px rgba(0, 0, 0, 0.04)"
	},
	drawerPaper: {
		width: isSmallScreen ? "100%" : CHAT_LEFT_SIDE_WIDTH,
		borderRight: `1px solid ${colors.grayScale[9]}`,
		borderLeft: `1px solid ${colors.grayScale[9]}`,
		overflow: "hidden"
	},
	input: {
		backgroundColor: colors.grayScale[11],
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	}
}))

export default useStyles
