import React, { useState } from "react"
import { Grid, Divider as MuiDivider } from "@material-ui/core"

import {
	ChatBotFlowBlockRule,
	ChatBotFlowBlockRuleCategory
} from "@/protocols/chatBot"
import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"

import { PopConfirm } from "@/components"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockConditionEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockConditionEditor"
import CreateConditionIconButton from "@/pages/Admin/ChatBot/ChatBotConstructor/components/CreateConditionIconButton"
import NoConditionText from "@/pages/Admin/ChatBot/ChatBotConstructor/components/NoConditionText"

import { generateUUID } from "@/utils/id"
import { getNextChatBotRuleDescription } from "@/utils/chatBot"

const AttendanceScheduleBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		changeNextChatBotFlowBlockRule,
		toggleNextChatBotFlowBlock,
		constructionResources,
		flowBlock,
		deleteChatBotFlowBlock
	} = props

	const [selectedNextFlowBlockRuleId, setSelectedNextFlowBlockRuleId] = useState("")

	const handleSelectNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		toggleNextChatBotFlowBlock(nextFlowBlockRule)

		setSelectedNextFlowBlockRuleId(lastSelectedNextFlowBlockRuleId => {
			if (lastSelectedNextFlowBlockRuleId === nextFlowBlockRule.id) {
				return ""
			}

			return nextFlowBlockRule.id
		})
	}

	const handleEditNextChatBotFlowBlockRule = (nextChatBotFlowBlockRule: ChatBotFlowBlockRule) => {
		BlockConditionEditor.open({
			onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("UPDATE", chatBotFlowBlockRule),
			nextChatBotFlowBlockRule: nextChatBotFlowBlockRule,
			constructionResources,
			description: "Se o dia da semana e o horário da mensagem recebida se encaixar dentro de qualquer uma das condições de horário abaixo, o fluxo segue.",
			chatBotFlowBlockType: "attendance-schedule",
			availableSlugInputs: ["between-week-time"],
			multipleConditions: true
		})
	}

	const handleCreateNextChatBotFlowBlockRule = (selectedCategory: ChatBotFlowBlockRuleCategory) => {
		const block = constructionResources.blocks.find(({ type }) => type === "attendance-schedule")

		const nextChatBotFlowBlockRule = block?.available_next_block_flow_rules?.find(({ category }) => category === selectedCategory)

		if (nextChatBotFlowBlockRule) {
			BlockConditionEditor.open({
				onSave: (chatBotFlowBlockRule) => changeNextChatBotFlowBlockRule("CREATE", chatBotFlowBlockRule),
				nextChatBotFlowBlockRule: {
					...nextChatBotFlowBlockRule,
					id: generateUUID(),
					next_chat_bot_flow_block_id: null,
					validations: []
				},
				description: "Se o dia da semana e o horário da mensagem recebida se encaixar dentro de qualquer uma das condições de horário abaixo, o fluxo segue.",
				constructionResources,
				chatBotFlowBlockType: "attendance-schedule",
				availableSlugInputs: ["between-week-time"],
				multipleConditions: true
			})
		}
	}

	const handleDeleteNextFlowBlockRule = (nextFlowBlockRule: ChatBotFlowBlockRule) => {
		PopConfirm.open({
			title: "EXCLUIR CONDIÇÃO",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => changeNextChatBotFlowBlockRule("DELETE", nextFlowBlockRule),
			confirmButtonText: "EXCLUIR"
		})
	}

	const continueConditionRules = nextChatBotFlowBlockRules.filter(({ category }) => category === "continue-condition")
	const exitConditionRule = nextChatBotFlowBlockRules.find(({ category }) => category === "exit-condition")

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Condições de Horário"
						endIcon={(
							<CreateConditionIconButton
								onClick={() => handleCreateNextChatBotFlowBlockRule("continue-condition")}
							/>
						)}
					/>

					{continueConditionRules?.length ? (
						continueConditionRules.map((nextChatBotFlowBlockRule) => (
							<BlockBodyItemInput
								onClick={() => handleSelectNextFlowBlockRule(nextChatBotFlowBlockRule)}
								key={nextChatBotFlowBlockRule.id}
								text={getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
								type="keep-going-condition"
								onEdit={() => handleEditNextChatBotFlowBlockRule(nextChatBotFlowBlockRule)}
								onDelete={() => handleDeleteNextFlowBlockRule(nextChatBotFlowBlockRule)}
								selectable={nextChatBotFlowBlockRule.id !== selectedNextFlowBlockRuleId}
								selected={nextChatBotFlowBlockRule.id === selectedNextFlowBlockRuleId}
								placeholder="Especifique um dia e hora da semana"
							/>
						))
					) : (
						<NoConditionText />
					)}
				</BlockBodyItem>

				<Grid
					item
				>
					<MuiDivider />
				</Grid>

				<BlockBodyItem>
					{exitConditionRule && (
						<BlockBodyItemInput
							onClick={() => handleSelectNextFlowBlockRule(exitConditionRule)}
							text={getNextChatBotRuleDescription(exitConditionRule)}
							type="finish-flow-condition"
							selectable={exitConditionRule.id !== selectedNextFlowBlockRuleId}
							selected={exitConditionRule.id === selectedNextFlowBlockRuleId}
						/>
					)}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default AttendanceScheduleBlock
