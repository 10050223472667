import React, { useRef, useEffect } from "react"

import {
	Grid,
	CircularProgress,
	Typography,
	Button
} from "@material-ui/core"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"

import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"

import {
	Divider,
	Portlet,
	Notification
} from "@/components"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeGenerated/styles"
import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useCounter from "@/hooks/useCounter"
import useBreakpoint from "@/hooks/useBreakpoint"

import QRCodeInstruction from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeInstruction"

import QRCodeTutorialStepOneImg from "@/assets/images/whatsapp/qr_code_tutorial_step_one.png"
import QRCodeTutorialStepTwoImg from "@/assets/images/whatsapp/qr_code_tutorial_step_two.png"

const EXPECTED_QR_CODE_READING_TIME_IN_SECONDS = 180

type QRCodeGeneratedProps = ConnectionFlowComponentDefaultProps & {
	qrCodeImage?: string
}

const QRCodeGenerated: React.FC<QRCodeGeneratedProps> = (props) => {
	const {
		qrCodeImage,
		resetSteps,
		goToNextStep,
		currentStep
	} = props

	/**
	 * Using ref since we need to be able to access the current value of this
	 * variable inside a listener function (and just passing the state variable to inside
	 * the listener function will only get the old value).
	 */
	const currentStepRef = useRef(currentStep)

	const onQRCodeReadingTimeout = () => {
		/**
		 * We need to that validation since this component keeps opened
		 * when the current step is changed to 'QRCodeScanned', but we just want
		 * to use this timeout when the current step is 'QRCodeGenerated'
		 */
		if (currentStepRef.current === "QRCodeGenerated") {
			Notification.warning({
				message: "O tempo limite para a leitura do QR Code chegou ao fim. Por favor gere um novo QR Code."
			})

			resetSteps()
		}
	}

	const isSmall = useBreakpoint({ type: "down", breakpoint: "md" })
	const whatsappConnection = useWhatsappConnection()
	const classes = useStyles()
	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const qrCodeReadingCounter = useCounter({
		direction: "down",
		startFromSeconds: EXPECTED_QR_CODE_READING_TIME_IN_SECONDS,
		endAtSeconds: 0,
		onTimeout: () => onQRCodeReadingTimeout()
	})

	const handleCancelQRCodeReading = () => {
		resetSteps()
	}

	const handleQRCodeUnexpectedError = () => {
		Notification.error({
			message: "Ocorreu um erro inesperado. Por favor gere um novo QR Code."
		})

		handleCancelQRCodeReading()
	}

	const addWhatsappEventListeners = () => {
		whatsappConnection.onQRCodeScanned(() => {
			goToNextStep()
		})

		whatsappConnection.onQRCodeTimedOut(() => {
			handleQRCodeUnexpectedError()
		})
	}

	const setupQRCodeReadingCounter = () => {
		qrCodeReadingCounter.reset()

		qrCodeReadingCounter.start()
	}

	useDidMount(() => {
		addWhatsappEventListeners()
		setupQRCodeReadingCounter()
	})

	useEffect(() => {
		currentStepRef.current = currentStep
	}, [currentStep])

	return (
		<Portlet
			className={whatsappConnectionFlowClasses.qrCodePortlet}
			style={{
				backgroundColor: "transparent",
				padding: 0
			}}
		>
			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
				className={classes.qrCodeGeneratedContainer}
			>
				<QRCodeInstruction>
					<Grid
						container
						alignItems="center"
						justify="center"
						direction="column"
						wrap="nowrap"
						className={classes.qrCodeContainer}
					>
						<Grid
							container
							alignItems="center"
							justify="center"
							className={classes.qrCodeImageContainer}
						>
							{
								qrCodeImage ? (
									<img
										src={(qrCodeImage as string)}
										alt="Whatsapp - QR CODE"
										className={classes.qrCodeImage}
									/>
								) : (
									<Grid
										container
										direction="column"
										alignItems="center"
										justify="center"
									>
										<Typography
											align="center"
										>
											Aguardando atualização
											<br />
											do QR Code
										</Typography>

										<Divider orientation="horizontal" size={4} />

										<CircularProgress />
									</Grid>
								)
							}
						</Grid>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="body1"
						>
							{qrCodeReadingCounter.formattedElapsedSeconds}
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Button
							variant="text"
							onClick={handleCancelQRCodeReading}
							className={classes.cancelButton}
						>
							CANCELAR
						</Button>
					</Grid>
				</QRCodeInstruction>
			</Grid>

			<Divider orientation="horizontal" size={6} />

			<Grid
				container
				direction="column"
				alignItems="center"
				justify="center"
			>
				<Typography
					align="center"
					variant="h3"
					className={whatsappConnectionFlowClasses.connectWppText}
				>
					Não sabe como ler o QR Code?
				</Typography>

				<Divider orientation="horizontal" size={6} />

				<Grid
					container
					alignItems="center"
					justify="center"
				>
					<img
						src={QRCodeTutorialStepOneImg}
						alt="Tutorial de geração de QR Code - Passo 1"
					/>

					<Divider orientation={isSmall ? "horizontal" : "vertical"} size={2} />

					<img
						src={QRCodeTutorialStepTwoImg}
						alt="Tutorial de geração de QR Code - Passo 2"
					/>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size={6} />
		</Portlet>
	)
}

export default QRCodeGenerated
