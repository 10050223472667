import { IntegrationPlatformEvent, IntegrationType } from "@/protocols/integration"
import { payloadFromVariables, customArrayMerge } from "@/utils/payloadFactory"
import deepmerge from "deepmerge"

type Payload = Record<string, string>

function buildActiveCampaignPayload (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) {
	if (platformEvent) {
		const types: Record<string, string> = {
			"Campanha aberta": "open",
			"Campanha compartilhada": "share",
			"Campanha enviada": "forward",
			"Cancelamento da inscrição do Contato": "unsubscribe",
			"Conta adicionada": "account_add",
			"Conta atualizada": "account_update",
			"Contato adicionado": "subscribe",
			"Contato adicionado à conta": "account_contact_add",
			"Contato atualizado": "update",
			"E-mails devolvidos": "bounce",
			"Etapa de funil de vendas adicionada": "deal_stage_add",
			"Funil de vendas adicionado": "deal_pipeline_add",
			"Iniciado o envio da campanha": "sent",
			"Link é clicado": "click",
			"Lista Adicionada": "list_add",
			"Negócio Adicionado": "deal_add",
			"Negócio Atualizado": "deal_update",
			"Nota adicionada ao contato": "contact_note",
			"Nota do Negócio Adicionada": "deal_note_add",
			"Respostas do e-mail": "reply",
			"Tag de contato adicionada": "contact_tag_added",
			"Tarefa Adicionada ao Negócio": "deal_task_add",
			"Tarefa de contato adicionada": "contact_task_add",
			"Tarefa de negócio completa": "deal_task_complete",
			"Tipo de Tarefa Adicionada ao Negócio": "deal_tasktype_add"
		}
		const extraPayload = {
			type: types[platformEvent.title],
			contact: {
				phone
			}
		}
		return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
	}

	const extraPayload = {
		contact: {
			phone
		}
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildDirectIntegrationPayload (variablesPayload: Payload, phone: string) {
	const extraPayload = {
		phone
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildRDStationPayload (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) {
	if (platformEvent) {
		const opportunity_date = new Date()
		const conversion_date = platformEvent.title === "Conversão" ? new Date(opportunity_date.getTime() + 1000) : new Date(opportunity_date.getTime() - 1000)

		const extraPayload = {
			leads: [{
				last_conversion: {
					created_at: conversion_date
				},
				last_marked_opportunity_date: opportunity_date,
				mobile_phone: phone
			}]
		}
		return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
	}

	const extraPayload = {
		leads: [{
			mobile_phone: phone
		}]
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildYampiPayload (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) {
	if (!platformEvent) {
		throw Error("Yampi only works with platform events")
	}

	const events: Record<string, string> = {
		"Pedido criado": "order.created",
		"Pedido aprovado": "order.paid",
		"O status de um pedido foi atualizado": "order.status.updated",
		"Nota fiscal de um pedido foi criada": "order.invoice.created",
		"Nota fiscal de um pedido foi atualizada": "order.invoice.updated",
		"O pagamento de uma transação foi negado": "transaction.payment.refused",
		"Notificação de carrinho abandonado": "cart.reminder",
		"Cliente criado": "customer.created",
		"Endereço do cliente criado": "customer.address.created"
	}
	const event = events[platformEvent.title]

	if (
		["customer.created", "customer.address.created"].includes(event)
	) {
		const extraPayload = {
			event,
			resource: {
				phone: {
					formated_number: phone
				}
			}
		}
		return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
	}

	const extraPayload = {
		event,
		resource: {
			customer: {
				data: {
					phone: {
						formated_number: phone
					}
				}
			}
		}
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildHotmartPayload (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) {
	if (!platformEvent) {
		throw Error("Hotmart only works with platform events")
	}

	const available_status: Record<string, string> = {
		"Abandono de carrinho": "cart_abandoned",
		"Aguardando pagamento": "billet_printed",
		Chargeback: "chargeback",
		"Compra aprovada": "approved",
		"Compra atrasada": "delayed",
		"Compra cancelada": "canceled",
		"Compra completa": "completed",
		"Compra expirada": "expired",
		"Compra reembolsada": "refunded",
		"Pedido de reembolso": "dispute"
	}
	const status = available_status[platformEvent.title]

	if (status === "cart_abandoned") {
		const extraPayload = {
			buyerVO: {
				phone: phone
			}
		}
		return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
	}

	const extraPayload = {
		status,
		phone_checkout_local_code: "",
		phone_checkout_number: phone
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildHotmartV2Payload (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) {
	if (!platformEvent) {
		throw Error("Hotmart only works with platform events")
	}

	const events: Record<string, string> = {
		"Abandono de carrinho": "PURCHASE_OUT_OF_SHOPPING_CART",
		"Aguardando pagamento": "PURCHASE_BILLET_PRINTED",
		Chargeback: "PURCHASE_CHARGEBACK",
		"Compra aprovada": "PUCHASE_APPROVED",
		"Compra atrasada": "PURCHASE_DELAYED",
		"Compra cancelada": "PURCHASE_CANCELED",
		"Compra completa": "PURCHASE_COMPLETE",
		"Compra expirada": "PURCHASE_EXPIRED",
		"Compra reembolsada": "PURCHASE_REFUNDED",
		"Pedido de reembolso": "PURCHASE_PROTEST"
	}
	const event = events[platformEvent.title]

	const extraPayload = {
		event,
		data: {
			buyer: {
				phone
			}
		}
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

function buildHubSpotPayload (variablesPayload: Payload, phone: string) {
	const extraPayload = {
		properties: {
			phone: {
				value: phone
			}
		}
	}
	return deepmerge(variablesPayload, extraPayload, { arrayMerge: customArrayMerge })
}

type Builder = (variablesPayload: Payload, phone: string, platformEvent?: IntegrationPlatformEvent) => Payload

export default function buildPayload (
	phone: string,
	variables: Set<string>,
	platform: IntegrationType,
	platformEvent?: IntegrationPlatformEvent
): Payload {
	const variablesPayload = payloadFromVariables(variables)
	const builders: Record<IntegrationType, Builder> = {
		ActiveCampaign: buildActiveCampaignPayload,
		DirectIntegration: buildDirectIntegrationPayload,
		RDStation: buildRDStationPayload,
		Yampi: buildYampiPayload,
		Hotmart: buildHotmartPayload,
		HotmartV2: buildHotmartV2Payload,
		HubSpot: buildHubSpotPayload
	}
	return builders[platform](variablesPayload, phone, platformEvent)
}
