import React, { useState, useRef } from "react"

import {
	Notification
} from "@/components"

import {
	ConnectionFlowComponentDefaultProps
} from "@/@integrations/Whatsapp/protocols/connectionFlow"

import QRCodeWaitingDialog, { QRCodeWaitingDialogHandler } from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeWaitingDialog"

import useCounter from "@/hooks/useCounter"
import useDidMount from "@/hooks/useDidMount"
import useAsyncState from "@/hooks/useAsyncState"
import useWhatsappConnection from "@/@integrations/Whatsapp/hooks/useWhatsappConnection"

const EXPECTED_QR_CODE_GENERATION_TIME_IN_SECONDS = 120

type GeneratingQRCodeProps = ConnectionFlowComponentDefaultProps

const GeneratingQRCode: React.FC<GeneratingQRCodeProps> = (props) => {
	const {
		resetSteps,
		goToNextStep
	} = props

	const whatsappConnection = useWhatsappConnection()

	const onQRCodeGenerationTimeOut = () => {
		Notification.warning({
			message: "O tempo limite para a geração do QR Code chegou ao fim. Por favor gere um novo QR Code."
		})

		resetSteps()
	}

	const qrCodeGenerationCounter = useCounter({
		direction: "down",
		startFromSeconds: EXPECTED_QR_CODE_GENERATION_TIME_IN_SECONDS,
		onTimeout: () => onQRCodeGenerationTimeOut()
	})

	const [qrCodeGenerationDialogOpened, setQrCodeGenerationDialogOpened] = useAsyncState(false)

	const [qrCodeGenerated, setQrCodeGenerated] = useState(false)
	const [qrCodeFailed, setQrCodeFailed] = useState(false)

	const qrCodeGenerationDialogRef = useRef<QRCodeWaitingDialogHandler>(null)

	const handleGoToNextStep = async () => {
		await qrCodeGenerationDialogRef.current?.finish?.()

		setQrCodeGenerationDialogOpened(() => false)

		goToNextStep()
	}

	const onQRCodeGenerationDialogClose = async () => {
		await resetSteps()

		qrCodeGenerationCounter.reset()

		setQrCodeGenerationDialogOpened(() => false)
	}

	const onQRCodeGenerationTimeout = () => {
		Notification.warning({
			message: "O tempo limite para a geração do QR Code chegou ao fim. Por favor gere um novo QR Code."
		})

		onQRCodeGenerationDialogClose()
	}

	const handleQRCodeGenerationFailure = () => {
		Notification.error({
			message: "Ocorreu um erro inesperado ao gerar o QR Code. Por favor tente novamente."
		})

		onQRCodeGenerationDialogClose()
	}

	const handleOpenQRCodeGenerationDialog = () => {
		qrCodeGenerationCounter.start()

		setQrCodeGenerationDialogOpened(() => true)

		if (qrCodeFailed) {
			handleQRCodeGenerationFailure()
		} else if (qrCodeGenerated) {
			handleGoToNextStep()
		}
	}

	const setupWhatsappListeners = () => {
		whatsappConnection.onQRCodeGenerated(() => {
			setQrCodeGenerated(true)

			if (qrCodeGenerationDialogOpened.current) {
				handleGoToNextStep()
			}
		})

		whatsappConnection.onQRCodeFailed(() => {
			setQrCodeFailed(true)

			if (qrCodeGenerationDialogOpened.current) {
				handleQRCodeGenerationFailure()
			}
		})

		whatsappConnection.onQRCodeTimedOut(() => {
			setQrCodeFailed(true)

			if (qrCodeGenerationDialogOpened.current) {
				handleQRCodeGenerationFailure()
			}
		})
	}

	useDidMount(() => {
		setupWhatsappListeners()
		handleOpenQRCodeGenerationDialog()
	})

	return (
		<>
			<QRCodeWaitingDialog
				title="QR Code sendo gerado"
				onClose={onQRCodeGenerationDialogClose}
				opened={qrCodeGenerationDialogOpened.current}
				onTimeout={onQRCodeGenerationTimeout}
				waitingTimeInSeconds={EXPECTED_QR_CODE_GENERATION_TIME_IN_SECONDS}
				ref={qrCodeGenerationDialogRef}
			/>
		</>
	)
}

export default GeneratingQRCode
