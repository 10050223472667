export const secondWordIsSimilarToFirst = (firstWord: string, secondWord: string): boolean => {
	const formattedFirstWord = firstWord?.toLowerCase()
	const formattedSecondWord = secondWord?.toLowerCase()

	const secondWordIsSimilarToFirst = formattedFirstWord?.includes(formattedSecondWord)

	return secondWordIsSimilarToFirst
}

export const getAvatarSymbol = (title: string) => {
	const name = title || ""

	const symbol = name[0]

	return symbol
}

export const onlyLettersNumbersAndAccents = (value: string): string => {
	if (!value) return value

	return value.replace(/[^\w\s\u00C0-\u017F]/gi, "")
}
