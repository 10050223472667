import React from "react"
import { Grid } from "@material-ui/core"

import useStyles from "@/components/ProductReportInfo/ReportInfoIcon/styles"

type ReportInfoIconProps = {
	backgroundColor: string
	color: string
	icon: React.ReactNode
	className?: string
}

const ReportInfoIcon: React.FC<ReportInfoIconProps> = (props) => {
	const {
		backgroundColor,
		icon,
		color,
		className
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			justify="center"
			className={`${classes.iconContainer} ${className}`}
			style={{
				backgroundColor,
				color
			}}
		>
			{icon}
		</Grid>
	)
}

export default ReportInfoIcon
