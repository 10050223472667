import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	buttonAction: {
		width: 305.2,
		height: 54,
		fontSize: 15,
		backgroundColor: "transparent",
		color: colors.palette.button1
	},
	inputLabelPadding: {
		marginBottom: 16,
		display: "flex",
		alignItems: "center"
	}
}))

export default useStyles
