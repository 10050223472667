import { useState } from "react"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

type UseFileUploadInput = {
	requestPath: string
}

type UploadFileResponse<ExpectedResponseData> = {
	error?: string
	key?: string
	url?: string
	data?: ExpectedResponseData
}

type UseFileUploadResponse = {
	totalBytesSent: number
	uploadFile: <ExpectedResponseData>(file: File, name?: string, body?: Record<string, unknown>) => Promise<UploadFileResponse<ExpectedResponseData>>
	clearTotalBytesSent: () => void
	isUploading: boolean
}

type LastBytesLoadedCache = {
	[id: number]: number
}

const useFileUpload = (data: UseFileUploadInput): UseFileUploadResponse => {
	const [totalBytesSent, setTotalBytesSent] = useState(0)
	const [isUploading, setIsUploading] = useState(false)

	let lastBytesLoadedCache = {} as LastBytesLoadedCache

	async function uploadFile<ExpectedResponseData> (file: File, name = "file", body = {}): Promise<UploadFileResponse<ExpectedResponseData>> {
		setIsUploading(true)

		const uploadResponse = {} as UploadFileResponse<ExpectedResponseData>

		const fileTempId = Date.now()

		lastBytesLoadedCache[fileTempId] = 0

		const formData = new FormData()
		formData.append(name, file)

		Object.entries(body).forEach(([key, value]) => {
			formData.append(key, value as string)
		})

		try {
			const response = await ApiService.post(
				data.requestPath,
				formData,
				{
					headers: {
						"Content-Type": "multipart/data"
					},
					// eslint-disable-next-line
					onUploadProgress: (progress: any) => {
						setTotalBytesSent(lastState => {
							const totalBytesLoaded = progress.loaded

							const actualCurrentBytesSent = totalBytesLoaded - lastBytesLoadedCache[fileTempId]

							lastBytesLoadedCache[fileTempId] = actualCurrentBytesSent

							const currentTotalBytesSent = lastState + actualCurrentBytesSent

							return currentTotalBytesSent
						})
					}
				}
			)

			uploadResponse.key = response?.data?.key
			uploadResponse.url = response?.data?.url
			uploadResponse.data = response?.data as ExpectedResponseData
		} catch (error) {
			ErrorHandlerService.handle(error)
			uploadResponse.error = error.message
		}

		delete lastBytesLoadedCache[fileTempId]

		const isThereAnyFileBeingUploaded = Object.keys(lastBytesLoadedCache).length > 0

		if (!isThereAnyFileBeingUploaded) {
			setIsUploading(false)
		}

		return uploadResponse
	}

	const clearTotalBytesSent = () => {
		setTotalBytesSent(0)
		lastBytesLoadedCache = {}
	}

	return {
		totalBytesSent,
		uploadFile,
		clearTotalBytesSent,
		isUploading
	}
}

export default useFileUpload
