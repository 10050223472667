import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: colors.grayScale[11],
		color: colors.palette.primary,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: colors.grayScale[11],
			opacity: 0.9
		}
	},
	whiteText: {
		color: colors.grayScale[11]
	},
	whiteInput: {
		backgroundColor: colors.grayScale[11],
		borderWidth: 0
	},
	whiteLabel: {
		background: colors.grayScale[11],
		padding: "0 6px",
		borderRadius: "9px"
	},
	whiteTextField: {
		"& legend": {
			height: 8
		}
	}
}))

export default useStyles
