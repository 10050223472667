import React from "react"
import { Grid } from "@material-ui/core"
import {
	WhatsApp as WhatsappIcon
} from "@material-ui/icons"

import { ChannelType } from "@/protocols/channel"

type ChannelInfo = {
	[key in ChannelType]: {
		icon: JSX.Element
	}
}

export const channelInfo: ChannelInfo = {
	whatsapp: {
		icon: (
			<Grid
				container
				alignItems="center"
				justify="center"
				style={{
					height: "100%",
					backgroundColor: "#06D755",
					borderRadius: "100%"
				}}
			>
				<WhatsappIcon
					style={{
						width: "12px",
						height: "12px",
						color: "#FFFFFF"
					}}
				/>
			</Grid>
		)
	},
	telegram: {
		icon: (
			<div style={{ width: "100%", height: "100%", backgroundColor: "#00ccff", borderRadius: "100%" }} />
		)
	}
}
