import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		flex: 1
	},
	separator: {
		width: 50
	}
})

const WebhookAssociationEditorSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						container
						className={classes.container}
					>
						<Skeleton variant="rect" width={200} height={20} />
					</Grid>

					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.separator}
					/>

					<Grid
						container
						className={classes.container}
					>
						<Skeleton variant="rect" width={300} height={20} />
					</Grid>
				</Grid>
			</Grid>

			{[...Array(3)].map((_, index) => (
				<Grid
					item
					xs={12}
					key={index}
				>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							container
							className={classes.container}
						>
							<Skeleton variant="rect" width="100%" height={56} />
						</Grid>

						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.separator}
						/>

						<Grid
							container
							className={classes.container}
						>
							<Skeleton variant="rect" width="100%" height={56} />
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	)
}

export default WebhookAssociationEditorSkeleton
