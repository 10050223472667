import React from "react"

import BaseMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/CommonMessage/BaseMessage"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import { Messages } from "@/components"

type CommonMessageProps = {
	messageId: string
}

const CommonMessage: React.FC<CommonMessageProps> = (props) => {
	const {
		messageId
	} = props

	const chatGlobalStateStore = useChatGlobalStateStore()

	const message = chatGlobalStateStore.message.getById(messageId)

	return (
		<BaseMessage
			message={message}
			messageContentBoxStyle={(
				Messages.getMessageContentBoxStyle(message.type)
			)}
			childContent={(
				Messages.getChildContent(message.type, {
					caption: message.caption,
					content: message.content,
					uploadingMedia: message.uploadingMedia,
					fancyBoxSlug: String(message.inboxChannelChatId),
					copySpecial: false,
					translateVariables: false,
					extraData: message.extraData
				})
			)}
			childContentFooter={(
				Messages.getChildContentFooter(message.type, {
					extraData: message.extraData
				})
			)}
			childContainerFooter={(
				Messages.getChildContainerFooter(message.type, {
					extraData: message.extraData,
					sendByCustomer: message.sentByCustomer
				})
			)}
		/>
	)
}

export default CommonMessage
