import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		margin: "auto"
	},
	trialEndedImg: {
		height: 250,
		width: "auto",
		margin: "auto",

		display: "flex"
	},
	supportButton: {
		margin: "0 auto"
	}
})

export default useStyles
