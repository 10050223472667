import React from "react"
import { Typography, Grid } from "@material-ui/core"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle/styles"

type BlockBodyItemTitleProps = {
	title: string
	endIcon?: React.ReactNode
}

const BlockBodyItemTitle: React.FC<BlockBodyItemTitleProps> = (props) => {
	const {
		title,
		endIcon
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			justify="space-between"
		>
			<Typography
				variant="h1"
				color="textPrimary"
				className={classes.title}
			>
				{title}
			</Typography>

			{endIcon}
		</Grid>
	)
}

export default BlockBodyItemTitle
