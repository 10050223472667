import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"
import {
	HorizontalBarSeries,
	FlexibleWidthXYPlot
} from "react-vis"

import {
	Divider,
	Portlet
} from "@/components"

import {
	IProductMessageMetrics
} from "@/protocols/metrics"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/TodayCard/styles"

import { DEFAULT_PRODUCT_REPORT_INFO } from "@/components/ProductReportInfo"
import ReportInfoIcon from "@/components/ProductReportInfo/ReportInfoIcon"

const AVAILABLE_MESSAGES_COLOR = "#F8F9FA"

type BarSeriesData = {
	data: Array<{ x: number, y: number }>
	color: string
	type: keyof TodayProductMessageMetrics
}

type TodayProductMessageMetrics = {
	delivered: number
	sent: number
	sentButNotDeliveredMessages: number
	notSent: number
	available: number
	maxMessagesPerDay: number
}

type TodayCardProps = {
	todayMetrics: IProductMessageMetrics["todayMetrics"]
}

const TodayCard: React.FC<TodayCardProps> = (props) => {
	const {
		todayMetrics
	} = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const todayFormattedMetrics: TodayProductMessageMetrics = {
		available: todayMetrics?.availableMessages || 0,
		delivered: todayMetrics?.deliveredMessages || 0,
		sent: todayMetrics?.sentMessages || 0,
		notSent: todayMetrics?.notSentMessages || 0,
		sentButNotDeliveredMessages: todayMetrics?.sentButNotDeliveredMessages || 0,
		// maxMessagesPerDay: todayMetrics?.maxMessagesPerDay || 1500
		/**
		 * TODO: remove fixed 1500 value and return to commented as above
		 * In order to avoid blocking user to send messages, send limit was set to 9999999 in prod
		 * In order to avoid showing such a high number to the user as their message limit, we fixed 1500
		 */
		maxMessagesPerDay: 1500
	}

	const getMessageCountText = (count: number, singularText: string, pluralText: string) => {
		return (
			<>
				<b>{count}</b>
				{" "}
				{count === 1 ? "mensagem" : "mensagens"}
				{" "}
				{count === 1 ? singularText : pluralText}
			</>
		)
	}

	const barSeries: BarSeriesData[] = [
		{
			data: [{ y: 0, x: todayFormattedMetrics.delivered }],
			color: DEFAULT_PRODUCT_REPORT_INFO["messages-delivered"].iconColor,
			type: "delivered"
		},
		{
			data: [{ y: 0, x: todayFormattedMetrics.sentButNotDeliveredMessages }],
			color: DEFAULT_PRODUCT_REPORT_INFO["messages-sent"].iconColor,
			type: "sent"
		},
		{
			data: [{ y: 0, x: todayFormattedMetrics.available }],
			color: AVAILABLE_MESSAGES_COLOR,
			type: "available"
		}
	]

	return (
		<Portlet>
			<Grid
				container
				spacing={2}
				justify="center"
			>
				<Grid
					item
					xs={12}
				>
					<Grid
						container
					>
						<Typography
							variant="h2"
							className={customClasses.uppercase}
						>
							HOJE
						</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						spacing={3}
					>
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								justify="space-between"
							>
								<Typography
									variant="h4"
									color="textPrimary"
								>
									0
								</Typography>

								<Typography
									variant="h4"
									color="textPrimary"
								>
									{todayFormattedMetrics.maxMessagesPerDay}
								</Typography>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
							>
								<FlexibleWidthXYPlot
									height={60}
									stackBy="x"
									className={classes.todayMetricsBar}
									animation
								>
									{barSeries.map((barSeriesItem) => (
										<HorizontalBarSeries
											key={barSeriesItem.type}
											data={barSeriesItem.data}
											barWidth={todayFormattedMetrics.maxMessagesPerDay}
											color={barSeriesItem.color}
											className={todayFormattedMetrics[barSeriesItem.type] > 0 ? classes.todayMetricBarPart : ""}
										/>
									))}
								</FlexibleWidthXYPlot>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
						>
							<Grid
								container
								wrap="wrap"
							>
								<Grid
									container
									alignItems="center"
									className={classes.todayMetricsDescriptionItemContainer}
								>
									<ReportInfoIcon
										backgroundColor={DEFAULT_PRODUCT_REPORT_INFO["messages-sent"].backgroundColor}
										color={DEFAULT_PRODUCT_REPORT_INFO["messages-sent"].iconColor}
										icon={DEFAULT_PRODUCT_REPORT_INFO["messages-sent"].icon}
										className={classes.todayMetricsDescriptionIconContainer}
									/>

									<Divider orientation="vertical" size={1} />

									<Typography
										variant="overline"
										color="textPrimary"
									>
										{getMessageCountText(todayFormattedMetrics.sentButNotDeliveredMessages, "enviada e não entregue", "enviadas e não entregues")}
									</Typography>
								</Grid>

								<Grid
									container
									alignItems="center"
									className={classes.todayMetricsDescriptionItemContainer}
								>
									<ReportInfoIcon
										backgroundColor={DEFAULT_PRODUCT_REPORT_INFO["messages-delivered"].backgroundColor}
										color={DEFAULT_PRODUCT_REPORT_INFO["messages-delivered"].iconColor}
										icon={DEFAULT_PRODUCT_REPORT_INFO["messages-delivered"].icon}
										className={classes.todayMetricsDescriptionIconContainer}
									/>

									<Divider orientation="vertical" size={1} />

									<Typography
										variant="overline"
										color="textPrimary"
									>
										{getMessageCountText(todayFormattedMetrics.delivered, "entregue", "entregues")}
									</Typography>
								</Grid>

								<Grid
									container
									alignItems="center"
									className={classes.todayMetricsDescriptionItemContainer}
								>
									<ReportInfoIcon
										backgroundColor={DEFAULT_PRODUCT_REPORT_INFO["messages-not-sent"].backgroundColor}
										color={DEFAULT_PRODUCT_REPORT_INFO["messages-not-sent"].iconColor}
										icon={DEFAULT_PRODUCT_REPORT_INFO["messages-not-sent"].icon}
										className={classes.todayMetricsDescriptionIconContainer}
									/>

									<Divider orientation="vertical" size={1} />

									<Typography
										variant="overline"
										color="textPrimary"
									>
										{getMessageCountText(todayFormattedMetrics.notSent, "não enviada", "não enviadas")}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TodayCard
