import React, { useState } from "react"
import {
	Avatar,
	Grid,
	Typography,
	Button,
	Link,
	Divider as MuiDivider
} from "@material-ui/core"
import { AvatarGroup } from "@material-ui/lab"

import useStyles from "@/components/Messages/ContactCardMessage/styles"

import { IMessageExtraData } from "@/protocols/channel"

import {
	ActionDialog,
	Portlet
} from "@/components"

type ContactCardMessageProps = {
	extraData: IMessageExtraData
}

const MAX_AVATARS = 3

export const getContactCardDescription = (extraData: IMessageExtraData): string => {
	const totalContacts = extraData?.contactCard?.items?.length || 0

	const descriptionParams = []

	const firstContact = extraData?.contactCard?.items[0]
	descriptionParams.push(firstContact?.name)

	const isThereMoreThanOneContact = totalContacts > 1

	if (isThereMoreThanOneContact) {
		const pendingContacts = totalContacts - 1

		const extraContactsDescription = `e ${pendingContacts} ${pendingContacts === 1 ? "outro contato" : "outros contatos"}`

		descriptionParams.push(extraContactsDescription)
	}

	const description = descriptionParams.join(" ")

	return description
}

export const ContactCardMessageContent: React.FC<ContactCardMessageProps> = (props) => {
	const {
		extraData
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			spacing={2}
			className={classes.contentContainer}
		>
			<Grid
				item
			>
				<AvatarGroup>
					{extraData?.contactCard?.items
						?.slice(0, MAX_AVATARS)
						?.map((item, index) => (
							<Avatar
								key={index}
								variant="circle"
								src={item.pictureUrl}
								className={classes.avatar}
							/>
						))}
				</AvatarGroup>
			</Grid>

			<Grid
				item
				className={classes.descriptionContainer}
			>
				<Typography
					variant="h2"
					color="textPrimary"
				>
					{getContactCardDescription(extraData)}
				</Typography>
			</Grid>
		</Grid>
	)
}

export const ContactCardMessageContentFooter: React.FC<ContactCardMessageProps> = (props) => {
	const {
		extraData
	} = props

	const classes = useStyles()

	const [showContactsDialogOpened, setShowContactsDialogOpened] = useState(false)

	const handleOpenShowContactsDialog = () => {
		setShowContactsDialogOpened(true)
	}

	const handleCloseShowContactsDialog = () => {
		setShowContactsDialogOpened(false)
	}

	const totalContacts = extraData?.contactCard?.items?.length
	const contactText = totalContacts === 1 ? "contato" : "contatos"

	return (
		<Grid
			container
		>
			<Link
				component={Button}
				fullWidth
				className={classes.showContactsButton}
				onClick={handleOpenShowContactsDialog}
			>
				Ver {contactText}
			</Link>

			<ActionDialog
				title={contactText.toUpperCase()}
				hideCloseButton
				onClose={handleCloseShowContactsDialog}
				openDialog={showContactsDialogOpened}
				maxWidth="sm"
				fullWidth
			>
				<Grid
					container
					spacing={3}
				>
					{extraData?.contactCard?.items?.map((item, index) => (
						<Grid
							item
							xs={12}
							key={index}
						>
							<Portlet>
								<Grid
									container
									spacing={2}
								>
									<Grid
										item
										xs={12}
									>
										<Grid
											container
											alignItems="center"
											spacing={2}
											className={classes.contentContainer}
										>
											<Grid
												item
											>
												<Avatar
													variant="circle"
													src={item.pictureUrl}
												/>
											</Grid>

											<Grid
												item
												className={classes.descriptionContainer}
											>
												<Typography
													variant="h2"
													color="textPrimary"
												>
													{item.name}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									{item?.contacts?.map(contact => (
										<>
											<Grid
												item
												xs={12}
											>
												<MuiDivider />
											</Grid>

											<Grid
												item
												xs={12}
											>
												<Grid
													container
													direction="column"
												>
													<Typography
														variant="h1"
														color="textPrimary"
													>
														{contact.value}
													</Typography>

													<Typography
														variant="caption"
														color="textSecondary"
													>
														{contact.channelType === "whatsapp" && "Celular"}
														{contact.channelType === "email" && "E-mail"}
													</Typography>
												</Grid>
											</Grid>
										</>
									))}
								</Grid>
							</Portlet>
						</Grid>
					))}
				</Grid>
			</ActionDialog>
		</Grid>
	)
}
