import React from "react"
import {
	Tooltip
} from "@material-ui/core"

import {
	Repeat as MessageCreatedIcon,
	Done as MessageSentIcon,
	DoneAll as MessageDeliveredIcon,
	Cancel as MessageNotSentIcon,
	QueryBuilder as MessageWaitingIcon
} from "@material-ui/icons"

import { MessageStatus as IMessageStatus } from "@/protocols/channel"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageStatus/styles"

export type MessageStatusProps = {
	status: IMessageStatus
	className?: string
}

const MessageStatus: React.FC<MessageStatusProps> = (props) => {
	const { status, className } = props

	const classes = useStyles()

	const defaultIconClassName = `${classes.messageStatusIcon} ${className}`

	return (
		<>
			{status === "created" && (
				<Tooltip
					title="Enviando para o seu Whatsapp Web"
				>
					<MessageCreatedIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				</Tooltip>
			)}

			{status === "waiting" && (
				<Tooltip
					title="A mensagem foi criada no Whatsapp Web e está em processo de envio"
				>
					<MessageWaitingIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				</Tooltip>
			)}

			{status === "sent" && (
				<Tooltip
					title="A mensagem foi enviada"
				>
					<MessageSentIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				</Tooltip>
			)}

			{status === "delivered" && (
				<Tooltip
					title="A mensagem foi entregue ao destinatário"
				>
					<MessageDeliveredIcon
						fontSize="small"
						className={defaultIconClassName}
					/>
				</Tooltip>
			)}

			{status === "not-sent" && (
				<Tooltip
					title="Houve um erro ao enviar a mensagem, tente novamente mais tarde"
				>
					<MessageNotSentIcon
						fontSize="small"
						className={defaultIconClassName}
						style={{ color: "#FF4343" }}
					/>
				</Tooltip>
			)}
		</>
	)
}

export default MessageStatus
