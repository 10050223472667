import React from "react"
import {
	Grid,
	Typography
} from "@material-ui/core"

import {
	VirtualizedList,
	VariableSizeVirtualizedList,
	ChatDivider
} from "@/components"

import { useChatGlobalStateStore, Chat } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatList/styles"
import useCustomStyles from "@/styles/custom"
import useCustomMemo from "@/hooks/useCustomMemo"
import useChat from "@/hooks/useChat"

import ChatListItem from "@/pages/Attendance/Chat/ChatListPanel/ChatList/ChatListItem"

import { sortChats, buildChatHash } from "@/utils/chat"

type ChatListProps = {
	chats: Chat[]
	onClick: (chatId: number) => void
}

const ChatList: React.FC<ChatListProps> = (props) => {
	const { chats, onClick } = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const chatMethods = useChat()

	const localOrderedChats = (chats || []).sort(sortChats())
	const queueLocalOrderedChats = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" ? chatMethods.formatQueueChats(localOrderedChats) : []

	const localChatsHash = localOrderedChats.map(chat => buildChatHash(chat)).join("")
	const queueChatsHash = queueLocalOrderedChats.map(chat =>	chat.type === "chat" ? buildChatHash(chat.data) : chat.data?.exhibitionName).join("")

	const getCustomChatListLength = () => {
		return localOrderedChats.length
	}
	const getVirtualizedListQueueSize = (index: number): number => queueLocalOrderedChats[index].height

	const customChatListLength = getCustomChatListLength()

	return useCustomMemo(() => (
		<>
			<Grid
				container
			>
				{!chatGlobalStateStore.chatListPanel.chatListFilter.current.text && chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" ? (
					<VariableSizeVirtualizedList
						refreshConditionals={[queueLocalOrderedChats]}
						className={`${classes.chatList} ${customClasses.scrollBar}`}
						itemSize={getVirtualizedListQueueSize}
						itemCount={queueLocalOrderedChats.length}
						itemRender={({ index, style }) => {
							const chatItem = queueLocalOrderedChats[index]

							if (chatItem.type === "title") {
								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatDivider
											summary={chatItem?.data?.exhibitionName}
											chatCount={chatItem?.data?.chatCount}
											key={index}
										/>
									</Grid>
								)
							}

							if (chatItem.type === "chat") {
								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatListItem
											key={chatItem.data.id}
											chatId={chatItem.data.id}
											onClick={() => onClick(chatItem.data.id)}
										/>
									</Grid>
								)
							}

							return (
								<Typography
									variant="h2"
									className={classes.noChatText}
									align="center"
								>
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" && "Nenhum novo atendimento"}
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "on-going" && "Nenhum atendimento assumido"}
									{chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "archived" && "Nenhum atendimento finalizado"}
								</Typography>
							)
						}}
					/>
				) : (
					localOrderedChats.length > 0 ? (
						<VirtualizedList
							className={`${classes.chatList} ${customClasses.scrollBar}`}
							itemSize={87}
							itemCount={customChatListLength}
							itemRender={({ index, style }) => {
								const chat = localOrderedChats[index]

								return (
									<Grid
										style={style}
										key={index}
									>
										<ChatListItem
											chatId={chat.id}
											onClick={() => onClick(chat.id)}
										/>
									</Grid>
								)
							}}
						/>
					) : (
						<Typography
							variant="h2"
							className={classes.noChatText}
							align="center"
						>
							{chatGlobalStateStore.chatListPanel.chatListFilter.current.text && "Nenhum chat encontrado"}
							{(!chatGlobalStateStore.chatListPanel.chatListFilter.current.text && chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue") && "Nenhum novo atendimento"}
							{(!chatGlobalStateStore.chatListPanel.chatListFilter.current.text && chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "on-going") && "Nenhum atendimento assumido"}
							{(!chatGlobalStateStore.chatListPanel.chatListFilter.current.text && chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "archived") && "Nenhum atendimento finalizado"}
						</Typography>
					)
				)}
			</Grid>
		</>
	), [
		chats.length,
		chatGlobalStateStore.chat.listHash,
		queueChatsHash,
		localChatsHash,
		globalStateStore.user.id
	])
}

export default ChatList
