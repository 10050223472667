import React, { useState } from "react"
import FileDownload from "js-file-download"
import { Link } from "react-router-dom"
import {
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	CircularProgress,
	IconButton,
	TextField,
	Typography,
	Avatar,
	Grid,
	Paper,
	TablePagination,
	InputAdornment,
	Chip,
	Tooltip,
	InputLabel
} from "@material-ui/core"
import {
	Edit as EditIcon,
	Search as SearchIcon,
	FilterList as FilterListIcon,
	Close as CloseIcon,
	InfoOutlined as InfoIcon,
	Block as BlockIcon
} from "@material-ui/icons"

import {
	Divider,
	Notification,
	Loading,
	Portlet,
	InfoDialog,
	PopConfirm,
	ClientIdentification,
	LoadingOverlay,
	ActionDialog,
	TypographyListGroup
} from "@/components"

import { Tag } from "@/protocols/tag"
import { Client, ClientCatalogPagination, Contact } from "@/protocols/clientCatalog"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import TagService from "@/services/Tag"
import HardCodedService from "@/services/HardCoded"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDebounce from "@/hooks/useDebounce"
import useStyles from "@/pages/Admin/ClientCatalog/styles"
import useCustomStyles from "@/styles/custom"

import { formatPhoneNumber } from "@/utils/mask"
import { isSmallScreen } from "@/utils/checkDevice"
import { convertRGBAObjectToString } from "@/utils/color"
import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import { getRowsLabel } from "@/utils/table"

import ClientCatalogTableSkeleton from "@/skeletons/Admin/ClientCatalogTable"

import colors from "@/styles/colors"
import AddClientDialog from "@/components/AddClientDialog"
import ContactFilters, { ContactsFiltersType } from "@/pages/Admin/ClientCatalog/ContactsFilters"
import CreateOrEditContactFilter from "@/pages/Admin/ClientCatalog/ContactsFilters/CreateOrEditFilters"
import CustomFieldsDialog from "@/pages/Admin/CustomFields"

export type ClientCatalogWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	searchTags?: string[]
	contactsFilters?: unknown[]
}
const ClientCatalogPage: React.FC = () => {
	const [loading, setLoading] = useState(true)
	const [loadingClientCatalog, setLoadingClientCatalog] = useState(true)
	const [clientCatalog, setClientCatalog] = useState<ClientCatalogPagination>({} as ClientCatalogPagination)
	const [exportingContacts, setExportingClients] = useState(false)
	const [whereData, setWhereData] = useState<ClientCatalogWhereData>({
		page: 0,
		rowsPerPage: 20
	})
	const [tags, setTags] = useState<Tag[]>([])
	const [clientInfoDialogOpen, setClientInfoDialogOpen] = useState(false)
	const [currentClientInfo, setCurrentClientInfo] = useState<Client>()
	const [savingClient, setSavingClient] = useState(false)
	const [editClientDialogOpen, setEditClientDialogOpen] = useState(false)
	const [editClientInfo, setEditClientInfo] = useState({
		id: 0,
		nickname: "",
		email: ""
	})

	const [openContactFiltersDialog, setOpenContactFiltersDialog] = useState(false)
	const [openEditContactFiltersDialog, setOpenEditContactFiltersDialog] = useState(false)
	const [selectedContactsFilter, setSelectedContactsFilter] = useState<ContactsFiltersType>({} as ContactsFiltersType)
	const { validation, clearValidation, triggerValidation } = useValidation()

	const isSelectedContactsFilterNotEmpty = Object.keys(selectedContactsFilter).length !== 0

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const getAndUpdateClientCatalogData = async (newWhereData?: Partial<ClientCatalogWhereData>) => {
		setLoading(true)

		try {
			const whereParams = newWhereData

			setWhereData({
				...whereData,
				...whereParams
			})

			const { data } = await ApiService.get("/clients", {
				params: {
					...whereData,
					...whereParams,
					contactType: "user"
				}
			})
			setClientCatalog({
				clientCatalog: data.clientCatalog,
				count: data.count,
				rows: data.rows
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoadingClientCatalog(false)

		setLoading(false)
	}

	const handleExportClients = async () => {
		PopConfirm.open({
			title: "Deseja realmente exportar seus contatos?",
			onConfirm: async () => {
				setExportingClients(true)
				let newWhereData: Partial<ClientCatalogWhereData> = {}

				if (isSelectedContactsFilterNotEmpty) {
					newWhereData = {
						contactsFilters: selectedContactsFilter.conditions
					}
				}

				try {
					const response = await ApiService.get("/clients/exportation/export", {
						responseType: "blob",
						params: {
							...whereData,
							...newWhereData
						}
					})
					FileDownload(response.data, "contatos.csv")
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
				setExportingClients(false)
			},
			confirmButtonText: "EXPORTAR",
			cancelButtonText: "FECHAR"

		})
	}

	const updateClientOnClientCatalog = (updatedClient: Client) => {
		const updatedClients = clientCatalog?.rows?.map(client => {
			if (client.id === updatedClient.id) {
				return {
					...client,
					...updatedClient
				}
			}

			return client
		})

		const updatedClientCatalog = {
			...clientCatalog,
			rows: updatedClients
		} as ClientCatalogPagination

		setClientCatalog(updatedClientCatalog)
	}

	const handleWhereDataChange = (newData: Partial<ClientCatalogWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getAndUpdateClientCatalogData({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getAndUpdateClientCatalogData({ rowsPerPage })
	}

	const handleSearchChange = async (searchData: string) => {
		clearValidation("search")
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const getAndUpdateTags = async () => {
		const data = await TagService.getTags()

		if (data) {
			setTags(data)
		}
	}

	const handleOpenClientInfoDialog = () => {
		setClientInfoDialogOpen(true)
	}

	const handleCloseClientInfoDialog = () => {
		setClientInfoDialogOpen(false)
	}

	const handleBlockClientAutomaticMessages = async (client: Client) => {
		PopConfirm.open({
			title: `${client.accepts_automatic_messages ? "Bloquear" : "Desbloquear"} mensagens automáticas`,
			confirmButtonText: `${client.accepts_automatic_messages ? "BLOQUEAR" : "DESBLOQUEAR"}`,
			description: "Você pode alterar essa configuração depois.",
			onConfirm: async () => {
				try {
					const payload = {
						nickname: client.nickname,
						accepts_automatic_messages: !client.accepts_automatic_messages
					}
					const { data } = await ApiService.put(`/clients/${client.id}`, payload)
					const { updatedClient } = data
					updateClientOnClientCatalog(updatedClient)
				} catch (error) {
					triggerValidation(error as ErrorType)
				}
			}
		})
	}

	const handleChangeEditClientInfo = (key: string, value: string) => {
		clearValidation(key)
		setEditClientInfo({
			...editClientInfo,
			[key]: value
		})
	}

	const handleOpenEditClientDialog = (client: Client) => {
		setCurrentClientInfo(client)
		setEditClientInfo({
			id: client.id,
			nickname: client.nickname,
			email: client.contacts?.find(contact => contact.channel_type === "email")?.data?.email || ""
		})
		clearValidation("nickname")
		clearValidation("email")
		setEditClientDialogOpen(true)
	}

	const handleEditClient = async () => {
		setSavingClient(true)

		try {
			await ApiService.put(
				`/clients/${editClientInfo.id}`,
				{ nickname: editClientInfo.nickname }
			)

			let contacts: Contact[] = currentClientInfo?.contacts || []
			if (editClientInfo.email) {
				const response = await ApiService.post(
					`/clients/${editClientInfo.id}/email`,
					{
						email: editClientInfo.email
					}
				)

				const contactsWithoutEmail = currentClientInfo?.contacts?.filter(contact => contact.channel_type !== "email") || []
				contacts = [...contactsWithoutEmail, response.data.updatedContact]
			}

			const updatedClient: Client = {
				...currentClientInfo as Client,
				nickname: editClientInfo.nickname,
				contacts
			}

			updateClientOnClientCatalog(updatedClient)

			Notification.success({ message: "Cliente atualizado." })
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setSavingClient(false)
	}

	const handleOpenFilterDialog = () => {
		setOpenContactFiltersDialog(!openContactFiltersDialog)
	}

	const handleRemoveSelectedContactFilters = async () => {
		setSelectedContactsFilter({} as ContactsFiltersType)

		await getAndUpdateClientCatalogData({ contactsFilters: undefined })
	}

	useDebounce(
		async () => await getAndUpdateClientCatalogData({ search: whereData?.search }),
		whereData.search,
		2250
	)

	useDidMount(() => {
		getAndUpdateClientCatalogData()
		getAndUpdateTags()
	})
	return (
		<>

			<Grid container spacing={2}>
				<Grid item>
					<AddClientDialog
						nickname={whereData.search}
						onAdd={getAndUpdateClientCatalogData}
					>
						<Button
							variant="contained"
							className={customClasses.buttonAction}
						>
							ADICIONAR CONTATO
						</Button>
					</AddClientDialog>
				</Grid>

				<Grid item>
					<Link to="client-catalog/import-contacts" style={{ textDecoration: "none" }}>
						<Button
							variant="contained"
							className={customClasses.buttonAction}
						>
							IMPORTAR CONTATOS
						</Button>
					</Link>
				</Grid>

				<Grid item>
					<Button
						variant="contained"
						className={customClasses.buttonAction}
						onClick={handleExportClients}
						endIcon={exportingContacts && <CircularProgress size={20} />}
					>
						EXPORTAR CONTATOS
					</Button>
				</Grid>

				<Grid item>
					<CustomFieldsDialog
					>
						<Button
							variant="contained"
							className={customClasses.buttonAction}
						>
							CAMPOS PERSONALIZADOS
						</Button>
					</CustomFieldsDialog>
				</Grid>
			</Grid>

			<Divider size={3} orientation="horizontal" />

			<Portlet
				elevation={1}
			>
				<Grid container>
					<Grid item xs={12}>
						<Grid container spacing={2} alignItems="center" justify="flex-end">
							<Grid item xs>
								{
									isSelectedContactsFilterNotEmpty && selectedContactsFilter?.id === undefined ? <Button
										style={{ padding: "11px" }}
										variant="contained"
										startIcon={<FilterListIcon />}
										color="primary"
										onClick={() => {
											setSelectedContactsFilter(selectedContactsFilter)
											setOpenEditContactFiltersDialog(true)
										}}
									>
										EDITAR FILTRO
									</Button> : <Button
										style={{ padding: "11px" }}
										variant="contained"
										startIcon={<FilterListIcon />}
										color="primary"
										onClick={handleOpenFilterDialog}
									>
										FILTRAR
									</Button>
								}
							</Grid>
							<Grid item xs={4}>
								<Tooltip title={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch") ? "Pesquisa desabillitada temporariamente, identificamos que sua conta possui muitos contatos e isso acabou gerando algumas instabilidades. Em breve essa solução será reabilitada e com maior estabilidade. Pedimos desculpas pelo transtorno e agradecemos a compreensão!" : ""} >
									<TextField
										value={whereData.search}
										onChange={({ target }) =>
											handleSearchChange(target.value)
										}
										placeholder="Pesquisar"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: whereData.search && (
												<IconButton
													size="small"
													onClick={() => handleSearchChange("")}
												>
													<CloseIcon
														fontSize="small"
													/>
												</IconButton>
											)
										}}
										fullWidth
										size="small"
										disabled={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch")}
										helperText={validation.search}
										error={!!validation.search}
									/>
								</Tooltip>
							</Grid>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					{isSelectedContactsFilterNotEmpty && <Grid container alignItems="center" spacing={1}>
						<Grid item>
							<b>Filtro Selecionado: </b>
						</Grid>
						<Grid item xs>
							<Chip
								label={selectedContactsFilter.title}
								style={{
									backgroundColor: "#FBEECC"
								}}
								onDelete={handleRemoveSelectedContactFilters}
							/>
						</Grid>
					</Grid>
					}

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12}>
						<Loading
							customLoadingElement={<ClientCatalogTableSkeleton />}
							loading={loadingClientCatalog}
						>
							{
								clientCatalog.count > 0 ? (
									<LoadingOverlay
										loading={loading}
									>
										<Paper className={classes.paper}>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow>
															<TableCell
																width="400px"
															>
																Cliente
															</TableCell>

															<TableCell
																width="190px"
															>
																WhatsApp
															</TableCell>

															<TableCell
																width="180px"
															>
																Tags
															</TableCell>

															<TableCell
																width="185px"
															/>
														</TableRow>
													</TableHead>

													<TableBody
														className={customClasses.reportTableBodyText}
													>
														{clientCatalog?.rows?.map(client => {
															const email = client.contacts?.find(contact => contact.channel_type === "email")?.data?.email
															return (
																<TableRow key={client.id} tabIndex={-1}>
																	<TableCell>
																		<Grid container justify="flex-start" alignItems="center" >
																			<Grid item>
																				<Avatar src={client.picture_url} />
																			</Grid>

																			<Divider size={isSmallScreen ? 1 : 2.5} orientation={isSmallScreen ? "horizontal" : "vertical"} />

																			<Grid item xs>
																				<TypographyListGroup>
																					<ClientIdentification
																						name={client.name}
																						nickname={client.nickname}
																						PrimaryTitleProps={{
																							align: "left",
																							variant: "body1"
																						}}
																					/>

																					{email && (
																						<Typography
																							className={customClasses.italicText}
																						>
																							{email}
																						</Typography>
																					)}
																				</TypographyListGroup>
																			</Grid>
																		</Grid>
																	</TableCell>

																	<TableCell>
																		{formatPhoneNumber(client.contacts?.find(contact => contact.channel_type === "whatsapp")?.data?.phone)}
																	</TableCell>

																	<TableCell>
																		<Grid container spacing={1}>
																			{client.tags?.map((tag, index) => {
																				return (
																					<Grid
																						key={index}
																						item
																					>
																						<Chip
																							label={tag.name}
																							style={{
																								color: colors.grayScale[11],
																								backgroundColor: convertRGBAObjectToString(tag.color)
																							}}
																							size="small"
																						/>
																					</Grid>
																				)
																			})}
																		</Grid>
																	</TableCell>

																	<TableCell>
																		<Grid container justify="flex-end" alignItems="center">
																			<Grid item>
																				<IconButton onClick={() => handleBlockClientAutomaticMessages(client)}>
																					<BlockIcon style={{ color: !client.accepts_automatic_messages ? "red" : "" }} />
																				</IconButton>
																			</Grid>

																			<Grid item>
																				<IconButton onClick={() => handleOpenEditClientDialog(client)}>
																					<EditIcon />
																				</IconButton>
																			</Grid>

																			<Grid item>
																				<IconButton onClick={() => {
																					setCurrentClientInfo(client)
																					handleOpenClientInfoDialog()
																				}}>
																					<InfoIcon />
																				</IconButton>
																			</Grid>
																		</Grid>
																	</TableCell>
																</TableRow>
															)
														})}
													</TableBody>

												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={clientCatalog?.count}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onChangeRowsPerPage={({ target }) => {
													handlePageChange(target, 0)
													handleChangeRowsPerPage(+target.value)
												}}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
											/>
										</Paper>
									</LoadingOverlay>
								) : (
									<>
										<Divider size={3} orientation="horizontal" />
										<Typography align="center" variant="h2">
											Nenhum resultado encontrado
										</Typography>
									</>
								)
							}
						</Loading>

						<InfoDialog
							title="Informações do cliente"
							openDialog={clientInfoDialogOpen}
							onClose={handleCloseClientInfoDialog}
						>
							{currentClientInfo && (
								<>
									<Grid container direction="column" spacing={1}>
										<Grid item style={{ alignSelf: "center" }}>
											<Avatar
												src={currentClientInfo.picture_url}
												style={{
													height: "160px",
													width: "160px"
												}}
											/>

											<Divider orientation="horizontal" size={2} />
										</Grid>

										<Grid item>
											<Typography variant="h4" display="inline">
												Número identificador:
											</Typography>
											{" "}
											<Typography variant="body1" display="inline">
												{currentClientInfo.id}
											</Typography>
										</Grid>

										<Grid item>
											<Typography variant="h4" display="inline">
												Nome na Letalk:
											</Typography>
											{" "}
											<Typography variant="body1" display="inline">
												{currentClientInfo.nickname}
											</Typography>
										</Grid>

										{currentClientInfo.name && (
											<Grid item>
												<Typography variant="h4" display="inline">
													Nome no WhatsApp:
												</Typography>
												{" "}
												<Typography variant="body1" display="inline">
													{currentClientInfo.name}
												</Typography>
											</Grid>
										)}

										<Grid item>
											<Typography variant="h4" display="inline">
												Telefone:
											</Typography>
											{" "}
											<Typography variant="body1" display="inline">
												{formatPhoneNumber(currentClientInfo.contacts?.[0].data?.phone)}
											</Typography>
										</Grid>

										<Grid item>
											<Typography variant="h4" display="inline">
												Data de cadastro:
											</Typography>
											{" "}
											<Typography variant="body1" display="inline">
												{currentClientInfo.created_at ? `${formatDateInBrazilianDate(new Date(currentClientInfo.created_at))} às ${formatDateInHours(new Date(currentClientInfo.created_at))}` : ""}
											</Typography>
										</Grid>

										<Grid item>
											<Typography variant="h4" display="inline">
												Data da última atualização:
											</Typography>
											{" "}
											<Typography variant="body1" display="inline">
												{currentClientInfo.updated_at ? `${formatDateInBrazilianDate(new Date(currentClientInfo.updated_at))} às ${formatDateInHours(new Date(currentClientInfo.updated_at))}` : ""}
											</Typography>
										</Grid>
									</Grid>
									<Grid item>
										<Typography variant="h4" display="inline">
											Data da última atualização:
										</Typography>
										{" "}
										<Typography variant="body1" display="inline">
											{currentClientInfo.updated_at ? `${formatDateInBrazilianDate(new Date(currentClientInfo.updated_at))} às ${formatDateInHours(new Date(currentClientInfo.updated_at))}` : ""}
										</Typography>
									</Grid>
									{currentClientInfo.client_custom_field_values && currentClientInfo.client_custom_field_values?.length > 0 && <Grid item direction="column" style={{ marginTop: 25 }} spacing={1}>
										<Grid item>
											<Typography variant="h4" display="inline">
											Campos customizados:
											</Typography>
										</Grid>
										{currentClientInfo.client_custom_field_values.map((customFieldCLientData, index) => {
											const { custom_field, value } = customFieldCLientData
											return (
												<Grid item key={custom_field?.display_name + index}>
													<Typography variant="h4" display="inline" key={"customFieldCLientData.custom_field"}>
														{custom_field?.display_name}:
													</Typography>
													{" "}
													<Typography variant="body1" display="inline" key={"customFieldCLientData.custom_field"}>
														{value}
													</Typography>
												</Grid>)
										})}

									</Grid>}
								</>
							)}
						</InfoDialog>
					</Grid>
				</Grid>

				<ActionDialog
					title="Alterar dados do cliente"
					onSave={() => handleEditClient()}
					saveText="SALVAR"
					onClose={() => setEditClientDialogOpen(false)}
					loading={savingClient}
					openDialog={editClientDialogOpen}
					fullWidth
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<InputLabel className={customClasses.inputLabel}>
                                Nome
							</InputLabel>

							<Divider orientation="horizontal" size={1} />
							<TextField
								id="input-name"
								name="nickname"
								value={editClientInfo.nickname}
								onChange={
									({ target }) => handleChangeEditClientInfo("nickname", target.value)
								}
								variant="outlined"
								placeholder="Nome"
								fullWidth
								helperText={validation.nickname}
								error={!!validation.nickname}
							/>
						</Grid>
						<Grid item xs={12}>
							<InputLabel className={customClasses.inputLabel}>
                                Email
							</InputLabel>

							<Divider orientation="horizontal" size={1} />
							<TextField
								value={editClientInfo.email}
								onChange={
									({ target }) => handleChangeEditClientInfo("email", target.value)
								}
								variant="outlined"
								placeholder="example@email.com"
								fullWidth
								helperText={validation.email}
								error={!!validation.email}
							/>
						</Grid>
					</Grid>
				</ActionDialog>

				<ContactFilters
					openDialog={openContactFiltersDialog}
					setOpenDialog={setOpenContactFiltersDialog}
					setSelectedFilter={setSelectedContactsFilter}
					getDataWithFilters={getAndUpdateClientCatalogData}
					contactsTags={tags}
				/>

				<CreateOrEditContactFilter
					openDialog={openEditContactFiltersDialog}
					setOpenDialog={setOpenEditContactFiltersDialog}
					selectedFilter={selectedContactsFilter}
					setSelectedFilter={setSelectedContactsFilter}
					getDataWithFilters={getAndUpdateClientCatalogData}
					contactsTags={tags}
				/>
			</Portlet>
		</>
	)
}

export default ClientCatalogPage
