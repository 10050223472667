import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	iconContainer: {
		width: 24,
		height: 24,
		borderRadius: "100%",
		padding: theme.spacing(0.5),
		"& svg": {
			width: "100%",
			height: "100%"
		}
	}
}))

export default useStyles
