import React from "react"
import {
	Grid
} from "@material-ui/core"

import { Divider, AccessibleDrawer, Profile } from "@/components"

import useChatStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

type ProfileInfoProps = {
	openDrawer: boolean
	onClose: () => void
}

const ProfileInfo = (props: ProfileInfoProps) => {
	const { openDrawer, onClose } = props

	const chatClasses = useChatStyles()

	const handleCloseDrawer = () => {
		onClose()
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleCloseDrawer()
		}
	}

	useDidMount(() => {
		window.addEventListener(
			"keydown",
			eventKeyDownHandler,
			{ capture: true }
		)
	})

	return (
		<AccessibleDrawer
			id="new-chat-drawer"
			anchor="left"
			open={openDrawer}
			variant="persistent"
			onClose={handleCloseDrawer}
			onMobileBackButtonPress={handleCloseDrawer}
			classes={{
				paper: chatClasses.drawerPaper
			}}
		>
			<Grid container>
				<Grid item xs={12}>
					<HeaderDrawer
						title="Meus dados"
						onClose={handleCloseDrawer}
					/>
				</Grid>

				<Divider size={2} orientation="horizontal" />

				<Grid item xs={12}>
					<Profile />
				</Grid>
			</Grid>
		</AccessibleDrawer>
	)
}

export default ProfileInfo
