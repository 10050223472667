import axios from "axios"
import axiosRetry from "axios-retry"

import apiConfig from "@/config/api"

import AuthService from "@/services/Auth"
import StorageService from "@/services/Storage"

import { Notification } from "@/components"

const ApiService = axios.create({
	baseURL: apiConfig.apiUrl,
	withCredentials: true
})

axiosRetry(ApiService, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

ApiService.interceptors.request.use(async config => {
	// this is added to make a delay in each request, to simulate production
	// await new Promise(resolve => setTimeout(resolve, 1000))

	config.headers[AuthService.authTokenKey] = AuthService.authToken
	config.headers["x-inbox-instance-id"] = StorageService.get("inbox-instance-id")

	return config
})

ApiService.interceptors.response.use(async requestConfig => requestConfig, async error => {
	// This status must be an exception in "src/hooks/useValidation.tsx" triggerValidation method to avoid double error notifications
	if (error?.response?.status === 403) {
		Notification.warning({
			message: "Você não possui permissão para realizar essa ação."
		})
	}
	return Promise.reject(error)
})

export default ApiService
