import React, { useState } from "react"
import {
	Grid,
	TextField,
	Chip
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { renderComponent } from "@/utils/node"
import { getCurrentAssignAttendanceBalancedOption, formatAssignAttendanceBalancedOptions } from "@/utils/chatBot"

import { ActionDialog } from "@/components"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/AssignAttendanceRandomlyBlock/RandomlyAttendanceEditor/styles"

import {
	ChatBotFlowBlockContent
} from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

type RandomlyAttendanceEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

type RandomlyAttendanceEditorType = {
	open: (props: RandomlyAttendanceEditorProps) => void
}

const RandomlyAttendanceEditor: RandomlyAttendanceEditorType & React.FC<RandomlyAttendanceEditorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		chatBotFlowBlockContent
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)

		handleClose()
	}

	const handleChangeContent = (content: Partial<ChatBotFlowBlockContent>) => {
		setBlockContent(lastState => ({
			...lastState,
			...content
		}))
	}

	const formattedOptions = formatAssignAttendanceBalancedOptions(constructionResources)

	return (
		<ActionDialog
			title="FORMA DE DISTRIBUIÇÃO DE ATENDIMENTOS"
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Autocomplete
						defaultValue={getCurrentAssignAttendanceBalancedOption(constructionResources, chatBotFlowBlockContent)}
						options={formattedOptions}
						getOptionSelected={(option, value) => option.name === value.name}
						getOptionLabel={(option) => option.name}
						fullWidth
						filterSelectedOptions
						onChange={(_, value) => {
							if (value) {
								handleChangeContent({
									balancedAttendanceAssignmentData: {
										teamId: value.teamId,
										type: value.type
									}
								})
							}
						}}
						renderInput={(params) => {
							return (
								<TextField
									className={classes.filterInput}
									variant="outlined"
									placeholder="Selecione uma forma de distribuição"
									{...({
										...params,
										InputProps: {
											...params.InputProps
										}
									})}
								/>
							)
						}}
						renderTags={(value, getTagProps) => {
							return value.map((attendant, index) => (
								<Chip
									key={index}
									label={attendant.name}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

RandomlyAttendanceEditor.open = (props: RandomlyAttendanceEditorProps) => {
	renderComponent(
		"balanced-attendance-editor",
		<RandomlyAttendanceEditor
			{...props}
		/>
	)
}

export default RandomlyAttendanceEditor
