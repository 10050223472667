import { SubscriptionData } from "@/protocols/instance"
import { UserRoleCode, UserRoleName } from "@/protocols/user"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { getStatusCode } from "@/utils/response"

export type UserExtraData = {
	is_account_user: boolean
	is_admin_mode: boolean
	is_tester?: boolean
	is_first_access?: boolean
}

export type UserTeam = {
	id: number
	name: string
}

export type UserDataProps = {
	id: number
	name: string
	email: string
	phone_number: string
	is_instance_owner: boolean
	extra_data: UserExtraData
	created_at: Date,
	user_teams: UserTeam[]
	valid_teams: number[]
}

export type UserInInstanceData = {
	id: number
	instance_id: number
	instance_created_at: Date
	is_instance_owner: boolean
	user_id: number
	user_role_code: UserRoleCode
	user_role_name: UserRoleName
	user_teams: UserTeam[]
	valid_teams: number[]
}

type UserInfo = {
	authToken: string,
	userData: UserDataProps
	userInInstanceData: UserInInstanceData
	subscriptionData: SubscriptionData
}

class UserService {
	private static cache: UserInfo | null = null

	async getInfo (): Promise<UserInfo | null> {
		try {
			/**
			 * That's a way to avoid making multiple repeated requests
			 * throughout the application lifetime.
			 */
			// TODO can't use cache because the subscription data need to be updated.
			// if (UserService.cache) {
			// 	return UserService.cache
			// }

			const response = await ApiService.get("/user")

			const userInfo = response.data

			UserService.cache = userInfo
			return userInfo
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}

	purgeCache (): void {
		UserService.cache = null
	}

	async isLoggedIn (): Promise<boolean> {
		let statusCode: number

		try {
			const response = await ApiService.get("/user/auth/check")

			statusCode = response.status
		} catch (error) {
			ErrorHandlerService.handle(error)

			statusCode = getStatusCode(error) || 500
		}

		/**
		 * User is not logged in if the status code
		 * returned by that request is 401 (Unauthorized).
		 */
		if (statusCode === 401) {
			return false
		}

		return true
	}
}

export default new UserService()
