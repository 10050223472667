import React, { useState } from "react"

import ActionDialog from "@/components/ActionDialog"
import { BlockCode } from "@/hooks/useSubscriptionLimits"
import { Link, Typography } from "@material-ui/core"
import { renderComponent } from "@/utils/node"
import useDidMount from "@/hooks/useDidMount"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

// type DialogueCodeToDialogContentProps = Record< BlockCode, {
// 	dialogueTitle: string
// 	dialogueBody: JSX.Element | JSX.Element[]
// }>
type DialogueCodeToDialogContentProps = Record< string, {
	dialogueTitle: string
	dialogueBody: JSX.Element | JSX.Element[]
}>

type UpsellDialogProps = {
	onSave?: () => Promise<void> | void
	onClose?: () => void
	saveText?: string
	openDialog?: boolean
	dialogCode: BlockCode
}

type UpsellDialogType = {
	open: (props: UpsellDialogProps) => void
}

/**
 * dialogCode param should be returned by useSubscriptionLimits hook
 */
const UpsellDialog: UpsellDialogType & React.FC<UpsellDialogProps> = (props) => {
	const {
		saveText = "Ok",
		dialogCode,
		onClose,
		onSave
	} = props

	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		setLoading(true)

		await onSave?.()

		setLoading(false)

		handleClose()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	const dialogCodeToDialogContent: DialogueCodeToDialogContentProps = {
		"user-limit-reached": {
			dialogueTitle: "Vish!!",
			dialogueBody: (
				<>
					<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
						{`Parece que o número de atendentes da sua conta chegou no limite :(

							Remova algum atendente/convite ou `}
						<Link
							href={"https://api.whatsapp.com/send?phone=555531975436343&text=Olá. Estou precisando de um usuário adicional em minha conta"}
							target="_BLANK"
						>
							clique aqui para falar com nosso comercial
						</Link>
					</Typography>
				</>
			)
		},
		"group-permission-denied": {
			dialogueTitle: "Vish!!",
			dialogueBody: (
				<>
					<Typography style={{ alignItems: "center", whiteSpace: "pre-line" }}>
						{`Parece que você ainda não possui permissão para acessar funcionalidades de grupos :(

							Faça um upgrade no seu plano ou adquira já um adicional!
							`}
						<Link
							href={"https://api.whatsapp.com/send?phone=555531975436343&text=Olá. Estou precisando de adquirir acesso ao módulo de grupos em minha conta"}
							target="_BLANK"
						>
							clique aqui para falar com nosso comercial
						</Link>
					</Typography>
				</>
			)
		}
	}
	const dialogContent = dialogCodeToDialogContent[dialogCode]

	return (
		<ActionDialog
			title={dialogContent.dialogueTitle}
			openDialog={opened}
			loading={loading}
			fullWidth={true}
			saveText={saveText}
			onClose={onClose}
			onSave={handleSave}
			hideCloseButton={true}
		>
			{dialogContent.dialogueBody}
		</ActionDialog>
	)
}

UpsellDialog.open = (props: UpsellDialogProps) => {
	renderComponent(
		"pop-confirm",
		<UpsellDialog
			{...props}
		/>
	)
}

export default UpsellDialog
