import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Button,
	Typography,
	Grid,
	Container,
	CircularProgress
} from "@material-ui/core"

import {
	Divider,
	Loading,
	Notification
} from "@/components"

import { MessageBlastData, MessageBlastStatus } from "@/protocols/messageBlast"

import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/MessageBlast/styles"

import MessageBlastSkeleton from "@/skeletons/Admin/MessageBlast"

import noMessageBlastImg from "@/assets/images/messageBlast/no_message_blast.svg"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import MessageBlastListItem from "@/pages/Admin/MessageBlast/MessageBlastListItem"
import { ErrorType } from "@/hooks/useValidation"
import { Add } from "@material-ui/icons"
import { messageBlastStatusTranslatedMapCamelCase } from "@/utils/messageBlast"

export type MessageBlastsGroupedByStatus = Record<MessageBlastStatus, MessageBlastData[]>

const MessageBlast: React.FC = () => {
	const classes = useStyles()
	const history = useHistory()

	const [loading, setLoading] = useState(true)
	const [creatingMessageBlast, setCreatingMessageBlast] = useState(false)
	const [messageBlasts, setMessageBlasts] = useState<MessageBlastsGroupedByStatus>()
	const [hasMoreThanOne, setHasMoreThanOne] = useState(false)
	const [messageBlastStatusListSorted, setMessageBlastStatusListSorted] = useState<Partial<MessageBlastStatus[]>>([])

	const loadMessageBlasts = async () => {
		try {
			const response = await ApiService.get("/message-blasts", {
				params: {
					type: "user"
				}
			})
			const messageBlastsGroupedByStatus = response.data.messageBlastsGroupedByStatus as MessageBlastsGroupedByStatus

			const messageBlastStatusKeyListSorted = Object.keys(messageBlastsGroupedByStatus).sort() as MessageBlastStatus[]

			setMessageBlastStatusListSorted(messageBlastStatusKeyListSorted)

			messageBlastStatusKeyListSorted.forEach((messageBlastStatusKey) => {
				const possibleMessageBlastList = messageBlastsGroupedByStatus[messageBlastStatusKey]

				if (possibleMessageBlastList.length > 0) {
					setHasMoreThanOne(true)
				}
			})

			setMessageBlasts(messageBlastsGroupedByStatus)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}

	const handleOpenMessageBlastManagement = (messageBlastId: number) => {
		history.push(`/admin/message-blast/${messageBlastId}`)
	}

	const handleCreateMessageBlast = async () => {
		setCreatingMessageBlast(true)

		const payload = {
			title: "Novo envio",
			type: "user"
		}

		try {
			const response = await ApiService.post("/message-blasts", payload)
			const messageBlastId = response.data.messageBlast.id
			Notification.success({ message: "Envio criado com sucesso!" })
			handleOpenMessageBlastManagement(messageBlastId)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
			Notification.error({ message: "Não foi possível criar o envio!" })
			setCreatingMessageBlast(false)
		}
	}

	const setup = async () => {
		await Promise.all([
			loadMessageBlasts()
		])

		setLoading(false)
	}

	useDidMount(() => {
		setup()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<MessageBlastSkeleton />}
		>
			<>
				{hasMoreThanOne ? (
					<Grid container spacing={3}>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleCreateMessageBlast}
								endIcon={creatingMessageBlast && <CircularProgress size={20} />}
								disabled={creatingMessageBlast}
								startIcon={<Add/>}
							>

								ADICIONAR ENVIO
							</Button>
						</Grid>

						<Divider orientation="horizontal" size={4}/>

						<Grid item xs={11}>
							<Grid container spacing={7} direction="column">
								{messageBlastStatusListSorted.map((messageBlastStatusKey) => {
									return <Grid item xs key={messageBlastStatusKey}>
										{(messageBlastStatusKey && messageBlasts) && <Grid container>
											<Typography
												variant="body1"
												className={classes.coloredTitle}
											>
                                                    ENVIOS PARA CONTATOS:
											</Typography>

											<Divider orientation="horizontal" size={1}/>

											<Typography variant="h4" className={classes.messageBlastStatusName}>
												{messageBlastStatusTranslatedMapCamelCase[messageBlastStatusKey]}
											</Typography>

											<Divider orientation="horizontal" size={3}/>
											{
												messageBlasts[messageBlastStatusKey].map(msg => (<Grid
													item
													xs={12}
													key={msg.id}
												>
													<MessageBlastListItem
														title={msg.title}
														status={msg.status}
														createdAt={new Date(msg.createdAt)}
														sentAt={msg.status === "SENT" ? new Date(msg.statusDate) : undefined}
														messageBlastId={msg.id}
														scheduleTime={msg.schedule?.time}
													/>
													<Divider orientation="horizontal" size={2}/>
												</Grid>))
											}
										</Grid>}
									</Grid>
								})}
							</Grid>
						</Grid>
					</Grid>
				) : (
					<Container maxWidth="md">
						<Grid
							container
							alignItems="center"
							justify="space-between"
							direction="column"
							spacing={8}
						>
							<Grid item>
								<img
									src={noMessageBlastImg}
									alt="Sem envios"
									className={classes.noMessageBlastImage}
								/>
							</Grid>

							<Grid item xs={10}>
								<Typography
									variant="h5"
									className={classes.noMessageBlastTitle}
									align="center"
								>
									Faça um envio simples e rápido agora mesmo
								</Typography>
							</Grid>

							<Grid xs={8}>
								<Typography
									variant="body1"
									className={classes.noMessageBlastText}
									align="center"
								>
                                    Envie mensagens para centenas de contatos ao mesmo tempo! Comece a conversa com seus leads
                                    e clientes em um canal de diálogo direto e com mais chances de visualizações e conversões.
								</Typography>
							</Grid>

							<Divider orientation="horizontal" size={2} />

							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleCreateMessageBlast}
									endIcon={creatingMessageBlast && <CircularProgress size={20} />}
									disabled={creatingMessageBlast}
									startIcon={<Add/>}
								>
                                    ADICIONAR O PRIMEIRO ENVIO
								</Button>
							</Grid>
						</Grid>
					</Container>
				)}
			</>
		</Loading>
	)
}

export default MessageBlast
