import React, { useState, useEffect } from "react"
import {
	Card,
	Grid,
	Typography,
	IconButton,
	Popper,
	InputBase,
	Box,
	CircularProgress,
	Tooltip
} from "@material-ui/core"

import {
	Autocomplete,
	AutocompleteCloseReason
} from "@material-ui/lab"

import {
	Close as CloseIcon,
	AddCircleOutline as AddIcon,
	Done as DoneIcon
} from "@material-ui/icons"

import {
	Divider,
	Loading,
	Notification,
	SvgIcon
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import ClientTagsSkeleton from "@/skeletons/ClientTags"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientTags/styles"

import { Color } from "@/protocols/color"
import { Tag } from "@/protocols/tag"
import { ErrorType } from "@/hooks/useValidation"

import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"

type ClientTagsProps = {
	clientId: number
}

type TagDataProps = {
	id: number
	name: string,
	color: Color,
	activeCampaignAssociationIsEnabled?: boolean
}

type ChangedTagAction = "ADD" | "REMOVE"

const ClientTags: React.FC<ClientTagsProps> = (props) => {
	const { clientId } = props

	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()

	const [loading, setLoading] = useState(false)
	const [clientTags, setClientTags] = useState<TagDataProps[]>([])
	const [selectedTags, setSelectedTags] = useState<TagDataProps[]>([])
	const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null)

	const [changedTags, setChangedTags] = useState<Record<number, ChangedTagAction>>({})

	const changeTag = (tagId: number, action: ChangedTagAction) => {
		setChangedTags(lastState => {
			lastState[tagId] = action

			return lastState
		})
	}

	const getClientTags = async () => {
		if (!clientId) {
			return
		}

		try {
			const { data } = await ApiService.get(`/tag-association/client/${clientId}/tags`)
			const clientTags = data.tags as Tag[]
			const clientTagIds = clientTags.map(clientTag => clientTag.id)

			chatGlobalStateStore.client.updateById(clientId, {
				tagIds: clientTagIds || []
			})

			setClientTags(clientTags)

			const currentSelectedTags = chatGlobalStateStore.tag.list.filter(tag => clientTagIds.includes(tag.id))
			setSelectedTags(currentSelectedTags)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}
	}
	const handleRemoveTagsOnFrontEnd = (tagIds: number[]) => {
		chatGlobalStateStore.client.updateById(clientId, {
			tagIds: tagIds || []
		})
		setClientTags(lastState => lastState.filter(function (tag) {
			return !tagIds.includes(tag.id)
		}))

		setSelectedTags(lastState => lastState.filter(function (tag) {
			return !tagIds.includes(tag.id)
		}))
	}
	const removeTags = async (tagIds: number[]) => {
		await ApiService.delete(`/tag-association/client/${clientId}`, {
			params: {
				tagIds: tagIds.join(","),
				inboxChannelChatId: chatGlobalStateStore.chat.current?.id
			}
		})
	}
	const handleAddTagsOnFrontEnd = (tagIds: number[]) => {
		chatGlobalStateStore.client.updateById(clientId, {
			tagIds: tagIds || []
		})
		setClientTags(lastState => ([
			...lastState,
			...chatGlobalStateStore.tag.list.filter(function (tag) {
				return tagIds.includes(tag.id)
			})
		]))
	}
	const addTags = async (tagIds: number[]) => {
		await ApiService.post(`/tag-association/client/${clientId}`, {
			tagIds,
			inboxChannelChatId: chatGlobalStateStore.chat.current?.id
		})
	}

	const handleOpenAddTagPopper = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElement(event.currentTarget)
	}

	const handleCloseAddTagPopper = async (event: React.ChangeEvent<unknown>, reason: AutocompleteCloseReason) => {
		try {
			/**
			 * When the search input is clicked
			 */
			if (reason === "toggleInput") {
				return
			}

			if (anchorElement) {
				anchorElement.focus()
			}

			setAnchorElement(null)

			const isThereAnyChange = Object.keys(changedTags).length > 0

			if (!isThereAnyChange) {
				return
			}

			const addedTagIds: number[] = []
			const removedTagIds: number[] = []

			for (const changedTagId in changedTags) {
				const action = changedTags[changedTagId]
				const tagId = Number(changedTagId)

				if (action === "ADD") {
					addedTagIds.push(tagId)
				} else if (action === "REMOVE") {
					removedTagIds.push(tagId)
				}
			}

			handleRemoveTagsOnFrontEnd(removedTagIds)
			handleAddTagsOnFrontEnd(addedTagIds)

			if (removedTagIds.length) {
				await removeTags(removedTagIds)
			}

			if (addedTagIds.length) {
				await addTags(addedTagIds)
			}
			setChangedTags({})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Algo deu errado ao atualizar as tags" })
			setLoading(false)
		}
	}

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	const handleRemoveTag = async (tagId: number) => {
		try {
			handleRemoveTagsOnFrontEnd([tagId])
			await removeTags([tagId])
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Ocorreu um erro ao remover a tag" })
		}
	}

	useEffect(() => {
		getClientTags()
		// eslint-disable-next-line
	}, [clientId])

	return (
		<>
			<Grid item >
				<Card className={classes.tagsCard}>
					<Loading loading={loading} customLoadingElement={<ClientTagsSkeleton />}>
						<Grid container alignItems="center" justify="space-between">
							<Grid item xs>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: "15px" }}
								>
									Tags
								</Typography>
							</Grid>
							<Grid item>
								<IconButton onClick={handleOpenAddTagPopper} aria-describedby="popper" disabled={loading}>
									<AddIcon />
								</IconButton>
							</Grid>

							<Divider orientation="horizontal" size={0.5} />

							<Grid item xs={12}>
								{
									!loading ? (
										clientTags.map(tag =>
											<Box key={tag.id} className={classes.tagCard} style={{ background: colorToRGB(tag.color) }}>
												<div
													className={classes.pluginFeatureIconCircle}
													style={
														{
															opacity: tag.activeCampaignAssociationIsEnabled ? 1 : 0
														}
													}
												>
													<SvgIcon
														className={classes.pluginFeatureIcon}
														icon={ActiveCampaignSVGIcon}
													/>
												</div>

												<Tooltip title={tag.activeCampaignAssociationIsEnabled ? "Essa tag foi adicionada ao contato no Active Campaign." : ""}>
													<span>{tag.name}</span>
												</Tooltip>

												<IconButton
													className={classes.closeIcon}
													size="small"
												>
													<CloseIcon
														onClick={() => handleRemoveTag(tag.id)}
													/>
												</IconButton>
											</Box>
										)
									) : (
										<Grid container justify="center">
											<CircularProgress size={30} color="inherit" />
										</Grid>
									)
								}
							</Grid>
						</Grid>
					</Loading>
				</Card>
			</Grid>

			<Popper
				id="popper"
				open={Boolean(anchorElement)}
				anchorEl={anchorElement}
				placement="bottom-start"
				className={classes.popper}
			>
				<Autocomplete
					open
					onClose={handleCloseAddTagPopper}
					multiple
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					value={selectedTags}
					onChange={(event, newValue, reason, details) => {
						const tagId = details?.option?.id

						const isAddReason = reason === "select-option"
						const isRemoveReason = reason === "remove-option"

						if (tagId) {
							if (isAddReason) {
								changeTag(tagId, "ADD")
							}

							if (isRemoveReason) {
								changeTag(tagId, "REMOVE")
							}
						}

						setSelectedTags(newValue)
					}}
					disableCloseOnSelect
					disablePortal
					renderTags={() => null}
					noOptionsText="Sem tags cadastradas"
					renderOption={(option, { selected }) => (
						<>
							<DoneIcon
								className={classes.iconSelected}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
							<Box className={classes.color} style={{ backgroundColor: colorToRGB(option.color) }} />
							<Typography className={classes.text}>
								{option.name}
							</Typography>
							<CloseIcon
								className={classes.close}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
						</>
					)}
					options={chatGlobalStateStore.tag.listOrderedAlphabetically}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={classes.inputBase}
						/>
					)}
				/>
			</Popper>
		</>
	)
}

export default ClientTags
