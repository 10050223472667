import React, { useState } from "react"
import {
	Grid,
	Typography,
	ButtonBase
} from "@material-ui/core"
import {
	Message as SendMessageIcon,
	LocalOffer as ManageClientTagsIcon,
	LiveHelp as ConditionalTextIcon,
	SyncAlt as AssignAttendanceIcon,
	Flag as FinishAttendanceIcon,
	AccountTree as AssignAttendanceToAccountManagerIcon,
	HelpOutline as DefaultIcon,
	KeyboardReturn as RestartFlowIcon,
	EventNote as AttendanceScheduleIcon,
	List as ConditionalMenuIcon,
	LowPriority as AssignAttendanceToGeneralQueueIcon,
	Assignment as SaveInputIcon
} from "@material-ui/icons"
import {
	ReactComponent as AssignAttendanceRandomlyIcon
} from "@/assets/icons/mediation.svg"
import {
	ReactComponent as ConditionalButtonIcon
} from "@/assets/icons/view_stream.svg"
import {
	ReactComponent as GoToFlowIcon
} from "@/assets/icons/chat_bot.svg"

import { renderComponent } from "@/utils/node"

import { ActionDialog, Portlet, Divider, SvgIcon } from "@/components"

import { ConstructionResources, FlowBlock } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockType } from "@/protocols/chatBot"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockCreator/styles"

type BlockCreatorProps = {
	onSave: (flowBlock: FlowBlock) => void
	constructionResources: ConstructionResources
	blockedBlockTypes?: ChatBotFlowBlockType[]
}

type BlockCreatorType = {
	open: (props: BlockCreatorProps) => void
}

const BLOCK_ICON_MAP: Record<ChatBotFlowBlockType | "default", React.ReactElement> = {
	"assign-attendance": <AssignAttendanceIcon />,
	"conditional-text": <ConditionalTextIcon />,
	"finish-attendance": <FinishAttendanceIcon />,
	"send-message": <SendMessageIcon />,
	"assign-attendance-randomly": <SvgIcon icon={AssignAttendanceRandomlyIcon} />,
	"assign-attendance-to-account-manager": <AssignAttendanceToAccountManagerIcon />,
	"restart-flow": <RestartFlowIcon />,
	"attendance-schedule": <AttendanceScheduleIcon />,
	"conditional-button": <SvgIcon icon={ConditionalButtonIcon} />,
	"assign-attendance-to-general-queue": <AssignAttendanceToGeneralQueueIcon />,
	"conditional-menu": <ConditionalMenuIcon />,
	"manage-client-tags": <ManageClientTagsIcon />,
	"save-input": <SaveInputIcon />,
	"go-to-flow": <SvgIcon icon={GoToFlowIcon} />,
	default: <DefaultIcon />
}

const BlockCreator: BlockCreatorType & React.FC<BlockCreatorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		blockedBlockTypes
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSelect = (flowBlockId: number) => {
		const flowBlock = constructionResources.blocks.find(block => block.id === flowBlockId)

		if (flowBlock) {
			onSave(flowBlock)
		}

		handleClose()
	}

	return (
		<ActionDialog
			title="BLOCOS"
			hideCloseButton
			onClose={handleClose}
			openDialog={opened}
			maxWidth="md"
			fullWidth
		>
			<Grid
				container
				spacing={6}
			>
				{constructionResources.blockCategories
					.map(category => (
						<Grid
							key={category.title}
							item
							xs={12}
						>
							<Grid
								container
								spacing={2}
							>
								<Grid
									item
									xs={12}
								>
									<Typography
										variant="h4"
										color="textPrimary"
										className={classes.categoryTitleText}
									>
										{category.title}
									</Typography>
								</Grid>

								<Grid
									item
									xs={12}
									style={{ padding: "8px 16px" }}
								>
									<Grid
										container
										spacing={2}
										className={classes.blocksContainer}
									>
										{category.blocks
											.filter(block => blockedBlockTypes ? !blockedBlockTypes?.includes(block.type) : true)
											.map(block => (
												<Grid
													key={block.type}
													container
												>
													<Portlet
														component={ButtonBase}
														onClick={() => handleSelect(block.id)}
														style={{
															alignItems: "flex-start",
															padding: "16px",
															borderRadius: "8px"
														}}
													>
														<Grid
															container
															direction="row"
														>
															{React.cloneElement(BLOCK_ICON_MAP[block.type] || BLOCK_ICON_MAP.default, {
																className: classes.blockIcon,
																color: "primary"
															})}

															<Divider orientation="vertical" size={2} />

															<Grid
																container
																direction="column"
																className={classes.blockInfoContainer}
															>
																<Typography
																	align="left"
																	variant="h5"
																	color="textPrimary"
																	className={classes.blockInfoTitleText}
																>
																	{block.title}
																</Typography>

																<Divider orientation="horizontal" size={1} />

																<Typography
																	align="left"
																	variant="h5"
																	color="textPrimary"
																	className={classes.blockInfoDescriptionText}
																>
																	{block.description}
																</Typography>
															</Grid>
														</Grid>
													</Portlet>
												</Grid>
											))}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
			</Grid>
		</ActionDialog>
	)
}

BlockCreator.open = (props: BlockCreatorProps) => {
	renderComponent(
		"block-creator",
		<BlockCreator
			{...props}
		/>
	)
}

export default BlockCreator
