import React, { useState, useEffect } from "react"
import {
	Grid,
	TextField,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Button,
	IconButton,
	InputLabel
} from "@material-ui/core"

import { Close as CloseIcon } from "@material-ui/icons"

import PhoneInputComponent, { OnChangePhoneInputParams } from "@/components/PhoneInput"

import useStyles from "@/components/AddClientDialog/styles"
import useCustomStyles from "@/styles/custom"

import ApiService from "@/services/Api"
import { Divider, Notification } from "@/components"
import useValidation, { ErrorType } from "@/hooks/useValidation"

type clientInfo = {
	nickname: string,
	phone: string,
	countryCode: string
}
const blankClient: clientInfo = {
	nickname: "",
	phone: "",
	countryCode: "55"
}

type AddClientDialogProps = {
	onClose?: () => void
	onAdd?: () => void,
	nickname?: string
}

const AddClientDialog: React.FC<AddClientDialogProps> = ({
	onClose,
	onAdd,
	nickname,
	children
}) => {
	const [newClient, setNewClient] = useState<clientInfo>(blankClient)

	const [open, setOpen] = useState(false)
	const [loading, setLoading] = useState(false)
	const { validation, triggerValidation, clearValidation } = useValidation()
	const classes = useStyles()
	const customClasses = useCustomStyles()

	useEffect(() => {
		setNewClient({
			...blankClient,
			nickname: nickname || ""
		})
	}, [nickname])

	const handleOpenDialog = () => {
		setOpen(true)
	}

	const handleCloseDialog = () => {
		setOpen(false)
		onClose?.()
	}

	const handleAddClient = async (event: React.SyntheticEvent) => {
		event.preventDefault()
		setLoading(true)

		const createData = {
			channel_type: "whatsapp",
			nickname: newClient.nickname,
			contactData: {
				phone: newClient.phone,
				countryCode: newClient.countryCode
			}
		}

		try {
			await ApiService.post(
				"/clients",
				createData
			)
			setNewClient(blankClient)

			Notification.success({ message: "Contato criado!" })
			onAdd?.()
			handleCloseDialog()
		} catch (err) {
			if ((err as ErrorType)?.response?.data?.codeMessages.phone === "contact_exist") {
				Notification.warning({ message: "Identificamos que já existia um contato com esse número, então apenas atualizamos o seu cadastro com as informações que acabou de preencher." })
			} else {
				triggerValidation(err as ErrorType)
			}
		}

		setLoading(false)
	}

	const handleChangeNewClientField = (value: string, field: keyof clientInfo) => {
		clearValidation(field)

		setNewClient(lastState => ({
			...lastState,
			[field]: value
		}))
	}

	const handleChangePhoneInput = (phoneData: OnChangePhoneInputParams) => {
		handleChangeNewClientField(phoneData.formattedPhone, "phone")
		handleChangeNewClientField(phoneData.countryCode, "countryCode")
	}

	return (
		<>
			<div
				onClick={handleOpenDialog}
			>
				{children}
			</div>
			<Dialog
				open={open}
				onClose={handleCloseDialog}
				fullWidth
			>
				<form onSubmit={handleAddClient}>
					<IconButton onClick={handleCloseDialog} className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>

					<DialogTitle>
						Novo contato
					</DialogTitle>

					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<InputLabel className={customClasses.inputLabel}>
                                    Nome
								</InputLabel>

								<Divider orientation="horizontal" size={1} />
								<TextField
									value={newClient.nickname}
									onChange={
										({ target }) => handleChangeNewClientField(target.value, "nickname")
									}
									placeholder="Ex: José Amilton"
									fullWidth
									variant="outlined"
									helperText={validation.nickname}
									error={!!validation.nickname}
								/>
							</Grid>

							<Grid item xs={12}>
								<InputLabel className={customClasses.inputLabel}>
                                    Número do WhatsApp
								</InputLabel>

								<Divider orientation="horizontal" size={1} />
								<PhoneInputComponent
									value={newClient.phone}
									specialLabel=''
									country={"br"}
									preferredCountries={["br", "pt", "us"]}
									onChange={handleChangePhoneInput}
									validation={validation.phone}
								/>
							</Grid>
						</Grid>

					</DialogContent>

					<DialogActions>
						<Button
							onClick={handleCloseDialog}
							color="inherit"
							className={[customClasses.cancelButton, classes.button].join(" ")}
						>
							CANCELAR
						</Button>
						<Button
							endIcon={loading && <CircularProgress size={20} className={classes.loading} />}
							onClick={handleAddClient}
							color="primary"
							variant="contained"
							type="submit"
							className={classes.button}
						>
							ADICIONAR
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		</>
	)
}

export default AddClientDialog
