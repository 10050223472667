import React from "react"
import { Typography, Link } from "@material-ui/core"

import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"
import BlockBodyEditMessageItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyEditMessageItem"

import FlowEditor from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/GoToFlowBlock/FlowEditor"

import {
	getNextChatBotRuleDescription,
	getCurrentGoToFlowOption
} from "@/utils/chatBot"

const GoToFlowBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		changeChatBotFlowContent,
		chatBotFlowBlockContent,
		nextChatBotFlowBlockRules,
		constructionResources,
		flowBlock,
		deleteChatBotFlowBlock
	} = props

	const handleChangeAttendance = () => {
		FlowEditor.open({
			onSave: (chatBotFlowBlockContent) => changeChatBotFlowContent(chatBotFlowBlockContent),
			chatBotFlowBlockContent,
			constructionResources
		})
	}

	const getCurrentGoToFlowTitle = () => {
		const option = getCurrentGoToFlowOption(constructionResources, chatBotFlowBlockContent)

		if (!option) {
			return ""
		}

		const chatBotFlowHref = `${window.location.origin}/admin/bots/${option.chatBotFlowId}`

		return (
			<Link
				href={chatBotFlowHref}
				target="_blank"
			>
				{option.name}
			</Link>
		)
	}

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyEditMessageItem
					constructionResources={constructionResources}
					title="Mensagem"
					onSave={(buildedMessages) => changeChatBotFlowContent({ messagesToSend: buildedMessages })}
					messages={chatBotFlowBlockContent.messagesToSend}
				/>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Direcionar para o Bot"
					/>

					<BlockBodyItemInput
						text={getCurrentGoToFlowTitle() || ""}
						type="action"
						placeholder="Selecione um Bot"
						onEdit={handleChangeAttendance}
					/>
				</BlockBodyItem>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Condição de saída"
					/>

					{nextChatBotFlowBlockRules
						.map((nextChatBotFlowBlockRule) => (
							<Typography
								key={nextChatBotFlowBlockRule.id}
								variant="overline"
								color="textPrimary"
							>
								{getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
							</Typography>
						))
					}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default GoToFlowBlock
