import React from "react"
import { Typography } from "@material-ui/core"

import { EditableBlockDefaultProps } from "@/protocols/chatBotConstructor"

import BlockBodyItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItem"
import BlockBodyItemTitle from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemTitle"
import BlockBodyContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyContainer"
import BlockBodyItemInput from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput"
import BlockEditContainer from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer"

import { getNextChatBotRuleDescription } from "@/utils/chatBot"

const RestartFlowBlock: React.FC<EditableBlockDefaultProps> = (props) => {
	const {
		nextChatBotFlowBlockRules,
		flowBlock,
		deleteChatBotFlowBlock
	} = props

	return (
		<BlockEditContainer
			name={flowBlock.title}
			onDelete={deleteChatBotFlowBlock}
		>
			<BlockBodyContainer>
				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Ação"
					/>

					<BlockBodyItemInput
						text="Voltar para o início do fluxo"
						type="action"
					/>
				</BlockBodyItem>

				<BlockBodyItem>
					<BlockBodyItemTitle
						title="Condição de saída"
					/>

					{nextChatBotFlowBlockRules
						.map((nextChatBotFlowBlockRule) => (
							<Typography
								key={nextChatBotFlowBlockRule.id}
								variant="overline"
								color="textPrimary"
							>
								{getNextChatBotRuleDescription(nextChatBotFlowBlockRule)}
							</Typography>
						))
					}
				</BlockBodyItem>
			</BlockBodyContainer>
		</BlockEditContainer>
	)
}

export default RestartFlowBlock
