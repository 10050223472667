import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	avatar: {
		marginLeft: `-${theme.spacing(3.5)}px`,
		width: theme.spacing(5),
		height: theme.spacing(5)
	},
	descriptionContainer: {
		flex: 1
	},
	contentContainer: {
		width: 300
	},
	showContactsButton: {
		textTransform: "none",
		fontWeight: "normal",
		fontSize: 14,
		"&:hover": {
			textDecoration: "none"
		}
	}
}))

export default useStyles
