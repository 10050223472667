export const verifyIfIsOldSyntax = (message: string) => {
	const oldSyntaxRegex = RegExp("<strong>.*</strong>|<a>.*</a>|<em>.*</em>|<del>.*</del>")
	const linkRegex = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/
	return oldSyntaxRegex.test(message) || linkRegex.test(message)
}

export const oldTranslatorDefinitions = [
	{
		styleType: "bold",
		tagHTML: "strong",
		whatsappMarkdown: "*",
		extraData: {
			htmlRegex: /<\/?strong>/g
		}
	},
	{
		styleType: "italic",
		whatsappMarkdown: "_",
		tagHTML: "em",
		extraData: {
			htmlRegex: /<\/?em>/g
		}
	},
	{
		styleType: "strikethrough",
		whatsappMarkdown: "~",
		tagHTML: "del",
		extraData: {
			htmlRegex: /<\/?del>/g
		}
	},
	{
		styleType: "monospace",
		whatsappMarkdown: "```",
		tagHTML: "span",
		extraData: {
			htmlStyle: "font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,Courier,monospace;font-size: 13px",
			htmlRegex: /<\/?span "font-family: Consolas,Menlo,Monaco,Lucida Console,Liberation Mono,DejaVu Sans Mono,Bitstream Vera Sans Mono,Courier New,Courier,monospace;font-size: 13px">/g
		}
	},
	{
		styleType: "line_break",
		whatsappMarkdown: "/n",
		tagHTML: "br",
		extraData: {
			htmlRegex: /<\/?br>/g
		}
	},
	{
		styleType: "paragraph",
		whatsappMarkdown: "",
		tagHTML: "p",
		extraData: {
			htmlRegex: /<\/?p>/g
		}
	}
]

export const oldSyntaxTranslator = (message: string): string => {
	let mutableMessage = message
	oldTranslatorDefinitions
		.forEach(({ whatsappMarkdown, extraData }) => {
			if (extraData?.htmlRegex) {
				mutableMessage = mutableMessage
					.replace(extraData?.htmlRegex, whatsappMarkdown)
			}
		})

	while (true) {
		const linkRegex = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/
		const linkExec = linkRegex.exec(mutableMessage)

		if (linkExec) {
			const foundAnchor = linkExec[0]
			const foundValue = linkExec[2]

			mutableMessage = mutableMessage.replace(foundAnchor, foundValue)
		} else {
			break
		}
	}

	return mutableMessage
}
