import React from "react"
import { BlockTriggerItemProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowTriggerType } from "@/protocols/chatBot"
import WebhookTriggerItem from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/WebhookTriggerItem"
import { PopConfirm } from "@/components"
import AttendanceTriggerItem
	from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/AttendanceTriggerItem"

type ComponentTriggerType = ChatBotFlowTriggerType

const BlockTriggerItem = (props: BlockTriggerItemProps) => {
	const {
		trigger,
		changeChatBotFlowTrigger
	} = props

	const deleteChatBotFlowTrigger = () => {
		PopConfirm.open({
			title: "REMOVER GATILHO?",
			description: "Tem certeza? Essa ação é irreversível.",
			onConfirm: () => changeChatBotFlowTrigger("DELETE", trigger),
			confirmButtonText: "EXCLUIR"
		})
	}

	const handleRenderComponent = () => {
		const componentMap: Record<ComponentTriggerType, React.ReactElement> = {
			"attendance-started-by-client": (
				<AttendanceTriggerItem
					{...props}
					onDelete={deleteChatBotFlowTrigger}
					onChange={() => changeChatBotFlowTrigger("UPDATE", trigger)}
				/>
			),
			"webhook-triggered": (
				<WebhookTriggerItem
					{...props}
					onDelete={deleteChatBotFlowTrigger}
					onChange={() => changeChatBotFlowTrigger("UPDATE", trigger)}
				/>
			)
		}

		return componentMap[trigger.type as ComponentTriggerType]
	}

	return handleRenderComponent()
}

export default BlockTriggerItem
