import React, { useState } from "react"
import { Grid, ButtonBase } from "@material-ui/core"
import clsx from "clsx"

import { ActionDialog, Notification } from "@/components"

import { integrationInfo } from "@/utils/integration"

import { IntegrationPlatform } from "@/protocols/integration"

import useStyles from "@/pages/Admin/Integration/NewIntegrationDialog/styles"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

export type NewIntegrationDialogProps = {
	integrationPlatforms: IntegrationPlatform[]
	onCreate: (integrationId: number) => void
	onClose: () => void
	opened: boolean
}

const NewIntegrationDialog: React.FC<NewIntegrationDialogProps> = (props) => {
	const {
		integrationPlatforms,
		onCreate,
		onClose,
		opened
	} = props

	const [selectedIntegrationPlatform, setSelectedIntegrationPlatform] = useState<IntegrationPlatform>(integrationPlatforms[0])
	const [loadingCreation, setLoadingCreation] = useState(false)

	const classes = useStyles()

	const handleCreateIntegration = async () => {
		setLoadingCreation(true)

		const payload = {
			integrationPlatformId: selectedIntegrationPlatform.id,
			title: selectedIntegrationPlatform.title,
			isActive: true
		}

		try {
			const response = await ApiService.post("/integrations", payload)
			const integrationId = response.data.integration.id
			onCreate(integrationId)

			Notification.success({ message: "Integração criada com sucesso!" })
		} catch (err) {
			ErrorHandler.handle(err)
			Notification.error({ message: "Não foi possível criar a integração" })
		}

		setLoadingCreation(false)
	}

	const handleSelectIntegrationPlatform = (integrationPlatformId: number) => {
		const integrationPlatform = integrationPlatforms.find(({ id }) => id === integrationPlatformId)

		if (integrationPlatform) {
			setSelectedIntegrationPlatform(integrationPlatform)
		}
	}

	return (
		<ActionDialog
			title="QUAL PLATAFORMA DESEJA INTEGRAR?"
			onClose={onClose}
			openDialog={opened}
			fullWidth
			saveText="CRIAR INTEGRAÇÃO"
			onSave={handleCreateIntegration}
			loading={loadingCreation}
		>
			<Grid container justifyContent="flex-start" alignItems="center" alignContent="center" spacing={2}>
				{integrationPlatforms.map(integrationPlatform => {
					return <Grid
						container
						key={integrationPlatform.id}
						xs={4}
						alignItems="center"
						justify="center"
						alignContent="center"
					>
						<Grid
							component={ButtonBase}
							onClick={() => handleSelectIntegrationPlatform(integrationPlatform.id)}
							item
							className={clsx({
								[classes.integrationPlatformContainer]: true,
								[classes.selectedIntegrationPlatform]: integrationPlatform.id === selectedIntegrationPlatform.id,
								[classes.nonSelectedIntegrationPlatform]: integrationPlatform.id !== selectedIntegrationPlatform.id
							})}
							xs={11}
						>
							<img
								src={integrationInfo[integrationPlatform.type]?.logo}
								alt={integrationPlatform.title}
								className={classes.integrationPlatformImg}
							/>
						</Grid>
					</Grid>
				})}
			</Grid>
		</ActionDialog>
	)
}

export default NewIntegrationDialog
