
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import { MINUTE_IN_MS } from "@/utils/time"
import { Divider, Portlet, Loading } from "@/components"
import { Grid, Typography, IconButton, Select, MenuItem } from "@material-ui/core"
import React, { useState, useEffect, useRef } from "react"
import useStyles from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/styles"
import { Refresh as RefreshIcon } from "@material-ui/icons"
import DailyAttendancesChart from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/DailyAttendancesChart"
import CumulativeAttendancesChart from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics/CumulativeAttendancesChart"
import useDidMount from "@/hooks/useDidMount"
import AttendancesMetricsSkeleton from "@/skeletons/Admin/AttendancesMetricsSkeleton"
import { AttendancesCount } from "@/protocols/metrics"
import useCustomStyles from "@/styles/custom"

type AttendancesMetricsData = {
	attendancesCount: AttendancesCount
	avgWaitTimeInMs: number
	avgAttendanceTimeInMs: number
	attendancesHistogram: Array<{
		day: Date
		attendancesCount: AttendancesCount
	}>
}

type Attendant = {
	id: number
	name: string
	email: string
	role_code: "admin" | "attendant"
}

const AttendancesMetrics: React.FC = () => {
	const [metrics, setMetrics] = useState<AttendancesMetricsData>()
	const [nLastDays, setNLastDays] = useState(7)
	const [attendants, setAttendants] = useState<Attendant[]>([])
	const [attendantId, setAttendantId] = useState(0)
	const [loading, setLoading] = useState(true)
	const classes = useStyles()
	const customClasses = useCustomStyles()

	// eslint-disable-next-line
	const getAndUpdateMetrics = useRef(() => { })
	getAndUpdateMetrics.current = async () => {
		setLoading(true)

		try {
			const { data } = await ApiService.get("/metrics/attendances", {
				params: {
					nLastDays,
					attendantId: attendantId === 0 ? undefined : attendantId
				}
			})
			setMetrics(data)
		} catch (err) {
			ErrorHandler.handle(err)
		}

		setLoading(false)
	}

	const handleChangeNLastDays = (value: number) => {
		setNLastDays(value)
	}

	const handleChangeAttendantId = (value: number) => {
		setAttendantId(value)
	}

	const getAttendants = async () => {
		const { data } = await ApiService.get("/user-in-instances/users", {
			params: {
				nLastDays
			}
		})

		setAttendants(data)
	}

	useEffect(() => {
		getAndUpdateMetrics.current()
	}, [nLastDays, attendantId, getAndUpdateMetrics])

	useDidMount(() => {
		getAttendants()
	})

	return (
		<>
			<Grid container justify="space-between" alignItems="center">
				<Grid item>
					<Typography
						variant="h2"
						color="textPrimary"
						className={customClasses.uppercase}
					>
						Atendimentos
					</Typography>
				</Grid>

				<Grid item xs>
					<Grid container spacing={1} justify="flex-end" alignItems="center">
						<Grid item>
							<IconButton onClick={getAndUpdateMetrics.current} disabled={loading}>
								<RefreshIcon />
							</IconButton>
						</Grid>

						<Grid item >
							<Select
								fullWidth
								variant="outlined"
								value={attendantId}
								onChange={({ target }) => handleChangeAttendantId(target.value as number)}
								disabled={loading}
							>
								<MenuItem value={0}>Todos atendentes</MenuItem>

								{attendants.map((attendant) => (
									<MenuItem key={attendant.id} value={attendant.id}>
										{attendant.name}
									</MenuItem>
								))}
							</Select>
						</Grid>

						<Grid item >
							<Select
								fullWidth
								variant="outlined"
								value={nLastDays}
								onChange={({ target }) => handleChangeNLastDays(target.value as number)}
								disabled={loading}
							>
								<MenuItem value={0}>Hoje</MenuItem>
								<MenuItem value={7}>Últimos 7 dias</MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size={2} />

			<Loading loading={loading} customLoadingElement={<AttendancesMetricsSkeleton />}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6}>
						<DailyAttendancesChart attendancesHistogram={metrics?.attendancesHistogram} />
					</Grid>

					<Grid item xs={12} md={6}>
						<CumulativeAttendancesChart attendancesCount={metrics?.attendancesCount} />
					</Grid>

					<Grid item xs={12} md={6}>
						<Portlet style={{ height: "100%" }}>
							<Grid container spacing={2} className={classes.indicatorContainer} justify="center">
								<Grid item xs={12}>
									<Typography variant="h2" className={customClasses.uppercase}>
										Tempo médio por atendimento
									</Typography>
								</Grid>

								<Grid item >
									<Grid container alignItems="center" direction="column" spacing={1}>
										<Grid item>
											<Typography align="center" className={classes.largeText}>
												{Math.round((metrics?.avgAttendanceTimeInMs || 0) / MINUTE_IN_MS)}
											</Typography>
										</Grid>

										<Grid item>
											<Typography
												align="center"
												color="textPrimary"
												variant="caption"
												className={customClasses.uppercase}
											>
												minutos
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>

					<Grid item xs={12} md={6}>
						<Portlet style={{ height: "100%" }}>
							<Grid container spacing={2} className={classes.indicatorContainer} justify="center">
								<Grid item xs={12}>
									<Typography variant="h2" className={customClasses.uppercase}>
										Tempo médio na fila de espera
									</Typography>
								</Grid>

								<Grid item >
									<Grid container alignItems="center" direction="column" spacing={1}>
										<Grid item>
											<Typography align="center" className={classes.largeText}>
												{Math.round((metrics?.avgWaitTimeInMs || 0) / MINUTE_IN_MS)}
											</Typography>
										</Grid>

										<Grid item>
											<Typography
												align="center"
												color="textPrimary"
												variant="caption"
												className={customClasses.uppercase}
											>
												minutos
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				</Grid>
			</Loading>
		</>
	)
}

export default AttendancesMetrics
