import React from "react"
import {
	Chip,
	Grid,
	IconButton,
	Typography,
	useMediaQuery,
	useTheme
} from "@material-ui/core"
import {
	Delete as DeleteIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	TextCopy
} from "@/components"

import useStyles from "@/pages/Admin/Attendant/styles"

type InvitePendingCardProps = {
	name: string
	email: string
	invite_link: string

	handleDelete: () => void
}

const InvitePendingCard: React.FC<InvitePendingCardProps> = (props) => {
	const { name, email, invite_link, handleDelete } = props

	const classes = useStyles()
	const MuiTheme = useTheme()

	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between">
				<Grid item xs={12} sm={3}>
					<Grid container justify="space-between" alignItems="center">
						<Grid
							item xs={6} sm={12}
							className={classes.textContainer}>
							<Typography
								color="textPrimary"
								variant="h2"
								className={classes.nameText}
							>
								{name}
							</Typography>

							<Typography
								color="textPrimary"
								variant="caption"
							>
								{email}
							</Typography>
						</Grid>

						{
							!isSmall &&
								<Divider size={2} orientation='horizontal'/>
						}

						<Grid item xs={6} sm={12}>
							<Grid
								container
								justify={isSmall ? "flex-end" : "flex-start"}
							>
								<Chip label="Pendente" className={classes.chipPending}/>
							</Grid>
						</Grid>
					</Grid>

				</Grid>

				{
					isSmall &&
								<Divider size={2} orientation='horizontal'/>
				}

				<Grid item xs={12} sm={9}>
					<Grid container justify="space-between" alignItems="center" style={{ height: "100%" }}>
						<Grid item xs={12} sm={10} md={11}>
							<Grid
								container
								alignContent="center"
								className={classes.copyContainer}
							>
								<TextCopy
									text={invite_link}
									onSuccessMessage="Link copiado com sucesso!"
								/>
							</Grid>
						</Grid>

						{
							isSmall &&
										<Divider size={1} orientation='horizontal'/>
						}

						<Grid item xs={12} sm={2} md={1}>
							<Grid container justify="flex-end">
								<IconButton onClick={handleDelete}>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>

	)
}

export default InvitePendingCard
