import React from "react"
import { Grid, Typography } from "@material-ui/core"

import BlockPortlet from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockPortlet"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockEditContainer/styles"

type BlockEditContainerProps = {
	name: string
	onDelete: () => void
}

const BlockEditContainer: React.FC<BlockEditContainerProps> = (props) => {
	const {
		name,
		children,
		onDelete
	} = props

	const classes = useStyles()

	return (
		<BlockPortlet
			onDelete={onDelete}
			headerClassName={classes.header}
			headerChildren={(
				<Grid
					container
					alignItems="center"
					justify="flex-start"
				>
					<Typography
						variant="overline"
						color="textPrimary"
						className={classes.headerTitle}
					>
						BLOCO: {name}
					</Typography>
				</Grid>
			)}
		>
			{children}
		</BlockPortlet>
	)
}

export default BlockEditContainer
