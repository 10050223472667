import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	triggerBlock: {
		paddingLeft: theme.spacing(1.5)
	},
	triggerIconContainer: {
		maxWidth: theme.spacing(8),
		height: theme.spacing(8),
		background: colors.grayScale[11],
		border: "1px solid rgba(224, 230, 237, 0.35)",
		borderRadius: theme.spacing(1),
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	triggerTitle: {
		fontSize: "12px",
		fontStyle: "normal",
		fontFamily: "IBM Plex Sans",
		fontWeight: 500,
		lineHeight: "24px",
		color: "rgba(73, 80, 87, 0.5)"
	},
	triggerDescriptionContainer: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3.5)
	},
	triggerDescription: {
		fontSize: "16px",
		fontStyle: "normal",
		fontFamily: "IBM Plex Sans",
		fontWeight: 600,
		lineHeight: "21px",
		color: colors.grayScale[4]
	},
	webhookTriggerIcon: {
		height: "100%"
	},
	webhookTriggerIconRoot: {
		textAlign: "center",
		fontSize: "1.9em"
	}
}))

export default useStyles
