import React, { createContext, useContext, useRef } from "react"

import useDidMount from "@/hooks/useDidMount"

export type OnMobileCloseFunction = () => Promise<void> | void

type DrawerInstance = {
	id: string
	onMobileClose: OnMobileCloseFunction
	hashBeforeOpening: string
}

export interface AccessibleDrawerStateContextData {
	onMobileOpen: (drawerId: string, onMobileClose: OnMobileCloseFunction) => void
	onMobileClose: (drawerId: string, triggerHistoryBackEvent: boolean) => void
}

const AccessibleDrawerStateStore = createContext<AccessibleDrawerStateContextData>({} as AccessibleDrawerStateContextData)

export const useAccessibleDrawerStateStore = () => useContext(AccessibleDrawerStateStore)

const AccessibleDrawerStateProvider: React.FC = (props) => {
	const { children } = props

	const drawerInstancesRef = useRef<DrawerInstance[]>([])

	const onMobileClose = (drawerId: string, triggerHistoryBackEvent: boolean) => {
		const drawerInstance = drawerInstancesRef.current.find(({ id }) => id === drawerId)

		if (drawerInstance) {
			drawerInstancesRef.current = drawerInstancesRef.current.filter(({ id }) => id !== drawerId)

			drawerInstance?.onMobileClose?.()

			if (triggerHistoryBackEvent) {
				window.history.back()
			}
		}
	}

	const onMobileOpen = (drawerId: string, onMobileClose: OnMobileCloseFunction) => {
		const drawerInstanceExists = drawerInstancesRef.current.find(({ id }) => id === drawerId)

		if (!drawerInstanceExists) {
			const hashBeforeOpening = window.location.hash

			// This is a workaround to change the path after other calls that also change the path
			// This was necessary because when we were opening the chat drawer, the path was changed to the correct hash and immediately changed again to blank
			setTimeout(() => {
				window.location.hash = drawerId
			}, 0)

			drawerInstancesRef.current.push({
				id: drawerId,
				onMobileClose,
				hashBeforeOpening
			})
		}
	}

	const onMobileBackButtonPress = () => {
		window.addEventListener("popstate", function onPopStateChange () {
			const newHash = window.location.hash

			const drawerInstance = drawerInstancesRef.current.find(
				({ hashBeforeOpening }) => hashBeforeOpening === newHash
			)

			if (drawerInstance) {
				onMobileClose(drawerInstance.id, false)
			}
		})
	}

	useDidMount(() => {
		onMobileBackButtonPress()
	})

	AccessibleDrawerStateStore.displayName = "AccessibleDrawerStateStore"

	return (
		<AccessibleDrawerStateStore.Provider
			value={{
				onMobileOpen,
				onMobileClose
			}}
		>
			{children}
		</AccessibleDrawerStateStore.Provider>
	)
}

export default AccessibleDrawerStateProvider
