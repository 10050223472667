import React, { useRef } from "react"
import {
	Grid,
	IconButton,
	Input,
	Tooltip
} from "@material-ui/core"

import {
	Search as SearchIcon,
	ArrowBack as CloseChatIcon,
	Close as ClearSearchIcon
} from "@material-ui/icons"

import {
	Loading,
	Divider,
	ChatDivider
} from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/styles"
import useMobileSwipeGesture, { SwipeDirection } from "@/hooks/useMobileSwipeGesture"
import useCustomMemo from "@/hooks/useCustomMemo"
import useDebounce from "@/hooks/useDebounce"

import ChatListSkeleton from "@/skeletons/ChatList"
import ChatListFilterSkeleton from "@/skeletons/ChatListFilter"

import NotificationBar from "@/pages/Attendance/Chat/ChatListPanel/NotificationBar"
import ChatListHeader from "@/pages/Attendance/Chat/ChatListPanel/ChatListHeader"
import ChatListTab, { TABS } from "@/pages/Attendance/Chat/ChatListPanel/ChatListTab"
import ChatList from "@/pages/Attendance/Chat/ChatListPanel/ChatList"
import ChatListFilter from "@/pages/Attendance/Chat/ChatListPanel/ChatListFilter"

import HardCodedService from "@/services/HardCoded"

const ChatListPanel: React.FC = () => {
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()

	const chatListRef = useRef<HTMLElement>()
	const filteredChats = useCustomMemo(() => {
		return chatGlobalStateStore.chat.list
			.filter(chat => {
				const filters = []

				const hasTextFilter = Boolean(chatGlobalStateStore.chatListPanel.chatListFilter.current.text)

				/**
				 * We only filter chats by attendance type in case
				 * there is no current search being made, since we
				 * consider all chats during a search.
				 */
				const enableChatStatusFilter = !hasTextFilter

				if (enableChatStatusFilter) {
					const chatStatusFilter = chat.status === chatGlobalStateStore.chatListPanel.chatStatusFilter.current

					filters.push(chatStatusFilter)
				}

				const comparison = filters.every(filter => filter)

				return comparison
			})
	}, [
		chatGlobalStateStore.chatListPanel.chatStatusFilter.current,
		chatGlobalStateStore.chat.listHash,
		chatGlobalStateStore.chat.current?.status,
		chatGlobalStateStore.chatListPanel.chatListFilter.current.text
	])

	const handleChangeCurrentChatId = (chatId: number) => {
		chatGlobalStateStore.chat.openById(chatId)
	}

	const handleChatListSwipe = (direction: SwipeDirection) => {
		const currentAttendanceIndex = TABS.findIndex(({ availableChatStatus }) => availableChatStatus.includes(chatGlobalStateStore.chatListPanel.chatStatusFilter.current))

		const isValidAttendanceIndex = currentAttendanceIndex !== -1

		if (!isValidAttendanceIndex) {
			return
		}

		let nextAttendanceIndex = currentAttendanceIndex

		if (direction === "right") {
			nextAttendanceIndex--
		} else {
			nextAttendanceIndex++
		}

		const nextAttendanceItem = TABS[nextAttendanceIndex]

		if (nextAttendanceItem) {
			chatGlobalStateStore.chatListPanel.chatStatusFilter.change(nextAttendanceItem.defaultChatStatus)
		}
	}

	const handleChangeSearch = (textTyped: string) => {
		chatGlobalStateStore.chatListPanel.chatListFilter.update({ text: textTyped })
	}

	const getSearchIcon = () => {
		if (chatGlobalStateStore.chatListPanel.chatListFilter.current.text) {
			return (
				<IconButton
					size="small"
					onClick={() => chatGlobalStateStore.chatListPanel.chatListFilter.update({ text: "" })}
					className={classes.closeChat}
				>
					<CloseChatIcon
						fontSize="small"
					/>
				</IconButton>
			)
		}
		return (
			<SearchIcon/>
		)
	}

	useMobileSwipeGesture({
		onSwipe: handleChatListSwipe,
		ref: chatListRef
	})

	useDebounce(
		async () => {
			if (!chatGlobalStateStore.chat.loadingFromServer) {
				await chatGlobalStateStore.chat.loadAllFromServer()
			}
		},
		chatGlobalStateStore.chatListPanel.chatListFilter.current.text,
		2250
	)

	return (
		<Grid
			container
			direction="column"
			className={classes.chatLeftSideContainer}
		>
			<Grid item>
				<Grid
					container
					className={classes.chatHeader}
				>
					<Grid item xs={12}>
						<ChatListHeader />
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Grid
					container
					alignItems="center"
					justify="space-between"
					className={classes.searchInputContainer}
				>
					<Grid item xs>
						<Tooltip title={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch") ? "Pesquisa desabillitada temporariamente, identificamos que sua conta possui muitos contatos e isso acabou gerando algumas instabilidades. Em breve essa solução será reabilitada e com maior estabilidade. Pedimos desculpas pelo transtorno e agradecemos a compreensão!" : ""}>
							<Input
								fullWidth
								startAdornment={getSearchIcon()}
								endAdornment={
									chatGlobalStateStore.chatListPanel.chatListFilter.current.text &&
									<IconButton
										size="small"
										onClick={() => chatGlobalStateStore.chatListPanel.chatListFilter.update({ text: "" })}
										className={classes.closeChat}
									>
										<ClearSearchIcon
											fontSize="small"
										/>
									</IconButton>
								}
								className={classes.input}
								value={chatGlobalStateStore.chatListPanel.chatListFilter.current.text}
								onChange={(({ target }) => handleChangeSearch(target.value))}
								disabled={HardCodedService.checkFeatureFlagByInstanceId("blockedSearch")}
							/>
						</Tooltip>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				className={classes.fullWidth}
			>
				<NotificationBar />
			</Grid>

			{
				chatGlobalStateStore.chatListPanel.chatListFilter.current.text ? (
					<>
						<Grid
							item
							className={classes.fullWidth}
						>
							<ChatDivider
								chatCount={filteredChats.length}
								summary="Resultado da busca"
							/>
						</Grid>
						<Loading
							loading={chatGlobalStateStore.chat.loadingFromServer}
							customLoadingElement={<ChatListSkeleton />}
						>
							<Grid
								container
								justify="center"
								className={classes.fixedHeightContainer}
								innerRef={chatListRef}
							>
								<ChatList
									chats={filteredChats}
									onClick={chatId => handleChangeCurrentChatId(chatId)}
								/>
							</Grid>
						</Loading>
					</>
				) : (
					<>
						<Grid
							item
							className={classes.fullWidth}
						>
							<ChatListTab />
						</Grid>

						<Loading
							loading={chatGlobalStateStore.attendant.loadingFromServer || chatGlobalStateStore.tag.loadingFromServer}
							customLoadingElement={<ChatListFilterSkeleton />}
						>
							<Grid
								container
								direction="column"
							>
								<ChatListFilter />

								<Divider orientation="horizontal" size={0.5}/>
							</Grid>
						</Loading>

						<Loading
							loading={chatGlobalStateStore.chat.loadingFromServer}
							customLoadingElement={<ChatListSkeleton />}
						>
							<Grid
								container
								justify="center"
								className={classes.fixedHeightContainer}
								innerRef={chatListRef}
							>
								<ChatList
									chats={filteredChats}
									onClick={chatId => handleChangeCurrentChatId(chatId)}
								/>
							</Grid>
						</Loading>
					</>
				)
			}
		</Grid>
	)
}

export default ChatListPanel
