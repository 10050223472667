// Remove all non-digit character
export const keepOnlyDigits = (value: string) => {
	return value?.replace(/\D/g, "")
}

export const secondPhoneContainsFirst = (phone1: string, phone2: string) => {
	const rawPhone1 = keepOnlyDigits(phone1)
	const rawPhone2 = keepOnlyDigits(phone2)

	return rawPhone2?.includes(rawPhone1)
}
