import _ from "lodash"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { useGlobalStateStore } from "@/store/GlobalState"
import { UserSettingsKeyTypes, PossibleUserSettings } from "@/protocols/settings"

const useSettings = () => {
	const globalStateStore = useGlobalStateStore()
	const activeChannelId = Number(globalStateStore?.whatsappChannel?.id)

	const userSettings = {
		retrieveActualUserSettings: async function (settingName: UserSettingsKeyTypes) {
			try {
				const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)

				const { data } = await ApiService.get(`/inbox-channel/user/settings/${settingName}`, {
					params: {
						inbox_channel_id: globalStateStore.channels[activeChannelIndex]?.id
					}
				})

				const settings = data?.value
				const { channels } = globalStateStore

				const newChannel = channels.map((channel) => {
					if (channel.id === activeChannelId) {
						channel.settings = { ...channel.settings }
						channel.settings[settingName] = settings
					}

					return channel
				})

				globalStateStore.setChannelsData(newChannel || [])

				return newChannel?.[activeChannelIndex]?.settings?.[settingName]
			} catch (error) {
				ErrorHandlerService.handle(error)
				return null
			}
		},
		updateActualUserSettings: async function (settingName: UserSettingsKeyTypes, newSetting: PossibleUserSettings<UserSettingsKeyTypes>) {
			try {
				const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)
				const { channels } = globalStateStore

				const newChannel = channels.map((channel) => {
					if (channel.id === activeChannelId) {
						channel.settings = { ...channel.settings }
						_.merge(channel.settings[settingName], newSetting)
					}

					return channel
				})

				const activeChannelSettingId = newChannel?.[activeChannelIndex]?.settings?.[settingName]?.id

				const { data: updatedData } = await ApiService.put(`/inbox-channel/user/settings/${activeChannelSettingId}`, {
					inbox_channel_id: globalStateStore.channels[activeChannelIndex]?.id,
					value: newChannel?.[activeChannelIndex]?.settings?.[settingName],
					key: settingName
				})

				const newChannelWithUpdatedData = channels.map((channel) => {
					if (channel.id === activeChannelId) {
						channel.settings = { ...channel.settings }
						_.merge(channel.settings[settingName], updatedData.value)
					}

					return channel
				})

				globalStateStore.setChannelsData(newChannelWithUpdatedData || [])

				return updatedData
			} catch (error) {
				ErrorHandlerService.handle(error)
				return null
			}
		},
		getUserSettings: function () {
			const activeChannelIndex = globalStateStore.channels.map(channel => channel.id).indexOf(activeChannelId)

			return globalStateStore?.channels?.[activeChannelIndex]?.settings
		}
	}

	return {
		userSettings
	}
}

export default useSettings
