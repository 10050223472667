import React from "react"
import {
	WifiOff as DisconnectIcon,
	Wifi as ConnectedIcon,
	Cached as ReconnectingIcon
} from "@material-ui/icons"

import { ChannelStatus } from "@/protocols/channel"

export type ComputedStatus = "connected" | "reconnecting" | "disconnected"

export const getComputedStatus = (status: ChannelStatus): ComputedStatus => {
	const connectedStatuses: ChannelStatus[] = ["connected"]
	const isConnected = connectedStatuses.includes(status)

	const reconnectingStatuses: ChannelStatus[] = ["offline", "connecting", "unpaired", "conflicted"]
	const isReconnecting = reconnectingStatuses.includes(status)

	const disconnectedStatuses: ChannelStatus[] = ["no-session"]
	const isDisconnected = disconnectedStatuses.includes(status)

	if (isConnected) {
		return "connected"
	} else if (isReconnecting) {
		return "reconnecting"
	} else if (isDisconnected) {
		return "disconnected"
	} else {
		return "" as ComputedStatus
	}
}

export const getStatusColor = (status: ChannelStatus) => {
	const statusColorMap: Record<ComputedStatus, string> = {
		connected: "#0DD682",
		disconnected: "#D60D22",
		reconnecting: "#FE9E59"
	}

	const computedStatus = getComputedStatus(status)

	return statusColorMap[computedStatus]
}

export const getStatusIcon = (status: ChannelStatus) => {
	const statusIconMap: Record<ComputedStatus, React.ReactElement> = {
		connected: <ConnectedIcon />,
		disconnected: <DisconnectIcon />,
		reconnecting: <ReconnectingIcon />
	}

	const computedStatus = getComputedStatus(status)

	return statusIconMap[computedStatus]
}

export const getStatusMessage = (status: ChannelStatus) => {
	const statusMessageMap: Record<ComputedStatus, string> = {
		connected: "",
		disconnected: "O seu Whatsapp está desconectado, isso significa que você não está com uma sessão ativa, ou seja, é necessário gerar e ler o QR Code novamente para estabelecer uma conexão.",
		reconnecting: "Por algum motivo não estamos conseguindo estabelecer uma conexão com o seu Whatsapp, tentamos reconectar automaticamente, mas o melhor a se fazer é encerrar a sessão e ler o QR Code novamente."
	}

	const computedStatus = getComputedStatus(status)

	return statusMessageMap[computedStatus]
}
