import { MutableRefObject, RefCallback, useEffect } from "react"
import { useSwipeable } from "react-swipeable"

export type SwipeDirection = "left" | "right"

type DefaultRefElement = HTMLElement | undefined

type SwipeRef<RefElement> = MutableRefObject<RefElement>

type UseMobileSwipeGestureProps<RefElement> = {
	onSwipe: (direction: SwipeDirection) => void
	ref: SwipeRef<RefElement>
}

function useMobileSwipeGesture<RefElement extends DefaultRefElement> (
	props: UseMobileSwipeGestureProps<RefElement>
) {
	const { onSwipe, ref } = props

	const handleSwipe = (direction: SwipeDirection) => {
		onSwipe(direction)
	}

	const handler = useSwipeable({
		onSwipedRight: () => handleSwipe("right"),
		onSwipedLeft: () => handleSwipe("left"),
		trackMouse: false
	}) as { ref: RefCallback<RefElement> }

	useEffect(() => {
		if (ref.current) {
			handler.ref(ref.current)
		}
	// eslint-disable-next-line
	}, [ref.current])
}

export default useMobileSwipeGesture
