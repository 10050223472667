import { ActionDialog, Divider, Loading } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import useCustomStyles from "@/styles/custom"
import { Button, Grid, InputLabel, TextField, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "@/pages/Admin/ChatBot/ChatBotList/styles"
import { useHistory } from "react-router-dom"
import ChatBotListItem from "@/pages/Admin/ChatBot/ChatBotList/ChatBotListItem"
import ChatBotListSkeleton from "@/skeletons/Admin/ChatBotListSkeleton"
import {
	Add as AddIcon
} from "@material-ui/icons"
import { ChatBotCategory, ChatBotFlow } from "@/protocols/chatBot"
import { WebhookTemplateType } from "@/protocols/webhook"

type WebhookTemplate = {
	id: number
	type: WebhookTemplateType
}

type Webhooks = {
	id: number
	display_title: string
	webhook_template?: WebhookTemplate | null
}

type ShortChatBotFlow = {
	id: number
	name: string
	active: boolean
	updated_at: Date
}

type WebhooksAndChatBotFlow = {
	id: number
	webhook: Webhooks
	chat_bot_flow: ShortChatBotFlow
}

type TriggerWithChatBotFlows = {
	type: ChatBotCategory
	description: string
	chat_bot_flow_in_chat_bot_triggers: Array<WebhooksAndChatBotFlow>
	id?: number
}

type CategorizedChatBotFlows = {
	[key in ChatBotCategory]: TriggerWithChatBotFlows
}

type NewChatBotDialogType = {
	open: boolean
	loading: boolean
}

const chatBotTriggerTranslatedByType = {
	"attendance-started-by-client": "O atendimento é iniciado pelo cliente",
	"webhook-triggered": "Um Webhook é recebido",
	"no-triggers": "Os gatilhos ainda não foram configurados"
}

const ChatBotList = () => {
	const [chatBotList, setChatBotList] = useState<CategorizedChatBotFlows>()
	const [loading, setLoading] = useState<boolean>(true)
	const [newChatBotDialog, setNewChatBotDialog] = useState<NewChatBotDialogType>({
		open: false,
		loading: false
	})
	const [chatBotData, setChatBotData] = useState<Pick<ChatBotFlow, "name">>({} as ChatBotFlow)

	const customClasses = useCustomStyles()
	const classes = useStyles()
	const history = useHistory()

	const { clearValidation, triggerValidation, validation } = useValidation()

	const handleOpenNewChatBotDialog = () => {
		setNewChatBotDialog({
			...newChatBotDialog,
			open: true
		})
	}

	const handleNewChatBot = async (data: Pick<ChatBotFlow, "name">) => {
		setNewChatBotDialog({
			...newChatBotDialog,
			loading: true
		})
		try {
			const response = await ApiService.post("/chat-bot/flow", {
				name: data.name
			})
			history.push(`/admin/bots/${response.data.id}`)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setNewChatBotDialog({
			...newChatBotDialog,
			loading: false
		})
	}

	const handleGetChatBotList = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/chat-bot/flows")
			setChatBotList(response.data as CategorizedChatBotFlows)
		} catch (error) {
			ErrorHandlerService.handle(error as ErrorType)
		}
		setLoading(false)
	}
	useDidMount(() => {
		handleGetChatBotList()
	})

	return 	<Loading loading={loading} customLoadingElement={<ChatBotListSkeleton/>}>
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography
					variant="h6"
					color="textPrimary"
					className={classes.title}
				>
                    Bem vindo ao Chatbot
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography
					variant="h1"
					color="textPrimary"
					className={classes.description}
				>
                    Construa fluxos e automações de comunicação, sem complicações.
				</Typography>
				<Typography
					className={classes.description}
				>
                    E o melhor de tudo: de maneira rápida e intuitiva.
				</Typography>
			</Grid>
			<Grid item>
				<Button
					variant="contained"
					className={customClasses.shortButtonAction}
					onClick={handleOpenNewChatBotDialog}
				>
					<AddIcon fontSize={"small"} style={{ marginRight: "9px" }} /> ADICIONAR CHATBOT
				</Button>
			</Grid>
			{Object.keys(chatBotList || {}).map(keyValue => {
				const key = keyValue as keyof CategorizedChatBotFlows

				if (chatBotList && chatBotList[key].chat_bot_flow_in_chat_bot_triggers.length > 0) {
					return (
						<Grid item xs={12} key={chatBotList[key].id}>
							<Divider orientation="horizontal" size={4} />

							<Grid container spacing={2} direction="column">
								<Grid item xs={12}>
									<Typography
										variant="h4"
										color="textPrimary"
										className={classes.eventTrigger}
									>
										{chatBotList[key].type !== "no-triggers" ? "CHATBOTS ACIONADOS QUANDO:" : "CHATBOTS QUE:"}
									</Typography>
									<Divider orientation="horizontal" size={1.5} />
									<Typography
										variant="h4"
										className={classes.descriptionTrigger}
									>
										{chatBotTriggerTranslatedByType[chatBotList[key].type]}
									</Typography>
								</Grid>
							</Grid>

							<Divider orientation="horizontal" size={5} />

							<Grid item xs={12}>
								<Grid container spacing={2} direction="column">
									{chatBotList[key].chat_bot_flow_in_chat_bot_triggers.map(bot => {
										return (
											<Grid item xs={12} key={bot.chat_bot_flow.id}>
												<Grid container spacing={2} direction="column">

													<Grid item xs>
														<ChatBotListItem
															chatBoFlowtId={bot.chat_bot_flow.id}
															title={bot.chat_bot_flow.name}
															isActive={bot.chat_bot_flow.active}
															category={chatBotList ? chatBotList[key].type : undefined}
															updated_at={bot.chat_bot_flow.updated_at}
															webhookTemplateType={bot?.webhook?.webhook_template?.type}
														/>
													</Grid>

												</Grid>
											</Grid>
										)
									})}
								</Grid>
							</Grid>
						</Grid>
					)
				} else {
					return null
				}
			})}

			<ActionDialog
				title="Novo bot"
				saveText="SALVAR"
				openDialog={newChatBotDialog.open}
				onSave={ () => handleNewChatBot(chatBotData) }
				onClose={() => setNewChatBotDialog({
					open: false,
					loading: false
				})}
				loading={newChatBotDialog.loading}
				fullWidth
			>
				<Grid container>
					<Grid xs={12}>
						<Grid
							item
							xs
						>
							<InputLabel className={customClasses.inputLabel}>
                                Defina um nome para esse bot
							</InputLabel>

							<Divider orientation="horizontal" size={1} />

							<TextField
								name="name"
								variant="outlined"
								placeholder="Ex.: Pré-atendimento"
								helperText={validation.name}
								error={!!validation.name}
								value={chatBotData?.name}
								onChange={(event) => {
									setChatBotData({ ...chatBotData, name: event.target.value })
									clearValidation("name")
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
				</Grid>
			</ActionDialog>
		</Grid>
	</Loading>
}

export default ChatBotList
