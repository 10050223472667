import React, { useState } from "react"
import {
	Grid,
	TextField,
	Chip
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { renderComponent } from "@/utils/node"
import { getCurrentTagsOptions } from "@/utils/chatBot"
import { convertRGBAObjectToString } from "@/utils/color"

import { ActionDialog } from "@/components"

import {
	ChatBotFlowBlockContent
} from "@/protocols/chatBot"
import { ConstructionResources, TagOption } from "@/protocols/chatBotConstructor"

import colors from "@/styles/colors"

type TagsEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
	action: "add" | "remove"
}

type TagsEditorType = {
	open: (props: TagsEditorProps) => void
}

const TagsEditor: TagsEditorType & React.FC<TagsEditorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		chatBotFlowBlockContent,
		action
	} = props

	const [opened, setOpened] = useState(true)

	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)

		handleClose()
	}

	const handleChangeTags = (tags: TagOption[]) => {
		const notEditedActions = chatBotFlowBlockContent?.clientTagsManagementData?.actions?.filter(({ type }) => type !== action) || []

		const updatedActions = tags.map(tag => ({
			type: action,
			tagId: tag.id
		}))

		setBlockContent(lastState => ({
			...lastState,
			clientTagsManagementData: {
				actions: [
					...notEditedActions,
					...updatedActions
				]
			}
		}))
	}

	const currentTagOptions = getCurrentTagsOptions(constructionResources, chatBotFlowBlockContent)

	return (
		<ActionDialog
			title={action === "add" ? "ADICIONAR TAGS" : "REMOVER TAGS"}
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Autocomplete
						multiple
						defaultValue={action === "add" ? currentTagOptions.tagsToAdd : currentTagOptions.tagsToRemove}
						options={constructionResources.tagOptions}
						getOptionSelected={(option, value) => option.id === value.id}
						getOptionLabel={(option) => option.name}
						fullWidth
						filterSelectedOptions
						onChange={(_, tags) => {
							handleChangeTags(tags)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder="Tags"
							/>
						)}
						renderTags={(value, getTagProps) => {
							return value.map((tag, index) => (
								<Chip
									key={index}
									label={tag.name}
									style={{
										color: colors.grayScale[11],
										backgroundColor: convertRGBAObjectToString(tag.color)
									}}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

TagsEditor.open = (props: TagsEditorProps) => {
	renderComponent(
		"tags-editor",
		<TagsEditor
			{...props}
		/>
	)
}

export default TagsEditor
