import React, { useState } from "react"

import {
	LinkOutlined as LinkIcon,
	InsertChartOutlined as WebhookRelatoryIcon,
	MoreVert as MoreOptionsIcon,
	SettingsEthernet as AssociationEditorIcon
} from "@material-ui/icons"

import CustomWebhookIcon from "@/assets/icons/custom_webhook.svg"

import {
	Grid,
	Icon,
	IconButton,
	ListItemText,
	Menu,
	MenuItem, Typography
} from "@material-ui/core"

import { isCustomWebhook, webhookTemplatesInfo } from "@/utils/webhook"

import { TriggerItemProps } from "@/protocols/chatBotConstructor"
import { WebhookType } from "@/protocols/webhook"

import WebhookReport from "@/pages/Admin/Webhooks/WebhookReport"
import WebhookUrlDialog from "@/pages/Admin/Webhooks/WebhookUrlDialog"
import WebhookFieldAssociationEditorDialog from "@/pages/Admin/Webhooks/WebhookFieldAssociationEditorDialog"

import HardCodedService from "@/services/HardCoded"
import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockTriggerItem/styles"
import { Divider } from "@/components"

const WebhookTriggerItem = (props: TriggerItemProps) => {
	const {
		trigger,
		constructionResources,
		onDelete,
		onChange
	} = props

	const classes = useStyles()

	const flowTrigger = constructionResources.triggers.find(({ id }) => id === trigger.id)

	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const innerTrigger = flowTrigger?.innerTriggers?.find(({ webhookId }) => webhookId === trigger.webhook_id)

	const [openWebhookReport, setOpenWebhookReport] = useState(false)

	const webhookTemplateType = innerTrigger?.webhookTemplateType
	const webhookType = innerTrigger?.webhookType
	const webhookUrl = innerTrigger?.webhookUrl
	const webhookId = innerTrigger?.webhookId

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	const handleShowWebhookUrl = () => {
		WebhookUrlDialog.open({
			webhookUrl: webhookUrl || "",
			saveText: "FECHAR",
			webhookType: webhookType as WebhookType,
			webhookTemplateType
		})
	}

	const handleShowWebhookAssociationEditorDialog = () => {
		WebhookFieldAssociationEditorDialog.open({
			webhookId: webhookId as number,
			webhookType: webhookType as WebhookType,
			saveText: "SALVAR",
			onSave: () => onChange?.()
		})
	}

	const webhookTemplateInfo = webhookTemplateType ? webhookTemplatesInfo[webhookTemplateType] : undefined

	const renderChatBotTriggerIcon = () => {
		return <Icon classes={{ root: classes.webhookTriggerIconRoot }} >
			<img src={webhookTemplateInfo?.svgIcon || CustomWebhookIcon} className={classes.webhookTriggerIcon} alt="trigger-icon"/>
		</Icon>
	}

	return <Grid container direction="column" className={classes.triggerBlock}>
		<Grid item xs>
			<Grid container direction="column" >
				<Grid item>
					<Grid container justifyContent="flex-start" alignItems="center">
						<Grid item xs={3} className={classes.triggerIconContainer}>
							{renderChatBotTriggerIcon()}
						</Grid>

						<Grid item xs={9} className={classes.triggerDescriptionContainer}>
							<Typography className={classes.triggerTitle}>
								{isCustomWebhook(webhookType) ? "CustomWebhook" : webhookTemplateInfo ? webhookTemplateInfo.title : undefined}
							</Typography>

							<Divider orientation="horizontal" size={0.5}/>

							<Typography className={classes.triggerDescription}>
								Chatbot acionado quando um webhook é recebido
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid item container justifyContent="flex-end">

					<IconButton
						onClick={handleShowWebhookUrl}
					>
						<LinkIcon />
					</IconButton>

					{(isCustomWebhook(webhookType) || (!isCustomWebhook(webhookType) && HardCodedService.checkFeatureFlagByInstanceId("webhookCustomFields"))) && (
						<IconButton
							onClick={handleShowWebhookAssociationEditorDialog}
						>
							<AssociationEditorIcon />
						</IconButton>
					)}

					<IconButton
						onClick={() => setOpenWebhookReport(true)}
					>
						<WebhookRelatoryIcon />
					</IconButton>

					<IconButton
						onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
					>
						<MoreOptionsIcon />
					</IconButton>
				</Grid>

				<Menu
					anchorEl={optionsMenuAnchorElement}
					keepMounted
					open={Boolean(optionsMenuAnchorElement)}
					onClose={handleCloseOptionsDialog}
					autoFocus={false}
					PaperProps={{
						style: {
							marginTop: 40
						}
					}}
				>
					{onDelete && (
						<MenuItem
							onClick={() => {
								onDelete()
								handleCloseOptionsDialog()
							}}
						>
							<ListItemText primary="Excluir" />
						</MenuItem>
					)}
				</Menu>
			</Grid>
		</Grid>

		<WebhookReport opened={openWebhookReport} setOpened={setOpenWebhookReport} webhookId={innerTrigger?.webhookId as number}/>
	</Grid>
}

export default WebhookTriggerItem
