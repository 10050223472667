import React, { useState } from "react"

import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	Typography,
	Link,
	CircularProgress,
	TextField,
	FormHelperText
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import ApiService from "@/services/Api"
import InstanceService from "@/services/Instance"
import AuthService from "@/services/Auth"

import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { formatEmail } from "@/utils/mask"

import { Divider, InfoDialog, Portlet } from "@/components"

import useAuthStyles from "@/pages/Auth/styles"
import useStyles from "@/pages/Auth/SignIn/styles"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"

import colors from "@/styles/colors"

type SignInDataType = {
	email: string
	password: string
}

const SignIn = () => {
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signInData, setSignInData] = useState<SignInDataType>({} as SignInDataType)
	const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false)

	const [oldBumeWarningOpenDialog, setOldBumeOpenDialog] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation,
		addValidation
	} = useValidation()

	const authClasses = useAuthStyles()
	const classes = useStyles()

	const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignIn(true)

		try {
			const containInMailingOldBume = await AuthService
				.containInMailingOldBume(signInData.email)

			if (containInMailingOldBume) {
				setOldBumeOpenDialog(true)

				addValidation({
					email: "Esse e-mail pertence ao BUME 1.0"
				})
			} else {
				await ApiService.post("/auth/signin", {
					email: signInData.email,
					password: signInData.password
				})

				await InstanceService
					.redirectToInstance()
			}
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSignIn(false)
	}

	const handleSignInDataChange = (type: string, value: string) => {
		setSignInData({ ...signInData, [type]: value })

		clearValidation(type)
		clearValidation("signin_form")
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const checkIfLoggedIn = async () => {
		const isLoggedIn = await AuthService.isLoggedIn()

		if (isLoggedIn) {
			await InstanceService
				.redirectToInstance()
		}
	}

	const handleOldBumeDialogClose = () => {
		setOldBumeOpenDialog(false)
	}

	useDidMount(() => {
		checkIfLoggedIn()
	})

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={authClasses.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={authClasses.authContent}>
						<Grid item xs={12} sm={10} md={9} className={authClasses.authWrapper}>
							<Portlet
								style={{
									borderRadius: "6px",
									backgroundColor: colors.palette.primary
								}}
								elevation={1}
								className={authClasses.portlet}>

								<img src={logoImg} alt="Letalk" className={authClasses.logoImg} />

								<Divider size={4} orientation="horizontal" />
								<form onSubmit={handleSignIn}>
									<TextField
										id="input-email"
										name="email"
										value={signInData.email}
										onChange={
											({ target }) => handleSignInDataChange("email", formatEmail(target.value))
										}
										variant="outlined"
										label="Email"
										fullWidth
										helperText={validation.email}
										error={Boolean(validation.email)}
										InputProps={{
											className: classes.whiteInput
										}}
										InputLabelProps={{
											className: classes.whiteLabel
										}}
										className={classes.whiteTextField}
									/>

									<Divider orientation="horizontal" size={2}/>

									<TextField
										id="input-password"
										type={visiblePassword ? "text" : "password"}
										value={signInData.password}
										onChange={
											({ target }) => handleSignInDataChange("password", target.value)
										}
										label="Senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											),
											className: classes.whiteInput
										}}
										InputLabelProps={{
											className: classes.whiteLabel
										}}
										className={classes.whiteTextField}
										fullWidth
										variant="outlined"
										helperText={validation.password}
										error={Boolean(validation.password)}
									/>
									<Divider orientation="horizontal" size={1} />

									<Grid item style={{ width: "100%" }}>
										<Grid container justify="flex-end" alignItems="center">
											<Typography
												variant="body1"
												align="right"
												className={classes.whiteText}
											>
												Esqueceu a senha?
											</Typography>

											<Divider orientation="vertical" size={1} />

											<Link
												href="/recovery/request"
												underline="always"
												color="inherit"
												className={classes.whiteText}
											>
												Clique aqui
											</Link>
										</Grid>
									</Grid>

									<Divider orientation="horizontal" size={4}/>

									<Button
										color="primary"
										variant="contained"
										type='submit'
										className={classes.button}
										disabled={loadingSignIn}
										endIcon={loadingSignIn && (
											<CircularProgress size={20} color="inherit" />
										)}
										fullWidth
									>
										ENTRAR
									</Button>

									<FormHelperText
										error={validation.signin_form}
										id="form-helper-text"
										variant="outlined"
										className={authClasses.signInFormHelperText}
									>
										{validation.signin_form}
									</FormHelperText>

									{/*
									<Divider orientation="horizontal" size={1.5}/>
									 <Typography align="center">
										Ainda não tem uma conta?{" "}
										<Link
											href={`/signup${invitationCode ? `?invitation_code=${invitationCode}` : ""}`}
											underline="always">
												Cadastrar
										</Link>
									</Typography>
									*/}

								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<InfoDialog
				openDialog={oldBumeWarningOpenDialog}
				onClose={handleOldBumeDialogClose}
				maxWidth="xs"
			>
				<Grid container spacing={3} justify="center">
					<Grid item xs={12}>
						<Typography
							align="center"
							variant="h4"
						>
							Esse e-mail pertence ao BUME 1.0
						</Typography>
					</Grid>
					<Grid item>
						<Button
							component="a"
							href="https://bume.com/public/signin"
							target="_BLANK"
							variant="contained"
							color="primary"
							disableFocusRipple
							disableElevation
						>
							FAZER LOGIN NO BUME 1.0
						</Button>
					</Grid>
				</Grid>
			</InfoDialog>
		</Container>
	)
}

export default SignIn
