import React from "react"
import {
	Grid,
	Typography,
	IconButton
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"

import {
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Team/styles"

type TeamCardProps = {
	name: string,
	onClickEditButton?: () => void,
	onClickDeleteButton?: () => void
}

const TeamCard: React.FC<TeamCardProps> = (props) => {
	const { name, onClickEditButton, onClickDeleteButton } = props

	const classes = useStyles()

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center">
				<Grid
					item xs={8} sm={6}
					className={classes.textContainer}
				>
					<Typography
						color="textPrimary"
						variant="h2"
						className={classes.nameText}
					>
						{name}
					</Typography>
				</Grid>

				<Grid item xs={4} sm={6}>
					<Grid container justify="flex-end">
						<Grid item>
							<IconButton onClick={onClickEditButton}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={onClickDeleteButton}>
								<DeleteIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TeamCard
