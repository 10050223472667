import React from "react"

import {
	Grid,
	Typography,
	Link as LinkMui
} from "@material-ui/core"

import { Divider, EditableText } from "@/components"

import useWhatsappConnectionFlowStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/styles"
import useStyles from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow/QRCodeInstruction/styles"
import useBreakpoint from "@/hooks/useBreakpoint"

import { openUrlOnlyOnce } from "@/utils/url"

const whatsappTermsOfServiceLink = "https://www.whatsapp.com/legal/terms-of-service?lang=pt_br"

const QRCodeInstruction: React.FC = (props) => {
	const { children } = props

	const whatsappConnectionFlowClasses = useWhatsappConnectionFlowStyles()
	const classes = useStyles()
	const isSmall = useBreakpoint({ type: "down", breakpoint: "md" })

	return (
		<Grid
			container
		>
			<Grid
				container
				direction="column"
				className={classes.qrCodeInstructionContainer}
			>
				<Typography
					variant="h3"
					className={whatsappConnectionFlowClasses.connectWppText}
				>
					CONECTE O WHATSAPP DA SUA EMPRESA
				</Typography>

				<Divider size={3} orientation="horizontal" />

				<Grid
					container
					alignItems="center"
					justify="center"
				>
					{isSmall && (
						<>
							{children}

							<Divider orientation="horizontal" size={3} />
						</>
					)}

					<Grid
						container
						direction="column"
					>
						<Grid
							container
							alignContent="center"
							alignItems="center"
							justify="space-between"
						>
							<Grid item xs={12} md={8} className={classes.qrCodeInstructionContent}>
								<Typography
									variant="body1"
									component={EditableText}
									code="dashboard_generate_qr_code_steps_help"
									errorText=" 1 - Abra o Whatsapp no seu celular <br/><br/>
									2 - Toque em <b>Mais opções</b> ou <b>Ajustes</b> e selecione <b>Aparelhos conectados</b> <br/><br/>
									3 - Gere o QR Code ao lado e aponte o celular para essa tela para capturar o código"
								/>
							</Grid>
						</Grid>

						<Divider size={4} orientation="horizontal" />

						<Typography variant="body1">
							Ao conectar o seu número, você estará concordando com o
							{" "}
							<LinkMui
								href={whatsappTermsOfServiceLink}
								target="_BLANK"
								underline="always"
								color="inherit"
								onClick={(e: React.MouseEvent<HTMLElement>) => openUrlOnlyOnce(e, whatsappTermsOfServiceLink)}
							>
								termo de uso
							</LinkMui>
							{" "}
							do Whatsapp
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{!isSmall && children}
		</Grid>
	)
}

export default QRCodeInstruction
