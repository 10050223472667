import React, { useState } from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"

import { renderComponent } from "@/utils/node"

import useStyles from "@/components/ChatMessageBuilder/TooMuchMessagesWarningDialog/styles"

import tooMuchMessagesImg from "@/assets/images/chatMessageBuilder/too_much_messages.svg"

import {
	ActionDialog,
	Divider
} from "@/components"

type TooMuchMessagesWarningDialogType = {
	open: () => void
}

const TooMuchMessagesWarningDialog: TooMuchMessagesWarningDialogType & React.FC = () => {
	const classes = useStyles()

	const [opened, setOpened] = useState<boolean>(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<ActionDialog
			title="DICA IMPORTANTE"
			openDialog={opened}
			onClose={handleClose}
			onSave={handleClose}
			saveText="ENTENDI"
			maxWidth="md"
			fullWidth
			hideCloseButton
		>
			<Grid
				container
				spacing={4}
				className={classes.container}
			>
				<Grid
					item
					md={5}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
					>
						<img
							className={classes.picture}
							src={tooMuchMessagesImg}
							alt="Too much messages"
						/>
					</Grid>
				</Grid>

				<Grid
					item
					md={7}
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						direction="column"
						className={classes.textContainer}
					>
						<Typography
							variant="h3"
							color="textPrimary"
							className={classes.title}
						>
							Para evitar que o seu número seja banido, existe um intervalo de aproximadamente 12 segundos entre as mensagens.
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="h1"
							className={classes.subTitle}
						>
							Se for fazer muitos envios, sugerimos que coloque o mínimo possível de mensagens para que o envio não se prolongue.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

TooMuchMessagesWarningDialog.open = () => {
	renderComponent(
		"too-much-messages-warning-dialog",
		<TooMuchMessagesWarningDialog />
	)
}

export default TooMuchMessagesWarningDialog
