import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { Description as FileIcon } from "@material-ui/icons"

import MediaService, { Media } from "@/services/Media"

import AudioPlayer from "@/components/Messages/MediaMessage/AudioPlayer"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview/MediaList/styles"
import mediaMessageUseStyles from "@/components/Messages/MediaMessage/styles"

import { Divider } from "@/components"

type MediaListProps = {
	medias: Media[]
}

const MediaList: React.FC<MediaListProps> = (props) => {
	const { medias } = props

	const classes = useStyles()
	const mediaMessageClasses = mediaMessageUseStyles()

	return (
		<Grid
			container
			alignItems="center"
			justify="flex-start"
			wrap="wrap"
		>
			{medias.map(media => (
				<>
					{media.type === "audio" && (
						<AudioPlayer
							src={media.url || MediaService.getUrlFromBlob(media.data)}
						/>
					)}

					{media.type === "picture" && (
						<img
							src={media.url || MediaService.getUrlFromBlob(media.data)}
							alt={media.data.name}
							className={classes.pictureMedia}
						/>
					)}

					{media.type === "file" && (
						<Grid
							container
							alignItems="center"
							className={`${mediaMessageClasses.fileContainer} ${classes.fileMediaContainer}`}
						>
							<FileIcon />

							<Divider orientation="vertical" size={1} />

							<Typography
								color="textPrimary"
								className={mediaMessageClasses.fileName}
							>
								{media.data.name}
							</Typography>
						</Grid>
					)}

					{media.type === "video" && (
						<video
							width="100%"
							className={classes.videoMediaContainer}
						>
							<source
								src={media.url || MediaService.getUrlFromBlob(media.data)}
								type={media.data.type}
							/>
						</video>
					)}
				</>
			))}
		</Grid>
	)
}

export default MediaList
