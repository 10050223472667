import { Divider, InfoDialog, Loading, Notification } from "@/components"
import { Tag } from "@/protocols/tag"
import { Button, Grid, Typography } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import CreateOrEditContactFilter from "@/pages/Admin/ClientCatalog/ContactsFilters/CreateOrEditFilters/index"
import ApiService from "@/services/Api"
import { useGlobalStateStore } from "@/store/GlobalState"
import ContactsFiltersListItem from "@/pages/Admin/ClientCatalog/ContactsFilters/ContactsFiltersListItem"
import { ClientCatalogWhereData } from "@/pages/Admin/ClientCatalog"
import ContactsFiltersSkeleton from "@/skeletons/Admin/ContactsFiltersSkeleton"

export type FilterOperators = "equal" | "not_equal" | "between" | "greater_than" | "less_than" | "in" | "not_in"

export type ContactsFiltersConditions = Record<string, string | number | null | undefined>

export type ContactsFiltersType = {
	id?: number
	inboxChannelId?: number
	title: string
	conditions: ContactsFiltersConditions[]
}

interface ContactFiltersProps {
	openDialog: boolean
	setOpenDialog: (value: boolean) => void
	contactsTags: Tag[]
	getDataWithFilters: (filters: Partial<ClientCatalogWhereData>) => Promise<void>
	setSelectedFilter: React.Dispatch<React.SetStateAction<ContactsFiltersType>>
}

const initialContactsFiltersValue = {
	title: "Filtro Temporário",
	conditions: [
		{
			type: "main_operator",
			value: "and"
		},
		{
			type: "contact_register_date",
			date_to: "",
			date_from: "",
			operator: "between"
		}
	]
}

const ContactFilters = (props: ContactFiltersProps) => {
	const { openDialog, setOpenDialog, setSelectedFilter, contactsTags, getDataWithFilters } = props

	const [openCreateOrEditFilterDialog, setOpenCreateOrEditFilterDialog] = useState(false)

	const [loadingContactsFilters, setLoadingContactsFilters] = useState(false)

	const [filterSavedSelectedToEdit, setFilterSavedSelectedToEdit] = useState<ContactsFiltersType>(initialContactsFiltersValue)

	const [contactsFiltersList, setContactsFiltersList] = useState<ContactsFiltersType[]>([])

	const globalStateStore = useGlobalStateStore()

	const handleGetDataWithFilters = async (filters: { contactsFilters: ContactsFiltersConditions[] }) => {
		try {
			setOpenDialog(false)

			await getDataWithFilters({
				contactsFilters: filters.contactsFilters
			})

			setOpenCreateOrEditFilterDialog(false)
			Notification.success({ message: "Filtros aplicados com sucesso!" })
		} catch (error) {
			Notification.error({ message: "Houve um erro ao aplicar os filtros" })
		}
	}

	const handleCreateContactsFilters = () => {
		setFilterSavedSelectedToEdit({
			...initialContactsFiltersValue,
			...initialContactsFiltersValue
		})
		setOpenCreateOrEditFilterDialog(true)
	}

	const handleGetContactsFilters = async () => {
		setLoadingContactsFilters(true)
		try {
			const response = await ApiService.get(`/clients/contacts-filters/${globalStateStore.whatsappChannel?.id}`)

			if (response?.data) {
				setContactsFiltersList(response.data)
			}
		} catch (error) {
			Notification.error({ message: "Houve um erro ao buscar os filtros" })
		}
		setLoadingContactsFilters(false)
	}

	const handleSelectContactFilter = (contactFilter: ContactsFiltersType) => {
		setSelectedFilter(contactFilter)
		setOpenDialog(false)
		handleGetDataWithFilters({ contactsFilters: contactFilter.conditions })
	}

	useEffect(() => {
		handleGetContactsFilters()
	// eslint-disable-next-line
	}, [openDialog])

	return <InfoDialog
		title="FILTRAR CONTATOS"
		openDialog={openDialog}
		onClose={ () => setOpenDialog(false) }
		maxWidth="md"
	>
		<Grid container spacing={4} style={{ height: "450px" }} alignContent="flex-start">
			<Grid item>
				<Button
					color="primary"
					variant="contained"
					onClick={handleCreateContactsFilters}
				>CRIAR FILTRO</Button>
			</Grid>
			<Divider size={1} orientation="horizontal" />
			<Grid item xs>
				<Typography variant="body1">
					<b> FILTROS SALVOS </b>
				</Typography>

				<Divider size={2} orientation="horizontal" />

				<Loading loading={loadingContactsFilters} customLoadingElement={<ContactsFiltersSkeleton/>}>
					<Grid container direction="column" spacing={1}>
						{
							contactsFiltersList.length > 0 ? contactsFiltersList.map((contactFilter: ContactsFiltersType) => {
								return <ContactsFiltersListItem
									contactFilter={contactFilter}
									handleGetContactsFilters={handleGetContactsFilters}
									handleSelectContactFilter={handleSelectContactFilter}
									setFilterSavedSelectedToEdit={setFilterSavedSelectedToEdit}
									setOpenCreateOrEditFilterDialog={setOpenCreateOrEditFilterDialog}
									key={contactFilter.id}
								/>
							}) : <Typography variant="body1">
								Nenhum filtro salvo! Crie o seu primeiro filtro agora mesmo!
							</Typography>
						}
					</Grid>
				</Loading>
			</Grid>
		</Grid>
		<CreateOrEditContactFilter
			getContactsFilters={handleGetContactsFilters}
			openDialog={openCreateOrEditFilterDialog}
			setOpenDialog={setOpenCreateOrEditFilterDialog}
			selectedFilter={filterSavedSelectedToEdit}
			getDataWithFilters={handleGetDataWithFilters}
			setSelectedFilter={setSelectedFilter}
			contactsTags={contactsTags}
		/>
	</InfoDialog>
}

export default ContactFilters
