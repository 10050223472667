import React from "react"
import { Grid, IconButton } from "@material-ui/core"
import {
	Close as DeleteIcon
} from "@material-ui/icons"

import { Portlet } from "@/components"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockPortlet/styles"

type BlockPortletProps = {
	headerClassName?: string
	headerChildren: React.ReactNode
	onDelete?: () => void
}

const BlockPortlet: React.FC<BlockPortletProps> = (props) => {
	const {
		headerChildren,
		headerClassName,
		children,
		onDelete
	} = props

	const classes = useStyles()

	return (
		<Portlet
			style={{ padding: 0 }}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					container
					alignItems="center"
					justify="center"
					className={`${classes.header} ${headerClassName}`}
				>
					{headerChildren && (
						React.cloneElement(headerChildren as React.ReactElement, {
							className: classes.headerChildren
						})
					)}

					{onDelete && (
						<IconButton
							size="small"
							onClick={onDelete}
							className={classes.deleteIcon}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</Grid>

				<Grid
					container
					className={classes.body}
				>
					{children}
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default BlockPortlet
