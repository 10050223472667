import React, { useState } from "react"
import DateFnsUtils from "@date-io/date-fns"
import { ptBR } from "date-fns/locale"
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {
	Grid,
	Typography,
	TextField,
	Chip,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	InputAdornment,
	Tooltip
} from "@material-ui/core"
import {
	FilterList as FilterIcon,
	Group as AttendantFilterIcon,
	LocalOffer as TagFilterIcon,
	Forum as ChatTypeFilterIcon,
	Cancel as ClearFilterIcon
} from "@material-ui/icons"

import colors from "@/styles/colors"

import { useChatGlobalStateStore, ChatListFilter as IChatListFilter } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"
import useStyles from "@/pages/Attendance/Chat/ChatListPanel/ChatListFilter/styles"
import useCustomMemo from "@/hooks/useCustomMemo"

import { Divider } from "@/components"

import { TABS } from "@/pages/Attendance/Chat/ChatListPanel/ChatListTab"

import { convertRGBAObjectToString } from "@/utils/color"
import useCustomStyles from "@/styles/custom"

import { IChatType } from "@/protocols/channel"

import useChat from "@/hooks/useChat"

type ChatTypeFilter = {
	type: IChatType
	name: string
	color: string
}

const CHAT_TYPE_FILTERS: ChatTypeFilter[] = [
	{
		type: "user",
		name: "Individuais",
		color: "#009688"
	},
	{
		type: "group",
		name: "Grupos",
		color: "#007CBA"
	}
]

const ChatListFilter: React.FC = () => {
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const chatMethods = useChat()
	const customClasses = useCustomStyles()

	const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(false)

	const handleSearchChange = async (filter: Partial<IChatListFilter>) => {
		await chatGlobalStateStore.chatListPanel.chatListFilter.update(filter)

		await chatGlobalStateStore.chat.loadAllFromServer()
	}

	const handleClearFilters = () => {
		chatGlobalStateStore.chatListPanel.chatListFilter.resetFilters()
	}

	const attendantFilterOptions = chatGlobalStateStore.attendant.list.map(attendant => ({ id: attendant.id, name: attendant.name }))
	const tagFilterOptions = chatGlobalStateStore.tag.list

	const actualChatTypesFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.chatTypes
		.filter(chatType => CHAT_TYPE_FILTERS.find(({ type }) => type === chatType))
		.map(chatType => CHAT_TYPE_FILTERS.find(({ type }) => type === chatType) as ChatTypeFilter)
	const actualAttendantFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.attendants
	const actualTagsFilter = chatGlobalStateStore.chatListPanel.chatListFilter.current.tags
	const hasFilters = !chatGlobalStateStore.chatListPanel.chatListFilter.isDefaultFilter()

	const isCurrentUserAdmin = globalStateStore.instance?.user_in_instance_role?.code === "admin"
	const isCurrentAttendanceFilterOnGoing = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "on-going"
	const showAttendantFilter = isCurrentAttendanceFilterOnGoing && isCurrentUserAdmin

	const othersTab = TABS.find(tab => tab.slug === "others")
	const showLastMessageTransactedFilter = othersTab?.availableChatStatus?.includes(chatGlobalStateStore.chatListPanel.chatStatusFilter.current)

	const currentFilteredChats = chatGlobalStateStore.chatListPanel.chatStatusFilter.current === "queue" ? (
		chatGlobalStateStore.chat.list.filter(chat => chatMethods.isQueueChat(chat))
	) : (
		chatGlobalStateStore.chat.list.filter(chat => chat.status === chatGlobalStateStore.chatListPanel.chatStatusFilter.current)
	)

	const currentFilteredChatsHash = currentFilteredChats.map(chat => chat.id).join("")
	return useCustomMemo(() => (
		<Accordion
			className={classes.container}
			expanded={isFilterExpanded}
		>
			{/* Must use style instead of class. With classes, seems like Material UI overrides the cursor style and it remains pointer. */}
			<AccordionSummary
				style={{
					cursor: "default"
				}}
			>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={classes.box}
				>
					<Typography
						variant="caption"
						color="textPrimary"
					>
						{`${currentFilteredChats.length} ${currentFilteredChats.length === 1 ? "Conversa" : "Conversas"}`}
					</Typography>

					<Grid
						item
					>
						<Grid
							container
							alignItems="center"
						>
							{hasFilters &&
								<Tooltip
									title="Resetar filtros"
									className={customClasses.cursorPointer}
								>
									<ClearFilterIcon
										onClick={handleClearFilters}
										className={classes.grayIcon}
										fontSize="small"
									/>
								</Tooltip>
							}

							<Divider orientation="vertical" size={1} />

							<Typography
								variant="caption"
								color="textPrimary"
							>
								Filtros
							</Typography>

							<Divider orientation="vertical" size={1} />

							<FilterIcon
								className={customClasses.cursorPointer}
								onClick={() => { setIsFilterExpanded(!isFilterExpanded) }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Grid
					container
					justify="space-between"
					alignItems="center"
					className={`${classes.box}`}
					spacing={3}
				>
					{showLastMessageTransactedFilter && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Última mensagem transacionada (Versão BETA)
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Grid
									container
									justify="space-between"
								>
									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<Grid
											className={classes.dateContainer}
										>
											<KeyboardDateTimePicker
												className={classes.filterInput}
												okLabel="Pronto"
												cancelLabel="Cancelar"
												ampm={false}
												value={new Date(chatGlobalStateStore.chatListPanel.chatListFilter.current.fromLastMessageTransactedDate)}
												inputVariant="outlined"
												onChange={(date) => handleSearchChange({ fromLastMessageTransactedDate: date as Date })}
												placeholder="De"
												size="small"
												format="dd/MM/yyyy HH:mm"
												InputAdornmentProps={{
													position: "start"
												}}
											/>
										</Grid>
									</MuiPickersUtilsProvider>

									<Divider orientation="vertical" size={1} />

									<MuiPickersUtilsProvider
										locale={ptBR}
										utils={DateFnsUtils}
									>
										<Grid
											className={classes.dateContainer}
										>
											<KeyboardDateTimePicker
												className={classes.filterInput}
												okLabel="Pronto"
												cancelLabel="Cancelar"
												ampm={false}
												value={new Date(chatGlobalStateStore.chatListPanel.chatListFilter.current.toLastMessageTransactedDate)}
												inputVariant="outlined"
												onChange={(date) => handleSearchChange({ toLastMessageTransactedDate: date as Date })}
												placeholder="Até"
												size="small"
												format="dd/MM/yyyy HH:mm"
												InputAdornmentProps={{
													position: "start"
												}}
											/>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>
							</Grid>
						</Grid>
					)}

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							direction="column"
						>
							<Typography
								variant="caption"
								color="textPrimary"
							>
								Tipos de conversa
							</Typography>

							<Divider orientation="horizontal" size={0.5} />

							<Autocomplete
								multiple
								options={CHAT_TYPE_FILTERS}
								getOptionSelected={(option, value) => option.type === value.type}
								getOptionLabel={(option) => option.name}
								value={actualChatTypesFilter}
								fullWidth
								filterSelectedOptions
								onChange={(_, chatTypesFilter) => handleSearchChange({ chatTypes: chatTypesFilter.map(({ type }) => type) })}
								renderInput={(params) => (
									<TextField
										{...params}
										className={classes.filterInput}
										variant="outlined"
										placeholder="Tipos de conversa"
										{...({
											...params,
											InputProps: {
												...params.InputProps,
												startAdornment: [
													(
														<InputAdornment
															position="start"
															key="icon"
														>
															<ChatTypeFilterIcon />
														</InputAdornment>
													),
													params.InputProps.startAdornment
												]
											}
										})}
									/>
								)}
								renderTags={(value, getTagProps) => {
									return value.map((chatTypeFilter, index) => (
										<Chip
											key={index}
											label={chatTypeFilter.name}
											style={{
												color: colors.grayScale[11],
												backgroundColor: chatTypeFilter.color
											}}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
								}
								size="small"
							/>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Grid
							container
							direction="column"
						>
							<Typography
								variant="caption"
								color="textPrimary"
							>
								Tags
							</Typography>

							<Divider orientation="horizontal" size={0.5} />

							<Autocomplete
								multiple
								options={tagFilterOptions}
								getOptionSelected={(option, value) => option.id === value.id}
								getOptionLabel={(option) => option.name}
								value={actualTagsFilter}
								fullWidth
								filterSelectedOptions
								onChange={(_, tags) => handleSearchChange({ tags })}
								renderInput={(params) => (
									<TextField
										{...params}
										className={classes.filterInput}
										variant="outlined"
										placeholder="Tags"
										{...({
											...params,
											InputProps: {
												...params.InputProps,
												startAdornment: [
													(
														<InputAdornment
															position="start"
															key="icon"
														>
															<TagFilterIcon />
														</InputAdornment>
													),
													params.InputProps.startAdornment
												]
											}
										})}
									/>
								)}
								renderTags={(value, getTagProps) => {
									return value.map((tag, index) => (
										<Chip
											key={index}
											label={tag.name}
											style={{
												color: colors.grayScale[11],
												backgroundColor: convertRGBAObjectToString(tag.color)
											}}
											size="small"
											{...getTagProps({ index })}
										/>
									))
								}
								}
								size="small"
							/>
						</Grid>
					</Grid>

					{showAttendantFilter && (
						<Grid
							item
							xs={12}
						>
							<Grid
								container
								direction="column"
							>
								<Typography
									variant="caption"
									color="textPrimary"
								>
									Atendentes
								</Typography>

								<Divider orientation="horizontal" size={0.5} />

								<Autocomplete
									multiple
									options={attendantFilterOptions}
									getOptionSelected={(option, value) => option.id === value.id}
									getOptionLabel={(option) => option.name}
									value={actualAttendantFilter}
									fullWidth
									filterSelectedOptions
									onChange={(_, attendants) => handleSearchChange({ attendants })}
									renderInput={(params) => {
										return (
											<TextField
												className={classes.filterInput}
												variant="outlined"
												placeholder="Atendentes"
												{...({
													...params,
													InputProps: {
														...params.InputProps,
														startAdornment: [
															(
																<InputAdornment
																	position="start"
																	key="icon"
																>
																	<AttendantFilterIcon />
																</InputAdornment>
															),
															params.InputProps.startAdornment
														]
													}
												})}
											/>
										)
									}}
									renderTags={(value, getTagProps) => {
										return value.map((attendant, index) => (
											<Chip
												key={index}
												label={attendant.name}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									}
									size="small"
								/>
							</Grid>
						</Grid>
					)}
				</Grid>
			</AccordionDetails>
		</Accordion>
	), [
		isFilterExpanded,
		chatGlobalStateStore.chatListPanel.chatListFilter.currentHash,
		chatGlobalStateStore.attendant.listHash,
		chatGlobalStateStore.tag.listHash,
		chatGlobalStateStore.chat.list.length,
		showAttendantFilter,
		currentFilteredChatsHash
	])
}

export default ChatListFilter
