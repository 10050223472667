import { Box, DialogContentText, Grid, Typography } from "@material-ui/core"
import React from "react"
import Custom_fields_intro from "@/assets/images/illustrations/custom_fields_intro.svg"
import useStyles from "@/pages/Admin/CustomFields/CustomFieldIntroduction/styles"

const CustomFieldIntroduction: React.FC = () => {
	const customStyles = useStyles()
	return (
		<Grid container alignItems="center" justifyContent="space-evenly" style={{ marginTop: 100 }}>
			<Grid item justifyContent="space-evenly">
				<Box className={customStyles.boxIntroductionOrganize} >
					<Typography variant="h6" className={customStyles.customFieldName} >
						Campos Personalizados
					</Typography>
					<DialogContentText className={customStyles.fieldExplanation}>
						A Letalk, fornece campos padrões para coletar informações sobre seus contatos. Mas,
						você também pode usar campos personalizados, para obter informações
						extras importantes para o seu negócio.
					</DialogContentText>
				</Box>
			</Grid>
			<Grid item>
				<img src={Custom_fields_intro} alt="ilustração para campos personalizados"></img>
			</Grid>
		</Grid>
	)
}

export default CustomFieldIntroduction
