import React from "react"

import { Divider } from "@/components"

import Metrics from "@/pages/Admin/Dashboard/Metrics"

import WhatsappConnectionFlow from "@/@integrations/Whatsapp/components/WhatsappConnectionFlow"

const Dashboard: React.FC = () => {
	return (
		<>
			<WhatsappConnectionFlow />

			<Divider orientation="horizontal" size={8} />

			<Metrics />
		</>
	)
}

export default Dashboard
