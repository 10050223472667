import { makeStyles } from "@material-ui/core"

export const MENU_MESSAGE_WIDTH = 336

const useStyles = makeStyles({
	descriptionContainer: {
		flex: 1
	},
	showOptionsButton: {
		textTransform: "none",
		fontWeight: "normal",
		fontSize: 14,
		"&:hover": {
			textDecoration: "none"
		},
		"& span": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center"
		}
	},
	menuItem: {
		height: 72,
		textTransform: "none",
		alignItems: "center",
		justifyContent: "space-between"
	}
})

export default useStyles
