import React, { useEffect, useState } from "react"

import {
	Grid,
	Typography,
	IconButton,
	TextField,
	Card,
	Chip
} from "@material-ui/core"

import {
	Edit as EditIcon
} from "@material-ui/icons"

import {
	Loading,
	ActionDialog,
	Notification
} from "@/components"

import ApiService from "@/services/Api"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientAttendantManager/styles"

import { Autocomplete } from "@material-ui/lab"
import useCustomStyles from "@/styles/custom"
import { UserInInstance } from "@/protocols/team"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import ClientAttendantManagerSkeleton from "@/skeletons/ClientAttendantManager"

interface ClientAttendantManagerProps {
	clientId: number,
	clientAttendantManagerId?: number,
	loading: boolean
}

const ClientAttendantManager = (props: ClientAttendantManagerProps) => {
	const {
		clientAttendantManagerId,
		clientId,
		loading
	} = props

	const chatGlobalStateStore = useChatGlobalStateStore()

	const allAssignOptions = [{ id: 0, name: "Sem atendente responsável" }, ...chatGlobalStateStore.attendant.listOrderedAlphabetically] as UserInInstance[]

	const getCurrentAttendantById = (attendantId?: number) => allAssignOptions.find(attendant => attendantId ? attendant.id === attendantId : attendant.id === 0)

	const [openAssignClientAttendantManagerDialog, setOpenAssignClientAttendantManagerDialog] = useState(false)

	const [assignClientAttendantManagerLoadingDialog, setAssignClientAttendantManagerLoadingDialog] = useState(false)

	const [selectedAttendantManager, setSelectedAttendantManager] = useState(getCurrentAttendantById(clientAttendantManagerId))

	const [selectAttendantManagerInputValue, setSelectAttendantManagerInputValue] = useState<UserInInstance>()

	const customClasses = useCustomStyles()

	const { clearAllValidations, validation, triggerValidation } = useValidation()

	const handleSetClientAttendantManager = async (attendant?: UserInInstance | null) => {
		if (attendant) {
			setSelectAttendantManagerInputValue(attendant)
		} else {
			setSelectAttendantManagerInputValue(undefined)
		}
		clearAllValidations()
	}

	const handleAssignClientAttendantManager = async (attendantId?: number) => {
		setAssignClientAttendantManagerLoadingDialog(true)
		try {
			await ApiService.put(`/clients/${clientId}/attendant-manager`, {
				attendantId: attendantId,
				inboxChannelChatId: chatGlobalStateStore.chat?.current?.id
			})

			setOpenAssignClientAttendantManagerDialog(false)
			setSelectedAttendantManager(getCurrentAttendantById(attendantId))
			Notification.success({ message: "Um atendente foi definido." })
		} catch (error) {
			setSelectedAttendantManager(getCurrentAttendantById(clientAttendantManagerId))
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Houve um erro ao tentar adicionar o atendente responsável." })
		}
		setSelectAttendantManagerInputValue(undefined)
		setAssignClientAttendantManagerLoadingDialog(false)
	}

	const handleRemoveClientAttendantManager = async () => {
		setAssignClientAttendantManagerLoadingDialog(true)
		try {
			await ApiService.delete(`/clients/${clientId}/attendant-manager`, {
				params: {
					inboxChannelChatId: chatGlobalStateStore.chat?.current?.id
				}
			})

			setOpenAssignClientAttendantManagerDialog(false)
			setSelectedAttendantManager(getCurrentAttendantById(0))
			Notification.success({ message: "O atendente foi removido." })
		} catch (error) {
			setSelectedAttendantManager(getCurrentAttendantById(clientAttendantManagerId))
			triggerValidation(error as ErrorType)
			Notification.error({ message: "Houve um erro ao tentar remover o atendente responsável." })
		}
		setSelectAttendantManagerInputValue(undefined)
		setAssignClientAttendantManagerLoadingDialog(false)
	}

	const handleCloseAssignClientAttendantManagerDialog = () => {
		setOpenAssignClientAttendantManagerDialog(false)
	}

	const classes = useStyles()

	useEffect(() => {
		setSelectedAttendantManager(getCurrentAttendantById(clientAttendantManagerId))
	},
	// eslint-disable-next-line
	[clientAttendantManagerId])

	return <Grid item >
		<Card className={classes.attendantManagerCard}>
			<Loading loading={loading} customLoadingElement={<ClientAttendantManagerSkeleton />}>
				<Grid container alignItems="center" justify="space-between">
					<Grid className={classes.attendantManagerCardTitle} container alignItems="center" justify="space-between">
						<Grid item xs>
							<Typography
								variant="h3"
								color="textPrimary"
								style={{ fontSize: "15px" }}
							>
								Atendente Responsável
							</Typography>
						</Grid>
						<Grid item>
							<IconButton onClick={() => setOpenAssignClientAttendantManagerDialog(true)}>
								<EditIcon />
							</IconButton>

						</Grid>
					</Grid>

					<Grid container spacing={1} justify="flex-start" className={classes.attendantManagerCardContent}>

						<Grid item>
							<Typography variant="h5">
								<h3>{selectedAttendantManager ? selectedAttendantManager.name : "Atendente não encontrado"}</h3>
							</Typography>
						</Grid>
					</Grid>
					<ActionDialog
						title="ASSOCIAR ATENDENTE RESPONSÁVEL"
						onSave={() => selectAttendantManagerInputValue?.id === 0 ? handleRemoveClientAttendantManager() : handleAssignClientAttendantManager(selectAttendantManagerInputValue?.id)}
						saveText="SALVAR"
						onClose={handleCloseAssignClientAttendantManagerDialog}
						loading={assignClientAttendantManagerLoadingDialog}
						openDialog={openAssignClientAttendantManagerDialog}
						fullWidth
						maxWidth="md"
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography
									color="textPrimary"
									className={customClasses.boldText}
								>
									Escolha o atendente responsável
								</Typography>
								<Autocomplete
									noOptionsText="Nenhum atendente encontrado"
									options={allAssignOptions}
									getOptionSelected={(option, value) => option.id === value.id}
									getOptionLabel={(option) => option.name}
									fullWidth
									filterSelectedOptions
									onChange={(_, attendant) => handleSetClientAttendantManager(attendant)}
									multiple={false}
									value={selectAttendantManagerInputValue}
									renderInput={(params) => {
										return (
											<TextField
												className={classes.filterInput}
												variant="outlined"
												placeholder="Selecionar o atendente"
												{...({
													...params,
													InputProps: {
														...params.InputProps
													}
												})}
												error={!!validation.attendant}
												helperText={validation.attendant}
											/>
										)
									}}
									renderTags={(value, getTagProps) => {
										return value.map((attendant, index) => (
											<Chip
												key={index}
												label={attendant.name}
												size="small"
												{...getTagProps({ index })}
											/>
										))
									}
									}
									size="small"
								/>
							</Grid>
						</Grid>
					</ActionDialog>
				</Grid>
			</Loading>
		</Card>
	</Grid>
}

export default ClientAttendantManager
