import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

interface ChatListFilterSkeletonProps {
	height?: number
	width?: number
}

const EditableTextSkeleton = (props: ChatListFilterSkeletonProps) => {
	const { height, width } = props

	return <Grid
		style={{ height: height || "200px", width: width || "100%" }}
		container
		spacing={1}
		direction="row"
	>
		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>

		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>

		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>

		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>

		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>

		<Grid item xs={12}>
			<Skeleton variant="rect" height="100%" />
		</Grid>
	</Grid>
}

export default EditableTextSkeleton
