import React from "react"
import { SvgIcon as MuiSvgIcon, SvgIconProps as MuiSvgIconProps } from "@material-ui/core"

type SvgIconProps = MuiSvgIconProps & {
	icon: React.FunctionComponent
}

const SvgIcon: React.FC<SvgIconProps> = (props) => {
	const {
		icon
	} = props

	return (
		<MuiSvgIcon
			component={icon}
			{...props}
		/>
	)
}

export default SvgIcon
