import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	input: {
		display: "none"
	},
	button: {
		minWidth: "auto",
		padding: 0,
		backgroundColor: "transparent"
	}
})

export default useStyles
