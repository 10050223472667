import React, { useState, useEffect } from "react"
import {
	Grid,
	Typography,
	IconButton
} from "@material-ui/core"
import {
	Refresh as RefreshIcon,
	FastForwardOutlined as EnqueuedIcon,
	Repeat as SendingIcon
} from "@material-ui/icons"

import {
	Loading,
	Divider,
	EditableText
} from "@/components"

import { IProductMessageMetrics } from "@/protocols/metrics"

import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import colors from "@/styles/colors"

import QueueMetricsCard from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/QueueMetricsCard"
import TodayCard from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/TodayCard"
import LastDaysMetrics, {
	LastDaysMetricsFilter,
	FILTER_OPTIONS
} from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/LastDaysMetricsTableCard"

import ProductMessageMetricsSkeleton from "@/skeletons/Admin/ProductMessageMetricsSkeleton"

const ProductMessageMetrics: React.FC = () => {
	const customClasses = useCustomStyles()

	const [metrics, setMetrics] = useState({} as IProductMessageMetrics)
	const [loading, setLoading] = useState(true)

	const [lastDaysMetricsFilter, setLastDaysMetricsFilter] = useState<LastDaysMetricsFilter>({
		lastDaysCount: FILTER_OPTIONS.lastDays[0].value,
		features: FILTER_OPTIONS.features[0].value
	})

	const handleChangeLastDaysMetricsFilter = (filter: Partial<LastDaysMetricsFilter>) => {
		setLastDaysMetricsFilter(lastState => ({
			...lastState,
			...filter
		}))
	}

	const getMetrics = async () => {
		setLoading(true)

		try {
			const { data } = await ApiService.get("/metrics/products/messages", {
				params: {
					lastDaysMetricsLastDaysCount: lastDaysMetricsFilter.lastDaysCount,
					lastDaysMetricsFeatures: lastDaysMetricsFilter.features.join(",")
				}
			})

			setMetrics(data)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	useDidMount(() => {
		getMetrics()
	})

	useEffect(() => {
		getMetrics()
	// eslint-disable-next-line
	}, [lastDaysMetricsFilter])

	return (
		<>
			<Grid container justify="space-between" alignItems="center">
				<Grid item>
					<Typography
						variant="h2"
						color="textPrimary"
						className={customClasses.uppercase}
					>
						MENSAGENS DE INTEGRAÇÃO E ENVIO EM MASSA
					</Typography>
				</Grid>

				<Grid item xs>
					<Grid container spacing={1} justify="flex-end" alignItems="center">
						<Grid item>
							<IconButton
								onClick={() => getMetrics()}
								disabled={loading}
							>
								<RefreshIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size={2} />

			<Loading
				loading={loading}
				customLoadingElement={<ProductMessageMetricsSkeleton />}
			>
				<Grid
					container
					spacing={2}
				>
					<Grid
						item
						xs={12}
					>
						<Grid
							container
							spacing={2}
						>
							<Grid
								item
							>
								<QueueMetricsCard
									count={metrics?.queueMetrics?.enqueuedMessages || 0}
									countDescription="Mensagens na fila"
									helperText={(
										<>
											<Typography
												component={EditableText}
												code="dashboard_integration_and_message_blast_queue"
												skeletonWidth={200}
												skeletonHeight={100}
											/>
										</>
									)}
									icon={<EnqueuedIcon />}
									iconBackgroundColor="#E6E6E4"
									mainColor={colors.grayScale[4]}
									title="Fila"
								/>
							</Grid>

							<Grid
								item
							>
								<QueueMetricsCard
									count={metrics?.queueMetrics?.sendingMessages || 0}
									countDescription="Sendo enviadas"
									helperText={(
										<>
											<Typography
												component={EditableText}
												code="dashboard_integration_and_message_blast_messages_being_sent"
												skeletonWidth={200}
												skeletonHeight={100}
											/>
										</>
									)}
									icon={<SendingIcon />}
									iconBackgroundColor={colors.palette.triggeredMessageLighter}
									mainColor={colors.palette.triggeredMessage}
									title="Enviando"
								/>
							</Grid>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Grid
							container
						>
							<TodayCard
								todayMetrics={metrics?.todayMetrics}
							/>
						</Grid>
					</Grid>

					<Grid
						item
						xs={12}
					>
						<Grid
							container
						>
							<LastDaysMetrics
								messageMetricsByDate={metrics?.messageMetricsByDate || []}
								changeLastDaysMetricsFilter={handleChangeLastDaysMetricsFilter}
								lastDaysMetricsFilter={lastDaysMetricsFilter}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Loading>
		</>
	)
}

export default ProductMessageMetrics
