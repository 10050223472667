import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	inputContainer: {
		flex: 1
	},
	deleteIcon: {
		borderRadius: "100%",
		color: colors.grayScale[11],
		width: theme.spacing(1.5),
		height: theme.spacing(1.5),
		backgroundColor: "rgba(172, 181, 189, 1)",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: "rgba(172, 181, 189, 0.8)"
		},
		"& svg": {
			width: theme.spacing(1.5),
			height: theme.spacing(1.5)
		}
	},
	deleteButton: {
		margin: theme.spacing(0, 2),
		padding: 0,
		height: theme.spacing(1.5),
		width: theme.spacing(1.5)
	}
}))

export default useStyles
