import React, { useState } from "react"
import {
	Grid,
	TextField,
	Chip
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"

import { renderComponent } from "@/utils/node"
import { formatSaveInputLocationOptions, getCurrentSaveInputLocationOption } from "@/utils/chatBot"

import { ActionDialog } from "@/components"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/Blocks/SaveInputBlock/InputLocationEditor/styles"

import {
	ChatBotFlowBlockContent
} from "@/protocols/chatBot"
import { ConstructionResources } from "@/protocols/chatBotConstructor"

type InputLocationEditorProps = {
	onSave: (chatBotFlowBlockContent: ChatBotFlowBlockContent) => void
	constructionResources: ConstructionResources
	chatBotFlowBlockContent: ChatBotFlowBlockContent
}

type InputLocationEditorType = {
	open: (props: InputLocationEditorProps) => void
}

const InputLocationEditor: InputLocationEditorType & React.FC<InputLocationEditorProps> = (props) => {
	const {
		onSave,
		constructionResources,
		chatBotFlowBlockContent
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const [blockContent, setBlockContent] = useState<ChatBotFlowBlockContent>(chatBotFlowBlockContent)

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		onSave(blockContent)

		handleClose()
	}

	const handleChangeContent = (content: Partial<ChatBotFlowBlockContent>) => {
		setBlockContent(lastState => ({
			...lastState,
			...content
		}))
	}

	return (
		<ActionDialog
			title="SELECIONE UM LOCAL PARA SALVAR A RESPOSTA"
			onSave={handleSave}
			saveText="SALVAR"
			onClose={handleClose}
			openDialog={opened}
			fullWidth
			maxWidth="md"
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<Autocomplete
						defaultValue={getCurrentSaveInputLocationOption(constructionResources, chatBotFlowBlockContent)}
						options={formatSaveInputLocationOptions(constructionResources)}
						getOptionSelected={(option, value) => option.slug === value.slug}
						getOptionLabel={(option) => option.name}
						fullWidth
						filterSelectedOptions
						onChange={(_, option) => {
							if (option) {
								handleChangeContent({
									locationToSaveInput: {
										customFieldId: option.customFieldId,
										type: "custom-field"
									}
								})
							}
						}}
						renderInput={(params) => {
							return (
								<TextField
									className={classes.filterInput}
									variant="outlined"
									placeholder="Selecione um campo personalizado"
									{...({
										...params,
										InputProps: {
											...params.InputProps
										}
									})}
								/>
							)
						}}
						renderTags={(value, getTagProps) => {
							return value.map((attendant, index) => (
								<Chip
									key={index}
									label={attendant.name}
									size="small"
									{...getTagProps({ index })}
								/>
							))
						}
						}
						size="small"
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

InputLocationEditor.open = (props: InputLocationEditorProps) => {
	renderComponent(
		"input-location-editor",
		<InputLocationEditor
			{...props}
		/>
	)
}

export default InputLocationEditor
