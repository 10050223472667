import React, { useEffect, useState, useRef } from "react"
import {
	Avatar,
	Card,
	Grid,
	Typography,
	IconButton,
	TextField
} from "@material-ui/core"

import {
	Close as CloseIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import {
	Divider,
	AccessibleDrawer,
	Loading,
	ActionDialog,
	Notification,
	ClientIdentification,
	GroupEditButton
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"

import { formatPhoneNumber } from "@/utils/mask"
import { forceRemountComponentProps } from "@/utils/node"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import ClientInfoSkeleton from "@/skeletons/ClientInfo"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/styles"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useCustomMemo from "@/hooks/useCustomMemo"

import { Color } from "@/protocols/color"

import ClientNotesTable from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientNotesTable"
import ClientTags from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientTags"

import { GroupInfo } from "@/components/GroupEditButton"
import ClientAttendantManager from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo/ClientAttendantManager"

type ClientInfoProps = {
	open: boolean
	onClose: () => void
	clientId: number
}

type TagDataProps = {
	id: number
	name: string,
	color: Color
}

const ClientInfo: React.FC<ClientInfoProps> = ({ open, onClose, clientId }) => {
	const { validation, triggerValidation } = useValidation()

	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()

	const [loading, setLoading] = useState(false)
	const [changingClient, setChangingClient] = useState(false)

	const [clientChangeableData, setClientChangeableData] = useState<{ nickname: string, email?: string }>({ nickname: "" })
	const [openedClientDataDialog, setOpenedClientDataDialog] = useState<boolean>(false)

	const client = chatGlobalStateStore.client.getById(clientId)
	const whatsAppContact = chatGlobalStateStore.client.getWhatsAppContact(client)
	const whatsappContactPhoneNumber = chatGlobalStateStore.client.getWhatsappPhoneNumber(client)
	const emailContact = chatGlobalStateStore.client.getEmailContact(client)

	// useRef so useEffect do not complains about dependencies
	// eslint-disable-next-line
	const getAndUpdateClientData = useRef(async () => { })

	getAndUpdateClientData.current = async () => {
		if (!clientId) {
			return
		}

		setLoading(true)

		try {
			const { data } = await ApiService.get(`/clients/${clientId}`)

			chatGlobalStateStore.client.updateById(clientId, data.client)
		} catch (err) {
			ErrorHandler.handle(err as ErrorType)
		}

		setLoading(false)
	}

	const eventHandler = (event: KeyboardEvent) => {
		if (event.key === "Escape") {
			onClose()
		}
	}

	const getClientNickname = () => {
		if (!client) {
			return ""
		}

		return client.nickname
	}

	const getClientName = () => {
		if (!client) {
			return ""
		}

		return client.name
	}

	const openClientDataDialog = () => {
		setClientChangeableData({
			nickname: client.nickname,
			email: emailContact?.data?.email
		})
		setOpenedClientDataDialog(true)
	}

	const onGroupChangeSuccess = async (groupInfo: GroupInfo) => {
		chatGlobalStateStore.client.updateById(clientId, {
			nickname: groupInfo.name
		})
	}

	const handleEditClient = async () => {
		setChangingClient(true)

		try {
			await ApiService.put(
				`/clients/${clientId}`,
				{ nickname: clientChangeableData.nickname }
			)

			const contacts = whatsAppContact ? [whatsAppContact] : []
			if (clientChangeableData.email) {
				const response = await ApiService.post(
					`/clients/${clientId}/email`,
					{
						email: clientChangeableData.email
					}
				)
				contacts.push(response.data.updatedContact)
			}

			chatGlobalStateStore.client.updateById(clientId, {
				nickname: clientChangeableData.nickname,
				contacts
			})

			Notification.success({ message: "Cliente atualizado." })

			setOpenedClientDataDialog(false)
		} catch (err) {
			triggerValidation(err as ErrorType)
		}

		setChangingClient(false)
	}

	useDidMount(() => {
		window.addEventListener("keydown", eventHandler)
	})

	useEffect(() => {
		getAndUpdateClientData.current()
	}, [clientId, getAndUpdateClientData])

	return useCustomMemo(() => (
		<AccessibleDrawer
			anchor="right"
			open={open}
			onMobileBackButtonPress={onClose}
			onClose={onClose}
			variant="persistent"
			className={`${classes.drawer} ${open ? classes.drawerOpened : classes.drawerClosed}`}
			classes={{
				paper: classes.drawerPaper
			}}
		>
			<Grid container direction="column">
				<Grid item>
					<HeaderDrawer
						title="Dados do contato"
						icon={<CloseIcon />}
						onClose={onClose}
					/>
				</Grid>

				<Grid item>
					<Card className={classes.card}>
						<Loading loading={loading} customLoadingElement={<ClientInfoSkeleton />}>
							<>
								<Grid container direction="column" alignItems="flex-start">
									<Grid item className={classes.avatarContainer}>
										<Avatar className={classes.avatar} src={client?.picture_url} />
										{/* <Badge
											anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
											badgeContent={(
												<Grid
													container
													className={classes.badgeGrid}
													justify="center"
													alignContent="center"
												>
													<IconButton className={classes.refreshProfilePictureButton}>
														<RefreshProfilePictureIcon />
													</IconButton>
												</Grid>
											)}
										>
											<Avatar className={classes.avatar} src={client?.picture_url} />
										</Badge> */}
									</Grid>
								</Grid>
								<Divider orientation="horizontal" size={3} />
								<Grid container alignItems="center" justify="space-between">
									<Grid item xs>
										<ClientIdentification
											nickname={getClientNickname()}
											name={getClientName()}
											PrimaryTitleProps={{
												variant: "h2",
												style: {
													fontSize: "15px"
												}
											}}
										/>

										{
											emailContact?.data.email && (
												<Grid item>
													<Divider orientation="horizontal" size={0.75} />

													<Typography color="textPrimary" variant="body2">
														{emailContact.data.email}
													</Typography>
												</Grid>
											)
										}

										{
											whatsappContactPhoneNumber && (
												<Grid item>
													<Divider orientation="horizontal" size={0.75} />

													<Typography color="textSecondary" variant="body2">
														{formatPhoneNumber(whatsappContactPhoneNumber)}
													</Typography>
												</Grid>
											)
										}
									</Grid>

									{client?.groupId ? (
										<GroupEditButton
											id={client.groupId}
											name={client.nickname}
											onSuccess={onGroupChangeSuccess}
										/>
									) : (
										<Grid item>
											<IconButton aria-describedby="popper">
												<EditIcon onClick={openClientDataDialog} />
											</IconButton>
										</Grid>
									)}
								</Grid>
							</>
						</Loading>
					</Card>
				</Grid>

				<Divider orientation="horizontal" size={2} />

				<ClientTags
					clientId={clientId}
					{...forceRemountComponentProps(clientId, "client-tags")}
				/>

				<ClientAttendantManager
					clientId={clientId}
					clientAttendantManagerId={client?.account_manager_user_id}
					loading={loading}
				/>

				<ClientNotesTable
					isExpandedNotes={false}
					clientId={clientId}
					loading={loading}
					{...forceRemountComponentProps(clientId, "client-notes")}
				/>

				<ActionDialog
					title="Alterar dados do cliente"
					onSave={() => handleEditClient()}
					saveText="SALVAR"
					onClose={() => setOpenedClientDataDialog(false)}
					loading={changingClient}
					openDialog={openedClientDataDialog}
					fullWidth
				>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="input-name"
								name="nickname"
								value={clientChangeableData.nickname}
								onChange={
									({ target }) => setClientChangeableData({ ...clientChangeableData, nickname: target.value })
								}
								variant="standard"
								label="Nome"
								fullWidth
								helperText={validation.nickname}
								error={!!validation.nickname}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={clientChangeableData.email}
								onChange={
									({ target }) => setClientChangeableData({ ...clientChangeableData, email: target.value })
								}
								variant="standard"
								label="Email"
								fullWidth
								helperText={validation.email}
								error={!!validation.email}
							/>
						</Grid>
					</Grid>
				</ActionDialog>
			</Grid>
		</AccessibleDrawer>
	), [
		loading,
		client.picture_url,
		client.name,
		client.nickname,
		openedClientDataDialog,
		JSON.stringify(clientChangeableData),
		open,
		clientId,
		changingClient
	])
}

export default ClientInfo
