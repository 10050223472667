import React from "react"

import { MediaMessageContent } from "@/components/Messages/MediaMessage"
import { TextMessageContent } from "@/components/Messages/TextMessage"
import { ContactCardMessageContent, ContactCardMessageContentFooter } from "@/components/Messages/ContactCardMessage"
import { ButtonMessageContainerFooter, ButtonMessageContentBoxStyle } from "@/components/Messages/ButtonMessage"
import { MenuMessageContentFooter, MenuMessageContentBoxStyle } from "@/components/Messages/MenuMessage"

import { IMessageExtraData, MessageType } from "@/protocols/channel"

import { isMediaMessage } from "@/utils/message"

type ChildContentInput = {
	caption?: string
	content?: string
	uploadingMedia?: boolean
	fancyBoxSlug?: string
	extraData?: IMessageExtraData
	copySpecial?: boolean
	translateVariables?: boolean
}

type ChildContentFooterInput = {
	extraData?: IMessageExtraData
}

type ChildContainerFooterInput = {
	extraData?: IMessageExtraData
	sendByCustomer?: boolean
}

export default {
	getChildContent: (type: MessageType, input: ChildContentInput): React.ReactElement => {
		const {
			caption,
			content,
			copySpecial,
			extraData,
			fancyBoxSlug,
			translateVariables,
			uploadingMedia
		} = input

		if (isMediaMessage(type)) {
			return (
				<MediaMessageContent
					caption={caption as string}
					content={content as string}
					type={type}
					uploadingMedia={uploadingMedia}
					fancyBoxSlug={String(fancyBoxSlug)}
				/>
			)
		} else if (type === "contact-card") {
			return (
				<ContactCardMessageContent
					extraData={extraData as IMessageExtraData}
				/>
			)
		} else {
			return (
				<TextMessageContent
					content={content as string}
					copySpecial={copySpecial}
					translateVariables={translateVariables}
				/>
			)
		}
	},
	getChildContentFooter: (type: MessageType, input: ChildContentFooterInput): React.ReactElement | undefined => {
		const {
			extraData
		} = input

		if (type === "contact-card") {
			return (
				<ContactCardMessageContentFooter
					extraData={extraData as IMessageExtraData}
				/>
			)
		}

		if (type === "menu") {
			return (
				<MenuMessageContentFooter
					extraData={extraData as IMessageExtraData}
				/>
			)
		}
	},
	getChildContainerFooter: (type: MessageType, input: ChildContainerFooterInput): React.ReactElement | undefined => {
		const {
			extraData,
			sendByCustomer
		} = input

		if (type === "button") {
			return (
				<ButtonMessageContainerFooter
					extraData={extraData as IMessageExtraData}
					sentByCustomer={sendByCustomer as boolean}
				/>
			)
		}
	},
	getMessageContentBoxStyle: (type: MessageType): React.CSSProperties | undefined => {
		if (type === "button") {
			return ButtonMessageContentBoxStyle
		}

		if (type === "menu") {
			return MenuMessageContentBoxStyle
		}
	}
}
