import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	title: {
		wordBreak: "break-word"
	},
	description: {
		wordBreak: "break-word",
		fontSize: 14
	},
	container: {}
})

export default useStyles
