
import React, { ReactNode } from "react"

import { Box, BoxProps } from "@material-ui/core"

import useStyles from "@/components/MessageContentBox/styles"

type MessageContentBoxProps = BoxProps & {
	children?: ReactNode
	style?: React.CSSProperties
}

const MessageContentBox = (props: MessageContentBoxProps) => {
	const { children, style, ...rest } = props

	const classes = useStyles()
	return (
		<Box
			component="span"
			className={classes.messageContent}
			style={{
				...style
			}}
			{...rest}
		>
			{children}
		</Box>
	)
}

export default MessageContentBox
