import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"
import theme from "@/styles/theme"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)",
		marginLeft: theme.spacing(3)
	},
	actions: {
		transform: "scale(1)",
		marginRight: theme.spacing(3)
	},
	title: {
		marginTop: theme.spacing(1),
		width: "100%"
	}
}))

const ClientAttendantManagerSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Grid xs={12} container alignItems="center" justifyContent="space-between" className={classes.title} spacing={2}>
				<Grid item xs>
					<Skeleton className={classes.skeleton} height={20} />
				</Grid>
				<Grid item xs={2}>
					<Skeleton variant="circle" className={classes.actions} height={25} />
				</Grid>
				<Grid item xs={2}>
					<Skeleton variant="circle" className={classes.actions} height={25} />
				</Grid>
			</Grid>

			<Grid xs={12} container alignItems="center" justifyContent="space-between" className={classes.title} spacing={2}>
				<Grid item xs>
					<Skeleton className={classes.skeleton} style={{ marginRight: theme.spacing(3) }} height={20} />
				</Grid>
			</Grid>
		</>
	)
}

export default ClientAttendantManagerSkeleton
