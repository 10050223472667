import { makeStyles } from "@material-ui/core"

export const BUTTON_MESSAGE_WIDTH = 336

const useStyles = makeStyles({
	descriptionContainer: {
		flex: 1
	},
	button: {
		pointerEvents: "none",
		boxShadow: "0px 1px 1px rgb(0 0 0 / 25%)",
		borderRadius: "8px",
		fontWeight: "normal",
		color: "#007CBA",
		fontSize: 14,
		textTransform: "none"
	}
})

export default useStyles
