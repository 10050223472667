import React from "react"
import { Typography } from "@material-ui/core"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/NoConditionText/styles"

type NoConditionTextProps = {
	text?: string
}

const NoConditionText: React.FC<NoConditionTextProps> = (props) => {
	const {
		text
	} = props

	const classes = useStyles()

	return (
		<Typography
			variant="overline"
			color="textPrimary"
			align="left"
			className={classes.noConditionText}
		>
			{text || "Nenhuma condição para seguir o fluxo"}
		</Typography>
	)
}

export default NoConditionText
