import React from "react"
import { Chip } from "@material-ui/core"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/CounterChip/styles"

type CounterChipSize = "small" | "medium" | "large"

type CounterChipProps = {
	active: boolean
	counter: number
	backgroundColor: string
	fontColor: string
	size: CounterChipSize
}

const CounterChip: React.FC<CounterChipProps> = (props) => {
	const {
		active,
		counter,
		backgroundColor,
		fontColor,
		size
	} = props

	const getActualSize = () => {
		const sizeMap: { [key in CounterChipSize]: number } = {
			small: 20,
			medium: 24,
			large: 30
		}

		const actualSize = sizeMap[size]

		return actualSize
	}

	const classes = useStyles({
		backgroundColor,
		fontColor,
		size: getActualSize()
	})

	return (
		<Chip
			label={counter > 0 ? counter : ""}
			className={classes.counterChip}
			style={{
				opacity: active ? 1 : 0
			}}
		/>
	)
}

export default CounterChip
