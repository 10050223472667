import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	paper: {
		width: "100%"
	},
	clientName: {
		paddingLeft: theme.spacing(0.5)
	}
}))

export default useStyles
