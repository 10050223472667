import React from "react"

import useStyles from "@/components/ChatDivider/styles"
import useCustomStyles from "@/styles/custom"

import { Typography, Grid } from "@material-ui/core"
import { Divider } from "@/components"

type ChatDividerProps = {
	summary: string
	chatCount: number
}

const ChatDivider: React.FC<ChatDividerProps> = (props) => {
	const {
		summary,
		chatCount
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	return (
		<>
			<Grid
				container
				justify="space-between"
				alignItems="center"
				className={classes.grayBox}
			>
				<Typography
					variant="caption"
					color="textPrimary"
					display="inline"
					className={`${classes.dividerSummary} ${customClasses.inlineText}`}
				>
					{summary}
				</Typography>

				<Grid
					item
				>
					<Typography
						display="inline"
						color="textPrimary"
						variant="caption"
					>
						{chatCount === 0 ? "Nenhuma conversa" : `${chatCount} ${chatCount === 1 ? "conversa" : "conversas"}`}
					</Typography>
				</Grid>

			</Grid>
			<Divider orientation="horizontal" size={0.15} />
		</>
	)
}

export default ChatDivider
