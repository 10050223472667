
import React, { useState } from "react"
import {
	Grid,
	Switch,
	TextField,
	Typography
} from "@material-ui/core"

import { Portlet } from "@/components"

import useValidation from "@/hooks/useValidation"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"

import ErrorHandlerService from "@/services/ErrorHandler"

const DisconnectedNotificationSettings: React.FC = () => {
	const [status, setStatus] = useState(false)
	const [phoneNumber, setPhoneNumber] = useState("")

	const {
		triggerValidation
	} = useValidation()

	const getNotificationSettings = async () => {
		try {
			const { data } = await ApiService.get("/notification-settings/DISCONNECTED")

			setStatus(data?.notificationSettings?.is_active)
			setPhoneNumber(data?.notificationSettings?.target_value)
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
	}

	const handleUpdateSettings = async (isActive: boolean, targetValue: string) => {
		const payload = {
			isActive,
			targetValue
		}

		try {
			await ApiService.post("/notification-settings/DISCONNECTED", payload)
		} catch (error) {
			ErrorHandlerService.handle(error)
			triggerValidation(error)
		}
	}

	const handleChangeStatus = () => {
		setStatus(!status)
		handleUpdateSettings(!status, phoneNumber)
	}

	const handleChangePhoneNumber = (value: string) => {
		setPhoneNumber(value)
		handleUpdateSettings(status, value)
	}

	useDidMount(() => {
		getNotificationSettings()
	})

	return (
		<Portlet elevation={1}>
			<Grid container spacing={2} alignItems="center">
				<Grid item>
					<Typography variant="h2" color="textPrimary">
						Notificar quando o WhatsApp desconectar
					</Typography>
				</Grid>

				<Grid item>
					<Switch
						checked={status}
						onChange={handleChangeStatus}
						name="checkedA"
					/>
				</Grid>

				<Grid item>
					<TextField
						disabled={!status}
						placeholder="Número do WhatsApp"
						value={phoneNumber}
						variant="outlined"
						onChange={(event) => handleChangePhoneNumber(event.target.value)}
					/>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default DisconnectedNotificationSettings
