import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

import { Portlet, Divider } from "@/components"

const useStyles = makeStyles({
	container: {
		height: 70
	}
})

const ChatBotListSkeleton = () => {
	const classes = useStyles()

	const exampleArray = Array.from(Array(5).keys())

	return (
		<Grid
			container
			direction="column"
			spacing={2}
		>
			<Skeleton variant="text" width={200} height={75} />

			<Skeleton variant="text" width={400} height={75} />

			<Skeleton variant="text" width={150} height={50} />

			<Divider size={6} orientation="horizontal" />

			<Skeleton variant="text" width={150} height={40} />
			<Skeleton variant="text" width={250} height={50} />

			<Divider size={5} orientation="horizontal" />

			{
				exampleArray.map((value, index) => (
					<>
						<Portlet
							elevation={1}
						>
							<Grid
								container
								alignItems="center"
								justify="space-between"
								className={classes.container}
							>
								<Grid item xs={1}>
									<Skeleton variant="rect" width={40} height={40} />
								</Grid>

								<Grid item xs={4}>
									<Skeleton variant="text" width={200} height={50} />
								</Grid>

								<Grid item xs={2}>
									<Skeleton variant="rect" width={100} height={30} />
								</Grid>

								<Grid item xs={4}>
									<Skeleton variant="text" width={300} height={50} />
								</Grid>

								<Grid item xs={1}>
									<Skeleton variant="rect" width={64} height={64} />
								</Grid>
							</Grid>
						</Portlet>

						<Divider size={2} orientation="horizontal" />
					</>
				))
			}
		</Grid>
	)
}

export default ChatBotListSkeleton
