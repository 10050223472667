import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	assignAttendanceIcon: {
		transform: "scaleX(-1)"
	},
	filterInput: {
		backgroundColor: colors.grayScale[11],
		"& fieldset": {
			borderRadius: 3
		}
	},
	textareaLineNumbers: {
		"& > div": {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2)
		}
	}
}))

export default useStyles
