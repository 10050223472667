import React, { Dispatch, SetStateAction, useState } from "react"
import FileDownload from "js-file-download"
import { Link } from "react-router-dom"
import {
	Button,
	CircularProgress,
	Grid
} from "@material-ui/core"
import {
	PopConfirm
} from "@/components"
import { ClientCatalogPagination } from "@/protocols/clientCatalog"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import AddClientDialog from "@/components/AddClientDialog"
import CustomFieldsDialog from "@/pages/Admin/CustomFields"
import { ClientCatalogWhereData } from "@/pages/Admin/NewClientCatalog"
import { ContactsFiltersType } from "@/pages/Admin/NewClientCatalog/ClientManageFilters/ContactsFilters"
import { Add } from "@material-ui/icons"
import useStyles from "@/pages/Admin/NewClientCatalog/ClientManageContactsHeader/styles"
import DonwloadFileIcon from "@/assets/icons/donwload_file_icon.svg"
import UploadFileIcon from "@/assets/icons/upload_file_icon.svg"
type CatalogHeaderProps = {
	setClientCatalog: Dispatch<SetStateAction<ClientCatalogPagination>>
	selectedContactsFilter: ContactsFiltersType
}

const ClientManageContactsHeader: React.FC<CatalogHeaderProps> = ({ selectedContactsFilter, setClientCatalog }) => {
	const [exportingContacts, setExportingClients] = useState(false)
	const [whereData, setWhereData] = useState<ClientCatalogWhereData>({
		page: 0,
		rowsPerPage: 20
	})
	const { triggerValidation } = useValidation()

	const isSelectedContactsFilterNotEmpty = Object.keys(selectedContactsFilter).length !== 0

	const getAndUpdateClientCatalogData = async (newWhereData?: Partial<ClientCatalogWhereData>) => {
		try {
			const whereParams = newWhereData

			setWhereData({
				...whereData,
				...whereParams
			})

			const { data } = await ApiService.get("/clients", {
				params: {
					...whereData,
					...whereParams,
					contactType: "user"
				}
			})
			setClientCatalog({
				clientCatalog: data.clientCatalog,
				count: data.count,
				rows: data.rows
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
			ErrorHandlerService.handle(error as ErrorType)
		}
	}

	const handleExportClients = async () => {
		PopConfirm.open({
			title: "Deseja realmente exportar seus contatos?",
			onConfirm: async () => {
				setExportingClients(true)
				let newWhereData: Partial<ClientCatalogWhereData> = {}

				if (isSelectedContactsFilterNotEmpty) {
					newWhereData = {
						contactsFilters: selectedContactsFilter.conditions
					}
				}

				try {
					const response = await ApiService.get("/clients/exportation/export", {
						responseType: "blob",
						params: {
							...whereData,
							...newWhereData
						}
					})
					FileDownload(response.data, "contatos.csv")
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
				setExportingClients(false)
			},
			confirmButtonText: "EXPORTAR",
			cancelButtonText: "FECHAR"

		})
	}
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Grid item>
				<AddClientDialog
					nickname={whereData.search}
					onAdd={getAndUpdateClientCatalogData}
				>
					<Button
						variant="contained"
						className={classes.buttonPrimaryColorContained}
						startIcon={<Add />}
					>
						Adicionar contato
					</Button>
				</AddClientDialog>
			</Grid>
			<Grid item>
				<Link to="client-catalog/import-contacts" style={{ textDecoration: "none" }}>
					<Button
						variant="contained"
						className={classes.buttonPrimaryColorOutLined}
						startIcon={<img src={DonwloadFileIcon} alt="donwload icon" />}
					>
						Importar contatos
					</Button>
				</Link>
			</Grid>

			<Grid item>
				<Button
					variant="contained"
					className={classes.buttonPrimaryColorOutLined}
					onClick={handleExportClients}
					startIcon={<img src={UploadFileIcon} alt="upload icon" />}
					endIcon={exportingContacts && <CircularProgress size={20} />}
				>
					Exportar contatos
				</Button>
			</Grid>
			<Grid item>
				<CustomFieldsDialog
				>
					<Button
						variant="contained"
						className={classes.buttonPrimaryColorOutLined}
						startIcon={<Add />}
					>
						Adicionar campos personalizados
					</Button>
				</CustomFieldsDialog>
			</Grid>
		</Grid>
	)
}

export default ClientManageContactsHeader
