import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Divider, Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	}
})

const ProductMessageMetricsSkeleton = () => {
	const classes = useStyles()

	const statusCardKeys = [0, 1]

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{statusCardKeys.map(key => (
						<Grid
							key={key}
							item
						>
							<Grid
								container
							>
								<Portlet
									style={{
										width: 200
									}}
								>
									<Grid container direction="column" spacing={1}>
										<Grid item>
											<Skeleton className={classes.skeleton} height={28} width={100} />
										</Grid>

										<Divider orientation="horizontal" size={1} />

										<Grid item>
											<Skeleton className={classes.skeleton} height={150} width="100%" />
										</Grid>
									</Grid>
								</Portlet>
							</Grid>
						</Grid>
					))}
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container>
					<Portlet>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<Skeleton className={classes.skeleton} height={28} width={100} />
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid item>
								<Skeleton className={classes.skeleton} height={160} width="100%" />
							</Grid>
						</Grid>
					</Portlet>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Grid container>
					<Portlet>
						<Grid container direction="column" spacing={1}>
							<Grid item>
								<Skeleton className={classes.skeleton} height={28} width={100} />
							</Grid>

							<Divider orientation="horizontal" size={1} />

							<Grid item>
								<Skeleton className={classes.skeleton} height={90} width="100%" />
							</Grid>
						</Grid>
					</Portlet>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ProductMessageMetricsSkeleton
