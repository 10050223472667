type GetFilledCircleWithTextInsideProps = {
	fontColor: string
	circleColor: string
	text: string
}

export const getFilledCircleWithTextInside = (props: GetFilledCircleWithTextInsideProps): string => {
	const { circleColor, fontColor, text } = props

	const svgNamespace = "http://www.w3.org/2000/svg"
	const radius = 100
	const size = Math.round(radius * 2)
	const fontSize = 130

	const svgElement = document.createElementNS(svgNamespace, "svg")
	svgElement.setAttribute("width", `${size}`)
	svgElement.setAttribute("height", `${size}`)
	svgElement.setAttribute("viewBox", `0 0 ${size} ${size}`)

	const circleElement = document.createElementNS(svgNamespace, "circle")
	circleElement.setAttribute("fill", `${circleColor}`)
	circleElement.setAttribute("cx", `${radius}`)
	circleElement.setAttribute("cy", `${radius}`)
	circleElement.setAttribute("r", `${radius}`)

	svgElement.appendChild(circleElement)

	const textElement = document.createElementNS(svgNamespace, "text")
	textElement.textContent = text
	textElement.setAttribute("x", `${radius}`)
	textElement.setAttribute("y", `${radius}`)
	textElement.setAttribute("fill", `${fontColor}`)
	textElement.setAttribute("font-size", `${fontSize}`)
	textElement.setAttribute("style", "text-anchor: middle; dominant-baseline: middle; font-weight: bolder")

	svgElement.appendChild(textElement)

	const serializer = new XMLSerializer()

	const serializedSvg = serializer.serializeToString(svgElement)

	const base64Svg = btoa(serializedSvg)

	const filledCircleWithTextInsideInBase64 = `data:image/svg+xml;base64,${base64Svg}`

	return filledCircleWithTextInsideInBase64
}
