import React, { useEffect, useRef, useState } from "react"
import {
	Grid,
	List,
	ListItem,
	Typography,
	InputAdornment,
	Input,
	Tooltip,
	IconButton
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Assistant as QuickReplyIcon
} from "@material-ui/icons"

import { Divider, AccessibleDrawer, Notification } from "@/components"

import { BuildedMessage, IQuickReply } from "@/protocols/messages"

import { KeyPressedProps } from "@/hooks/useKeyPressed"
import useAutocomplete, { createFilterOptions } from "@material-ui/lab/useAutocomplete"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/QuickReply/styles"
import useChatStyles from "@/pages/Attendance/Chat/styles"
import useCustomStyles from "@/styles/custom"
import useAsyncState from "@/hooks/useAsyncState"

import { deviceIsMobile } from "@/utils/checkDevice"
import { runAfterReactRender } from "@/utils/node"

import HeaderDrawer from "@/pages/Attendance/Chat/HeaderDrawer"

import MessageItem from "@/components/ChatMessageBuilder/MessageItem"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { hasVariable, getMessageColor } from "@/utils/message"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { ErrorType } from "@/hooks/useValidation"

type QuickReplyProps = {
	quickReplies: IQuickReply[],
	shortcutEnabled: boolean
	onSelect: (message: BuildedMessage) => void
	onClose?: () => void
	disabled?: boolean
	substituteVariables?: boolean
	withoutTriggerHistoryBackEvent?: boolean
}

const QuickReply: React.FC<QuickReplyProps> = (props) => {
	const {
		quickReplies,
		onSelect,
		onClose,
		shortcutEnabled,
		disabled,
		substituteVariables = false,
		withoutTriggerHistoryBackEvent = false
	} = props

	const searchRef = useRef<HTMLInputElement>(null)
	const [search, setSearch] = useState(null)
	const [quickReplyFocusedId, setQuickReplyFocusedId] = useState<number | null>(null)
	const quickReplyFocused = quickReplies.find(({ id }) => id === quickReplyFocusedId) as IQuickReply
	const chatGlobalStateStore = useChatGlobalStateStore()

	const [opened, setOpened] = useAsyncState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const chatClasses = useChatStyles()

	const orderedQuickReply = Object.values(quickReplies)
		.sort((replyA: IQuickReply, replyB: IQuickReply) => replyA.shortcut_name.toLowerCase() < replyB.shortcut_name.toLowerCase() ? -1 : 1)

	const handleClose = () => {
		setSearch(null)
		setOpened(() => false)

		onClose && onClose()
	}

	const getQuickReplyMessage = (quickReply: IQuickReply): BuildedMessage => {
		const message = quickReply?.content?.messages?.[0] as BuildedMessage

		return message
	}

	const handleSelectQuickReply = (message: BuildedMessage) => {
		onSelect(message)

		handleClose()
	}

	const filterOptions = createFilterOptions({
		stringify: (option: IQuickReply) => option.shortcut_name + option.message
	})

	const replaceVariables = async (content: string): Promise<string> => {
		const currentClientId = chatGlobalStateStore.client.current?.id

		const response = await ApiService.post("/quick-reply/variables", {
			clientId: currentClientId,
			message: content
		})
		return response.data.message
	}

	const onQuickReplySelect = async (quickReply: IQuickReply) => {
		setSearch(null)

		const message = getQuickReplyMessage(quickReply)
		let messageWithVariablesReplaced = message

		if (substituteVariables && message.type === "text" && hasVariable(message.content)) {
			try {
				messageWithVariablesReplaced = {
					...message,
					content: await replaceVariables(message.content)
				}
			} catch (err) {
				ErrorHandlerService.handle(err as ErrorType)
				Notification.error({ message: "Não foi possível substituir as variáveis da mensagem." })
			}
		}

		if (messageWithVariablesReplaced) {
			handleSelectQuickReply(messageWithVariablesReplaced)
		}
	}

	const {
		getRootProps,
		getInputLabelProps,
		getInputProps,
		getListboxProps,
		getOptionProps,
		groupedOptions
	} = useAutocomplete({
		id: "quick-reply-autocomplete",
		options: orderedQuickReply,
		open: true,
		freeSolo: true,
		clearOnEscape: true,
		selectOnFocus: true,
		clearOnBlur: true,
		autoHighlight: true,
		value: quickReplyFocused,
		getOptionLabel: (option) => option.shortcut_name,
		filterOptions,
		onHighlightChange: (e, option) => {
			if (option?.id) {
				setQuickReplyFocusedId(option.id)
			}
		},
		onClose: (_, reason) => reason === "select-option" && onQuickReplySelect(quickReplyFocused),
		blurOnSelect: false
	})

	const focusOnInput = () => {
		if (opened.current) {
			runAfterReactRender(() => {
				searchRef.current?.focus()
			})
		}
	}

	const eventKeyDownHandler = (event: KeyboardEvent) => {
		const { key } = event

		if (key === "Escape") {
			handleClose()
		}
	}

	const handleOpen = () => {
		setOpened(() => true)
	}

	const addShortcutListener = () => {
		const keysPressed: KeyPressedProps = {}

		document.addEventListener("keydown", (event: KeyboardEvent) => {
			if (shortcutEnabled) {
				keysPressed[event.key] = true

				const quickReplyShortcut = keysPressed.Control && event.key === "'"

				if (quickReplyShortcut) {
					setOpened((lastState) => !lastState)
				}
			}
		})

		document.addEventListener("keyup", (event: KeyboardEvent) => {
			delete keysPressed[event.key]
		})
	}

	useEffect(() => {
		focusOnInput()
		// eslint-disable-next-line
	}, [opened.current])

	useDidMount(() => {
		window.addEventListener(
			"keydown",
			eventKeyDownHandler,
			{ capture: true }
		)

		addShortcutListener()
	})

	return (
		<>
			<AccessibleDrawer
				id="quick-reply-drawer"
				anchor="left"
				open={opened.current}
				variant="persistent"
				onClose={handleClose}
				onMobileBackButtonPress={handleClose}
				withoutTriggerHistoryBackEvent={withoutTriggerHistoryBackEvent}
				classes={{
					paper: chatClasses.drawerPaper
				}}
			>
				<Grid container>
					<Grid item xs={12}>
						<HeaderDrawer
							title="Respostas Rápidas"
							onClose={handleClose}
						/>
					</Grid>

					<Grid item xs={12}>
						<Grid container {...getRootProps()}>
							<Grid item xs={12} className={classes.searchInputContainer}>
								<Input
									{...getInputProps()}
									inputRef={searchRef}
									value={search}
									startAdornment={
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									}
									fullWidth
									className={classes.searchInput}
								/>
							</Grid>

							<Grid item xs={12}>
								{groupedOptions.length ? (
									<List
										{...getListboxProps()}
										className={`${classes.quickReplyListContent} ${customClasses.scrollBar}`}
									>
										{groupedOptions
											.map((quickReply, index) => (
												<ListItem
													{...getOptionProps({ option: quickReply, index })}
													key={index}
													className={classes.quickReplyItem}
												>
													<Grid
														container
													>
														<Grid
															item
															xs={12}
														>
															<Typography
																{...getInputLabelProps()}
																variant="caption"
																className={classes.shortcutNameText}
															>
																{quickReply.shortcut_name}
															</Typography>
														</Grid>

														<Divider size={1} orientation="horizontal" />

														<Grid
															item
															xs={12}
														>
															<Grid
																container
																className={classes.quickReplyItemContainer}
															>
																<MessageItem
																	type={getQuickReplyMessage(quickReply)?.type}
																	content={getQuickReplyMessage(quickReply)?.content}
																	mediaName={getQuickReplyMessage(quickReply)?.mediaName}
																	style={{
																		backgroundColor: (deviceIsMobile() || quickReplyFocusedId === quickReply.id) ? (
																			getMessageColor(true)
																		) : (
																			getMessageColor(false)
																		)
																	}}
																/>
															</Grid>
														</Grid>
													</Grid>
												</ListItem>
											))}
									</List>
								)
									: <>
										<Divider size={3} orientation="horizontal" />
										<Typography align="center" variant="h2">
											Nenhuma resposta rápida encontrada
										</Typography>
									</>
								}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</AccessibleDrawer>

			<Grid
				onClick={handleOpen}
			>
				<Tooltip
					title="Respostas rápidas - Atalho Ctrl + '"
				>
					<IconButton
						disabled={disabled}
					>
						<QuickReplyIcon />
					</IconButton>
				</Tooltip>
			</Grid>
		</>
	)
}

export default QuickReply
