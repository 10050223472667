import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	plotHint: {
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(1, 2),
		boxShadow: theme.shadows[1],
		borderRadius: theme.spacing(0.5)
	}
}))

export default useStyles
