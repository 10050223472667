import React from "react"

import { Media } from "@/services/Media"

import { beautifyBytes } from "@/utils/format"

import { ActionDialog } from "@/components"

class MediaValidation {
	private maxVideoSizeInMegabytes = 20

	async validateMedia (media: Media, onSuccess: () => Promise<void> | void): Promise<void> {
		const isVideoMedia = media.type === "video"
		const hasValidVideoSize = this.hasValidSize(media.data.size)

		if (isVideoMedia && !hasValidVideoSize) {
			ActionDialog.open({
				title: "Vídeo muito grande",
				hideCloseButton: true,
				openDialog: true,
				children: (
					<>
						O vídeo que você está tentando usar pesa <b>{beautifyBytes(media.data.size, 0)}</b>,
						{" "}
						porém só é possível enviar vídeos menores que <b>{this.maxVideoSizeInMegabytes}MB</b>.
					</>
				)
			})
		} else {
			await onSuccess()
		}
	}

	private hasValidSize (sizeInBytes: number): boolean {
		const sizeInMegabytes = sizeInBytes / 1024 / 1024

		const hasValidSize = sizeInMegabytes <= this.maxVideoSizeInMegabytes

		return hasValidSize
	}
}

export default new MediaValidation()
