import { useRef } from "react"

import useDidMount from "@/hooks/useDidMount"

const useActiveWindowListener = () => {
	const focusedRef = useRef<boolean>(true)

	const addListeners = () => {
		window.addEventListener("focus", () => {
			focusedRef.current = true
		})

		window.addEventListener("blur", () => {
			focusedRef.current = false
		})
	}

	useDidMount(() => {
		addListeners()
	})

	return {
		get active (): boolean {
			return focusedRef.current
		}
	}
}

export default useActiveWindowListener
