import { Grid } from "@material-ui/core"
import React, { useEffect, useRef } from "react"
import ActionDialog from "@/components/ActionDialog"
import useStyles from "@/components/MerlinDialog/styles"

type MerlinDialogProps = {
	title: string
	onClose?: () => void
	onMerlinInit?: () => void
	cancelText?: string
	openDialog?: boolean
	hideCloseButton?: boolean
	hideCloseIcon?: boolean
	loading?: boolean
	fullWidth?: boolean
	merlinUrl: string
	initVariables?: Record<string, unknown>
	onFormAnswered?: () => void
	preventCloseOnCancelButtonClick?: boolean
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
}

const MerlinDialog: React.FC<MerlinDialogProps> = (props) => {
	const {
		title,
		openDialog,
		loading,
		fullWidth,
		onClose,
		onMerlinInit,
		hideCloseButton,
		hideCloseIcon,
		cancelText,
		merlinUrl,
		onFormAnswered,
		initVariables = {},
		preventCloseOnCancelButtonClick,
		maxWidth
	} = props

	const classes = useStyles()

	const isMerlinAlreadyLoaded = useRef(false)
	const getUrlFormattedInitParams = () => {
		const urlParams = new URLSearchParams()
		for (const key in initVariables) {
			const value = initVariables[key]
			urlParams.append(key, String(value))
		}
		return urlParams.toString()
	}

	/**
	 * Cannot call getUrlFormattedInitParams directly from useEffect, warnings triggered
	 */
	const urlParams = getUrlFormattedInitParams()

	window.addEventListener("message", (event) => {
		/**
		 * This is the way to recieve data from Merlin
		 * https://gomerlin.notion.site/Enviando-eventos-e-convers-es-487fcb5639be497385e4a9eaf6fdef3a
		 */
		const eventId = String(event.data.id)

		if (eventId === "form_answered") {
			onFormAnswered?.()
		}

		if (eventId === "clear_url_params" && openDialog) {
			/**
			 * Clearing browser params must be triggered by Merlin
			 * If params are cleared right afer "Merlin?.Container?.initFromSource" the params are not passed
			 */
			window.history.replaceState({}, "", window.location.pathname)
		}
	})

	useEffect(() => {
		const canInitMerlin = !isMerlinAlreadyLoaded.current && openDialog

		if (canInitMerlin) {
			const Merlin = window?.Merlin

			/**
			 * Variables cleared before inserting new ones to avoid injection
			 */
			window.history.replaceState({}, "", window.location.pathname)
			/**
			 * Variables need to be passed by letalk's url
			 * https://gomerlin.notion.site/Passar-vari-vel-para-o-Merlin-0047e05cbf2e4332af1d233896ba160d
			 */
			window.history.replaceState({}, "", `?${urlParams}`)
			Merlin?.Container?.initFromSource(merlinUrl, "#merlin-container")
			onMerlinInit?.()

			isMerlinAlreadyLoaded.current = true
		}
	}, [openDialog, merlinUrl, urlParams, onMerlinInit])

	return (
		<ActionDialog
			title={title}
			onClose={() => {
				onClose?.()
				isMerlinAlreadyLoaded.current = false
			}}
			openDialog={openDialog}
			fullWidth={fullWidth}
			loading={loading}
			hideCloseButton={hideCloseButton}
			hideCloseIcon={hideCloseIcon}
			cancelText={cancelText}
			preventCloseOnCancelButtonClick={preventCloseOnCancelButtonClick}
			maxWidth={maxWidth}
		>
			<Grid container>
				<Grid item xs={12}>
					<div id="merlin-container" className={classes.merlinContainer}>
					</div>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default MerlinDialog
