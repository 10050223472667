export const InvalidGroupInfoMessages: Record<string, string> = {
	default: "Grupo inválido.",
	GroupNotExists: "Esse grupo não existe mais no whatsapp conectado na Letalk.",
	NoPermissionToSendMessageToGroup: "Esse grupo está no modo leitura no whatsapp conectado na Letalk.",
	NotMemberOfGroup: "Você não é mais membro desse grupo no whatsapp conectado na Letalk."
}

export const InvalidGroupEditMessages: Record<string, string> = {
	default: "Algo deu errado ao modificar esse grupo.",
	GroupNotExists: "Esse grupo não existe mais no whatsapp conectado na Letalk.",
	LacksPermission: "Você não possui permissão para editar as informações desse grupo que está no whatsapp conectado na Letalk.",
	GroupNameCanNotBeEmpty: "O nome do grupo deve ser preenchido.",
	GroupNameMustRespectMaxLength: "O nome do grupo só pode ter no máximo 25 caracteres."
}
