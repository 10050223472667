import { Box, Tab, Tabs } from "@material-ui/core"
import React from "react"
import color from "@/styles/colors"

type ClientTabNavigationProps = {
	onChange: (value: string) => void
	value: string
	options: Array<string>
}
const ClientTabNavigation: React.FC<ClientTabNavigationProps> = ({ value, onChange, options }) => {
	const handleChange = (event: React.ChangeEvent<unknown>, newValue: string) => {
		onChange(newValue)
	}
	return (<Box sx={{ width: "100%" }}>
		<Tabs
			value={value}
			onChange={handleChange}
			TabIndicatorProps={{
				style: { background: color.palette.primary }
			}}
		>
			{options.map((value) => {
				return (
					<Tab key={value} value={value} label={value} />
				)
			})}
		</Tabs>
	</Box >)
}
export default ClientTabNavigation
