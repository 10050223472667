import React, { useEffect } from "react"
import {
	Grid
} from "@material-ui/core"
import { useHistory } from "react-router-dom"

import Notification from "@/components/Notification"

import ChatGlobalStateProvider, { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Attendance/Chat/styles"
import useDidMount from "@/hooks/useDidMount"
import useSocket from "@/hooks/useSocket"
import useThrottledSound from "@/hooks/useThrottledSound"
import useActiveWindowListener from "@/hooks/useActiveWindowListener"
import useChat from "@/hooks/useChat"
import useSettings from "@/hooks/useSettings"
import useQuery from "@/hooks/useQuery"

import { getPageFavicon, changePageFavicon } from "@/utils/node"
import { getFilledCircleWithTextInside } from "@/utils/image"

import ChatListPanel from "@/pages/Attendance/Chat/ChatListPanel"
import ConversationPanel from "@/pages/Attendance/Chat/ConversationPanel"

import newMessageSound from "@/assets/sounds/new-message.mp3"

const initialPageTitle = document.title
const initialPageFavicon = getPageFavicon()?.href || ""

const Chat: React.FC = () => {
	const socket = useSocket()
	const notificationThrottledSound = useThrottledSound(newMessageSound)
	const chatMethods = useChat()
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const globalStateStore = useGlobalStateStore()
	const activeWindowListener = useActiveWindowListener()
	const { userSettings } = useSettings()
	const query = useQuery()
	const history = useHistory()

	const unreadChats = chatGlobalStateStore.chat.list.filter(chat => chat.unreadMessagesCount)
	const currentNewUnreadChats = unreadChats.filter(chat => chatMethods.isQueueChat(chat))
	const totalUnreadChats = currentNewUnreadChats.length

	const makeVisualNotification = () => {
		const newTitle = `(${totalUnreadChats}) ${initialPageTitle}`

		const isTitleAlreadySet = newTitle === initialPageTitle

		if (totalUnreadChats && !isTitleAlreadySet) {
			const newFavicon = getFilledCircleWithTextInside({
				circleColor: "#000000",
				fontColor: "#FFFFFF",
				text: `${totalUnreadChats}`
			})

			document.title = newTitle
			changePageFavicon(newFavicon)
		} else {
			document.title = initialPageTitle
			changePageFavicon(initialPageFavicon)
		}
	}

	const makeAudioNotification = () => {
		notificationThrottledSound.playSound(3000)
	}

	const setupSocket = async () => {
		socket.onMessageStatusChanged(async updatedMessage => {
			chatGlobalStateStore.message.updateById(updatedMessage.id, {
				status: updatedMessage.status,
				...(updatedMessage.status === "sent" && { createdAt: updatedMessage.createdAt }),
				...(Boolean(updatedMessage.content) && { content: updatedMessage.content })
			})
		})

		socket.onMessageDeleted(async message => {
			chatGlobalStateStore.message.updateById(message.id, {
				content: "",
				deletedAt: String(new Date())
			})
		})

		socket.onClientDataChanged(async client => {
			chatGlobalStateStore.client.updateById(client.id, {
				name: client.name
			})
		})

		socket.onNewChat(async newChat => {
			const isChatWithNoAttendant = newChat.attendance?.status !== "active"

			const isChatWithAttendant = newChat.attendance?.status === "active"
			const isChatAttendanceOwnedByCurrentUser = newChat.attendance?.userId === globalStateStore?.user?.id
			const isChatWithAttendantThatCanBeShown = isChatWithAttendant && isChatAttendanceOwnedByCurrentUser

			const canSaveChat = isChatWithNoAttendant || isChatWithAttendantThatCanBeShown

			if (canSaveChat) {
				chatGlobalStateStore.chat.add(newChat)
			}
		})

		socket.onNewAssignedChat(async newChat => {
			const isChatWithNoAttendant = newChat.attendance?.status !== "active"

			const isChatAttendanceAssignedToCurrentUser = newChat.attendance?.assignUserId === globalStateStore?.user?.id
			const isChatAttendanceAssignedToUserTeam = globalStateStore?.user?.valid_teams.includes(Number(newChat?.attendance?.assignTeamId))
			const isChatWithAttendantThatCanBeShown = isChatWithNoAttendant && (isChatAttendanceAssignedToCurrentUser || isChatAttendanceAssignedToUserTeam)

			if (isChatWithAttendantThatCanBeShown) {
				chatGlobalStateStore.chat.add(newChat)
			}
		})

		socket.onNewClient(async newClient => {
			chatGlobalStateStore.client.setup([newClient])
		})

		socket.onChatAttendanceTaken(attendance => {
			const isAttendanceOwnedByCurrentUser = attendance?.userId === globalStateStore?.user?.id
			const isThisAttendanceOpened = attendance.inboxChannelChatId === chatGlobalStateStore.chat.current?.id

			if (isAttendanceOwnedByCurrentUser) {
				chatGlobalStateStore.chat.updateById(attendance.inboxChannelChatId, {
					status: "on-going",
					unreadMessagesCount: 0,
					attendance: {
						assignUserId: attendance?.assignUserId,
						assignTeamId: attendance?.assignTeamId,
						userName: globalStateStore?.user?.name,
						createdAt: String(new Date()),
						status: "active"
					}
				})
			} else {
				if (isThisAttendanceOpened) {
					chatGlobalStateStore.chat.close()
					Notification.warning({
						message: `O atendente ${attendance.userName} assumiu esse atendimento.`
					})
				}
				chatGlobalStateStore.chat.removeById(attendance.inboxChannelChatId)
			}
		})

		socket.onChatAttendanceAssigned(attendance => {
			const isAttendanceAssignedToCurrentUser = attendance?.assignUserId === globalStateStore?.user?.id
			const isAttendanceAssignedToCurrentUserTeam = globalStateStore?.user?.valid_teams.includes(Number(attendance?.assignTeamId))

			const isCurrentUserResponsability = (isAttendanceAssignedToCurrentUser || isAttendanceAssignedToCurrentUserTeam)

			if (!isCurrentUserResponsability) {
				chatGlobalStateStore.chat.removeById(attendance.inboxChannelChatId)
				const isActualOpenedChat = attendance.inboxChannelChatId === chatGlobalStateStore.chat.currentOpenedId
				if (isActualOpenedChat) {
					chatGlobalStateStore.chat.close()
				}
			}
		})

		socket.onChatAttendanceFinished(attendance => {
			chatGlobalStateStore.chat.updateById(attendance.inboxChannelChatId, {
				status: "archived",
				attendance: {
					userName: "",
					assignUserId: undefined,
					assignTeamId: undefined,
					assignmentQueueType: undefined,
					status: "finished"
				}
			})
		})

		socket.onNewChatAttendanceNotification(notification => {
			chatGlobalStateStore.message.add(notification)
		})

		socket.onNewMessage(async message => {
			if (!message.sentByCustomer) {
				chatGlobalStateStore.chat.incrementUnreadMessagesCountById(message.inboxChannelChatId, +1)
			}

			await chatGlobalStateStore.message.add(message)

			const isMessageFromOpenedChat = message.inboxChannelChatId === chatGlobalStateStore.chat.currentOpenedId
			const isMessageSentByCustomer = message.sentByCustomer
			const messageBelongsToFilteredChat = chatGlobalStateStore.chat.filteredChatIds.list.includes(message.inboxChannelChatId)

			if (isMessageFromOpenedChat) {
				chatGlobalStateStore.message.setReadByChatId(message.inboxChannelChatId)
			}

			const isNotActiveWindowMessageNotification = !isMessageSentByCustomer && isMessageFromOpenedChat && !activeWindowListener.active
			const isFilteredChatMessageNotification = !isMessageSentByCustomer && !isMessageFromOpenedChat && messageBelongsToFilteredChat

			const actualUserSettings = userSettings.getUserSettings()
			const recieveSoundNotification = actualUserSettings?.notifications?.soundNotification

			const chat = chatGlobalStateStore.chat.getById(message.inboxChannelChatId)

			const isChatBotAttendance = chat.status === "chat-bot"

			const notifyByAudio = recieveSoundNotification && (isNotActiveWindowMessageNotification || isFilteredChatMessageNotification) && !isChatBotAttendance

			if (notifyByAudio) {
				makeAudioNotification()
			}
		})
	}

	const setupInitialChatPhoneSearch = async (): Promise<string | null> => {
		const phoneQueryParamKey = "phone"

		const phoneSearch = query.get(phoneQueryParamKey)

		const isValidPhoneSearch = !isNaN(Number(phoneSearch))

		if (phoneSearch && isValidPhoneSearch) {
			await chatGlobalStateStore.chatListPanel.chatListFilter.update({ text: phoneSearch })
		}

		if (phoneSearch) {
			query.delete(phoneQueryParamKey)

			history.replace({ search: query.toString() })
		}

		if (isValidPhoneSearch) {
			return phoneSearch
		} else {
			return null
		}
	}

	const setupChatByInitialPhoneSearch = async (phoneSearch: string | null) => {
		if (phoneSearch) {
			const searchedChat = chatGlobalStateStore.chat.list.find(chat => chat?.client?.contacts?.some(contact => contact?.data?.phone?.includes(phoneSearch)))

			if (searchedChat) {
				await chatGlobalStateStore.chat.openById(searchedChat.id)
			}
		}
	}

	const initialize = async () => {
		const phoneSearch = await setupInitialChatPhoneSearch()

		await Promise.all([
			setupSocket(),
			chatGlobalStateStore.chat.loadAllFromServer(),
			chatGlobalStateStore.quickReply.loadAllFromServer(),
			chatGlobalStateStore.attendant.loadAllFromServer(),
			chatGlobalStateStore.tag.loadAllFromServer(),
			userSettings.retrieveActualUserSettings("notifications")
		])

		await setupChatByInitialPhoneSearch(phoneSearch)
	}

	useDidMount(() => {
		initialize()
	})

	useEffect(() => {
		makeVisualNotification()
		// eslint-disable-next-line
	}, [chatGlobalStateStore.chat.currentOpenedId, chatGlobalStateStore.chat.list.length, chatGlobalStateStore.message.list.length, totalUnreadChats])

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			className={classes.pageContainer}
		>
			<Grid
				container
				className={classes.chatContainer}
			>
				<ChatListPanel />

				<ConversationPanel />
			</Grid>
		</Grid>
	)
}

const ChatWithContext = () => (
	<ChatGlobalStateProvider>
		<Chat />
	</ChatGlobalStateProvider>
)

export default ChatWithContext
