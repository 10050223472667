import React from "react"
import clsx from "clsx"
import {
	Tooltip,
	Grid
} from "@material-ui/core"

import useStyles from "@/components/GroupValidatedName/styles"

import { FormattedGroup } from "@/protocols/group"

import { InvalidGroupInfoMessages } from "@/utils/groupInfo"

type GroupValidatedNameProps = {
	valid: boolean
	invalidReason: FormattedGroup["invalidReason"]
}

const GroupValidatedName: React.FC<GroupValidatedNameProps> = (props) => {
	const {
		valid,
		invalidReason,
		children
	} = props

	const classes = useStyles()

	const isInvalid = !valid

	return (
		<Tooltip
			title={isInvalid ? (
				InvalidGroupInfoMessages[invalidReason] || InvalidGroupInfoMessages.default
			) : (
				""
			)}
			className={clsx({
				[classes.invalidGroupName]: isInvalid
			})}
		>
			<Grid>
				{children}
			</Grid>
		</Tooltip>
	)
}

export default GroupValidatedName
