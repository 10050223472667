import {
	Grid,
	Typography,
	Divider as MuiDivider
} from "@material-ui/core"

import { EditableText } from "@/components"

import React from "react"
import useStyles from "@/pages/Admin/Settings/InboxChannel/styles"
import SendMessagesTimeLimits from "@/pages/Admin/Settings/InboxChannel/SendMessagesTimeLimits/index"
import SendMessagesTimeDelay from "@/pages/Admin/Settings/InboxChannel/SendMessagesTimeDelay/index"
import HardCoded from "@/services/HardCoded"

const InboxChannelSettings = () => {
	const classes = useStyles()

	const userPermited = HardCoded.checkFeatureFlagByInstanceId("delayMessageSettings")

	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Typography variant="h4" className={classes.titleText}>
					Configurações do canal
				</Typography>
			</Grid>

			<Grid item>
				<MuiDivider orientation="horizontal" />
			</Grid>

			<Grid item>
				<Typography
					variant="body1"
					component={EditableText}
					code="settings_inbox_channel_send_messages_time_limits"
					errorText="HORÁRIOS DE ENVIO DAS MENSAGENS AUTOMÁTICAS"
				/>
			</Grid>

			<Grid item>
				<SendMessagesTimeLimits />
			</Grid>
			{userPermited &&
				<Grid item>
					<SendMessagesTimeDelay />
				</Grid>
			}

		</Grid>
	)
}

export default InboxChannelSettings
