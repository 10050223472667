import { LabelDisplayedRowsArgs } from "@material-ui/core"

export const getTotalPages = (totalItems: number, rowsPerPage: number) => {
	return Math.ceil(totalItems / rowsPerPage)
}

export const getRowsLabel = (tableData: LabelDisplayedRowsArgs, rowsPerPage: number) => {
	const {
		page,
		count
	} = tableData

	const pageInfo = `Página ${page + 1} de ${getTotalPages(count, rowsPerPage)}`
	const totalResultsInfo = `Total de resultados: ${count}`

	const rowsLabel = `${pageInfo} | ${totalResultsInfo}`

	return rowsLabel
}
