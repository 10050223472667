import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	messageContent: {
		padding: theme.spacing(2, 2),
		border: "3px solid transparent",
		borderRadius: 6,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		maxWidth: 370,
		wordBreak: "break-word"
	}
}))

export default useStyles
