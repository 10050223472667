import { IChat, IMessage } from "@/protocols/channel"

import { Chat, Message } from "@/store/ChatGlobalState"

import { isSameTimestamp } from "@/utils/time"

export const getChatInfo = (chat: Chat) => {
	const lastMessage = chat.lastMessage

	return {
		lastMessage,
		lastMessageDate: lastMessage?.createdAt ? new Date(lastMessage?.createdAt) : null
	}
}

export const sortChatByLastMessageDate = (chatA: Chat, chatB: Chat) => {
	const chatAInfo = getChatInfo(chatA)
	const chatBInfo = getChatInfo(chatB)

	/**
	 * Keep chats with no last message in the end of the list.
	 */
	if (!chatAInfo.lastMessageDate) {
		return 1
	} else if (!chatBInfo.lastMessageDate) {
		return -1
	}

	const lastMessageDateA = +chatAInfo.lastMessageDate
	const lastMessageDateB = +chatBInfo.lastMessageDate

	const descendingOrder = lastMessageDateB - lastMessageDateA

	return descendingOrder
}

export const sortChatByLastAttendanceCreationDate = (chatA: IChat, chatB: IChat) => {
	const lastAttendanceCreationDateA = +new Date(chatA.attendance.createdAt as string)
	const lastAttendanceCreationDateB = +new Date(chatB.attendance.createdAt as string)

	const ascendingOrder = lastAttendanceCreationDateA - lastAttendanceCreationDateB

	return ascendingOrder
}

export const sortChats = (
	// attendanceType: AttendanceType
) => (chatA: Chat, chatB: Chat) => {
	/**
	 * The filter below maybe needed sometime in case we start
	 * filtering queue chats in another way again. So we kept
	 * it commented.
	 */
	// const isNewChatFilterSelected = attendanceType === "queue"

	// if (isNewChatFilterSelected) {
	// 	return sortChatByLastAttendanceCreationDate(chatA, chatB)
	// } else {
	// 	return sortChatByLastMessageDate(chatA, chatB)
	// }

	return sortChatByLastMessageDate(chatA, chatB)
}

export const sortMessagesInAscendingOrder = (messageA: IMessage, messageB: IMessage) => {
	const messageADate = new Date(messageA.createdAt)
	const messageBDate = new Date(messageB.createdAt)

	const messageATimestamp = +messageADate
	const messageBTimestamp = +messageBDate

	const messageASequentialId = messageA.sequentialId
	const messageBSequentialId = messageB.sequentialId

	const isSameMessageTimestamp = isSameTimestamp(messageADate, messageBDate)

	if (isSameMessageTimestamp) {
		return messageASequentialId - messageBSequentialId
	}

	return messageATimestamp - messageBTimestamp
}

export const getLastChatMessage = (chatId: number, messages: Record<string, Message>): Message => {
	const messagesInAscendingOrder = Object.values(messages || {})
		.filter(message => message.inboxChannelChatId === chatId)
		.filter(message => !message.sentBySystem)
		.sort(sortMessagesInAscendingOrder)

	const lastMessage = messagesInAscendingOrder.pop()

	return lastMessage as Message
}

/**
 * This hash is usually used to know when to re-render components
 * that need to deal with chat listing. Being minded about, we need to add
 * params to the hash that when changed, must force the chat item to change either.
 */
export const buildChatHash = (chat: Chat): string => {
	return `${chat?.id}@${chat?.status}@${chat?.attendance?.assignTeamId}@${chat?.attendance?.assignUserId}`
}
