import { Typography, Breadcrumbs } from "@material-ui/core"
import { Link as LinkRouter } from "react-router-dom"
import React from "react"
import useStyles from "@/components/Breadcrumb/styles"

type BreadcrumbData = {
	name?: string
	pathname?: string
}

interface BreadcrumbProps{
	data: BreadcrumbData[]
}

const Breadcrumb = (props: BreadcrumbProps) => {
	const classes = useStyles()

	const { data } = props

	return <Breadcrumbs aria-label="breadcrumb">

		{data.map((item, index) => {
			return <Typography
				variant="h4"
				color="textPrimary"
				key={index}
			>
				<LinkRouter
					to={item.pathname ? item.pathname : ""}
					className={classes.breadcrumbLink}
				>
					{item.name}
				</LinkRouter>
			</Typography>
		})}

	</Breadcrumbs>
}

export default Breadcrumb
