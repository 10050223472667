import React from "react"
import {
	Grid,
	Select,
	MenuItem,
	Typography
} from "@material-ui/core"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

import {
	transformHourAndMinutesToDate,
	transformDateIntoHourAndMinutes,
	translatedDaysOfWeek,
	isValidDate
} from "@/utils/time"

import { BlockConditionEditorInputDefaultProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockRuleValidationSlug } from "@/protocols/chatBot"

const BetweenWeekTimeInput: React.FC<BlockConditionEditorInputDefaultProps> = (props) => {
	const {
		changeValidation,
		validation
	} = props

	const weekDayValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "week-day")?.second_param
	const fromHourAndMinutesValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "from-hour-and-minutes")?.second_param as string
	const toHourAndMinutesValue = validation.deep_validations?.find(deepValidation => deepValidation.slug === "to-hour-and-minutes")?.second_param as string

	const handleChange = (deepValidationSlug: ChatBotFlowBlockRuleValidationSlug, value: string) => {
		changeValidation({
			deep_validations: validation.deep_validations?.map(deepValidation => {
				if (deepValidation.slug === deepValidationSlug) {
					deepValidation.second_param = value
				}

				return deepValidation
			})
		})
	}

	return (
		<>
			<Grid
				item
				xs={6}
			>
				<Select
					value={weekDayValue || ""}
					variant="outlined"
					onChange={(event) => handleChange("week-day", event.target.value as string)}
					fullWidth
					displayEmpty
				>
					{Object.entries(translatedDaysOfWeek).map(([weekDay, weekName]) => (
						<MenuItem
							key={weekDay}
							value={weekDay}
						>
							{weekName}
						</MenuItem>
					))}

					<MenuItem
						disabled
						value=""
					>
						<Typography
							variant="body1"
							style={{
								lineHeight: "unset",
								color: "#ADADAD"
							}}
						>
							Dia da semana
						</Typography>
					</MenuItem>
				</Select>
			</Grid>

			<Grid
				item
				xs={3}
			>
				<MuiPickersUtilsProvider
					locale={ptBR}
					utils={DateFnsUtils}
				>
					<Grid>
						<KeyboardTimePicker
							okLabel="Pronto"
							cancelLabel="Cancelar"
							ampm={false}
							value={transformHourAndMinutesToDate(fromHourAndMinutesValue)}
							inputVariant="outlined"
							onChange={(date) => isValidDate(date as Date) && handleChange("from-hour-and-minutes", transformDateIntoHourAndMinutes(date as Date))}
							placeholder="De"
						/>
					</Grid>
				</MuiPickersUtilsProvider>
			</Grid>

			<Grid
				item
				xs={3}
			>
				<MuiPickersUtilsProvider
					locale={ptBR}
					utils={DateFnsUtils}
				>
					<Grid>
						<KeyboardTimePicker
							okLabel="Pronto"
							cancelLabel="Cancelar"
							ampm={false}
							value={transformHourAndMinutesToDate(toHourAndMinutesValue)}
							inputVariant="outlined"
							onChange={(date) => isValidDate(date as Date) && handleChange("to-hour-and-minutes", transformDateIntoHourAndMinutes(date as Date))}
							placeholder="Até"
						/>
					</Grid>
				</MuiPickersUtilsProvider>
			</Grid>
		</>
	)
}

export default BetweenWeekTimeInput
