import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Avatar,
	Grid,
	makeStyles
} from "@material-ui/core"
import { Divider } from "@/components"

const useStyles = makeStyles({
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 160,
		height: 160,
		backgroundColor: "transparent"
	},
	skeleton: {
		transform: "scale(1)"
	}
})

const ClientInfoSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container direction="column" alignItems="flex-start">
			<Grid item className={classes.avatarContainer}>
				<Avatar className={classes.avatar}>
					<Skeleton width={160} height={160} variant="circle" />
				</Avatar>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid item>
				<Skeleton className={classes.skeleton} width={60} height={18} />
			</Grid>

			<Divider orientation="horizontal" size={1} />

			<Grid item>
				<Skeleton className={classes.skeleton} width={160} height={18} />
			</Grid>

			<Divider orientation="horizontal" size={1} />

			<Grid item>
				<Skeleton className={classes.skeleton} width={132} height={18} />
			</Grid>
		</Grid>
	)
}

export default ClientInfoSkeleton
