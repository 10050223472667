import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	noMessageBlastTitle: {
		color: colors.grayScale[4],
		fontSize: "32px",
		lineHeight: "48px"
	},
	noMessageBlastText: {
		color: "#818285",
		fontSize: "20px"
	},
	noMessageBlastImage: {
		maxWidth: "100%"
	},
	messageBlastStatusName: {
		fontSize: "24px"
	},
	coloredTitle: {
		color: colors.palette.primary
	}
}))

export default useStyles
