import React from "react"
import { Grid } from "@material-ui/core"

import useStyles from "@/components/TypographyListGroup/styles"

const TypographyListGroup: React.FC = (props) => {
	const {
		children
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			className={classes.textContainer}
		>
			{React.Children.map(children, (child) => child)}
		</Grid>
	)
}

export default TypographyListGroup
