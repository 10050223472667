import React from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"
import {
	SettingsEthernet as AssociationIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Admin/Webhooks/WebhookFieldAssociationInput/styles"

type WebhookFieldAssociationInputProps = {
	items: Array<{
		rightSide: React.ReactElement
		leftSide: React.ReactElement
	}>
	label: {
		rightSide: string
		leftSide: string
	}
}

const WebhookFieldAssociationInput: React.FC<WebhookFieldAssociationInputProps> = (props) => {
	const {
		items,
		label
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			spacing={2}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					alignItems="center"
				>
					<Grid
						container
						className={classes.inputContainer}
					>
						<Typography
							variant="h2"
							color="textPrimary"
						>
							{label.leftSide}
						</Typography>
					</Grid>

					<Grid
						container
						alignItems="center"
						justifyContent="center"
						className={classes.associationIconContainer}
					/>

					<Grid
						container
						className={classes.inputContainer}
					>
						<Typography
							variant="h2"
							color="textPrimary"
						>
							{label.rightSide}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			{items.map((item, index) => (
				<Grid
					item
					xs={12}
					key={index}
				>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							container
							className={classes.inputContainer}
						>
							{item.leftSide}
						</Grid>

						<Grid
							container
							alignItems="center"
							justifyContent="center"
							className={classes.associationIconContainer}
						>
							<AssociationIcon />
						</Grid>

						<Grid
							container
							className={classes.inputContainer}
						>
							{item.rightSide}
						</Grid>
					</Grid>
				</Grid>
			))}
		</Grid>
	)
}

export default WebhookFieldAssociationInput
