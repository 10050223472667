import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	enableIcon: {
		color: "#50CA81"
	},
	disableIcon: {
		color: "#807c7c"
	}
})

export default useStyles
