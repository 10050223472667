import React from "react"
import {
	MenuItem
} from "@material-ui/core"

import {
	Divider
} from "@/components"

import useStyles from "@/components/SettingsMenuItem/styles"

type SettingsMenuItemProps = {
	onClick: () => void
	text: string
	icon: React.ReactNode
}

const SettingsMenuItem: React.FC<SettingsMenuItemProps> = (props) => {
	const {
		onClick,
		text,
		icon
	} = props

	const classes = useStyles()

	return (
		<MenuItem
			onClick={onClick}
			className={classes.settingsMenuItem}
		>
			{icon}
			<Divider size={2} orientation="vertical" />
			{text}
		</MenuItem>
	)
}

export default SettingsMenuItem
