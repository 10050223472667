import React, { useRef, useEffect } from "react"
import { ListChildComponentProps, VariableSizeList } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"

import { consolidateAutoSizerHeight } from "@/utils/virtualizedList"

export type VirtualizedListItemProps = ListChildComponentProps

export type VirtualizedListProps = {
	refreshConditionals?: React.DependencyList
	itemSize: ((index: number) => number)
	itemCount: number
	itemRender: React.ComponentType<ListChildComponentProps> & React.ReactNode
	className: string
	/**
	 * Container width and height are useful in the cases the children does not work well
	 * when dealing with AutoSizer (per ex when using virtualized lists inside drawers).
	 */
	containerWidth?: number
	containerHeight?: number
	isVariableSizeList?: boolean
}

const VirtualizedList: React.FC<VirtualizedListProps> = (props) => {
	const {
		className,
		itemCount,
		itemSize,
		itemRender,
		containerWidth,
		containerHeight,
		refreshConditionals = []
	} = props

	const virtualizedListRef = useRef<VariableSizeList>(null)
	const refreshConditionalsHash = JSON.stringify(refreshConditionals)

	useEffect(() => {
		virtualizedListRef.current?.resetAfterIndex(0)
	}, [
		refreshConditionalsHash
	])

	if (containerWidth && containerHeight) {
		return (
			<VariableSizeList
				className={className}
				itemSize={itemSize}
				itemCount={itemCount}
				width={containerWidth}
				height={containerHeight}
				ref={virtualizedListRef}
			>
				{itemRender}
			</VariableSizeList>
		)
	} else {
		return (
			<AutoSizer>
				{({ width, height }) => (
					<VariableSizeList
						className={className}
						itemSize={itemSize}
						itemCount={itemCount}
						width={width}
						height={consolidateAutoSizerHeight(height)}
						ref={virtualizedListRef}
					>
						{itemRender}
					</VariableSizeList>
				)}
			</AutoSizer>
		)
	}
}

export default VirtualizedList
