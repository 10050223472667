import React from "react"
import { Add as CreateConditionIcon } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/CreateConditionIconButton/styles"

type CreateConditionIconButtonProps = {
	onClick?: () => void
	disabled?: boolean
}

const CreateConditionIconButton: React.FC<CreateConditionIconButtonProps> = (props) => {
	const {
		onClick,
		disabled
	} = props

	const classes = useStyles()

	return (
		<IconButton
			onClick={onClick}
			className={classes.icon}
			size="small"
			disabled={disabled}
			classes={{
				disabled: classes.disabled
			}}
		>
			<CreateConditionIcon />
		</IconButton>
	)
}

export default CreateConditionIconButton
