import React, { useState } from "react"
import {
	Grid,
	Typography,
	Table,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
	Button,
	Container,
	Chip,
	Box,
	TextField,
	InputAdornment,
	IconButton
} from "@material-ui/core"

import useStyles from "@/pages/Admin/MessageBlast/Management/Contacts/styles"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider,
	LoadingOverlay
} from "@/components"

import { MessageBlastContact, MessageBlastContactToAdd } from "@/protocols/messageBlast"

import noContactImg from "@/assets/images/messageBlast/no_contacts.svg"
import useCustomStyles from "@/styles/custom"

import AddContactDialog from "@/pages/Admin/MessageBlast/Management/Contacts/AddContactDialog"

import { formatPhoneNumber } from "@/utils/mask"
import { getRowsLabel } from "@/utils/table"

export type ContactsWhereData = {
	page: number
	rowsPerPage: number
	search?: string
}

export const CONTACTS_DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

type ContactsProps = {
	isMessageBlastSent: boolean
	contacts: MessageBlastContact[]
	contactCount: number
	onClearContacts: () => Promise<void>
	onRemoveContact: (contact: MessageBlastContact) => Promise<void>
	onAddContacts: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	whereData: ContactsWhereData
	setWhereData: React.Dispatch<React.SetStateAction<ContactsWhereData>>
	loading: boolean
}

const Contacts: React.FC<ContactsProps> = ({
	isMessageBlastSent,
	contacts,
	contactCount,
	onClearContacts,
	onRemoveContact,
	onAddContacts,
	whereData,
	setWhereData,
	loading
}) => {
	const [addContactDialogOpened, setAddContactDialogOpened] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleOpenAddContactDialog = () => {
		setAddContactDialogOpened(true)
	}

	const handleCloseAddContactDialog = () => {
		setAddContactDialogOpened(false)
	}

	const handleVerifyIfContactHavePhone = (contact: MessageBlastContact): string | undefined => {
		if (contact.phone) {
			const possiblePhoneNumber = formatPhoneNumber(contact.phone)
			return possiblePhoneNumber
		}

		return "Número de telefone não existe no whatsapp"
	}

	const handleWhereDataChange = (newData: Partial<ContactsWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
	}

	const handleSearchChange = async (searchData: string) => {
		handleWhereDataChange({
			search: searchData,
			page: 0
		})
	}

	const handleDeleteContact = async (contact: MessageBlastContact) => {
		await onRemoveContact(contact)
	}

	return (
		<>
			{ contactCount || whereData.search || loading ? (
				<Container maxWidth={false} disableGutters>
					{!isMessageBlastSent && (
						<>
							<Button
								variant="contained"
								color="secondary"
								className={customClasses.buttonAction}
								onClick={handleOpenAddContactDialog}
							>
								ADICIONAR MAIS CONTATOS AO ENVIO
							</Button>
						</>
					)}

					<Divider orientation="horizontal" size={4} />

					<Portlet>
						<Grid container alignItems="center">
							<Grid item xs>
								<Typography variant="h4" className={classes.title}>
									Lista de contatos adicionados a este envio
								</Typography>
							</Grid>

							<Grid item>
								<TextField
									value={whereData.search}
									placeholder="Pesquisar"
									variant="outlined"
									onChange={({ target }) =>
										handleSearchChange(target.value)
									}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: whereData.search && (
											<IconButton
												size="small"
												onClick={() => handleSearchChange("")}
											>
												<CloseIcon
													fontSize="small"
												/>
											</IconButton>
										)
									}}
									size="small"
								/>
							</Grid>

							<Divider orientation="horizontal" size={3} />

							<Grid item xs={12}>
								<LoadingOverlay
									loading={loading}
								>
									{ contactCount
										? <>
											<TableContainer>
												<Table stickyHeader size="small">
													<TableHead>
														<TableRow>
															<TableCell>
															WhatsApp
															</TableCell>

															<TableCell >
																{!isMessageBlastSent && (
																	<Box
																		display="flex"
																		justifyContent="flex-end"
																		alignItems="center"
																	>
																		<Chip
																			label="Remover todos os contatos"
																			className={classes.clearContactsChip}
																			onClick={onClearContacts}
																		/>
																	</Box>
																)}
															</TableCell>
														</TableRow>
													</TableHead>

													<TableBody>
														{contacts
														?.map(contact => (
															<TableRow key={contact.id} tabIndex={-1}>
																<TableCell className={classes.tableCell}>
																	{handleVerifyIfContactHavePhone(contact)}
																</TableCell>

																<TableCell>
																	{!isMessageBlastSent && (
																		<Box
																			display="flex"
																			justifyContent="flex-end"
																			alignItems="center"
																		>
																			<IconButton onClick={() => handleDeleteContact(contact)} >
																				<DeleteIcon />
																			</IconButton>
																		</Box>
																	)}
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={contactCount}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onChangeRowsPerPage={({ target }) => {
													handlePageChange(target, 0)
													handleChangeRowsPerPage(+target.value)
												}}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
											/>
										</>
										: <Grid container justify="center" direction="row">
											<Typography variant="h5" className={classes.title}>
											Nenhum contato encontrado
											</Typography>
										</Grid>
									}
								</LoadingOverlay>
							</Grid>
						</Grid>
					</Portlet>
				</Container>
			) : (
				<Container maxWidth="xs" disableGutters>
					<Grid container alignItems="center" direction="column" spacing={3}>
						<Grid item>
							<img src={noContactImg} alt="no contacts" />
						</Grid>

						<Grid item>
							<Typography
								variant="h3"
								className={classes.noContactsText}
								align="center"
							>
								Não existe nenhum contato adicionado a este envio.
							</Typography>
						</Grid>

						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={handleOpenAddContactDialog}
							>
								ADICIONAR CONTATOS AO ENVIO
							</Button>
						</Grid>
					</Grid>
				</Container>
			)}
			<AddContactDialog
				opened={addContactDialogOpened}
				onAdd={onAddContacts}
				onClose={handleCloseAddContactDialog}
			/>
		</>
	)
}

export default Contacts
