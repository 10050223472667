import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

import { isSmallScreen } from "@/utils/checkDevice"

const drawerWidth = "400px"

const useStyles = makeStyles((theme) => ({
	drawer: {
		transition: theme.transitions.create(["width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerClosed: {
		width: 0
	},
	drawerOpened: {
		width: isSmallScreen ? undefined : drawerWidth
	},
	drawerPaper: {
		width: isSmallScreen ? "100%" : drawerWidth,
		zIndex: 1200, // open above chat
		borderRight: `1px solid ${colors.grayScale[9]}`,
		borderLeft: `1px solid ${colors.grayScale[9]}`
	},
	card: {
		padding: theme.spacing(2, 3)
	},
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 160,
		height: 160,
		fontSize: "64px"
	},
	notesCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	notesCardTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3)
	},
	header: {
		borderBottom: "1px solid #e1e4e8",
		padding: "8px 10px",
		fontWeight: 600
	},
	clientName: {
		paddingLeft: theme.spacing(0.5)
	},
	badgeGrid: {
		width: 40,
		height: 40,
		backgroundColor: colors.palette.primary,
		borderRadius: "100%",
		border: `3px solid ${colors.grayScale[11]}`,
		marginRight: theme.spacing(5),
		marginBottom: theme.spacing(5)
	},
	refreshProfilePictureButton: {
		color: colors.palette.background
	}
}))

export default useStyles
