import React from "react"
import {
	Grid,
	Typography,
	Tooltip
} from "@material-ui/core"
import {
	InfoOutlined as InfoIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider
} from "@/components"

import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics/QueueMetricsCard/styles"

type QueueMetricsCardProps = {
	title: string
	icon: React.ReactNode
	iconBackgroundColor: string
	mainColor: string
	count: number
	helperText: string | React.ReactFragment
	countDescription: string
}

const QueueMetricsCard: React.FC<QueueMetricsCardProps> = (props) => {
	const {
		count,
		countDescription,
		helperText,
		icon,
		iconBackgroundColor,
		mainColor,
		title
	} = props

	const customClasses = useCustomStyles()
	const classes = useStyles()

	return (
		<Portlet
			style={{
				width: 200
			}}
		>
			<Grid
				container
				spacing={2}
				justify="center"
			>
				<Grid
					item
					xs={12}
				>
					<Grid
						container
						justify="space-between"
						alignItems="center"
					>
						<Typography
							variant="h2"
							className={customClasses.uppercase}
						>
							{title}
						</Typography>

						<Tooltip
							title={helperText}
						>
							<InfoIcon
								htmlColor="#ACB5BD"
							/>
						</Tooltip>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						direction="column"
						className={classes.dataContainer}
					>
						<Grid
							className={classes.iconContainer}
							style={{
								backgroundColor: iconBackgroundColor
							}}
							container
							alignItems="center"
							justify="center"
						>
							{React.cloneElement(icon as React.ReactElement, {
								style: {
									color: mainColor
								}
							})}
						</Grid>

						<Divider orientation="horizontal" size={3} />

						<Typography
							className={classes.count}
							style={{
								color: mainColor
							}}
						>
							{count}
						</Typography>

						<Divider orientation="horizontal" size={1} />

						<Typography
							variant="caption"
							style={{
								color: mainColor
							}}
						>
							{countDescription}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default QueueMetricsCard
