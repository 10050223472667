import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	button: {
		padding: 3,
		fontSize: "12px",
		minWidth: "40px",
		backgroundColor: "#7E0DD6",
		"&:hover": {
			backgroundColor: "#7E0DD6"
		},
		"& svg": {
			fontSize: "16px !important"
		}
	}
})

export default useStyles
