import React from "react"

import useStyles from "@/components/FancyBox/styles"

/**
 * Work around to load FancyBox on React.js
 */
// eslint-disable-next-line
const Window = window as any
Window.jQuery = require("jquery")
require("@fancyapps/fancybox")
require("@fancyapps/fancybox/dist/jquery.fancybox.css")

type FancyBoxProps = {
	caption: string
	src: string
	id?: string
	options: {
		/**
		 * Specifies the context of FancyBox, if you do not
		 * choose a slug, all content will be shown on the same
		 * fancybox.
		 */
		slug?: string
		/**
		 * Enables history while changing between resources
		 */
		enableHashChange?: boolean
		/**
		 * Enable to return to the element that is current opened
		 */
		backFocus?: boolean
	}
}

const FancyBox: React.FC<FancyBoxProps> = (props) => {
	const { children, src, caption, id, options } = props

	const classes = useStyles()

	const handleOpenFancyBox = (event: React.SyntheticEvent) => {
		event.preventDefault()

		const fancyBoxElementsSelector = `[data-fancybox='${options?.slug}']`
		/**
		 * Type 'NodeListOf<Element>' can only be iterated through when using the '--downlevelIteration' flag or with a '--target' of 'es2015' or higher
		 * In order to iterate them, we need to type as any
		 */
		// eslint-disable-next-line
		const fancyBoxElements = document.querySelectorAll(fancyBoxElementsSelector) as any
		const selectedElementIndex = [...fancyBoxElements].findIndex(element => element.id === id)

		window.jQuery.fancybox.open(fancyBoxElements, {}, selectedElementIndex)
	}

	return (
		<a
			data-fancybox={options?.slug}
			data-hash={options?.enableHashChange ? options?.slug : false}
			href={src}
			data-back-focus={options?.backFocus}
			data-caption={caption}
			className={classes.link}
			id={id}
			onClick={handleOpenFancyBox}
		>
			{children}
		</a>
	)
}

export default FancyBox
