import React, { useState } from "react"
import {
	Grid,
	IconButton,
	Link,
	Typography
} from "@material-ui/core"

import {
	ReactComponent as VariableIcon
} from "@/assets/icons/variable.svg"

import useStyles from "@/components/ChatMessageBuilder/VariableInput/styles"

import VariableTableList from "@/components/ChatMessageBuilder/VariableInput/VariableTableList"

import { InfoDialog, SvgIcon } from "@/components"

export type Variable = {
	title: string | React.ReactNode
	value: string
	category?: string
}

export type VariableColumns = {
	name: "title" | "value"
	label: string
}

type VariableInputProps = {
	variables: Variable[]
	variablesCategoryTitleMap?: Record<string, string>
	onVariable: (variable: string) => void
	disabled?: boolean
	helpLink?: {
		from?: "integrations" | "message-blast",
		href: string
	}
}

const VariableInput: React.FC<VariableInputProps> = (props) => {
	const {
		onVariable,
		variablesCategoryTitleMap,
		variables,
		disabled,
		helpLink
	} = props

	const [openVariableDialog, setOpenVariableDialog] = useState(false)

	const classes = useStyles()

	const helpLinkMessages: Record<string, string> = {
		integrations: "Quer aprender a usar as variáveis das integrações?",
		"message-blast": "Quer aprender a usar as variáveis no envio em massa?"
	}

	const handleOpenMenu = () => {
		setOpenVariableDialog(true)
	}

	const handleSelectVariable = (value: string) => {
		onVariable(value)

		setOpenVariableDialog(false)
	}

	const needToSortVariablesByCategory = Object.keys(variablesCategoryTitleMap || {}).length > 0

	return (
		<>
			<Grid>
				<IconButton
					disabled={disabled}
					onClick={() => handleOpenMenu()}
				>
					<SvgIcon
						icon={VariableIcon}
					/>
				</IconButton>
			</Grid>

			<InfoDialog
				onClose={() => setOpenVariableDialog(false)}
				openDialog={openVariableDialog}
				title={<>
						Lista de Variáveis
					{
						helpLink && <Typography variant="subtitle2" className={classes.helpLink}>
							{helpLink.from && helpLinkMessages[helpLink.from]} <Link
								href={helpLink.href}
								target="_BLANK"
							>
								Clique aqui
							</Link>
						</Typography>
					}
				</>}
				maxWidth="sm"
			>
				{needToSortVariablesByCategory ? (
					<Grid
						container
						spacing={4}
					>
						{Object.entries(variablesCategoryTitleMap || {}).map(([category, title]: [string, string]) => {
							const categoryVariables = variables.filter(variable => variable.category === category)

							const isThereAnyVariable = categoryVariables.length > 0

							return (
								<Grid
									item
									xs={12}
									key={category}
								>
									<Grid
										container
										spacing={1}
									>
										<Grid
											item
											xs={12}
										>
											<Typography
												variant="h4"
												color="textPrimary"
											>
												{title}
											</Typography>
										</Grid>

										<Grid
											item
											xs={12}
										>
											{isThereAnyVariable ? (
												<VariableTableList
													variables={categoryVariables}
													onVariableSelected={handleSelectVariable}
												/>
											) : (
												<Typography
													variant="body1"
												>
													Suas variáveis aparecerão aqui...
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							)
						})}
					</Grid>
				) : (
					<div
						className={classes.menuList}
					>
						<VariableTableList
							variables={variables}
							onVariableSelected={handleSelectVariable}
						/>
					</div>
				)}
			</InfoDialog>
		</>
	)
}

export default VariableInput
