import React from "react"
import { TypographyProps } from "@material-ui/core"

import useStyles from "@/components/ClientIdentification/styles"

import ClientNickname from "@/components/ClientIdentification/ClientNickname"
import ClientName from "@/components/ClientIdentification/ClientName"

type ClientIdentificationProps = {
	/**
	 * Name supplied from the channel. Ex: Name on Whatsapp
	 */
	name?: string
	/**
	 * Name set inside Inbox.
	 */
	nickname?: string
	PrimaryTitleProps?: TypographyProps
	SecondaryTitleProps?: TypographyProps
}

const ClientIdentification: React.FC<ClientIdentificationProps> = (props) => {
	const {
		name,
		nickname,
		PrimaryTitleProps,
		SecondaryTitleProps
	} = props

	const classes = useStyles()

	return (
		<ClientNickname
			{...(PrimaryTitleProps || {})}
			className={PrimaryTitleProps?.className}
		>
			{nickname || ""}

			{name && (
				<ClientName
					name={name}
					{...(SecondaryTitleProps || {})}
					className={`${classes.name} ${SecondaryTitleProps?.className}`}
				/>
			)}
		</ClientNickname>
	)
}

export default ClientIdentification
