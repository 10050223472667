import { useEffect } from "react"

const useDidMount = (f: () => Promise<void> | void) => (
	useEffect(() => {
		f()
	// eslint-disable-next-line
	},[])
)

export default useDidMount
