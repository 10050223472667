import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	link: {
		textDecoration: "initial",
		color: "initial"
	}
})

export default useStyles
