import { useRef } from "react"

type Fn = () => Promise<void> | void

const useThrottle = () => {
	const timeoutCode = useRef<NodeJS.Timeout | null>(null)

	const throttle = (fn: Fn, throttleInMilliseconds = 1000) => {
		if (timeoutCode.current) {
			return
		}

		fn()

		const newTimeoutCode = setTimeout(() => {
			if (timeoutCode.current) {
				timeoutCode.current = null
			}
		}, throttleInMilliseconds)

		timeoutCode.current = newTimeoutCode
	}

	return throttle
}

export default useThrottle
