import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	danger: {
		color: colors.palette.error
	}
})

export default useStyles
