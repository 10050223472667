
import React, { useState } from "react"
import {
	Button,
	Grid,
	TextField,
	Typography
} from "@material-ui/core"

import useCustomStyles from "@/styles/custom"
import { Divider, ActionDialog, Notification, Loading, PopConfirm } from "@/components"

import useValidation from "@/hooks/useValidation"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"

import TeamCard from "@/pages/Admin/Team/components/TeamCard"

import TeamsSkeleton from "@/skeletons/Admin/Teams"

import useStyles from "@/pages/Admin/Attendant/styles"
import ErrorHandlerService from "@/services/ErrorHandler"

import { getErrorName } from "@/utils/response"

type TeamDataProps = {
	id?: number
	instanceId?: number
	name: string
}

const Team: React.FC = () => {
	const [teamData, setTeamData] = useState<TeamDataProps>({ name: "" })
	const [loadingTeam, setLoadingTeam] = useState<boolean>(false)
	const [openedTeamDialog, setOpenedTeamDialog] = useState<boolean>(false)
	const [loadingTeams, setLoadingTeams] = useState<boolean>(false)
	const [teams, setTeams] = useState<TeamDataProps[]>([])

	const customClasses = useCustomStyles()
	const {
		validation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()

	const openCreateTeamDialog = () => {
		setTeamData({ name: "" })

		setOpenedTeamDialog(true)
	}

	const openEditTeamDialog = (team: TeamDataProps) => {
		setTeamData(team)

		setOpenedTeamDialog(true)
	}

	const getTeams = async () => {
		setLoadingTeams(true)

		try {
			const { data } = await ApiService
				.get("/team")

			setTeams(data.teams)
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingTeams(false)
	}

	const handleSaveTeam = async () => {
		setLoadingTeam(true)

		try {
			if (teamData.id) {
				await ApiService
					.put(`/team/${teamData.id}`, {
						name: teamData.name
					})
			} else {
				await ApiService
					.post("/team", {
						name: teamData.name
					})
			}

			setOpenedTeamDialog(false)

			getTeams()

			if (teamData.id) {
				Notification.success({ message: "A equipe foi modificada com sucesso!" })
			} else {
				Notification.success({ message: "A equipe foi criada com sucesso!" })
			}
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingTeam(false)
	}

	const handleTeamClose = () => {
		setOpenedTeamDialog(false)
	}

	const handleTeamDataChange = (key: string, value: unknown) => {
		setTeamData({
			...teamData,
			[key]: value
		})
	}

	const handleDeleteTeam = async (team: TeamDataProps) => {
		PopConfirm.open({
			title: "Excluir Equipe",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/team/${team.id}`)

					await getTeams()

					Notification.success({ message: "Equipe deletada com sucesso!" })
				} catch (error) {
					const errorName = getErrorName(error)

					if (errorName === "HaveUsersInTeam") {
						Notification.error({ message: "Exclusão não permitida, pois existem atendentes vinculados a essa equipe." })
					}
					ErrorHandlerService.handle(error)
				}
			}
		})
	}

	useDidMount(() => {
		getTeams()
	})

	return (
		<>
			<Button
				variant="contained"
				className={customClasses.buttonAction}
				onClick={openCreateTeamDialog}
			>
				CRIAR NOVA EQUIPE
			</Button>

			<Divider size={3} orientation="horizontal" />

			<Typography
				variant="h4"
				color="textPrimary"
				className={classes.titleText}
			>
				Equipes Cadastradas
			</Typography>

			<Divider size={3} orientation="horizontal" />

			<Loading
				loading={loadingTeams}
				customLoadingElement={<TeamsSkeleton/> }>
				<Grid container>
					{
						teams.length
							? teams.map((team, index) => (
								<Grid item xs={12} key={index}>
									<TeamCard
										name={team?.name}
										onClickEditButton={() => openEditTeamDialog(team)}
										onClickDeleteButton={() => handleDeleteTeam(team)}
									/>

									<Divider size={2} orientation="horizontal" />
								</Grid>
							))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2" className={classes.titleText}>
										Nenhuma equipe cadastrada
								</Typography>
							</Grid>
					}
				</Grid>
			</Loading>
			<ActionDialog
				title={teamData.id ? "Atualizar equipe" : "Criar nova equipe"}
				onSave={handleSaveTeam}
				saveText={teamData.id ? "SALVAR" : "CRIAR"}
				onClose={handleTeamClose}
				openDialog={openedTeamDialog}
				loading={loadingTeam}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="input-name"
							name="name"
							value={teamData.name}
							onChange={
								({ target }) => handleTeamDataChange("name", target.value)
							}
							variant="outlined"
							placeholder="Nome"
							fullWidth
							helperText={validation.name}
							error={!!validation.name}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default Team
