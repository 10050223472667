import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	},
	closeIcon: {
		position: "absolute",
		top: 20,
		right: 10
	}
}))

export default useStyles
