import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	generateQRCodeContent: {
		maxWidth: 275,
		border: "1px solid #EFEFEF",
		borderRadius: 6,
		padding: theme.spacing(4, 2)
	},
	generateQRCodeText: {
		fontSize: 16,
		lineHeight: "24px"
	},
	arrowDownIcon: {
		fontSize: 72
	},
	qrCodeGenerationProgress: {
		height: 44,
		width: "100%",
		borderRadius: theme.spacing(0.5),
		backgroundColor: "#F8F8F8"
	},
	iconButtonLink: {
		color: colors.grayScale[4]
	}
}))

export default useStyles
