import { useEffect, useRef } from "react"
import useSound from "use-sound"

import useThrottle from "@/hooks/useThrottle"

const useThrottledSound = (soundFilePath: string) => {
	const soundThrottle = useThrottle()

	/**
	 * We do the workaround below using refs in order to access valid state
	 * inside listener functions (usually common state variables can not be
	 * accessed from inside listener functions).
	 */
	const [playNotificationSound, notificationSoundData] = useSound(soundFilePath)
	const playNotificationSoundRef = useRef<() => void>(playNotificationSound)

	const playSound = (throttleInMilliseconds?: number) => {
		if (playNotificationSoundRef.current) {
			soundThrottle(() => {
				playNotificationSoundRef.current()
			}, throttleInMilliseconds)
		}
	}

	useEffect(() => {
		playNotificationSoundRef.current = playNotificationSound
		// eslint-disable-next-line
	}, [notificationSoundData])

	return {
		playSound
	}
}

export default useThrottledSound
