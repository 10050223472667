import React, { useState } from "react"
import {
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Typography,
	Divider as DividerMui
} from "@material-ui/core"

import {
	ArrowForwardIos as ArrowForwardIosIcon,
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import ApiService from "@/services/Api"
import { UserDataProps } from "@/services/User"

import useValidation from "@/hooks/useValidation"
import { isSmallScreen } from "@/utils/checkDevice"

import { useGlobalStateStore } from "@/store/GlobalState"

import { InputDialog, Divider, ActionDialog, Notification } from "@/components"

import useStyles from "@/components/Profile/styles"
import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"

type NewPasswordPros = {
	openDialog: boolean
	current_password?: string
	new_password?: string
}

type VisiblePasswordProps = {
	current_password: boolean
	new_password: boolean
}

const Profile = () => {
	const [updatePasswordData, setUpdatePasswordData] = useState<NewPasswordPros>({
		openDialog: false
	})
	const [updatePasswordLoading, setUpdatePasswordLoading] = useState<boolean>(false)

	const [visiblePassword, setVisiblePassword] = useState<VisiblePasswordProps>({
		current_password: false,
		new_password: false
	})
	const [owner, setOwner] = useState<UserDataProps>()
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()
	const globalStateStore = useGlobalStateStore()

	const userStore = globalStateStore.user

	const handleChangeUserData = async (field: keyof UserDataProps, value: string): Promise<boolean> => {
		try {
			await ApiService.put("/user", { [field]: value })

			globalStateStore.setUserData({
				[field]: value
			})

			Notification.success({ message: "Informação alterada com sucesso!" })

			return true
		} catch (err) {
			triggerValidation(err)
			return false
		}
	}

	const clearUserDataValidation = (field: keyof UserDataProps) => {
		clearValidation(field)
	}

	const handleUpdatePasswordClose = () => {
		clearValidation("current_password")
		clearValidation("new_password")

		setUpdatePasswordData({
			openDialog: false
		})
	}

	const handleUpdatePasswordOpen = () => {
		setUpdatePasswordData({
			openDialog: true
		})
	}

	const handleUpdatePasswordSave = async () => {
		setUpdatePasswordLoading(true)

		try {
			await ApiService.put("/user/password", {
				current_password: updatePasswordData.current_password,
				new_password: updatePasswordData.new_password
			})

			Notification.success({ message: "Senha alterada com sucesso!" })

			handleUpdatePasswordClose()
		} catch (err) {
			triggerValidation(err)
		}

		setUpdatePasswordLoading(false)
	}

	const handleUpdatePasswordChange = (key: string, value: string) => {
		clearValidation(key)

		setUpdatePasswordData({
			...updatePasswordData,
			[key]: value
		})
	}

	const handleClickShowPassword = (type: "current_password" | "new_password") => {
		setVisiblePassword({
			...visiblePassword,
			[type]: !visiblePassword[type]
		})
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const getOwner = async () => {
		try {
			const resp = await ApiService.get("/users/owner")
			setOwner(resp.data)
		} catch (err) {
			triggerValidation(err)
		}
	}

	useDidMount(() => {
		getOwner()
	})

	return (
		<>
			<Typography
				variant="h4"
				color="textPrimary"
				className={customClasses.uppercase}
			>
				Dados do usuário
			</Typography>

			<Divider size={3} orientation="horizontal" />

			<List className={classes.list}>
				<InputDialog
					title="Alterar nome"
					onOk={
						(value) => handleChangeUserData("name", value as string)
					}
					onDataChange={() => { clearUserDataValidation("name") }}
					initialValue={userStore.name || ""}
					customInputElement={(
						<TextField
							placeholder="Nome"
							variant="outlined"
							color="primary"
							multiline
							fullWidth
							error={!!validation.name}
							helperText={validation.name}
						/>
					)}
					openOnDrawer={isSmallScreen}
					fullWidth
				>
					<ListItem button className={classes.listItem}>
						<ListItemText
							primary={userStore.name}
							primaryTypographyProps={{
								variant: "h4",
								color: "textPrimary",
								noWrap: true,
								className: classes.listItemText
							}}
						/>
						<ListItemSecondaryAction className={classes.nextIcon}>
							<IconButton>
								<ArrowForwardIosIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				</InputDialog>

				<Divider size={2} orientation="horizontal" />

				<ListItem disabled button className={classes.listItem}>
					<ListItemText
						primary={userStore.email}
						primaryTypographyProps={{
							variant: "h4",
							color: "textPrimary",
							noWrap: true,
							className: classes.listItemText
						}}
					/>
					<ListItemSecondaryAction
						className={`${classes.nextIcon} ${classes.nextIconDisabled}`}
					>
						<IconButton>
							<ArrowForwardIosIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>

				<Divider size={1} orientation="horizontal" />

				{
					!userStore.extra_data?.is_account_user &&
					<ListItem onClick={handleUpdatePasswordOpen} button className={classes.listItem}>
						<ListItemText
							primary={"Alterar Senha"}
							primaryTypographyProps={{
								variant: "h4",
								color: "textPrimary",
								noWrap: true,
								className: classes.listItemText
							}}
						/>
						<ListItemSecondaryAction className={classes.nextIcon}>
							<IconButton>
								<ArrowForwardIosIcon />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				}
			</List>

			<Divider size={3} orientation="horizontal" />
			<DividerMui />
			<Divider size={3} orientation="horizontal" />

			<Typography
				variant="h4"
				color="textPrimary"
				className={customClasses.uppercase}
			>
				Dados da conta
			</Typography>

			<Divider size={3} orientation="horizontal" />

			<List className={classes.list}>
				<ListItem className={classes.listItem}>
					<ListItemText
						primary={owner?.name}
						primaryTypographyProps={{
							variant: "h4",
							color: "textPrimary",
							noWrap: true,
							className: classes.listItemText
						}}
					/>
				</ListItem>

				<Divider size={2} orientation="horizontal" />

				<ListItem className={classes.listItem}>
					<ListItemText
						primary={owner?.email}
						primaryTypographyProps={{
							variant: "h4",
							color: "textPrimary",
							noWrap: true,
							className: classes.listItemText
						}}
					/>
				</ListItem>
			</List>

			<ActionDialog
				title={"Alterar senha"}
				onSave={handleUpdatePasswordSave}
				saveText="ALTERAR"
				onClose={handleUpdatePasswordClose}
				openDialog={updatePasswordData.openDialog}
				loading={updatePasswordLoading}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							id="input-current-password"
							name="current_password"
							value={updatePasswordData.current_password}
							onChange={
								({ target }) => handleUpdatePasswordChange("current_password", target.value)
							}
							variant="outlined"
							placeholder="Senha atual"
							type={
								visiblePassword.current_password
									? "text"
									: "password"
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => handleClickShowPassword("current_password")}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{
												visiblePassword.current_password
													? <ShowPassword />
													: <HiddenPassword />
											}
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth
							helperText={validation.current_password}
							error={!!validation.current_password}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<Grid item xs={12}>
						<TextField
							id="input-new-password"
							name="new_password"
							value={updatePasswordData.new_password}
							onChange={
								({ target }) => handleUpdatePasswordChange("new_password", target.value)
							}
							variant="outlined"
							placeholder="Nova senha"
							type={visiblePassword.new_password ? "text" : "password"}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={() => handleClickShowPassword("new_password")}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{visiblePassword.new_password ? <ShowPassword /> : <HiddenPassword />}
										</IconButton>
									</InputAdornment>
								)
							}}
							fullWidth
							helperText={validation.new_password}
							error={!!validation.new_password}
						/>
					</Grid>
				</Grid>

			</ActionDialog>
		</>

	)
}

export default Profile
