import { makeStyles } from "@material-ui/core"

import { PLACEHOLDER_COLOR } from "@/components/Messages/MediaMessage/styles"

const useStyles = makeStyles({
	pictureMedia: {
		objectFit: "cover",
		objectPosition: "center",
		width: 70,
		height: 50,
		backgroundColor: PLACEHOLDER_COLOR
	},
	fileMediaContainer: {
		maxWidth: 200,
		width: "auto"
	},
	videoMediaContainer: {
		width: 70,
		height: 50,
		backgroundColor: PLACEHOLDER_COLOR
	}
})

export default useStyles
