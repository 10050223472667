import React, { useState } from "react"
import { useParams } from "react-router-dom"
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	IconButton,
	Typography,
	Grid,
	Paper,
	TablePagination,
	Tooltip,
	Box,
	Chip
} from "@material-ui/core"
import {
	InfoOutlined as InfoIcon,
	Extension as ExtraDataIcon,
	RefreshOutlined as ProcessingIcon,
	Restore as ReceivedIcon
} from "@material-ui/icons"

import {
	Divider,
	Portlet,
	InfoDialog,
	Notification,
	ProductReportClient,
	ProductReportInfo,
	LoadingOverlay,
	ProductReportActionButtonGroup,
	ProductReportHeader,
	TypographyListGroup,
	PopConfirm
} from "@/components"

import { ProductMessage, ProductMessageStatus } from "@/components/ProductReportInfo"

import { formatDateInBrazilianDate, formatDateInHours } from "@/utils/time"
import { getRowsLabel } from "@/utils/table"

import useStyles from "@/pages/Admin/Integration/Management/Report/styles"
import useDebounced from "@/hooks/useDebounce"
import useCustomStyles from "@/styles/custom"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"
import { FiltersListType } from "@/components/FiltersDialog/index"
import ReportFiltersBar from "@/pages/Admin/Integration/Management/Report/ReportFiltersBar"
import { ErrorType } from "@/hooks/useValidation"

export type IntegrationWebhookLogWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	filters?: FiltersListType[]
}

export type IntegrationWebhookLogStatus = ProductMessageStatus | "processing"

export type PaginatedIntegrationWebhookLog = {
	count: number
	rows: IntegrationWebhookLog[]
}

export type IntegrationWebhookLog = {
	id: number
	error: string
	createdAt: Date
	body: unknown
	status: IntegrationWebhookLogStatus
	integrationCustomWebhook: {
		id: number
		title: string
	}
	client?: {
		id: number
		name: string
		nickname: string
		phoneNumber: string
	}
	messages: ProductMessage[]
}

type ReportProps = {
	messageTitles?: string[]
	integrationWebhookLogs: PaginatedIntegrationWebhookLog
	loadWebhookLogs: (where: Partial<IntegrationWebhookLogWhereData>) => Promise<void>
}

export const DEFAULT_WHERE_DATA = {
	page: 0,
	rowsPerPage: 20,
	search: ""
}

const Report: React.FC<ReportProps> = (props) => {
	const { integrationWebhookLogs, loadWebhookLogs, messageTitles } = props

	const { integrationId } = useParams<{ integrationId: string }>()

	const [whereData, setWhereData] = useState<IntegrationWebhookLogWhereData>(DEFAULT_WHERE_DATA)
	const [loading, setLoading] = useState(true)
	const [selectedFilters, setSelectedFilters] = useState<FiltersListType[]>([])

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleLoadWebhookLogs = async (newWhereData: Partial<IntegrationWebhookLogWhereData>) => {
		setLoading(true)

		await loadWebhookLogs({
			...whereData,
			...newWhereData,
			filters: selectedFilters
		})

		setLoading(false)
	}

	const getWebhookLogsWithFilters = async (filters: FiltersListType[]) => {
		setLoading(true)

		setSelectedFilters(filters)

		await loadWebhookLogs({
			...whereData,
			filters: filters
		})

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<IntegrationWebhookLogWhereData>) => {
		setWhereData((currentState) => ({
			...currentState,
			...newData
		}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await handleLoadWebhookLogs({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await handleLoadWebhookLogs({ rowsPerPage })
	}

	const handleShowWebhookLogExtraData = (integrationWebhookLogId: number) => {
		const webhookLog = integrationWebhookLogs?.rows?.find(({ id }) => id === integrationWebhookLogId)

		if (webhookLog?.body) {
			InfoDialog.open({
				title: "Dados recebidos",
				openDialog: true,
				children: (
					<pre>
						<code>
							{JSON.stringify(webhookLog?.body || {}, null, 2)}
						</code>
					</pre>
				)
			})
		}
	}

	const handleRefreshData = async () => {
		await handleLoadWebhookLogs({})

		Notification.success({ message: "A lista de webhooks recebidos foi atualizada com sucesso!" })
	}

	const handleCancelPending = async () => {
		PopConfirm.open({
			title: "Cancelar disparos",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "SIM",
			cancelButtonText: "NÃO",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/integrations/${integrationId}/queue/pending/clear`)

					handleRefreshData()

					Notification.success({ message: "Os webhooks que estavam sendo processados foram cancelados com sucesso!" })
				} catch (error) {
					ErrorHandlerService.handle(error as ErrorType)
				}
			}
		})
	}

	useDebounced(
		async () => await handleLoadWebhookLogs({ search: whereData?.search }),
		whereData.search,
		1250
	)

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				spacing={6}
			>
				<Grid
					item
					xs={12}
				>
					<ProductReportHeader
						title="WEBHOOKS RECEBIDOS"
						subTitle="Entenda o processo de disparo de mensagens:"
						description={(
							<>
								Recebemos o Webhook e criamos uma fila;
								<Divider orientation="horizontal" size={2} />
								Processamos o Webhook para certificar que no pacote de dados que recebemos tem todas as informações corretas que precisamos;
								<Divider orientation="horizontal" size={2} />
								Disparamos a mensagem para o Inbox da Letalk, que será responsável pelo envio da mensagem, existe um intervalo de tempo entre o disparo de uma mensagem e outra.
							</>
						)}
					/>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid
						container
						spacing={3}
						alignItems="center"
					>
						<Grid
							item
							xs={9}
						>
							<ReportFiltersBar
								getDataWithFilters={getWebhookLogsWithFilters}
								handleWhereDataChange={handleWhereDataChange}
								whereData={whereData}
								messages={messageTitles}
							/>
						</Grid>

						<Grid
							item
							xs={3}
						>
							<Grid
								container
								alignItems="center"
								justifyContent="flex-end"
							>
								<ProductReportActionButtonGroup
									refresh={{
										tooltip: "Atualizar",
										onClick: handleRefreshData
									}}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
				>
					<Grid container alignItems="flex-end" justifyContent="flex-end">
						<Grid>
							<Tooltip title="Cancelar webhooks que ainda estão com status “processando”">
								<Box
									display="flex"
									justifyContent="flex-end"
									alignItems="center"
								>
									<Chip
										label="Cancelar envios"
										className={classes.cancelPendingMessageQueueChip}
										onClick={handleCancelPending}
									/>
								</Box>
							</Tooltip>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={1}/>
					{integrationWebhookLogs?.rows?.length > 0 ? (
						<LoadingOverlay
							loading={loading}
						>
							<Paper className={classes.paper}>
								<TableContainer>
									<Table
										stickyHeader
										size="small"
									>
										<TableHead>
											<TableRow>
												<TableCell>
													Mensagem
												</TableCell>

												<TableCell>
													Contato
												</TableCell>

												<TableCell>
													<Grid
														container
														alignItems="center"
														justify="center"
													>
														Data / Hora

														<Divider orientation="vertical" size={2} />

														<Tooltip
															title="Data e hora do recebimento do webhook"
														>
															<InfoIcon />
														</Tooltip>
													</Grid>
												</TableCell>

												<TableCell>
													Infor.
												</TableCell>

												<TableCell />
											</TableRow>
										</TableHead>

										<TableBody
											className={customClasses.reportTableBodyText}
										>
											{integrationWebhookLogs?.rows?.map(integrationWebhookLog => (
												<TableRow
													key={integrationWebhookLog.id}
													tabIndex={-1}
												>
													<TableCell>
														{integrationWebhookLog.integrationCustomWebhook?.title}
													</TableCell>

													<TableCell>
														<ProductReportClient
															client={integrationWebhookLog.client}
														/>
													</TableCell>

													<TableCell>
														<TypographyListGroup>
															<Typography>
																{formatDateInBrazilianDate(new Date(integrationWebhookLog.createdAt))}
															</Typography>

															<Typography
																className={customClasses.italicText}
															>
																{formatDateInHours(new Date(integrationWebhookLog.createdAt))}
															</Typography>
														</TypographyListGroup>
													</TableCell>

													<TableCell>
														<ProductReportInfo
															messages={integrationWebhookLog.messages}
															status={integrationWebhookLog.status}
															error={integrationWebhookLog.error}
															productErrorMap={{
																NoPhoneNumberOnWebhookData: "Não recebemos o número do Whatsapp desse contato",
																InvalidPhoneNumber: "Número de telefone inválido",
																InactiveIntegrationPlatformEventCustomWebhook: "Webhook inativo",
																PlatformEventHandlerNotFound: "Evento não encontrado",
																NoContent: "Mensagem sem conteúdo",
																NoValidContactFound: "Número de telefone não existe no whatsapp",
																EventNotMapped: "Evento não mapeado na Letalk"
															}}
															extraInfo={{
																error: {
																	title: "Erro de Webhook"
																},
																processing: {
																	title: "Processando",
																	backgroundColor: "#E0E6ED",
																	icon: <ProcessingIcon />,
																	tooltipText: "Recemos o webhook, está na fila para ser enviado para o Inbox"
																},
																received: {
																	title: "Webhook recebido",
																	backgroundColor: "#DBEBFF",
																	icon: <ReceivedIcon />,
																	iconColor: "#4B74A8",
																	tooltipText: "O webhook foi recebido e será processado em breve"
																}
															}}
														/>
													</TableCell>

													<TableCell>
														<Tooltip
															title="Dados recebidos"
														>
															<IconButton
																onClick={() => handleShowWebhookLogExtraData(integrationWebhookLog.id)}
															>
																<ExtraDataIcon />
															</IconButton>
														</Tooltip>
													</TableCell>
												</TableRow>

											))}
										</TableBody>

									</Table>
								</TableContainer>

								<TablePagination
									rowsPerPageOptions={[20, 50, 100, 200]}
									component="div"
									count={integrationWebhookLogs?.count}
									rowsPerPage={whereData.rowsPerPage}
									page={whereData.page}
									onPageChange={handlePageChange}
									onChangeRowsPerPage={({ target }) => {
										handlePageChange(target, 0)
										handleChangeRowsPerPage(+target.value)
									}}
									labelRowsPerPage={"Resultados por página:"}
									labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
								/>
							</Paper>
						</LoadingOverlay>
					) : (
						<>
							<Divider size={3} orientation="horizontal" />

							<Typography align="center" variant="h2">
								Nenhum resultado encontrado
							</Typography>
						</>
					)
					}
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default Report
