import React, { ReactNode } from "react"
import { Link, useLocation } from "react-router-dom"
import {
	ListItem,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Chip
} from "@material-ui/core"

import Divider from "@/components/Divider"

import { deviceIsMobile } from "@/utils/checkDevice"

import useStyles from "@/components/ListItemMenu/styles"

type ListItemMenuProps = {
	to?: string
	icon: ReactNode
	text?: string
	isExternalLink?: boolean
	isNewFeature?: boolean
	onClick?: (
		e: React.MouseEvent<HTMLElement>
	) => void
}

const ListItemMenu: React.FC<ListItemMenuProps> = (props) => {
	const { to = "", icon, text, isExternalLink, onClick, isNewFeature } = props

	const classes = useStyles()

	const location = useLocation()

	const isMenuItemActive = (route: string) => location.pathname.includes(route)
	const isMenuItemWithoutAction = !to

	if (isMenuItemWithoutAction) {
		return (
			<MenuItem
				component="a"
				onClick={onClick}
				className={classes.menuItem}
			>
				<ListItemIcon className={classes.listItemIcon}>
					{icon}
				</ListItemIcon>
				{
					text && <ListItemText primary={text} />
				}
			</MenuItem>
		)
	} else {
		if (isExternalLink) {
			return (
				<MenuItem
					component="a"
					href={to}
					target={deviceIsMobile() ? "_SELF" : "_BLANK"}
					onClick={onClick}
					className={classes.menuItem}
				>
					<ListItemIcon className={classes.listItemIcon}>
						{icon}
					</ListItemIcon>
					{
						text && <ListItemText primary={text} />
					}
				</MenuItem>
			)
		} else {
			return (
				<ListItem
					button
					component={Link}
					to={to}
					key={to}
					className={`${isMenuItemActive(to) ? classes.listItemActive : ""}`}
				>
					<ListItemIcon className={classes.listItemIcon}>
						{icon}
					</ListItemIcon>

					{text && (
						<ListItemText
							primary={text}
							className={`${isNewFeature && classes.newFeatureListItemText}`}
						/>
					)}

					{isNewFeature && (
						<>
							<Divider size={1} orientation="vertical" />

							<Chip
								size="small"
								label="Novo"
								className={classes.newFeatureItem}
							/>
						</>
					)}
				</ListItem>
			)
		}
	}
}

export default ListItemMenu
