import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { UserInInstance } from "@/protocols/team"

class TeamService {
	async getUsersInInstance (): Promise<UserInInstance[] | null> {
		try {
			const { data } = await ApiService.get("/user-in-instances/users")

			return data
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}
}

export default new TeamService()
