import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing(3)
	},
	link: {
		fontWeight: "bold",
		display: "block",
		flex: 1,
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	arrowIndicator: {
		transform: "rotate(90deg)",
		color: colors.grayScale[4]
	},
	copyButton: {
		color: colors.grayScale[3]
	},
	headerContent: {
		flex: 1,
		transform: "translateY(18px)"
	},
	linkContainer: {
		flex: 1
	},
	isAdminMode: {
		zIndex: 9999,
		position: "fixed",
		bottom: 0,
		left: 0,
		margin: "0 auto",

		background: "#F1C34E",
		color: colors.grayScale[11],
		fontWeight: "bold",
		textTransform: "uppercase",

		padding: theme.spacing(0.5, 2)
	}
}))

export default useStyles
