import React from "react"
import { Route } from "react-router-dom"

import Typography from "@/pages/Development/Typography"

const DevelopmentRoutes = [
	<Route
		exact
		key="typography"
		path="/dev/typography"
		component={Typography}
	/>
]

export default DevelopmentRoutes
