import React, { useEffect, useState } from "react"

import { AdminRoutes } from "@/routes/private"
import { Container, Grid } from "@material-ui/core"

import useStyles from "@/pages/Admin/styles"
import { Divider, Navbar, PopConfirm } from "@/components"
import Menu from "@/pages/Admin/Menu"
import { useLocation, matchPath } from "react-router"
import useDidMount from "@/hooks/useDidMount"
import ApiService from "@/services/Api"
import MerlinDialog from "@/components/MerlinDialog"
import { useGlobalStateStore } from "@/store/GlobalState"
import { timeout, SECOND_IN_MS } from "@/utils/time"

const ROUTES_WITHOUT_CONTAINER_WIDTH_LIMITATION: string[] = [
	"/admin/bot",
	"/admin/bots/:chatBotFlowId"
]

const Admin: React.FC = () => {
	const globalStateStore = useGlobalStateStore()

	const [openDrawer, setOpenDrawer] = useState(false)

	const [hideMerlinCloseIcon, setHideMerlinCloseIcon] = useState(true)
	const [openMerlin, setOpenMerlin] = useState(false)
	const [merlinInteractionData, setMerlinInteractionData] = useState({
		id: 0,
		status: "finished"
	})

	const classes = useStyles({ openDrawer })
	const location = useLocation()

	const isCurrentPath = (path: string) => {
		const matchedPath = matchPath(location.pathname, { path, exact: true, strict: true })

		return 	Boolean(matchedPath)
	}

	const handleDrawerOpen = () => {
		setOpenDrawer(true)
	}

	const handleDrawerClose = () => {
		setOpenDrawer(false)
	}

	const closeMerlin = () => {
		setOpenMerlin(false)
	}

	const onCloseMerlinModal = () => {
		PopConfirm.open({
			title: "Fechar Wizard",
			description: "Tem certeza? Essas perguntas foram pensadas para deixar sua experiência ainda mais incrível com a Letalk.",
			onConfirm: async () => {
				closeMerlin()
			},
			confirmButtonText: "FECHAR"
		})
	}

	useEffect(() => {
		handleDrawerClose()
	}, [location.pathname])

	useDidMount(() => {
		// Semicolon needed due JS error with declaration followed by auto called function
		window.name = "inbox_admin_page"
		const userIsInstanceOwner = globalStateStore?.user?.is_instance_owner

		const merlinStartDate = new Date("2022/09/01")
		const isInstanceCreatedAfterMerlinStartDate = new Date(globalStateStore?.instance?.instance_created_at) >= merlinStartDate

		if (userIsInstanceOwner && isInstanceCreatedAfterMerlinStartDate) {
			(async () => {
				const merlinInteraction = await ApiService.get("/merlin-interactions")
				setMerlinInteractionData(merlinInteraction.data)

				const isMerlinPending = merlinInteraction.data.status === "pending"
				setOpenMerlin(isMerlinPending)
			})()
		}
	})
	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
		>
			<Navbar
				title='PAINEL DO GESTOR'
				theme={"dark"}
				onOpen={handleDrawerOpen}
			/>

			<Grid
				container
				className={classes.adminContainer}
			>
				<Menu
					openDrawer={openDrawer}
					onClose={handleDrawerClose}
				/>

				<Grid
					container
					justify="center"
					className={classes.routesContainer}
				>
					<Container
						className={classes.routesContent}
						style={{
							...(ROUTES_WITHOUT_CONTAINER_WIDTH_LIMITATION.some(path => isCurrentPath(path)) && ({
								maxWidth: "none"
							}))
						}}
					>
						<Divider size={4} orientation="horizontal" />

						<AdminRoutes />
						<MerlinDialog
							title={""}
							onClose={() => { onCloseMerlinModal() }}
							openDialog={openMerlin}
							fullWidth={true}
							hideCloseButton={true}
							hideCloseIcon={hideMerlinCloseIcon}
							preventCloseOnCancelButtonClick={true}
							maxWidth={"md"}
							merlinUrl="https://app.gomerlin.com.br/chat/f1b8a09c-aa7d-4bc9-abcb-afcf75a2601b"
							initVariables={{
								firstName: (globalStateStore?.user?.name?.split(" ")[0]),
								phone: globalStateStore?.user.phone_number,
								email: globalStateStore.user.email,
								isTrial: globalStateStore.instance.subscriptionData?.isTrial,
								startDate: (new Date())
							}}
							onFormAnswered={async () => {
								const newMerlinInteraction = await ApiService.put(`/merlin-interactions/${merlinInteractionData.id}/finish-interaction`)
								setMerlinInteractionData(newMerlinInteraction.data)
								await timeout(5 * SECOND_IN_MS)
								closeMerlin()
							}}
							onMerlinInit={async () => {
								await timeout(5 * SECOND_IN_MS)
								setHideMerlinCloseIcon(false)
							}}
						>
						</MerlinDialog>

						<Divider size={4} orientation="horizontal" />
					</Container>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Admin
