import { getFormattedCounterTime } from "@/utils/time"
import { useState, useRef, useEffect } from "react"

type UseCounterProps = {
	startFromSeconds: number
	endAtSeconds?: number
	direction: "up" | "down"
	onTimeout?: () => void
	onChange?: (elapsedSeconds: number) => void
}

const useCounter = (props: UseCounterProps) => {
	const {
		direction,
		onChange,
		startFromSeconds,
		endAtSeconds,
		onTimeout
	} = props

	const startSeconds = useRef<number>(startFromSeconds)
	const [elapsedSeconds, setElapsedSeconds] = useState(startSeconds.current)

	const counterInterval = useRef<NodeJS.Timeout | number>()

	const handleTimeout = () => {
		clearInterval(counterInterval.current as number)

		if (onTimeout) {
			onTimeout()
		}
	}

	const handleStart = () => {
		setElapsedSeconds(startSeconds.current)

		counterInterval.current = setInterval(() => {
			setElapsedSeconds(lastState => {
				let updatedState = lastState

				if (direction === "up") {
					updatedState++
				} else {
					updatedState--
				}

				if (onChange) {
					onChange(updatedState)
				}

				if (onTimeout) {
					if (direction === "up") {
						const timedOut = updatedState >= (endAtSeconds as number)

						if (timedOut) {
							handleTimeout()
						}
					} else {
						const timedOut = updatedState <= (endAtSeconds as number)

						if (timedOut) {
							handleTimeout()
						}
					}
				}

				return updatedState
			})
		}, 1000)
	}

	const handleReset = () => {
		setElapsedSeconds(startSeconds.current)

		clearInterval(counterInterval.current as number)
	}

	const formattedElapsedSeconds = getFormattedCounterTime(elapsedSeconds)

	useEffect(() => {
		startSeconds.current = startFromSeconds
	}, [startFromSeconds])

	return {
		start: handleStart,
		reset: handleReset,
		formattedElapsedSeconds,
		elapsedSeconds
	}
}

export default useCounter
