const colors = {
	palette: {
		primary: "#7E0DD6",
		confirm: "#1AB956",
		error: "#D84910",
		tag1: "#5CC395",
		tag2: "#5BD1D7",
		tag3: "#F1C34E",
		link: "#007CBA",
		background: "#F8F9FA",
		button1: "#03DAC5",
		activeAttendance: "#06D755",
		inactiveAttendance: "#C1C1C1",
		whatsappSentMessage: "#E7FFDB",
		triggeredMessage: "#9350C8",
		triggeredMessageLighter: "#E1D3F8"
	},
	grayScale: {
		1: "#212429",
		2: "#DDE2E5",
		3: "#ACB5BD",
		4: "#495057",
		5: "#969696",
		6: "#ABABAB",
		7: "#BFBFBF",
		8: "#D0D0D0",
		9: "#E1E1E1",
		10: "#F0F0F0",
		11: "#FFFFFF",
		12: "#E0E6ED"
	}
}

export default colors
