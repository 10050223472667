import React, { useState } from "react"
import {
	Typography,
	Grid,
	Tab,
	Tabs
} from "@material-ui/core"

import {
	Divider,
	Loading,
	Notification
} from "@/components"

import { integrationInfo } from "@/utils/integration"

import Breadcrumb from "@/components/Breadcrumb"
import Settings from "@/pages/Admin/ActiveCampaignPlugin/Settings"
import useStyles from "@/pages/Admin/ActiveCampaignPlugin/styles"
import ApiService from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"
import { ActiveCampaignPluginSkeleton } from "@/skeletons/Admin/ActiveCampaignPluginSkeletons"

type ActiveCampaignPluginTabType = "settings"

type ActiveCampaignPluginTab = {
	title: string
	type: ActiveCampaignPluginTabType
}

export type ActiveCampaignPluginSettings = {
	id?: number
	instance_id?: number
	plugin_type?: string
	status?: "enabled" | "disabled"
	settings?: {
		api_key?: string
		base_url?: string
		add_tag?: boolean
		create_tag?: boolean
		remove_tag_association?: boolean
	}
}

const ACTIVE_CAMPAIGN_PLUGIN_TABS: ActiveCampaignPluginTab[] = [
	{
		title: "CONFIGURAÇÕES",
		type: "settings"
	}
]

const ActiveCampaignPlugin = () => {
	const [loading, setLoading] = useState(false)
	const [currentActiveCampaignPluginTabType, setCurrentActiveCampaignPluginTabType] = useState<ActiveCampaignPluginTabType>(ACTIVE_CAMPAIGN_PLUGIN_TABS[0]?.type)
	const [pluginSettings, setPluginSettings] = useState<ActiveCampaignPluginSettings>({})

	const classes = useStyles()

	const handleChangeCurrentActiveCampaignPluginTabType = (type: ActiveCampaignPluginTabType): void => {
		setCurrentActiveCampaignPluginTabType(type)
	}

	const handleGetPluginSettings = async () => {
		setLoading(true)
		try {
			const response = await ApiService.get("/plugin-settings", {
				params: {
					pluginType: "active-campaign"
				}
			})

			setPluginSettings({
				...pluginSettings,
				...response.data.dataValues
			})
		} catch (error) {
			Notification.error({ message: "Não foi possível carregar os dados do plugin." })
		}
		setLoading(false)
	}

	useDidMount(() => {
		handleGetPluginSettings()
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<ActiveCampaignPluginSkeleton />}
		>
			<Grid
				container
				direction="column"
			>
				<Grid
					alignItems="center"
					justify="space-between"
					container
				>
					<Typography
						variant="h4"
						color="textPrimary"
						className={classes.breadcrumb}
					>
						<Breadcrumb
							data={[
								{ name: "PLUGINS", pathname: "/admin/plugins/active-campaign" },
								{ name: "ACTIVE CAMPAIGN", pathname: "/admin/plugins/active-campaign" }
							]}
						/>

						<Divider orientation="vertical" size={2} />
					</Typography>

					<img
						src={integrationInfo.ActiveCampaign?.logo}
						alt="Active Campaign logo"
						className={classes.logoImg}
					/>
				</Grid>

				<Divider size={3} orientation="horizontal" />

				<Tabs
					color="primary"
					value={currentActiveCampaignPluginTabType}
					className={classes.tabs}
					onChange={(_, value) => handleChangeCurrentActiveCampaignPluginTabType(value)}
					classes={{
						indicator: classes.tabIndicator
					}}
				>
					{ACTIVE_CAMPAIGN_PLUGIN_TABS.map(ActiveCampaignPluginTab => (
						<Tab
							key={ActiveCampaignPluginTab.title}
							label={ActiveCampaignPluginTab.title}
							value={ActiveCampaignPluginTab.type}
							className={classes.tab}
						/>
					))}
				</Tabs>

				<Divider size={3} orientation="horizontal" />

				{currentActiveCampaignPluginTabType === "settings" && (
					<Settings
						pluginSettings={pluginSettings}
						setPluginSettings={setPluginSettings}
					/>
				)}
			</Grid>
		</Loading>
	)
}

export default ActiveCampaignPlugin
