import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

export const MAX_MESSAGE_CONTENT_WIDTH = 446

const useStyles = makeStyles(theme => ({
	messageContent: {
		display: "inline-block",
		height: "auto",
		width: "auto",
		position: "relative",
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(3, 2, 1, 2),
		borderRadius: theme.spacing(1),
		boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
		maxWidth: MAX_MESSAGE_CONTENT_WIDTH,
		wordBreak: "break-word",
		whiteSpace: "break-spaces",
		color: colors.grayScale[4],
		fontSize: "14px",
		lineHeight: "24px"
	}
}))

export default useStyles
