import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Portlet } from "@/components"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	},
	indicatorContainer: {
		height: "100%",
		minHeight: "200px"
	}
})

const AttendancesMetricsSkeleton = () => {
	const classes = useStyles()

	const chartKeys = [0, 1]

	const indicatorsKeys = [0, 1]

	return (
		<Grid container spacing={2}>
			{chartKeys.map(key => {
				return (
					<Grid key={key} item md={6} xs={12}>
						<Portlet style={{ height: "100%" }}>
							<Grid container spacing={2} justify="center">
								<Grid item xs={12}>
									<Skeleton className={classes.skeleton} height={28} width={120} />
								</Grid>

								<Grid item xs={12}>
									<Skeleton className={classes.skeleton} height={300} width="100%" />
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				)
			})}

			{indicatorsKeys.map(key => {
				return (
					<Grid key={key} item md={6} xs={12}>
						<Portlet style={{ height: "100%" }}>
							<Grid container spacing={2} className={classes.indicatorContainer} justify="center">
								<Grid item xs={12}>
									<Skeleton className={classes.skeleton} height={28} width={280} />
								</Grid>

								<Grid item >
									<Grid container alignItems="center" direction="column" spacing={1}>
										<Grid item>
											<Skeleton className={classes.skeleton} height={50} width={100} />
										</Grid>

										<Grid item>
											<Skeleton className={classes.skeleton} height={14} width={60} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Portlet>
					</Grid>
				)
			})}
		</Grid>
	)
}

export default AttendancesMetricsSkeleton
