import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	Grid,
	makeStyles
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	skeleton: {
		transform: "scale(1)",
		marginLeft: theme.spacing(3)
	},
	actions: {
		transform: "scale(1)",
		marginRight: theme.spacing(3)
	},
	title: {
		marginTop: theme.spacing(1)
	},
	notes: {
		marginTop: theme.spacing(1)
	},
	note: {
		transform: "scale(1)",
		marginTop: "10px",
		width: "100%"
	}
}))

const ClientNotesSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Grid container alignItems="center" justify="space-between" className={classes.title}>
				<Grid item xs>
					<Skeleton className={classes.skeleton} width={60} height={20} />
				</Grid>
				<Skeleton className={classes.actions} width={60} height={20} />
			</Grid>
			<Grid container alignItems="center" className={classes.notes}>
				<Skeleton className={classes.note} height={50} />
				<Skeleton className={classes.note} height={50} />
				<Skeleton className={classes.note} height={50} />
			</Grid>
		</>
	)
}

export default ClientNotesSkeleton
