import { Notification, PopConfirm, Portlet } from "@/components"
import { Grid, IconButton, List, ListItem, ListItemText, Popover, Typography } from "@material-ui/core"
import React from "react"
import ApiService from "@/services/Api"
import { MoreVert as MoreVertIcon } from "@material-ui/icons"
import useStyles from "@/pages/Admin/ClientCatalog/ContactsFilters/ContactsFiltersListItem/styles"
import { ContactsFiltersType } from "@/pages/Admin/ClientCatalog/ContactsFilters"
import { useGlobalStateStore } from "@/store/GlobalState"

interface ContactsFiltersListItemProps {
	contactFilter: ContactsFiltersType
	setFilterSavedSelectedToEdit: React.Dispatch<React.SetStateAction<ContactsFiltersType>>
	setOpenCreateOrEditFilterDialog: React.Dispatch<React.SetStateAction<boolean>>
	handleSelectContactFilter: (filter: ContactsFiltersType) => void
	handleGetContactsFilters: () => Promise<void>
}

const ContactsFiltersListItem = (props: ContactsFiltersListItemProps) => {
	const { setFilterSavedSelectedToEdit, setOpenCreateOrEditFilterDialog, handleGetContactsFilters, handleSelectContactFilter } = props
	const contactFilter = props.contactFilter

	const [moreOptionsMenuAnchorEl, setMoreOptionsMenuAnchorEl] = React.useState<null | HTMLElement>(null)
	const moreOptionsMenuIsOpen = Boolean(moreOptionsMenuAnchorEl)

	const classes = useStyles()

	const globalStateStore = useGlobalStateStore()

	const handleMoreOptionsMenuClose = () => {
		setMoreOptionsMenuAnchorEl(null)
	}

	const handleMoreOptionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setMoreOptionsMenuAnchorEl(event.currentTarget)
	}

	const handleEditContactFilter = () => {
		if (contactFilter) {
			setFilterSavedSelectedToEdit && setFilterSavedSelectedToEdit(contactFilter)
			setOpenCreateOrEditFilterDialog(true)
		}
	}

	const handleDeleteContactFilter = async () => {
		PopConfirm.open({
			onConfirm: async () => {
				try {
					await ApiService.delete(`/clients/contacts-filters/${contactFilter.id}`,
						{
							params: {
								inboxChannelId: globalStateStore.whatsappChannel?.id
							}
						}
					)

					await handleGetContactsFilters()

					Notification.success({ message: "Filtro excluído com sucesso!" })
				} catch (error) {
					Notification.error({ message: "Houve um erro ao buscar os filtros" })
				}
			},
			title: "Excluir Filtro",
			confirmButtonText: "EXCLUIR"
		})
	}

	return <Grid item key={contactFilter.id} >
		<Portlet>
			<ListItem>
				<Grid container direction="row" justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography variant="body1" className={classes.contactFilterItem} onClick={
							() => handleSelectContactFilter(contactFilter)
						}>
							{contactFilter.title}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton
							aria-label="more"
							aria-controls="long-menu"
							aria-haspopup="true"
							size="small"
							onClick={handleMoreOptionsMenuClick}
						>
							<MoreVertIcon />
						</IconButton>
						<Popover
							open={moreOptionsMenuIsOpen}
							anchorEl={moreOptionsMenuAnchorEl}
							onClose={handleMoreOptionsMenuClose}
							anchorOrigin={{ vertical: "top", horizontal: "left" }}
							transformOrigin={{ vertical: "top", horizontal: "left" }}
						>
							<List>
								<ListItem
									button
									onClick={() => {
										handleEditContactFilter()
										handleMoreOptionsMenuClose()
									}}
								>
									<ListItemText primary="EDITAR" />
								</ListItem>

								<ListItem
									button
									onClick={() => {
										handleMoreOptionsMenuClose()
										handleDeleteContactFilter()
									}}
								>
									<ListItemText primary="EXCLUIR" />
								</ListItem>
							</List>
						</Popover>
					</Grid>
				</Grid>
			</ListItem>
		</Portlet>
	</Grid>
}

export default ContactsFiltersListItem
