import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	Grid,
	Typography,
	Avatar,
	Container,
	useTheme,
	useMediaQuery,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Button,
	Box
} from "@material-ui/core"
import {
	ExitToApp as LogoutIcon,
	AccountCircleOutlined as ProfileIcon,
	Menu as MenuIcon,
	HelpOutline as HelpIcon,
	CardGiftcard as GiftIcon
} from "@material-ui/icons"

import { useGlobalStateStore } from "@/store/GlobalState"
import AuthService from "@/services/Auth"

import { Divider } from "@/components"
import useStyles from "@/components/Navbar/styles"
import colors from "@/styles/colors"
import { isSmallScreen } from "@/utils/checkDevice"

import lightLogoImg from "@/assets/images/logos/letalk-logo.svg"
import darkLogoImg from "@/assets/images/logos/letalk-logo-white.svg"

type NavbarProps = {
	title: string
	theme: "light" | "dark"
	onOpen: () => void
}

const themeStyles = {
	light: {
		backgroundHexColor: colors.grayScale[11],
		colorText: colors.grayScale[4],
		logo: lightLogoImg
	},
	dark: {
		backgroundHexColor: colors.palette.primary,
		colorText: "#ffffff",
		logo: darkLogoImg
	}
}

const Navbar: React.FC<NavbarProps> = (props) => {
	const {
		title,
		theme,
		onOpen
	} = props

	const globalStateStore = useGlobalStateStore()
	const MuiTheme = useTheme()
	const classes = useStyles(themeStyles[theme])
	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const openMenu = Boolean(anchorEl)

	const userStore = globalStateStore.user

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout(userStore.extra_data?.is_account_user)
	}

	const handleDrawerOpen = () => {
		onOpen()
	}

	return (
		<Grid
			container
			alignItems="center"
			className={classes.navbarContainer}
		>
			<Container maxWidth={"xl"}>
				<Grid
					container
					justify="space-between"
					alignContent="center"
					alignItems="center"
				>
					<Grid item>
						<Grid
							container
							className={classes.leftContainer}
							alignItems="center"
							wrap="nowrap"
						>
							{
								isSmallScreen
									? <IconButton
										color="inherit"
										onClick={handleDrawerOpen}
										className={classes.iconButton}
									>
										<MenuIcon
											className={classes.menuIcon}
										/>
									</IconButton>
									:	<img
										alt="Letalk"
										src={themeStyles[theme].logo}
										className={classes.logoImg}
									/>
							}

							<Divider orientation="vertical" size={2} />

							{
								!isSmall &&
							<Typography
								variant="button"
								color="inherit"
								className={classes.titleText}
							>
								{title}
							</Typography>
							}
						</Grid>
					</Grid>
					<Grid item>
						<Grid
							container
							className={classes.rightContainer}
							wrap="nowrap"
							alignItems="center"
						>
							<Grid item>
								<Button
									style={{ backgroundColor: "white" }}
									size="small"
									variant="contained"
									href="https://letalk.com.br/seja-um-afiliado/"
									target={"_blank"}
									startIcon={
										<GiftIcon
											color="primary"
										/>
									}
								>
									<Typography
										noWrap
										color="primary"
									>
										Indique e ganhe
									</Typography>
								</Button>
							</Grid>

							<Box className={classes.iconNavGroup}>
								<Grid item>
									<IconButton
										onClick={() => { window.open("https://bit.ly/3ou8kcS", "_blank") }}
									>
										<HelpIcon
											style={{
												fontSize: "24px",
												color: "white"
											}}
											fontSize="large"
										/>
									</IconButton>
								</Grid>
							</Box>

							{
								!isSmall &&
								<>
									<Grid
										container
										direction="column"
										alignItems="flex-end"
									>
										<Typography
											variant="h2"
											color="inherit"
											className={classes.nameText}
										>
											{userStore?.name}
										</Typography>

										<Typography
											color="inherit"
											variant="h2"
											className={classes.emailText}
										>
											{userStore?.email}
										</Typography>
									</Grid>

									<Divider orientation="vertical" size={3} />
								</>
							}

							<Avatar
								aria-label="more"
								aria-controls="header-menu"
								aria-haspopup="true"
								onClick={handleClickMenu}
								className={classes.profile}
							>
								{
							userStore
							?.name
							.charAt(0)
							.toUpperCase()
								}
							</Avatar>

							<Menu
								id="header-menu"
								anchorEl={anchorEl}
								keepMounted
								open={openMenu}
								onClose={handleCloseMenu}
								autoFocus={false}
								PaperProps={{
									style: {
										marginTop: 40
									}
								}}
							>
								<MenuItem
									component={Link}
									to={"/admin/profile"}
									onClick={handleCloseMenu}
								>
									<ListItemIcon>
										<ProfileIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Meus dados" />
								</MenuItem>

								<MenuItem
									component="a"
									href="#logout"
									onClick={handleLogout}
								>
									<ListItemIcon>
										<LogoutIcon fontSize="small" />
									</ListItemIcon>
									<ListItemText primary="Sair" />
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default Navbar
