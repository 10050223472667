import React, { useState } from "react"
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	Fab,
	Popover,
	Tooltip,
	Divider as MuiDivider
} from "@material-ui/core"

import {
	ExpandMore as MessageOptionsIcon,
	Smartphone as ExternalPlatformIcon
} from "@material-ui/icons"

import {
	Divider,
	MessageContentBox,
	InfoDialog,
	PopConfirm
} from "@/components"

import { formatMessageDate } from "@/utils/time"
import { isSmallScreen } from "@/utils/checkDevice"
import { getMessageColor } from "@/utils/message"

import ReplyMessage from "@/pages/Attendance/Chat/ConversationPanel/MessageList/ReplyMessage"
import MessageStatus from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageStatus"
import MessageFeature from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageFeature"
import MessageDeletedContent from "@/pages/Attendance/Chat/ConversationPanel/MessageList/MessageDeletedContent"

import { useGlobalStateStore } from "@/store/GlobalState"
import { Message, useChatGlobalStateStore } from "@/store/ChatGlobalState"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/MessageList/CommonMessage/BaseMessage/styles"
import useSocket from "@/hooks/useSocket"
import useCustomMemo from "@/hooks/useCustomMemo"

export type BaseMessageProps = {
	message: Message
	childContent: React.ReactElement
	childContentFooter?: React.ReactElement
	childContainerFooter?: React.ReactElement
	messageContentBoxStyle?: React.CSSProperties
}

const BaseMessage: React.FC<BaseMessageProps> = (props) => {
	const {
		message,
		childContent,
		childContentFooter,
		childContainerFooter,
		messageContentBoxStyle
	} = props

	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const socket = useSocket()

	const inboxChannelChat = chatGlobalStateStore.chat.getById(message.inboxChannelChatId)
	const currentChatAttendanceStatus = chatGlobalStateStore.chat.current?.attendance?.status

	const messageId = message.id
	const inboxChannelChatId = message.inboxChannelChatId
	const content = message.content
	const createdAt = message.createdAt
	const deletedAt = message.deletedAt
	const extraData = message.extraData
	const feature = message.feature
	const status = message.status
	const sentByCustomer = message.sentByCustomer
	const senderName = message.senderName
	const sentByExternalPlatform = message.sentByExternalPlatform
	const type = message.type
	const uploadingMedia = message.uploadingMedia
	const replyMessage = message.replyMessage && {
		id: message.replyMessage.id,
		caption: message.replyMessage.caption,
		content: message.replyMessage.content,
		senderName: chatGlobalStateStore.client.current?.nickname || "",
		sentByCustomer: message.replyMessage.sentByCustomer,
		type: message.replyMessage.type,
		extraData: message.replyMessage.extraData
	}

	const showMessageOptionsButton = (
		currentChatAttendanceStatus === "active" &&
		!message.deletedAt &&
		!chatGlobalStateStore.conversationPanel.inputBlocked
	)

	const [messageOptionsRef, setMessageOptionsRef] = useState<HTMLElement | null>(null)
	const [optionsIconVisible, setOptionsIconVisible] = useState(false)

	const handleShowOptionsIcon = () => {
		setOptionsIconVisible(true)
	}

	const handleHideOptionsIcon = () => {
		setOptionsIconVisible(false)
	}

	const handleCloseMessageOptionsPopover = () => {
		setMessageOptionsRef(null)
	}

	const handleOpenMessageOptionsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMessageOptionsRef(event.currentTarget)
	}

	const handleClose = () => {
		handleCloseMessageOptionsPopover()
		handleHideOptionsIcon()
	}

	const handleReplyMessage = () => {
		handleClose()

		chatGlobalStateStore.conversationPanel.replyMessage.change(messageId)

		chatGlobalStateStore.conversationPanel.textInput.focus()
	}

	const handleDeleteMessage = () => {
		handleClose()

		if (!chatGlobalStateStore.chat.current) {
			return
		}

		const formattedMessageDate = new Date(message.createdAt)

		const beforeYesterdayDate = new Date()
		beforeYesterdayDate.setHours(beforeYesterdayDate.getHours() - 3)

		if (formattedMessageDate < beforeYesterdayDate) {
			InfoDialog.open({
				title: "Está mensagem não pode mais ser apagada",
				children: <Typography key="alert">
					Permitimos deletar mensagens até 3 horas após o envio, se for necessário deletar após esse prazo, considere fazer a exclusão pelo próprio celular!
				</Typography>,
				openDialog: true
			})
		} else {
			PopConfirm.open({
				title: "Excluir mensagem",
				description: "Deseja excluir esta mensagem?",
				confirmButtonText: "EXCLUIR",
				onConfirm: async () => {
					if (!chatGlobalStateStore.chat.current) {
						return
					}

					const { id, inboxChannelId, channelType } = chatGlobalStateStore.chat.current

					socket.deleteMessage({
						inboxChannelId,
						inboxChannelChatId: id,
						inboxChannelChatMessageId: messageId,
						channelType
					})
				}
			})
		}
	}

	const handleShowAdditionalInfo = () => {
		handleClose()

		InfoDialog.open({
			title: "Informações adicionais",
			children: (
				<Typography
					variant="body1"
				>
					<b>ID da Mensagem:</b> {messageId}
					<br />
					<b>ID do Chat:</b> {inboxChannelChatId}
					<br />
					<br />
					<b>Tipo da Mensagem:</b> {type}
					<br />
					<b>Status da Mensagem:</b> {status}
					<br />
					<b>Conteúdo da Mensagem:</b> {content}
					<br />
					<br />
					<b>Data de criação da Mensagem (Whatsapp):</b> {new Date(createdAt).toLocaleString()}
					<br />
					<b>Data de criação da Mensagem (Inbox):</b> {extraData?.inboxCreatedAt ? (
						new Date(extraData?.inboxCreatedAt).toLocaleString()
					) : "Data não encontrada"}
				</Typography>
			),
			openDialog: true
		})
	}

	const isGroupChat = inboxChannelChat?.type === "group"
	const messageOptionsPopoverOpened = Boolean(messageOptionsRef)
	const showExternalPlatformIcon = sentByExternalPlatform && sentByCustomer
	const showOptionsButton = (
		showMessageOptionsButton &&
		(optionsIconVisible || isSmallScreen)
	)

	if (isGroupChat && replyMessage) {
		replyMessage.senderName = message?.replyMessage?.senderName || ""
	}

	const getSentTooltipText = () => {
		if (feature === "attendance") {
			return `Enviada por: ${senderName}`
		}

		if (!sentByExternalPlatform) {
			return "Enviada pelo sistema"
		}

		return ""
	}

	return useCustomMemo(() => (
		<Grid
			container
			direction="column"
			className={classes.messageContainer}
			justify={sentByCustomer ? "flex-end" : "flex-start"}
			alignItems={sentByCustomer ? "flex-end" : "flex-start"}
		>
			<MessageContentBox
				id={messageId}
				onMouseEnter={() => handleShowOptionsIcon()}
				onMouseLeave={() => handleHideOptionsIcon()}
				style={{
					backgroundColor: getMessageColor(sentByCustomer),
					...messageContentBoxStyle
				}}
			>
				<Grid
					container
					alignItems="center"
					justify="flex-end"
					className={classes.messageInfoContainer}
				>
					{showExternalPlatformIcon && (
						<Tooltip
							title="Essa mensagem foi enviada pelo Whatsapp (Celular ou Web)."
						>
							<ExternalPlatformIcon
								fontSize="small"
								className={classes.infoIcon}
							/>
						</Tooltip>
					)}

					<MessageFeature
						message={message}
					/>

					{showOptionsButton && (
						<>
							<Popover
								open={messageOptionsPopoverOpened}
								anchorEl={messageOptionsRef}
								onClose={handleCloseMessageOptionsPopover}
								anchorOrigin={{ vertical: "top", horizontal: "left" }}
								transformOrigin={{ vertical: "top", horizontal: "left" }}
							>
								<List>
									<ListItem
										button
										onClick={() => handleReplyMessage()}
									>
										<ListItemText primary="Responder" />
									</ListItem>

									{sentByCustomer && (
										<ListItem
											button
											onClick={() => handleDeleteMessage()}
										>
											<ListItemText primary="Apagar para todos" />
										</ListItem>
									)}

									{globalStateStore?.user?.extra_data?.is_tester && (
										<ListItem
											button
											onClick={handleShowAdditionalInfo}
										>
											<ListItemText primary="Informações adicionais" />
										</ListItem>
									)}
								</List>
							</Popover>

							<Fab
								onClick={(event) => handleOpenMessageOptionsPopover(event)}
								/**
								 * Setting up that way instead of using className since for some reason
								 * the className disappears in production.
								 */
								style={{
									minHeight: 30,
									height: 30,
									width: 30,
									boxShadow: "none",
									backgroundColor: "transparent"
								}}
							>
								<MessageOptionsIcon />
							</Fab>
						</>
					)}
				</Grid>

				{(replyMessage && !deletedAt) && (
					<>
						<ReplyMessage
							id={replyMessage.id}
							senderName={replyMessage.senderName}
							sentByCustomer={replyMessage.sentByCustomer}
							content={replyMessage.content}
							type={replyMessage.type}
							caption={replyMessage.caption}
							extraData={replyMessage.extraData}
						/>

						<Divider orientation="horizontal" size={1} />
					</>
				)}

				{(isGroupChat && !sentByCustomer) && (
					<>
						<Typography
							variant="caption"
							className={classes.messageSenderName}
						>
							{message.senderName}
						</Typography>

						<Divider orientation="horizontal" size={0.5} />
					</>
				)}

				{deletedAt ? (
					<MessageDeletedContent
						sentByCustomer={sentByCustomer}
					/>
				) : (
					childContent
				)}

				<Divider orientation="horizontal" size={1} />

				<Grid
					container
					justify="flex-end"
					alignItems="center"
				>
					<Tooltip title={getSentTooltipText()}>
						<Typography
							variant="caption"
							className={classes.messageTimeText}
							color="textPrimary"
						>
							{formatMessageDate(new Date(createdAt))}
						</Typography>
					</Tooltip>

					{(sentByCustomer && !deletedAt) && (
						<>
							<Divider orientation="vertical" size={0.5} />

							<MessageStatus
								status={status}
							/>
						</>
					)}
				</Grid>

				{childContentFooter && (
					<>
						<Divider orientation="horizontal" size={0.5} />

						<MuiDivider />

						<Divider orientation="horizontal" size={0.5} />

						{childContentFooter}
					</>
				)}
			</MessageContentBox>

			{childContainerFooter && (
				<>
					<Divider orientation="horizontal" size={0.5} />

					{childContainerFooter}
				</>
			)}
		</Grid>
	), [
		createdAt,
		deletedAt,
		status,
		uploadingMedia,
		optionsIconVisible,
		messageOptionsRef,
		currentChatAttendanceStatus
	])
}

export default BaseMessage
