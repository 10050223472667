import React, { useState } from "react"
import { Button, Grid, Link, Typography, Radio, Tooltip } from "@material-ui/core"
import { List as MenuIcon } from "@material-ui/icons"

import useStyles, { MENU_MESSAGE_WIDTH } from "@/components/Messages/MenuMessage/styles"
import useCustomStyles from "@/styles/custom"

import Divider from "@/components/Divider"
import ActionDialog from "@/components/ActionDialog"

import { IMessageExtraData } from "@/protocols/channel"

type MenuMessageProps = {
	extraData: IMessageExtraData
}

export const MenuMessageContentFooter: React.FC<MenuMessageProps> = (props) => {
	const {
		extraData
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [showMenuDialogOpened, setShowMenuDialogOpened] = useState(false)

	const handleOpenShowMenuDialog = () => {
		setShowMenuDialogOpened(true)
	}

	const handleCloseShowMenuDialog = () => {
		setShowMenuDialogOpened(false)
	}

	const title = extraData?.menu?.buttonText || ""

	return (
		<Grid>
			<Link
				component={Button}
				fullWidth
				className={`${classes.showOptionsButton} ${customClasses.inlineText}`}
				onClick={handleOpenShowMenuDialog}
			>
				<MenuIcon />

				<Divider orientation="vertical" size={0.5} />

				{title}
			</Link>

			<ActionDialog
				title={title.toUpperCase()}
				hideCloseButton
				onClose={handleCloseShowMenuDialog}
				openDialog={showMenuDialogOpened}
				maxWidth="sm"
				fullWidth
			>
				<Grid
					container
					spacing={3}
				>
					{extraData?.menu?.sections?.map((section, index) => (
						<Grid
							item
							xs={12}
							key={index}
						>
							{section.rows.map(row => (
								<Grid
									container
									spacing={2}
									key={row.id}
								>
									<Tooltip
										title="Somente seu destinatário pode escolher uma opção."
									>
										<Grid
											item
											xs={12}
										>
											<Grid
												container
												component={Button}
												className={classes.menuItem}
												disabled
											>
												<Typography
													variant="body1"
													color="textPrimary"
												>
													{row.title}
												</Typography>

												<Radio disabled />
											</Grid>
										</Grid>
									</Tooltip>
								</Grid>
							))}
						</Grid>
					))}
				</Grid>
			</ActionDialog>
		</Grid>
	)
}

export const MenuMessageContentBoxStyle: React.CSSProperties = {
	width: MENU_MESSAGE_WIDTH
}
