import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	chatMessagesContainer: {
		padding: theme.spacing(1),
		flex: 1,
		overflowY: "auto",
		overflowX: "hidden",
		position: "absolute",
		top: 0,
		bottom: 0,
		width: "100%"
	},
	loadMoreMessagesButton: {
		color: colors.grayScale[11],
		backgroundColor: "#b7b7b7",
		fontSize: 14,
		boxShadow: "none",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			backgroundColor: "#b7b7b7",
			opacity: 0.7,
			boxShadow: "none"
		}
	},
	scrollButtonArea: {
		position: "absolute",
		bottom: "5px",
		right: "10px"
	},
	roundedIconButton: {
		padding: "4px",
		backgroundColor: "#FFFFFF80",
		"&:hover": {
			backgroundColor: "#FFFFFF80"
		}
	}
}))

export default useStyles
