import React from "react"
import { Typography, Divider as MuiDivider } from "@material-ui/core"

import { Divider } from "@/components"

import useCustomStyles from "@/styles/custom"

import AttendancesMetrics from "@/pages/Admin/Dashboard/Metrics/AttendancesMetrics"
import ProductMessageMetrics from "@/pages/Admin/Dashboard/Metrics/ProductMessageMetrics"

const Metrics: React.FC = () => {
	const customClasses = useCustomStyles()

	return (
		<>
			<Typography
				variant="h4"
				color="textPrimary"
				className={customClasses.uppercase}
			>
				Métricas para o gestor
			</Typography>

			<Divider orientation="horizontal" size={2} />

			<MuiDivider />

			<Divider orientation="horizontal" size={2} />

			<ProductMessageMetrics />

			<Divider orientation="horizontal" size={4} />

			<MuiDivider />

			<Divider orientation="horizontal" size={2} />

			<AttendancesMetrics />

			<Divider orientation="horizontal" size={4} />
		</>
	)
}

export default Metrics
