import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import { Divider } from "@/components"

const useStyles = makeStyles({
	skeleton: {
		transform: "scale(1)"
	}
})

const ChatBotSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			container
			spacing={4}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					justifyContent="space-between"
				>
					<Grid item xs={6} container justifyContent="flex-start" alignItems="center">
						<Skeleton className={classes.skeleton} variant="text" height={20} width={100} />
						<Divider orientation="vertical" size={2} />
						<Skeleton className={classes.skeleton} variant="rect" height={30} width={30} />
						<Divider orientation="vertical" size={2} />
						<Skeleton className={classes.skeleton} variant="rect" height={30} width={30} />
						<Divider orientation="vertical" size={2} />
						<Skeleton className={classes.skeleton} variant="rect" height={30} width={30} />
					</Grid>

					<Grid item xs={6} container justifyContent="flex-end" alignItems="center">
						<Skeleton className={classes.skeleton} height={20} width={250} />
						<Divider orientation="vertical" size={2} />
						<Skeleton className={classes.skeleton} height={40} width={150} />
					</Grid>

				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					spacing={4}
				>
					<Grid
						item
						xs={3}
					>
						<Grid
							container
							direction="column"
						>
							<Skeleton className={classes.skeleton} height={24} width={150} />

							<Divider orientation="horizontal" size={2} />

							<Skeleton className={classes.skeleton} height={200} width="100%" />
						</Grid>
					</Grid>

					<Grid
						item
					>
						<Grid
							container
						>
							<Skeleton variant="circle" height={40} width={40} />
						</Grid>
					</Grid>

					<Grid
						item
						xs={3}
					>
						<Grid
							container
							direction="column"
						>
							<Skeleton className={classes.skeleton} height={24} width={170} />

							<Divider orientation="horizontal" size={2} />

							<Skeleton className={classes.skeleton} height={600} width="100%" />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ChatBotSkeleton
