import React from "react"
import {
	Grid,
	IconButton
} from "@material-ui/core"

import {
	Close as CloseIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview/styles"

import { Media } from "@/services/Media"

import MediaList from "@/pages/Attendance/Chat/ConversationPanel/Input/MediaPreview/MediaList"

type MediaPreviewProps = {
	medias: Media[]
	onClear: () => void
}

const MediaPreview: React.FC<MediaPreviewProps> = (props) => {
	const { medias, onClear } = props

	const isThereAnyMedia = Boolean(medias.length)

	const classes = useStyles()

	const handleClear = () => {
		onClear()
	}

	return (
		<>
			{isThereAnyMedia && (
				<Grid
					container
					alignItems="center"
					justify="center"
				>
					<Grid
						container
						className={classes.mediaContainer}
					>
						<MediaList
							medias={medias}
						/>
					</Grid>

					<IconButton
						onClick={handleClear}
					>
						<CloseIcon />
					</IconButton>
				</Grid>
			)}
		</>
	)
}

export default MediaPreview
