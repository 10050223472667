import deepmerge from "deepmerge"
import { range } from "lodash"

function removeIdxBrackets (value: string): string {
	return value.replace(/\[\d+\]/, "").trim()
}

// eslint-disable-next-line
export function objectFromVariable (variable: string, value: any = "TESTE"): Record<string, string> {
	const parts = variable.split(".")
	const last = parts.pop() as string
	const remain = parts.join(".")

	let result
	const arrRegex = /\[(.*?)\]/
	if (arrRegex.test(last)) {
		const idx = parseInt(last[last.search(arrRegex) + 1])
		const arr: Record<string, string>[] = Array(idx + 1).fill({})
		arr[idx] = value
		result = { [removeIdxBrackets(last)]: arr }
	} else {
		result = { [last]: value }
	}

	if (remain) {
		return objectFromVariable(remain, result)
	} else {
		return result
	}
}

export function customArrayMerge<Item> (target: Item[], source: Item[]): Item[] {
	const result: Item[] = []
	for (const idx in range(Math.max(target.length, source.length))) {
		const t = target[idx] || {}
		const s = source[idx] || {}
		result.push(deepmerge<Item, Item>(t, s))
	}
	return result
}

export function payloadFromVariables (variables: Set<string>): Record<string, string> {
	let result: Record<string, string> = {}
	variables.forEach(variable => {
		const tmp = objectFromVariable(variable)
		result = deepmerge(result, tmp, {
			arrayMerge: customArrayMerge
		})
	})
	return result
}

export function variablesFromText (texts: string[]): Set<string> {
	const result: Set<string> = new Set()

	texts.forEach(text => {
		const regex = /{{(.*?)}}/g
		const found = text.match(regex) || []
		found.forEach(v => {
			result.add(v.replace(/\{|\}/g, "").trim())
		})
	})

	return result
}
