import React, { useState, useEffect, ReactNode } from "react"
import {
	Dialog,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	ThemeProvider
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/InfoDialog/styles"

type InfoDialogProps = {
	title?: string | ReactNode
	children: ReactNode
	openDialog?: boolean
	maxWidth?: DialogProps["maxWidth"]

	onClose?: () => void
}

type InfoDialogType = {
	open: (props: InfoDialogProps) => void
}

const InfoDialog: InfoDialogType & React.FC<InfoDialogProps> = (props) => {
	const {
		children,
		openDialog,
		title,
		maxWidth,
		onClose
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState<boolean>(Boolean(openDialog))

	const handleClose = () => {
		setOpened(false)

		onClose && onClose()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	useEffect(() => {
		setOpened(Boolean(openDialog))
	}, [openDialog])

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				maxWidth={maxWidth || "sm"}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<DialogTitle>
					{title}
				</DialogTitle>

				<DialogContent className={classes.dialogContainer}>
					{children}
				</DialogContent>
			</Dialog>
		</ThemeProvider>
	)
}

InfoDialog.open = (props: InfoDialogProps) => {
	renderComponent(
		"info-dialog",
		<InfoDialog
			{...props}
		/>
	)
}

export default InfoDialog
