import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	messageContainer: {
		paddingBottom: theme.spacing(1)
	},
	messageInfoContainer: {
		position: "absolute",
		top: 0,
		right: 0,
		minHeight: 30
	},
	infoIcon: {
		height: 14
	},
	messageTimeText: {
		fontSize: 10
	},
	messageSenderName: {
		fontWeight: "bold",
		color: "#000000"
	}
}))

export default useStyles
