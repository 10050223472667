import React, { useState } from "react"
import { Grid, Typography, TextField, InputLabel } from "@material-ui/core"

import { ActionDialog, Notification, Divider } from "@/components"
import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useCustomStyles from "@/styles/custom"
import { MessageBlastContactToAdd } from "@/protocols/messageBlast"
import useStyles from "@/pages/Admin/MessageBlast/Management/Contacts/AddContactDialog/styles"

export type AddContactDialogProps = {
	onAdd: (contacts: MessageBlastContactToAdd[]) => Promise<void>
	onClose: () => void
	opened: boolean
}

const AddContactDialog: React.FC<AddContactDialogProps> = (props) => {
	const {
		onAdd,
		onClose,
		opened
	} = props

	const [loadingCreation, setLoadingCreation] = useState(false)
	const [whatsappNums, setWhatsappNums] = useState("")

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const handleAddContacts = async () => {
		setLoadingCreation(true)

		try {
			const response = await ApiService.post("/clients/whatsapp", {
				textWithPhones: whatsappNums
			})

			await onAdd(response.data.clients)
			Notification.success({ message: "Contatos adicionados com sucesso!" })

			onClose()
		} catch (err) {
			Notification.error({ message: "Não foi possível adicionar os contatos!" })
			ErrorHandler.handle(err)
		}

		setLoadingCreation(false)
	}

	const getLineNumbers = () => {
		const nLines = whatsappNums.split("\n").length
		let lines = Array(nLines).fill(0)
		lines = lines.map((_, idx) => idx + 1)
		return lines.join("\n")
	}

	const handleChangeWhatsappNums = (value: string) => {
		setWhatsappNums(value)
	}

	return (
		<ActionDialog
			title="Adicionar contatos"
			onClose={onClose}
			openDialog={opened}
			fullWidth
			saveText="ADICIONAR"
			onSave={handleAddContacts}
			loading={loadingCreation}
		>
			<Grid
				container
				spacing={1}
			>
				<Grid item xs={12}>
					<InputLabel
						className={customClasses.inputLabel}
					>
						Insira aqui a lista de contatos que deseja enviar, sendo um contato por linha. Somente os números do WhatsApp.
					</InputLabel>
				</Grid>

				<Grid item xs={12}>
					<Typography className={customClasses.formText}>
						Limite de 200 números por envio.
					</Typography>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid item xs={12}>
					<InputLabel
						className={customClasses.inputLabel}
					>
						Exemplo:
					</InputLabel>
				</Grid>

				<Grid item xs={12}>
					<Grid container >
						<Grid item xs={1}>
							<TextField
								value={"1\n2"}
								className={classes.textareaLineNumbers}
								multiline
								variant="outlined"
								disabled
							/>
						</Grid>

						<Grid item xs>
							<TextField
								fullWidth
								multiline
								variant="outlined"
								value={"+55 (31) 99279-4500\n+55 (31) 99999-9999"}
								disabled
							/>
						</Grid>
					</Grid>
				</Grid>

				<Divider orientation="horizontal" size={1} />

				<Grid item xs={12}>
					<InputLabel
						className={customClasses.inputLabel}
					>
						Lista de contatos:
					</InputLabel>
				</Grid>

				<Grid item xs={12}>
					<Grid container >
						<Grid item xs={1}>
							<TextField
								value={getLineNumbers()}
								className={classes.textareaLineNumbers}
								multiline
								variant="outlined"
								disabled
							/>
						</Grid>

						<Grid item xs>
							<TextField
								fullWidth
								multiline
								variant="outlined"
								onChange={({ target }) => handleChangeWhatsappNums(target.value)}
								value={whatsappNums}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

export default AddContactDialog
