import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	avatarContainer: {
		alignSelf: "center"
	},
	avatar: {
		width: 240,
		height: 240,
		fontSize: "64px"
	}
}))

export default useStyles
