import React from "react"
import {
	Grid,
	Select,
	MenuItem,
	TextField
} from "@material-ui/core"

import { BlockConditionEditorInputDefaultProps } from "@/protocols/chatBotConstructor"
import { ChatBotFlowBlockRuleValidationType } from "@/protocols/chatBot"

const MessageReceivedInput: React.FC<BlockConditionEditorInputDefaultProps> = (props) => {
	const {
		changeValidation,
		validation,
		constructionResources
	} = props

	const value = validation.second_param

	return (
		<>
			<Grid
				item
				xs={3}
			>
				<Select
					value={validation.type}
					variant="outlined"
					fullWidth
					onChange={(event) => changeValidation({ type: event.target.value as ChatBotFlowBlockRuleValidationType })}
				>
					{constructionResources.nextBlockRuleValidations
						.filter(validation => (["equals", "contains"] as ChatBotFlowBlockRuleValidationType[]).includes(validation.type))
						.map(({ type }) => (
							<MenuItem
								key={type}
								value={type}
							>
								{type === "contains" && "Contém"}
								{type === "equals" && "Exatamente igual"}
							</MenuItem>
						))}
				</Select>
			</Grid>

			<Grid
				item
				xs={3}
			>
				<TextField
					fullWidth
					variant="outlined"
					placeholder="1"
					value={value}
					onChange={(event) => changeValidation({ second_param: event.target.value })}
				/>
			</Grid>
		</>
	)
}

export default MessageReceivedInput
