import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	title: {
		fontWeight: "bold"
	},
	container: {
		paddingLeft: theme.spacing(2)
	}
}))

export default useStyles
