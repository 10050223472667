import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	titleText: {
		textTransform: "uppercase"
	},
	nameText: {
		fontWeight: "normal"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	chipPending: {
		height: "26px",
		background: colors.palette.tag3,
		color: "#FFFFFF",
		textTransform: "uppercase",
		fontSize: "12px",
		fontWeight: "bold",
		lineHeight: "24px",
		padding: theme.spacing(0, 2)
	},
	copyContainer: {
		height: "100%"
	},
	copyButton: {
		height: "100%",
		width: "100%",
		fontSize: "14px",
		lineHeight: "24px",
		borderRadius: "0px 6px 6px 0px",
		marginLeft: "-3px"
	},
	copyInput: {
		borderRadius: "6px 0px 0px 6px"
	},
	teamsListContainer: {
		background: colors.grayScale[10],
		borderRadius: "5px",
		padding: theme.spacing(0, 2),
		marginTop: theme.spacing(1),
		maxHeight: "150px",
		overflowY: "scroll"
	},
	teamChip: {
		background: colors.grayScale[10],
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5),
		marginBottom: theme.spacing(1)
	},
	roleChip: {
		background: colors.grayScale[10],
		marginTop: theme.spacing(1),
		height: theme.spacing(2.5),
		border: "none"
	},
	editButton: {
		marginLeft: theme.spacing(2)
	},
	noTeamListed: {
		textTransform: "uppercase",
		marginTop: "65px",
		marginBottom: "65px"
	}
}))

export default useStyles
