import React, { useState } from "react"
import {
	Grid
} from "@material-ui/core"

import {
	AccessibleDrawer
} from "@/components"

import { useChatGlobalStateStore } from "@/store/ChatGlobalState"
import useStyles from "@/pages/Attendance/Chat/ConversationPanel/styles"

import { isSmallScreen } from "@/utils/checkDevice"

import Input from "@/pages/Attendance/Chat/ConversationPanel/Input"
import ConversationHeader from "@/pages/Attendance/Chat/ConversationPanel/ConversationHeader"
import ClientInfo from "@/pages/Attendance/Chat/ConversationPanel/ClientInfo"
import MessageList from "@/pages/Attendance/Chat/ConversationPanel/MessageList"

const ConversationPanel: React.FC = () => {
	const classes = useStyles()
	const chatGlobalStateStore = useChatGlobalStateStore()
	const [openClientInfoDrawer, setOpenClientInfoDrawer] = useState(!isSmallScreen)

	const handleCloseChat = () => {
		chatGlobalStateStore.chat.close()
	}

	const handleOpenClientInfoDrawer = () => {
		setOpenClientInfoDrawer(true)
	}

	const handleCloseClientInfoDrawer = () => {
		setOpenClientInfoDrawer(false)
	}

	return (
		<>
			<AccessibleDrawer
				id="conversation-panel-drawer"
				variant={isSmallScreen ? chatGlobalStateStore.chat.current ? "persistent" : "temporary" : "permanent"}
				open={Boolean(chatGlobalStateStore.chat.current)}
				anchor="right"
				onClose={handleCloseChat}
				onMobileBackButtonPress={handleCloseChat}
				className={classes.chatDrawer}
				classes={{
					paper: classes.chatDrawerPaper
				}}
			>
				<Grid
					container
					alignItems="center"
					justify="center"
					direction="column"
					className={classes.chatRightSideContainer}
				>
					<Grid
						container
						className={classes.chatHeader}
						justify="flex-start"
						alignItems="center"
						wrap="nowrap"
					>
						<ConversationHeader
							onClose={handleCloseChat}
							onClick={handleOpenClientInfoDrawer}
						/>
					</Grid>

					<Grid
						container
						className={classes.fixedHeightContainer}
					>
						<MessageList />
					</Grid>

					<Grid
						container
						direction="column"
					>
						<Input />
					</Grid>
				</Grid>
			</AccessibleDrawer>

			{chatGlobalStateStore.client.current && (
				<ClientInfo
					open={openClientInfoDrawer}
					onClose={handleCloseClientInfoDrawer}
					clientId={chatGlobalStateStore.client.current.id}
				/>
			)}
		</>
	)
}

export default ConversationPanel
