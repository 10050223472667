import React from "react"
import {
	Grid,
	Typography,
	IconButton
} from "@material-ui/core"

import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	LocalOffer as TagIcon
} from "@material-ui/icons"

import {
	Portlet
} from "@/components"
import useStyles from "@/pages/Admin/Tag/styles"

export type Color = {
	r: number,
	g: number,
	b: number,
	a?: number,
}

type TagCardProps = {
	name: string,
	color: Color,
	onClickEditButton: () => void,
	onClickDeleteButton: () => void
}

const TagCard: React.FC<TagCardProps> = (props) => {
	const { name, color, onClickEditButton, onClickDeleteButton } = props

	const classes = useStyles()

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	return (
		<Portlet elevation={1}>
			<Grid container justify="space-between" alignItems="center">
				<Grid
					item xs={7} sm={6}
					className={classes.textContainer}
				>
					<Grid container alignItems="center">
						<Grid item className={ classes.cardTagIcon}>
							<TagIcon style={{ color: colorToRGB(color) }} />
						</Grid>
						<Grid item>
							<Typography
								color="textPrimary"
								variant="h2"
								className={classes.nameText}
							>
								{name}
							</Typography>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={4} sm={5}>
					<Grid container justify="flex-end">
						<Grid item>
							<IconButton onClick={onClickEditButton}>
								<EditIcon />
							</IconButton>
							<IconButton onClick={onClickDeleteButton}>
								<DeleteIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TagCard
