import { makeStyles } from "@material-ui/core"
import { isSmallScreen } from "@/utils/checkDevice"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	paper: {
		margin: theme.spacing(2),
		zIndex: 1600
	},
	drawerPaper: {
		width: `${isSmallScreen && "100vw"}`
	},
	paperFullWidth: {
		width: "calc(100% - 32px)"
	},
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	},
	closeIcon: {
		position: "absolute",
		top: 20,
		right: 10
	},
	dialogActions: {
		marginTop: theme.spacing(2)
	},
	dialogContent: {
		overflowY: "inherit"
	},
	secondaryActionButton: {
		color: colors.palette.button1
	},
	button: {
		height: "40px",
		padding: "0px 8px",
		fontSize: "14px"
	}
}))

export default useStyles
