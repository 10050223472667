import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	contactFilterItem: {
		"&:hover": {
			textDecoration: "underline"
		},
		marginTop: theme.spacing(0.5),
		color: colors.palette.link,
		cursor: "pointer"
	}
}))

export default useStyles
