import React, { useEffect, useState } from "react"
import { Card, Grid, Typography, IconButton, InputBase, Box, Tooltip, Popover } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import {
	Close as CloseIcon,
	AddCircleOutline as AddIcon,
	Done as DoneIcon
} from "@material-ui/icons"
import close_icon from "@/assets/icons/close_icon.svg"
import {
	Divider,
	Notification,
	SvgIcon
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandler from "@/services/ErrorHandler"
import useStyles from "@/pages/Admin/NewClientCatalog/ClientProfileInfo/Tags/styles"
import { Color } from "@/protocols/color"
import { ErrorType } from "@/hooks/useValidation"
import {
	ReactComponent as ActiveCampaignSVGIcon
} from "@/assets/images/logos/active_campaign.svg"
import { ClientProfileInfoData, TagDataProps } from "@/protocols/clientCatalog"

type ClientTagsProps = {
	clientId: number
	clientAssociatedTags?: TagDataProps[]
	availableTags: TagDataProps[]
	inboxChannelChat: {
		id?: number
	}
	onDataChange: (id: number, data: Partial<ClientProfileInfoData>) => void
}

type ChangedTagAction = "ADD" | "REMOVE"

const ClientTags: React.FC<ClientTagsProps> = ({ clientId, clientAssociatedTags, inboxChannelChat, availableTags, onDataChange }) => {
	const classes = useStyles()
	const [selectedTags, setSelectedTags] = useState<TagDataProps[]>(clientAssociatedTags as TagDataProps[])
	const [popoverAnchorElement, setPopoverAnchorElement] = useState<null | HTMLElement>(null)
	const [changedTags, setChangedTags] = useState<Record<number, ChangedTagAction>>({})

	const changeTag = (tagId: number, action: ChangedTagAction) => {
		setChangedTags(lastState => {
			lastState[tagId] = action

			return lastState
		})
	}
	const handleRemoveTagsOnFrontEnd = (tagIds: number[]) => {
		setSelectedTags(lastState => lastState.filter(function (tag) {
			return !tagIds.includes(tag.id)
		}))
	}
	const removeTagsAssociations = async (tagIds: number[]) => {
		await ApiService.delete(`/tag-association/client/${clientId}`, {
			params: {
				tagIds: tagIds.join(","),
				inboxChannelChatId: inboxChannelChat.id
			}
		})
	}

	const addTagsAssociations = async (tagIds: number[]) => {
		await ApiService.post(`/tag-association/client/${clientId}`, {
			tagIds,
			inboxChannelChatId: inboxChannelChat.id
		})
	}

	const handleOpenTagPopover = (event: React.MouseEvent<HTMLElement>) => {
		setPopoverAnchorElement(event.currentTarget)
	}

	const handleTagPopoverChange = async () => {
		try {
			setPopoverAnchorElement(null)

			const isThereAnyChange = Object.keys(changedTags).length > 0

			if (!isThereAnyChange) {
				return
			}

			const addedTagIds: number[] = []
			const removedTagIds: number[] = []

			for (const changedTagId in changedTags) {
				const action = changedTags[changedTagId]
				const tagId = Number(changedTagId)

				if (action === "ADD") {
					addedTagIds.push(tagId)
				} else if (action === "REMOVE") {
					removedTagIds.push(tagId)
				}
			}

			if (removedTagIds.length) {
				await removeTagsAssociations(removedTagIds)
			}

			if (addedTagIds.length) {
				await addTagsAssociations(addedTagIds)
			}
			setChangedTags({})
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Algo deu errado ao atualizar as tags" })
		}
	}

	const colorToRGB = (color: Color): string => {
		return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
	}

	const handleRemoveTag = async (tagId: number) => {
		try {
			handleRemoveTagsOnFrontEnd([tagId])
			await removeTagsAssociations([tagId])
		} catch (error) {
			ErrorHandler.handle(error as ErrorType)
			Notification.error({ message: "Ocorreu um erro ao remover a tag" })
		}
	}
	useEffect(() => {
		onDataChange(clientId, { tags: selectedTags })
		// eslint-disable-next-line
	}, [selectedTags])

	return (
		<Grid item style={{ paddingRight: 16, paddingLeft: 32 }}>
			<Card elevation={0}>
				<Grid container alignItems="center" justify="space-between">
					<Grid item style={{ paddingRight: 17, width: "100%" }}>
						<Grid container alignItems="center" justifyContent="space-between">
							<Grid item>
								<Typography
									variant="h3"
									color="textPrimary"
									style={{ fontSize: 16, fontWeight: 600 }}
								>
									Tags
								</Typography>
							</Grid>
							<Grid item aria-describedby="popper">
								<IconButton onClick={(event) => handleOpenTagPopover(event)} style={{ padding: 1 }} >
									<AddIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>

					<Divider orientation="horizontal" size={0.5} />
					{selectedTags.length > 0 ? <Grid item style={{ width: "100%", height: 167 }} >
						<div className={classes.scrollBar} >
							{selectedTags && <Grid item>
								{
									selectedTags.map(tag =>
										<Grid container direction="row" key={tag.id} className={classes.tagCard} style={{ background: colorToRGB(tag.color) }}>

											{tag.activeCampaignAssociationIsEnabled && <Grid item
												className={classes.pluginFeatureIconCircle}
												style={{ marginLeft: 6 }}
											>
												<SvgIcon
													className={classes.pluginFeatureIcon}
													icon={ActiveCampaignSVGIcon}
												/>
											</Grid>}
											<Grid item style={{ width: tag.activeCampaignAssociationIsEnabled ? "90%" : "100%" }}>
												<Grid container justifyContent="space-between">
													<Grid item style={{ paddingLeft: 8 }}>
														<Tooltip title={tag.name}>
															<span>{tag.name}</span>
														</Tooltip>
													</Grid>
													<Grid item style={{ paddingRight: 6 }}>
														<IconButton onClick={() => handleRemoveTag(tag.id)}
															className={classes.closeIcon}
															size="small"
														>
															<img src={close_icon} alt="close icon" />
														</IconButton>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									)
								}
							</Grid>}
						</div>
					</Grid>
						: <Grid item justifyContent="center" alignItems="center" style={{ display: "flex", width: "100%", height: 139 }}>
							<Typography style={{ fontWeight: "lighter", fontSize: 20 }}>
								Não ha tags adicionadas
							</Typography>
						</Grid>}
				</Grid>
			</Card>
			<Popover
				id="popper"
				open={Boolean(popoverAnchorElement)}
				anchorEl={popoverAnchorElement}
				onClose={handleTagPopoverChange}
			>
				<Autocomplete
					open
					multiple
					classes={{
						paper: classes.paper,
						option: classes.option,
						popperDisablePortal: classes.popperDisablePortal
					}}
					value={selectedTags}
					onChange={(event, newValue, reason, details) => {
						const tagId = details?.option?.id

						const isAddReason = reason === "select-option"
						const isRemoveReason = reason === "remove-option"

						if (tagId) {
							if (isAddReason) {
								changeTag(tagId, "ADD")
							}

							if (isRemoveReason) {
								changeTag(tagId, "REMOVE")
							}
						}

						setSelectedTags(newValue)
					}}
					disableCloseOnSelect
					disablePortal
					renderTags={() => null}
					noOptionsText="Sem tags cadastradas"
					renderOption={(option, { selected }) => (
						<>
							<DoneIcon
								className={classes.iconSelected}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
							<Box className={classes.color} style={{ backgroundColor: colorToRGB(option.color) }} />
							<Typography className={classes.text}>
								{option.name}
							</Typography>
							<CloseIcon
								className={classes.close}
								style={{ visibility: selected ? "visible" : "hidden" }}
							/>
						</>
					)}
					options={availableTags}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option.name}
					renderInput={(params) => (
						<InputBase
							ref={params.InputProps.ref}
							inputProps={params.inputProps}
							autoFocus
							className={classes.inputBase}
						/>
					)}
				/>
			</Popover>
		</Grid>
	)
}

export default ClientTags
