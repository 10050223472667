import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import { IChannel } from "@/protocols/channel"

class ChannelService {
	async setupChannels (instance_id: number): Promise<IChannel[] | null> {
		try {
			const response = await ApiService
				.get("/inbox/channel", {
					params: {
						instanceId: instance_id
					}
				})

			return response.data.inboxChannels
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}
}

export default new ChannelService()
