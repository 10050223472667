import React from "react"
import { Typography, Container, Grid, Button } from "@material-ui/core"

import { Divider } from "@/components"

import TrialIsEndedImg from "@/assets/images/illustrations/trial_ended.svg"
import useStyles from "@/pages/IsTrialEnded/styles"

const IsTrialEnded: React.FC = () => {
	const classes = useStyles()

	return (
		<Container maxWidth="sm" className={classes.container}>
			<Grid
				container
				alignContent="center"
				alignItems="center"
				justify="center"
			>

				<Grid item xs={12}>
					<Typography variant="h3" align="center">
						O período de teste chegou ao fim...
					</Typography>

					<Divider size={2} orientation="horizontal" />
				</Grid>

				<Grid item xs={12}>
					<img
						src={TrialIsEndedImg}
						alt="Fim do período de teste"
						className={classes.trialEndedImg}
					/>

					<Divider size={3} orientation="horizontal" />
				</Grid>

				<Grid item xs={12}>
					<Grid
						container
						justify="center"
					>
						<Grid item xs={12}>
							<Typography variant="body1" align="center">
								Entre em contato conosco para mais informações
							</Typography>
						</Grid>

						<Divider size={4} orientation="horizontal" />

						<Grid item>
							<Button
								component="a"
								href="https://web.whatsapp.com/send?phone=5531975436343"
								target="_BLANK"
								variant="contained"
								color="primary"
								className={classes.supportButton}
							>
								FALE COM A LETALK
							</Button>
						</Grid>
					</Grid>
				</Grid>

			</Grid>
		</Container>
	)
}

export default IsTrialEnded
