import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	titleText: {
		textTransform: "uppercase"
	},
	nameText: {
		fontWeight: "normal"
	},
	textContainer: {
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	colorPickerGrid: {
		marginTop: theme.spacing(2),
		paddingBottom: theme.spacing(1)
	},
	colorPicker: {
		marginTop: theme.spacing(1)
	},
	cardTagIcon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		paddingTop: theme.spacing(0.4)
	}
}))

export default useStyles
