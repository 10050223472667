import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useCustomStyles = makeStyles(theme => ({
	chatInputContainer: {
		backgroundColor: "#F0F0F0",
		padding: theme.spacing(2, 3),
		position: "relative"
	},
	buttonAction: {
		fontSize: "14px",
		backgroundColor: colors.palette.button1,
		color: colors.grayScale[11],
		"&:hover": {
			backgroundColor: "#02c3b0"
		}
	},
	shortButtonAction: {
		fontSize: "14px",
		padding: theme.spacing(0.75, 3),
		backgroundColor: colors.palette.button1,
		color: colors.grayScale[11],
		"&:hover": {
			backgroundColor: "#02c3b0"
		}
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	},
	scrollBar: {
		"&::-webkit-scrollbar": {
			width: "6px !important",
			height: "6px !important"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.2)"
		},
		"&::-webkit-scrollbar-track": {
			background: "hsla(0, 0%, 100%, 0.1)"
		}
	},
	uppercase: {
		textTransform: "uppercase"
	},
	noTextTransform: {
		textTransform: "none"
	},
	cancelButton: {
		color: colors.grayScale[3],
		padding: 16
	},
	inputLabel: {
		fontSize: 15,
		fontWeight: "bolder"
	},
	formText: {
		color: "#818285",
		fontSize: 14
	},
	reportTableBodyText: {
		"& p, span, td": {
			fontSize: "0.825rem",
			lineHeight: "18px"
		}
	},
	italicText: {
		fontStyle: "italic"
	},
	boldText: {
		fontWeight: "bold"
	},
	cursorPointer: {
		cursor: "pointer"
	},
	tableActionButton: {
		borderRadius: 3,
		backgroundColor: "#F8F9FA"
	},
	copyIcon: {
		cursor: "pointer",
		padding: "2px",
		marginRight: "2px"
	}
}), {
	index: 1 // this attribute is necessary to ensure classes not being overriden
})

export default useCustomStyles
