import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	merlinContainer: {
		width: "100%",
		height: "500px",
		padding: "10px"
	}
}))

export default useStyles
