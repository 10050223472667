import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	mediaContainer: {
		flex: 1,
		paddingRight: theme.spacing(2),
		position: "relative"
	}
}))

export default useStyles
