import React, { ReactNode, useState, useEffect } from "react"
import {
	ThemeProvider,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress,
	Drawer
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/ActionDialog/styles"
import useCustomStyles from "@/styles/custom"

type ActionDialogProps = {
	title: string
	children: ReactNode
	onSave?: () => Promise<void> | void
	onClose?: () => void
	onSecondaryAction?: () => Promise<void> | void
	saveButtonDisabled?: boolean
	saveText?: string
	secondaryActionText?: string
	cancelText?: string
	openDialog?: boolean
	hideCloseButton?: boolean
	hideCloseIcon?: boolean
	loading?: boolean
	fullWidth?: boolean
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"
	dialogContentClassName?: string
	// Allow to use popconfirm in a action dialogue
	preventCloseOnCancelButtonClick?: boolean
}

type ActionDialogType = {
	open: (props: ActionDialogProps) => void
}

const ActionDialog: ActionDialogType & React.FC<ActionDialogProps> = (props) => {
	const {
		title,
		openDialog,
		children,
		loading,
		fullWidth,
		maxWidth,
		saveText,
		onClose,
		onSave,
		onSecondaryAction,
		secondaryActionText,
		hideCloseButton,
		hideCloseIcon,
		saveButtonDisabled,
		cancelText,
		dialogContentClassName,
		preventCloseOnCancelButtonClick = false
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [opened, setOpened] = useState<boolean>(Boolean(openDialog))

	const handleClose = () => {
		onClose?.()

		if (!preventCloseOnCancelButtonClick) {
			setOpened(false)
		}
	}

	const handleSave = async () => {
		if (onSave) {
			await onSave()
		}
	}

	const handleSecondayAction = async () => {
		if (onSecondaryAction) {
			await onSecondaryAction()
		}
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		handleSave()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(() => handleClose())
		}
	})

	useEffect(() => {
		setOpened(Boolean(openDialog))
	}, [openDialog])

	const content = (
		<form onSubmit={handleSubmit}>
			{ !hideCloseIcon &&
				<IconButton
					onClick={handleClose}
					className={classes.closeIcon}
				>
					<CloseIcon />
				</IconButton>
			}

			<DialogTitle>
				{title}
			</DialogTitle>

			<DialogContent
				className={`${classes.dialogContent} ${dialogContentClassName}`}
			>
				{children}
			</DialogContent>

			<DialogActions
				className={classes.dialogActions}
			>
				{!hideCloseButton && (
					<Button
						onClick={handleClose}
						className={[customClasses.cancelButton, classes.button].join(" ")}
					>
						{cancelText || "CANCELAR"}
					</Button>
				)}

				{onSecondaryAction && (
					<Button
						onClick={handleSecondayAction}
						className={[classes.secondaryActionButton, classes.button].join(" ")}
						endIcon={loading && <CircularProgress size={20} />}
						disabled={loading || saveButtonDisabled}
					>
						{secondaryActionText || "AÇÃO"}
					</Button>
				)}

				{onSave && (
					<Button
						onClick={handleSave}
						variant="contained"
						color="primary"
						endIcon={loading && <CircularProgress size={20} />}
						className={classes.button}
						disabled={loading || saveButtonDisabled}
					>
						{saveText || "SALVAR"}
					</Button>
				)}
			</DialogActions>
		</form>
	)

	return (
		<ThemeProvider theme={theme}>
			{
				isSmallScreen ? (
					<Drawer
						anchor="right"
						open={opened}
						onClose={handleClose}
						classes={{ paper: classes.drawerPaper }}
						style={{ width: "100vw" }}
					>
						{content}
					</Drawer>
				) : (
					<Dialog
						open={opened}
						onClose={handleClose}
						PaperProps={{
							className: classes.paper
						}}
						classes={{
							paperFullWidth: classes.paperFullWidth
						}}
						fullWidth={fullWidth}
						maxWidth={maxWidth || "sm"}
						disableBackdropClick
					>
						{content}
					</Dialog>
				)
			}
		</ThemeProvider>
	)
}

ActionDialog.open = (props: ActionDialogProps) => {
	renderComponent(
		"action-dialog",
		<ActionDialog
			{...props}
		/>
	)
}

export default ActionDialog
