import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	title: {
		fontSize: 15
	},
	subTitle: {},
	description: {}
})

export default useStyles
