import React from "react"
import { Skeleton } from "@material-ui/lab"
import {
	TableBody,
	TableCell,
	TableRow,
	Grid,
	Paper,
	TableContainer,
	TableHead,
	Table
} from "@material-ui/core"

const SkeletonTableRow = () => (
	<TableRow>
		<TableCell>
			<Grid container alignItems="center" >
				<Grid item>
					<Skeleton width={280} height={32} />
				</Grid>
			</Grid>
		</TableCell>

		<TableCell>
			<Skeleton width={120} height={32} />
		</TableCell>

		<TableCell>
			<Grid container spacing={1}>
				<Grid item>
					<Skeleton width={32} height={48} />
				</Grid>

				<Grid item>
					<Skeleton width={32} height={48} />
				</Grid>
			</Grid>
		</TableCell>
	</TableRow>
)

const GroupTableSkeleton = () => {
	return (
		<Paper style={{ width: "100%" }}>
			<TableContainer>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								width="250px"
							>
								Grupo
							</TableCell>

							<TableCell
								width="300px"
							>
								Admin
							</TableCell>

							<TableCell
								width="100px"
								align="right"
							>
								Ações
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
						<SkeletonTableRow />
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default GroupTableSkeleton
