import React, { createContext, useContext, useState } from "react"
import { UserRoleCode, UserRoleName } from "@/protocols/user"
import { IChannel } from "@/protocols/channel"
import { SetState } from "@/protocols/react"
import { UserDataProps } from "@/services/User"
import { SubscriptionData } from "@/protocols/instance"

type TeamDataProps = {
	id?: number
	instanceId?: number
	name: string
	usersInTeamCount: number
}

type InstanceDataProps = {
	instance_id: number
	instance_created_at: Date
	teams: TeamDataProps[]
	user_in_instance_id: number
	user_in_instance_role: {
		code:	UserRoleCode
		name: UserRoleName
	}
	subscriptionData: SubscriptionData
}

type ChannelDataProps = IChannel
export interface GlobalStateContextData {
	user: UserDataProps
	setUserData: (data: Partial<UserDataProps>) => void

	instance: InstanceDataProps
	setInstanceData: (data: Partial<InstanceDataProps>) => void

	channels: ChannelDataProps[]
	setChannelsData: (setState: SetState<ChannelDataProps[]>) => void
	isChannelConnected: (channel?: ChannelDataProps) => boolean

	whatsappChannel?: ChannelDataProps
	isWhatsappChannelConnected: boolean
	isBaileysWhatsappChannel: boolean
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [user, setUser] = useState<UserDataProps>({} as UserDataProps)
	const [instance, setInstance] = useState<InstanceDataProps>({} as InstanceDataProps)
	const [channels, setChannels] = useState<ChannelDataProps[]>([])

	const setUserData = (
		data: Partial<UserDataProps>
	) => {
		setUser(lastState => ({
			...lastState,
			...data
		}))
	}

	const setInstanceData = (
		data: Partial<InstanceDataProps>
	) => {
		setInstance(lastState => ({
			...lastState,
			...data
		}))
	}

	const setChannelsData = (setState: SetState<ChannelDataProps[]>) => {
		setChannels(setState)
	}

	const isChannelConnected = (channel?: ChannelDataProps) => {
		const isChannelConnected = channel?.status === "connected" ?? false

		return isChannelConnected
	}

	const isBaileysChannel = (channel?: ChannelDataProps) => {
		const isBaileysWhatsappChannel = channel?.extraData?.libraryClientType === "baileys"

		return isBaileysWhatsappChannel
	}

	const whatsappChannel = channels.find(channel => channel.channelType === "whatsapp")

	const isWhatsappChannelConnected = isChannelConnected(whatsappChannel)
	const isBaileysWhatsappChannel = isBaileysChannel(whatsappChannel)

	GlobalStateStore.displayName = "GlobalStateStore"

	return (
		<GlobalStateStore.Provider
			value={{
				user,
				setUserData,
				instance,
				setInstanceData,
				channels,
				isChannelConnected,
				isBaileysWhatsappChannel,
				whatsappChannel,
				isWhatsappChannelConnected,
				setChannelsData
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
