import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	categoryTitleText: {
		textTransform: "uppercase"
	},
	blocksContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 1fr 1fr",
		gridGap: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			gridTemplateColumns: "1fr 1fr"
		},
		[theme.breakpoints.down("xs")]: {
			gridTemplateColumns: "1fr"
		}
	},
	blockItem: {
		height: "100%"
	},
	blockIcon: {
		minWidth: theme.spacing(4),
		minHeight: theme.spacing(4)
	},
	blockInfoContainer: {
		flex: 1
	},
	blockInfoTitleText: {
		textTransform: "uppercase"
	},
	blockInfoDescriptionText: {
		fontWeight: 400
	}
}))

export default useStyles
