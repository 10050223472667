import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	titleText: {
		fontSize: "15px"
	},
	leftContainer: {
		width: "auto"
	},
	rightContainer: {
		width: "auto"
	},
	nameText: {},
	emailText: {
		color: "#8D9BA8"
	},
	profile: {},
	iconButton: {
		padding: "6px"
	}
})

export default useStyles
