import React, { useState } from "react"
import {
	Grid,
	TextField
} from "@material-ui/core"
import _ from "lodash"

import { renderComponent } from "@/utils/node"
import { sanitizeChatBotFlowRule, getMessageButtonText } from "@/utils/chatBot"

import { ActionDialog } from "@/components"

import { ChatBotFlowBlockRule, ChatBotFlowBlockRuleValidation } from "@/protocols/chatBot"

type BlockConditionEditorProps = {
	onSave: (chatBotFlowBlockRule: ChatBotFlowBlockRule) => void
	nextChatBotFlowBlockRule: ChatBotFlowBlockRule
}

type ButtonInputType = {
	open: (props: BlockConditionEditorProps) => void
}

const BlockConditionEditor: ButtonInputType & React.FC<BlockConditionEditorProps> = (props) => {
	const {
		onSave,
		nextChatBotFlowBlockRule
	} = props

	const [opened, setOpened] = useState(true)

	/**
	 * WARNING:
	 * - Make sure to clone data deep when initializing state to avoid changing the data by reference.
	 */
	const [chatBotFlowBlockRule, setChatBotFlowBlockRule] = useState<ChatBotFlowBlockRule>(_.cloneDeep(nextChatBotFlowBlockRule))

	const validationIndex = nextChatBotFlowBlockRule.validations.findIndex(({ slug }) => slug === "message-received")

	const handleChangeButtonText = (text: string) => {
		setChatBotFlowBlockRule(lastState => {
			const oldValidation = lastState.validations[validationIndex]

			const updatedValidation = { ...(oldValidation || {}) } as ChatBotFlowBlockRuleValidation

			const updatedValidations = lastState.validations

			updatedValidations[validationIndex] = {
				...updatedValidation,
				second_param: text
			}

			return {
				...lastState,
				validations: updatedValidations
			}
		})
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleSave = async () => {
		const sanitizedChatBotFlowBlockRule = sanitizeChatBotFlowRule(chatBotFlowBlockRule)

		onSave(sanitizedChatBotFlowBlockRule)

		handleClose()
	}

	return (
		<ActionDialog
			title="Botão"
			onSave={handleSave}
			saveText="SALVAR"
			cancelText="CANCELAR"
			onClose={handleClose}
			openDialog={opened}
			maxWidth="xs"
			fullWidth
		>
			<Grid
				container
				spacing={2}
			>
				<Grid
					item
					xs={12}
				>
					<TextField
						variant="outlined"
						placeholder="Texto do botão"
						value={getMessageButtonText(chatBotFlowBlockRule)}
						onChange={(event) => handleChangeButtonText(event.target.value as string)}
						fullWidth
					/>
				</Grid>
			</Grid>
		</ActionDialog>
	)
}

BlockConditionEditor.open = (props: BlockConditionEditorProps) => {
	renderComponent(
		"block-condition-editor",
		<BlockConditionEditor
			{...props}
		/>
	)
}

export default BlockConditionEditor
