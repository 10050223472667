import { useState, useRef, useEffect, useCallback } from "react"

import { generateUUID } from "@/utils/id"

type ResolverRef<Data> = (stateData: Data) => void

type StateAction<Data> = (lastState: Data) => Data

type SetAsyncState<Data> = (stateAction: StateAction<Data>) => Promise<Data>

type UseAsyncStateResponse<Data> = [{ current: Data }, SetAsyncState<Data>]

function useAsyncState<StateData extends unknown> (
	initialState: StateData | StateAction<StateData>
): UseAsyncStateResponse<StateData> {
	const [state, setState] = useState<StateData>(initialState as StateData)

	/**
	 * We do the workaround below using refs in order to access valid state
	 * inside listener functions (usually common state variables can not be
	 * accessed from inside listener functions).
	 */
	const stateRef = useRef<StateData>(state)
	const scheduledResolverIdsRef = useRef<string[]>([])
	const resolverRef = useRef<Record<string, ResolverRef<StateData>>>({})

	/**
	 * Everytime we have new promises to be resolved on 'scheduledResolverIdsRef',
	 * we run the routine below, that takes the current promises scheduled to be resolved
	 * and so resolve them with the updated state.
	 */
	useEffect(() => {
		const scheduledResolverIds = scheduledResolverIdsRef.current

		if (scheduledResolverIds) {
			scheduledResolverIds.forEach(scheduledResolverId => {
				const resolver = resolverRef.current[scheduledResolverId]

				if (resolver) {
					delete resolverRef.current[scheduledResolverId]

					if (resolver) {
						resolver(state)
					}
				}
			})
		}
	// eslint-disable-next-line
	}, [scheduledResolverIdsRef.current.length, state])

	const setAsyncState = useCallback((stateAction: StateAction<StateData>) => {
		return new Promise<StateData>(resolve => {
			const resolverId = generateUUID()

			resolverRef.current[resolverId] = resolve

			/**
			 * When the state is finally set, we schedule its promise
			 * to be resolved.
			 */
			setState(lastState => {
				const updatedState = stateAction(lastState)

				stateRef.current = updatedState
				scheduledResolverIdsRef.current.push(resolverId)

				return updatedState
			})
		})
	}, [setState])

	return [stateRef, setAsyncState]
}

export default useAsyncState
