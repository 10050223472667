import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	searchInputContainer: {
		backgroundColor: "#F6F6F6",
		padding: theme.spacing(2)
	},
	searchInput: {
		backgroundColor: colors.grayScale[11],
		height: "40px",
		borderRadius: "3px",
		"& svg": {
			marginLeft: theme.spacing(1)
		},
		"&::before": {
			borderBottom: "none"
		}
	},
	quickReplyListContent: {
		backgroundColor: "#F6F6F6",
		overflowY: "scroll",
		height: "100%",
		maxHeight: "calc(100vh - 128px)",
		paddingTop: 0,
		"& li[data-focus='true']": {
			cursor: "pointer"
		}
	},
	quickReplyItem: {
		paddingBottom: theme.spacing(2),
		outline: "none"
	},
	shortcutNameText: {
		color: colors.grayScale[4],
		fontWeight: "bold"
	},
	quickReplyItemContainer: {
		pointerEvents: "none"
	}
}))

export default useStyles
