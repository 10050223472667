import React from "react"
import { Typography, IconButton, Grid } from "@material-ui/core"
import {
	ArrowForwardIos as RArrowIcon
} from "@material-ui/icons"

import { IntegrationType } from "@/protocols/integration"

import { Portlet, Divider } from "@/components"

import useStyles from "@/pages/Admin/Integration/IntegrationListItem/styles"

import { integrationInfo } from "@/utils/integration"

import { Link } from "react-router-dom"

type IntegrationListItemProps = {
	title: string
	type: IntegrationType
	integrationId: number
}

const IntegrationListItem: React.FC<IntegrationListItemProps> = (props) => {
	const { title, type, integrationId } = props

	const classes = useStyles()

	return (
		<Portlet
			elevation={1}
			style={{
				padding: "18px"
			}}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
			>
				<Grid item>
					<img
						src={integrationInfo[type]?.logo}
						alt={title}
						className={classes.listItemLogo}
					/>
				</Grid>

				<Grid item xs>
					<Link to={`/admin/integration/${integrationId}`} style={{ textDecoration: "none" }}>
						<Typography
							variant="body1"
							className={classes.listItemDescription}
						>
							{title}
						</Typography>
					</Link>

					<Divider orientation="horizontal" size={0.5}/>
					<Typography
						variant="body2"
						className={classes.listItemDescription2}
					>
						{integrationInfo[type]?.title}
					</Typography>
				</Grid>

				<Grid item>
					<Grid container>
						<Grid item>
							<Link to={`/admin/integration/${integrationId}`} style={{ textDecoration: "none" }}>
								<IconButton>
									<RArrowIcon className={classes.listItemIcon} />
								</IconButton>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default IntegrationListItem
