import { Chip } from "@material-ui/core"
import React from "react"

import {
	DraftsOutlined as DraftIcon,
	MailOutlined as SendIcon,
	ScheduleRounded
} from "@material-ui/icons"
import { fullDatetime } from "@/utils/time"
import { MessageBlastData } from "@/protocols/messageBlast"

export const renderMessageBlastStatusToMaterialChips = (messageBlast: Partial<MessageBlastData>) => {
	const messageBlastStatus = messageBlast.status || "DRAFT"

	const messageBlastStatusToMaterialChips: Record<string, JSX.Element> = {
		SENT: <Chip
			icon={<SendIcon style={{ marginLeft: "10px" }}/>}
			label={`Envio realizado em: ${messageBlast.statusDate && fullDatetime(new Date(messageBlast.statusDate))}`} style={{ backgroundColor: "#D4F8D3" }}/>,
		SCHEDULED: <Chip
			icon={<ScheduleRounded/>}
			label={`Envio agendado para: ${messageBlast.schedule?.time && fullDatetime(new Date(messageBlast.schedule.time))}`}
			style={{ backgroundColor: "#D3E4F8" }}
		/>,
		DRAFT: <Chip icon={<DraftIcon style={{ marginLeft: "10px" }}/>} label="Rascunho" style={{ backgroundColor: "#F8DAD3" }}/>
	}

	return messageBlastStatusToMaterialChips[messageBlastStatus]
}

export const messageBlastStatusTranslatedMapCamelCase = {
	DRAFT: "Rascunhos",
	SCHEDULED: "Agendados",
	SENT: "Realizados"
}
