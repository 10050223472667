import React, { useState } from "react"
import Skeleton from "@material-ui/lab/Skeleton"

import { Loading } from "@/components"

import useDidMount from "@/hooks/useDidMount"

export type VideoPlayer = {
	src: string
	classes?: {
		afterLoad?: string
		beforeLoad?: string
	}
}

const VideoPlayer: React.FC<VideoPlayer> = (props) => {
	const { classes, src } = props

	const [loaded, setLoaded] = useState(false)

	const initialize = () => {
		const videoElement = document.createElement("video")

		const videoSourceElement = document.createElement("source")

		videoElement.appendChild(videoSourceElement)

		videoElement.onloadedmetadata = () => setLoaded(true)

		videoSourceElement.onerror = () => setLoaded(true)

		videoSourceElement.src = src
	}

	useDidMount(() => {
		initialize()
	})

	return (
		<Loading
			loading={!loaded}
			customLoadingElement={(
				<Skeleton
					variant="rect"
					className={classes?.beforeLoad}
				/>
			)}
		>
			<video
				width="100%"
				className={classes?.afterLoad}
			>
				<source
					src={src}
					type="video/mp4"
				/>
			</video>
		</Loading>
	)
}

export default VideoPlayer
