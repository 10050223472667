import { useMemo } from "react"

type ValidDependencyType = boolean | string | number | undefined | null | HTMLElement

/**
 * - That's a wrapper above the common react 'useMemo' hook created in order
 * to avoid the warning of not using all the component dependencies (since sometimes
 * we want to update the component without using all the existing dependencies).
 * - It's a way to centralize the usage of 'useMemo' as well in order to make
 * improvements in the future (like adding a proxy validation to check if
 * some prop of a dependency object changed).
 * - Avoid using object/array as dependencies since their comparison is made by
 * reference in order to know if the data changed or not. That way, every time
 * react renders a component, the object/array changes its reference - even if
 * its data did not change, what means that react will think that the data changed
 * and so, it will make useless re-renders.
 */
function useCustomMemo<T> (fn: () => T, dependencies: ValidDependencyType[]) {
	return useMemo(fn, dependencies)
}

export default useCustomMemo
