import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	title: {
		textTransform: "uppercase"
	},
	tableCell: {},
	invalidGroupLabel: {
		textDecoration: "line-through"
	}
})

export default useStyles
