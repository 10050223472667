import { ConstructionResources } from "@/protocols/chatBotConstructor"

import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

class ChatBotConstructorService {
	async retrieveAllResources (): Promise<ConstructionResources | null> {
		try {
			const result = await ApiService.get("/chat-bot/constructor/resources")

			return result.data
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}
}

export default new ChatBotConstructorService()
