import { makeStyles } from "@material-ui/core"

export const MESSAGE_MEDIA_MAX_WIDTH = 330
const MESSAGE_IMAGE_MAX_HEIGHT = MESSAGE_MEDIA_MAX_WIDTH
const MESSAGE_VIDEO_MAX_HEIGHT = 240
export const PLACEHOLDER_COLOR = "#F9F9F9"

const useStyles = makeStyles(theme => ({
	messageCaption: {
		whiteSpace: "break-spaces"
	},
	messageImage: {
		maxWidth: MESSAGE_MEDIA_MAX_WIDTH,
		maxHeight: MESSAGE_IMAGE_MAX_HEIGHT,
		objectFit: "cover",
		objectPosition: "center",
		width: "100%",
		height: "100%",
		backgroundColor: PLACEHOLDER_COLOR
	},
	unloadedMessageImage: {
		width: MESSAGE_MEDIA_MAX_WIDTH,
		height: `${MESSAGE_IMAGE_MAX_HEIGHT}px !important`
	},
	messageVideo: {
		maxWidth: MESSAGE_MEDIA_MAX_WIDTH,
		width: "100%",
		height: "100%",
		maxHeight: 240,
		backgroundColor: PLACEHOLDER_COLOR
	},
	unloadedMessageVideo: {
		width: MESSAGE_MEDIA_MAX_WIDTH,
		height: MESSAGE_VIDEO_MAX_HEIGHT
	},
	fileContainer: {
		background: "rgba(0, 0, 0, 0.03)",
		padding: theme.spacing(1),
		width: MESSAGE_MEDIA_MAX_WIDTH
	},
	fileLeftSideContainer: {
		flex: 1
	},
	fileName: {},
	loadingContainer: {
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0
	},
	pictureVideoContainer: {
		position: "relative"
	}
}))

export default useStyles
