import { IQuickReply } from "@/protocols/messages"
import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

class QuickReplyService {
	async getAll (): Promise<IQuickReply[]> {
		try {
			const { data } = await ApiService.get("/quick-reply")

			return data
		} catch (error) {
			ErrorHandlerService.handle(error)

			return []
		}
	}
}

export default new QuickReplyService()
