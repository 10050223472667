import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"
import { debugContextDevtool } from "react-context-devtool"

import GlobalStateProvider from "@/store/GlobalState"
import AccessibleDrawerStateProvider from "@/store/AccessibleDrawerState"
import { isDevEnv } from "@/utils/environment"

import App from "@/App"

import theme from "@/styles/theme"

import "@/styles/global.css"
import "react-vis/dist/style.css"

const container = document.getElementById("root")

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<GlobalStateProvider>
					<AccessibleDrawerStateProvider>
						<App />
					</AccessibleDrawerStateProvider>
				</GlobalStateProvider>
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
	container
)

// To make use of the context debug it is necessary to install the extension
// https://github.com/deeppatel234/react-context-devtool
debugContextDevtool(container, {
	disable: !isDevEnv
})
