import React from "react"
import clsx from "clsx"
import {
	Grid,
	Typography,
	Fab
} from "@material-ui/core"

import {
	getStatusIcon,
	getComputedStatus,
	getStatusMessage
} from "@/utils/channelStatus"

import useCustomMemo from "@/hooks/useCustomMemo"
import { useGlobalStateStore } from "@/store/GlobalState"

import useStyles from "@/pages/Attendance/Chat/ChatListPanel/NotificationBar/styles"

import { ChannelStatus } from "@/protocols/channel"

const NotificationBar = () => {
	const classes = useStyles()

	const globalStateStore = useGlobalStateStore()
	const whatsappChannelStatus = globalStateStore.whatsappChannel?.status as ChannelStatus

	const computedStatus = getComputedStatus(whatsappChannelStatus)
	const statusIcon = getStatusIcon(whatsappChannelStatus)
	const statusMessage = getStatusMessage(whatsappChannelStatus)

	const isDisconnected = computedStatus === "disconnected"

	const needToShowNotification = isDisconnected

	return useCustomMemo(() => (
		<>
			{needToShowNotification && (
				<Grid
					container
					alignItems="center"
					justify="center"
					className={clsx({
						[classes.container]: true,
						[classes.disconnectedContainer]: isDisconnected
					})}
				>
					<Grid
						container
						alignItems="center"
						justify="center"
						className={classes.iconContainer}
					>
						<Fab
							className={classes.fab}
						>
							{statusIcon}
						</Fab>
					</Grid>

					<Grid
						container
						direction="column"
						className={classes.messageContainer}
					>
						{isDisconnected && (
							<>
								<Typography
									variant="body1"
									className={classes.statusDescriptionText}
								>
									{statusMessage}
								</Typography>
							</>
						)}
					</Grid>
				</Grid>
			)}
		</>
	), [
		whatsappChannelStatus
	])
}

export default NotificationBar
