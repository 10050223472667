import React from "react"
import { Typography, TypographyProps } from "@material-ui/core"

const ClientNickname: React.FC<TypographyProps> = (props) => {
	const { children, className, ...rest } = props

	return (
		<Typography
			variant="body1"
			color="textPrimary"
			{...rest}
			className={`${className}`}
		>
			{children}
		</Typography>
	)
}

export default ClientNickname
