import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	messageStatusIcon: {
		width: 16,
		height: 16
	}
})

export default useStyles
