import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	listItemLogo: {
		maxHeight: 75,
		maxWidth: 150,
		marginRight: theme.spacing(1)
	},
	listItemDescription: {
		flex: 1,
		"&:hover": {
			textDecoration: "underline"
		},
		color: colors.palette.link,
		cursor: "pointer",
		fontSize: "20px",
		fontWeight: 300
	},
	listItemDescription2: {
		opacity: 0.85,
		fontSize: "16px",
		fontWeight: 300
	},
	listItem: {
		"&:hover": {
			boxShadow: theme.shadows[16]
		},
		cursor: "pointer",
		padding: "18px"
	},
	listItemIcon: {
		color: "#495057",
		fontSize: "20px"
	}
}))

export default useStyles
