import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	title: {
		fontSize: "16px",
		fontWeight: "bolder",
		marginBottom: "13px"
	},
	textAlert: {
		marginTop: "17px",
		paddingTop: "18px",
		paddingBottom: "18px"

	},
	boxSlider: {
		marginTop: "50px",
		marginLeft: "1%",
		marginRight: "1%"

	}
})

export default useStyles
