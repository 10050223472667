import React from "react"
import clsx from "clsx"
import {
	Grid,
	IconButton,
	Button,
	Menu,
	MenuItem,
	ListItemText
} from "@material-ui/core"
import {
	MoreVert as MoreOptionsIcon,
	Edit as EditIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInput/styles"

import BlockBodyItemInputDescription from "@/pages/Admin/ChatBot/ChatBotConstructor/components/BlockBodyItemInputDescription"

type BlockBodyItemInputType =
"text" |
"keep-going-condition" |
"action" |
"finish-flow-condition"

type BlockBodyItemInputProps = {
	customContainerClassName?: string
	customStyle?: React.CSSProperties
	type: BlockBodyItemInputType
	text: string | React.ReactNode | React.ReactNodeArray
	placeholder?: string
	selectable?: boolean
	selected?: boolean
	onEdit?: () => void
	onDelete?: () => void
	onClick?: () => void
}

const BlockBodyItemInput: React.FC<BlockBodyItemInputProps> = (props) => {
	const {
		customContainerClassName,
		onEdit,
		text,
		type,
		onClick,
		onDelete,
		customStyle,
		placeholder,
		selectable,
		selected
	} = props

	const showCustomizationMenu = Boolean(onEdit || onDelete)

	const classes = useStyles({ showCustomizationMenu })
	const [optionsMenuAnchorElement, setOptionsMenuAnchorElement] = React.useState<null | HTMLElement>(null)

	const getContainerColorByType = () => {
		const containerColorMap: Record<BlockBodyItemInputType, string> = {
			text: "#E7FFDB",
			action: "#F3DBFF",
			"finish-flow-condition": "#FFDBE4",
			"keep-going-condition": "#DBF2FF"
		}

		return containerColorMap[type]
	}

	const getSelectedContainerClassByType = () => {
		const containerClassMap: Record<BlockBodyItemInputType, string> = {
			text: classes.selectedText,
			action: "",
			"finish-flow-condition": classes.selectedFinishFlowCondition,
			"keep-going-condition": classes.selectedKeepGoingCondition
		}

		return containerClassMap[type]
	}

	const handleOpenOptionsDialog = (element: HTMLElement) => {
		setOptionsMenuAnchorElement(element)
	}

	const handleCloseOptionsDialog = () => {
		setOptionsMenuAnchorElement(null)
	}

	const isThereAnyValidText = () => {
		const isString = typeof text === "string"

		if (isString) {
			return Boolean(text)
		} else {
			return React.Children.count(text) > 0
		}
	}

	const isOnlyEditable = Boolean(onEdit) && !onDelete

	return (
		<Grid
			container
			className={classes.outsideContainer}
			alignItems="center"
			style={{ background: getContainerColorByType() }}
		>
			{isOnlyEditable ? (
				<IconButton
					onClick={onEdit}
					size="small"
					className={classes.editButton}
				>
					<EditIcon />
				</IconButton>
			) : (
				<>
					{showCustomizationMenu && (
						<IconButton
							onClick={event => handleOpenOptionsDialog(event.target as HTMLElement)}
							size="small"
							className={classes.editButton}
							disabled={!showCustomizationMenu}
						>
							<MoreOptionsIcon />
						</IconButton>
					)}
				</>
			)}

			{showCustomizationMenu && (
				<Menu
					anchorEl={optionsMenuAnchorElement}
					keepMounted
					open={Boolean(optionsMenuAnchorElement)}
					onClose={handleCloseOptionsDialog}
					autoFocus={false}
					PaperProps={{
						style: {
							marginTop: 40
						}
					}}
				>
					{onEdit && (
						<MenuItem
							onClick={() => {
								onEdit()
								handleCloseOptionsDialog()
							}}
						>
							<ListItemText primary="Editar" />
						</MenuItem>
					)}

					{onDelete && (
						<MenuItem
							onClick={() => {
								onDelete()
								handleCloseOptionsDialog()
							}}
						>
							<ListItemText primary="Excluir" />
						</MenuItem>
					)}
				</Menu>
			)}

			<Grid
				container
				alignItems="center"
				className={clsx({
					[classes.insideContainer]: true,
					[classes.selectable]: selectable,
					[getSelectedContainerClassByType()]: selected
				}, customContainerClassName)}
				style={customStyle}
				{...(onClick && ({
					component: Button,
					onClick,
					style: {
						display: "flex",
						justifyContent: "flex-start",
						...(customStyle || {})
					}
				}))}
			>
				{isThereAnyValidText() ? (
					<BlockBodyItemInputDescription
						text={text}
					/>
				) : (
					<BlockBodyItemInputDescription
						text={placeholder}
						className={classes.textPlaceholder}
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default BlockBodyItemInput
