
import React, { useState } from "react"
import {
	Button,
	Grid,
	InputLabel,
	TextField,
	Typography
} from "@material-ui/core"

import { CirclePicker, ColorResult } from "react-color"

import useCustomStyles from "@/styles/custom"
import { Divider, ActionDialog, Notification, Loading, PopConfirm } from "@/components"

import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useStyles from "@/pages/Admin/Tag/styles"

import TagService from "@/services/Tag"
import ErrorHandlerService from "@/services/ErrorHandler"
import ApiService from "@/services/Api"

import TagCard from "@/pages/Admin/Tag/components/TagCard"

import TagsSkeleton from "@/skeletons/Admin/Tags"

import { Tag as ITag } from "@/protocols/tag"
import { Color } from "@/protocols/color"

type TagDataProps = Omit<ITag, "id" | "instanceId"> & {
	id?: number
	instanceId?: number
}

const Tag: React.FC = () => {
	const generateRandomRGBColor = (): Color => {
		let rgbs = [
			+(Math.random() * (85 - 0) + 0).toFixed(0),
			+(Math.random() * (170 - 85) + 85).toFixed(0),
			+(Math.random() * (255 - 170) + 170).toFixed(0)
		]

		rgbs = rgbs.sort(() => Math.random() - 0.5)

		return {
			r: rgbs[0],
			g: rgbs[1],
			b: rgbs[2],
			a: 1
		}
	}

	const [tagData, setTagData] = useState<TagDataProps>({
		name: "",
		color: {
			r: 233,
			g: 30,
			b: 99,
			a: 1
		}
	})
	const [loadingTag, setLoadingTag] = useState<boolean>(false)
	const [openedTagDialog, setOpenedTagDialog] = useState<boolean>(false)
	const [loadingTags, setLoadingTags] = useState<boolean>(false)
	const [tags, setTags] = useState<TagDataProps[]>([])

	const customClasses = useCustomStyles()
	const {
		validation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()

	const openCreateTagDialog = () => {
		setTagData({ name: "", color: generateRandomRGBColor() })

		setOpenedTagDialog(true)
	}

	const openEditTagDialog = (tag: TagDataProps) => {
		setTagData(tag)

		setOpenedTagDialog(true)
	}

	const getTags = async () => {
		setLoadingTags(true)

		const data = await TagService.getTags()

		if (data) {
			setTags(data)
		}

		setLoadingTags(false)
	}

	const handleTagClose = () => {
		setOpenedTagDialog(false)
	}

	const handleSaveTag = async () => {
		setLoadingTag(true)

		try {
			if (tagData?.id) {
				await ApiService
					.put(`/tag/${tagData.id}`, {
						name: tagData.name,
						color: tagData.color
					})
			} else {
				await ApiService
					.post("/tag", {
						name: tagData.name,
						color: tagData.color
					})
			}

			handleTagClose()

			getTags()

			Notification.success({ message: `A tag foi ${tagData?.id ? "modificada" : "criada"} com sucesso!` })
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingTag(false)
	}

	const handleTagDataChange = (key: string, value: unknown) => {
		setTagData({
			...tagData,
			[key]: value
		})
	}

	const handleChangeColor = (color: ColorResult) => {
		setTagData({
			...tagData,
			color: color.rgb
		})
	}

	const handleDeleteTag = async (tag: TagDataProps) => {
		PopConfirm.open({
			title: "Excluir Tag",
			description: "Tem certeza? Essa ação é irreversível.",
			confirmButtonText: "EXCLUIR",
			onConfirm: async () => {
				try {
					await ApiService.delete(`/tag/${tag.id}`)

					await getTags()

					Notification.success({ message: "Tag deletada com sucesso!" })
				} catch (error) {
					ErrorHandlerService.handle(error)
				}
			}
		})
	}

	useDidMount(() => {
		getTags()
	})

	return (
		<>
			<Button
				variant="contained"
				className={customClasses.buttonAction}
				onClick={openCreateTagDialog}
			>
				CRIAR NOVA TAG
			</Button>

			<Divider size={3} orientation="horizontal" />

			<Typography
				variant="h4"
				color="textPrimary"
				className={classes.titleText}
			>
				Tags Cadastradas
			</Typography>

			<Divider size={3} orientation="horizontal" />

			<Loading
				loading={loadingTags}
				customLoadingElement={<TagsSkeleton />}>
				<Grid container>
					{
						tags.length
							? tags.map((tag, index) => (
								<Grid item xs={12} key={index}>
									<TagCard
										name={tag?.name}
										color={tag?.color}
										onClickEditButton={() => openEditTagDialog(tag)}
										onClickDeleteButton={() => handleDeleteTag(tag)}
									/>

									<Divider size={2} orientation="horizontal" />
								</Grid>
							))
							: <Grid item xs={12}>
								<Typography align="center" variant="h2" className={classes.titleText}>
									Nenhuma tag cadastrada
								</Typography>
							</Grid>
					}
				</Grid>
			</Loading>
			<ActionDialog
				title={tagData.id ? "Atualizar tag" : "Criar nova tag"}
				onSave={handleSaveTag}
				saveText={tagData.id ? "SALVAR" : "CRIAR"}
				onClose={handleTagClose}
				openDialog={openedTagDialog}
				loading={loadingTag}
				fullWidth
			>
				<Grid container>
					<Grid item xs={12}>
						<InputLabel className={customClasses.inputLabel}>
                            Nome da Tag
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<TextField
							name="name"
							value={tagData.name}
							onChange={
								({ target }) => handleTagDataChange("name", target.value)
							}
							variant="outlined"
							placeholder="Ex: Compra Aprovada"
							fullWidth
							helperText={validation.name}
							error={!!validation.name}
						/>
					</Grid>
					<Grid item xs={12} className={classes.colorPickerGrid}>
						<InputLabel className={customClasses.inputLabel}>
                            Selecione uma cor
						</InputLabel>

						<Divider orientation="horizontal" size={1} />
						<CirclePicker
							className={classes.colorPicker}
							color={tagData.color}
							onChange={handleChangeColor}
						/>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}

export default Tag
