import React, { useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Grid, Typography } from "@material-ui/core"

import useDidMount from "@/hooks/useDidMount"

import { Divider, Loading } from "@/components"

import { accountAppUrl } from "@/config/url"

import ApiService from "@/services/Api"
import StorageService from "@/services/Storage"
import ErrorHandlerService from "@/services/ErrorHandler"
import useStyles from "@/pages/Auth/Integration/styles"
import { ErrorOutline } from "@material-ui/icons"

const AuthIntegration = () => {
	const { account_instance_id } = useParams<{ account_instance_id: string }>()

	const history = useHistory()
	const classes = useStyles()
	const [loading, setLoading] = useState(true)

	const handleAuth = async () => {
		if (!account_instance_id) {
			window.location.replace(`${accountAppUrl.cpURL}/signin`)
		}
		try {
			const response = await ApiService
				.get(`/integration/account/${account_instance_id}`)

			const instanceId = response.data?.accountIntegration?.instance_id

			StorageService.set(StorageService.reservedKeys.INBOX_INSTANCE_ID, instanceId)

			history.push("/admin")
		} catch (error) {
			setLoading(false)
			ErrorHandlerService.handle(error)
		}
	}

	useDidMount(() => {
		handleAuth()
	})

	return (
		<Grid
			container
			direction="column"
			justifyContent="center"
			alignItems="center"
			className={classes.container}
		>
			<Grid item>
				<Loading loading={loading}>
					<Grid item>
						<ErrorOutline opacity={0.5} fontSize="large"/>
					</Grid>
				</Loading>
			</Grid>

			<Divider size={4} orientation="horizontal"/>

			<Grid item>
				<Typography>
					{
						loading ? "Aguarde! Finalizando as ultimas configurações..." : "Não conseguimos completar o seu cadastro, tente novamente mais tarde."
					}
				</Typography>
			</Grid>

		</Grid>
	)
}

export default AuthIntegration
