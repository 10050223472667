import React from "react"
import { Typography, Grid, Box } from "@material-ui/core"
import {
	ArrowForwardIos as RArrowIcon
} from "@material-ui/icons"

import { Portlet, Divider } from "@/components"

import useStyles from "@/pages/Admin/MessageBlast/MessageBlastListItem/styles"

import { MessageBlastStatus } from "@/protocols/messageBlast"
import { fullDatetime } from "@/utils/time"

import { Link } from "react-router-dom"
import { renderMessageBlastStatusToMaterialChips } from "@/utils/messageBlast"

type MessageBlastListItemProps = {
	title: string
	status: MessageBlastStatus
	createdAt: Date
	sentAt?: Date
	messageBlastId: number
	scheduleTime?: Date
}

const MessageBlastListItem: React.FC<MessageBlastListItemProps> = (props) => {
	const { title, status, sentAt, messageBlastId, scheduleTime, createdAt } = props

	const classes = useStyles()

	return (
		<Portlet
			elevation={1}
		>
			<Grid
				container
				alignItems="center"
				spacing={2}
				className={classes.listItem}
			>
				<Grid item xs={4}>
					<Link to={`/admin/message-blast/${messageBlastId}`} style={{ textDecoration: "none", wordWrap: "break-word" }}>
						<Typography
							variant="h4"
							className={classes.listItemTitle}
						>
							{title}
						</Typography>
					</Link>
					<Divider orientation="horizontal" size={1}/>
					<Typography variant="body2">
						#{messageBlastId}
					</Typography>
				</Grid>

				<Grid item xs={4}>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						{renderMessageBlastStatusToMaterialChips({
							createdAt: createdAt,
							statusDate: sentAt,
							schedule: {
								time: scheduleTime
							},
							status: status
						})}
					</Box>
				</Grid>

				<Divider orientation="vertical" size={1}/>

				<Grid item xs>
					<Typography
						variant="h4"
						className={classes.listItemDescription}
					>
						Adicionado em:
					</Typography>

					<Divider orientation="horizontal" size={0.5} />

					<Typography
						variant="body1"
						className={classes.listItemDescription2}
					>
						{createdAt ? fullDatetime(createdAt) : "-"}
					</Typography>
				</Grid>

				<Grid item>
					<Grid container>
						<Grid item >
							<Link to={`/admin/message-blast/${messageBlastId}`} style={{ textDecoration: "none" }}>
								<Grid container className={classes.listItemIconButton} justifyContent="center" alignItems="center">
									<RArrowIcon className={classes.listItemIcon} />
								</Grid>
							</Link>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default MessageBlastListItem
