import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	replyMessageContainer: {
		borderRadius: theme.spacing(1),
		background: "rgba(0, 0, 0, 0.05)",
		minWidth: 100,
		maxHeight: 60,
		height: "100%",
		cursor: "pointer"
	},
	replyMessageContent: {
		padding: theme.spacing(1),
		"& span": {
			color: "rgba(0, 0, 0, 0.5)",
			textOverflow: "ellipsis",
			whiteSpace: "pre-wrap",
			overflow: "hidden",
			maxWidth: "100%",
			display: "-webkit-box",
			WebkitBoxOrient: "vertical",
			WebkitLineClamp: 1
		},
		"& svg": {
			color: "rgba(0, 0, 0, 0.5)"
		},
		flex: 1
	},
	replyMessageThumbnail: {
		height: 60,
		width: 60,
		objectFit: "cover",
		objectPosition: "center",
		borderRadius: theme.spacing(0, 1, 1, 0)
	},
	senderName: {
		fontWeight: "bold"
	}
}))

export default useStyles
