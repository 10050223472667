import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const customColor = colors
const useStyles = makeStyles(() => ({
	gridSpacing: {
		paddingTop: 40
	},
	gridSpacingGroup: {
		paddingTop: 16
	},
	groupName: {
		paddingRight: 11,
		paddingLeft: 0,
		"& h2": {
			fontWeight: 700
		}
	},
	personalVariableStyle: {
		backgroundColor: customColor.palette.triggeredMessage,
		width: "fit-content",
		padding: "2px 4px",
		borderRadius: 6,
		color: colors.grayScale[11],
		fontWeight: 700
	},
	paper: {
		width: "100%"
	},
	buttonGroup: {
		width: "fit-content"
	},
	variableBox: {
		marginBottom: 16,
		display: "flex",
		alignItems: "center"
	}
}))

export default useStyles
