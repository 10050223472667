import React, { useState } from "react"
import {
	Avatar, Box, Chip, Grid, IconButton, Paper, Table, TableBody, TableCell,
	TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography
} from "@material-ui/core"

import { Divider, Loading, LoadingOverlay, Portlet, TypographyListGroup } from "@/components"
import { Tag } from "@/protocols/tag"
import { ClientCatalogPagination } from "@/protocols/clientCatalog"
import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import TagService from "@/services/Tag"
import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDebounce from "@/hooks/useDebounce"
import ClientManageContactsHeader from "@/pages/Admin/NewClientCatalog/ClientManageContactsHeader"
import ClientManageContacts from "@/pages/Admin/NewClientCatalog/ClientManageFilters"
import { ContactsFiltersType } from "@/pages/Admin/NewClientCatalog/ClientManageFilters/ContactsFilters"
import { isSmallScreen } from "@/utils/checkDevice"
import { convertRGBAObjectToString } from "@/utils/color"
import { formatPhoneNumber } from "@/utils/mask"
import { getRowsLabel } from "@/utils/table"
import { Person } from "@material-ui/icons"
import useStyles from "@/pages/Admin/NewClientCatalog/styles"
import useCustomStyles from "@/styles/custom"
import ClientProfileInfo, { OnDataChangeFn } from "@/pages/Admin/NewClientCatalog/ClientProfileInfo"
import ClientCatalogTableSkeleton from "@/skeletons/Admin/ClientCatalogTable"

export type ClientCatalogWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	searchTags?: string[]
	contactsFilters?: unknown[]
}

const NewClientCatalogPage: React.FC = () => {
	const [loadingClientCatalog, setLoadingClientCatalog] = useState(false)
	const [clientCatalog, setClientCatalog] = useState<ClientCatalogPagination>({} as ClientCatalogPagination)
	const [whereData, setWhereData] = useState<ClientCatalogWhereData>({
		page: 0,
		rowsPerPage: 20
	})
	const [customerTags, setCustomerTags] = useState<Tag[]>([])
	const [selectedContactsFilter, setSelectedContactsFilter] = useState<ContactsFiltersType>({} as ContactsFiltersType)
	const { triggerValidation } = useValidation()
	const getAndUpdateClientCatalogData = async (newWhereData?: Partial<ClientCatalogWhereData>) => {
		setLoadingClientCatalog(true)
		try {
			const whereParams = newWhereData

			setWhereData({
				...whereData,
				...whereParams
			})

			const { data } = await ApiService.get("/clients", {
				params: {
					...whereData,
					...whereParams,
					contactType: "user"
				}
			})
			setClientCatalog({
				clientCatalog: data.clientCatalog,
				count: data.count,
				rows: data.rows
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
			ErrorHandlerService.handle(error as ErrorType)
		}

		setLoadingClientCatalog(false)
	}

	const onDataChange: OnDataChangeFn = (id, data) => {
		const updatedClients = clientCatalog?.rows?.map(client => {
			if (client.id === id) {
				if (data.contacts?.email) {
					client.contacts = client.contacts?.map((contact) => {
						if (contact.channel_type === "email") {
							contact.data.email = data.contacts?.email as string
						}
						return contact
					})
				}
				if ("optIn" in data) {
					client.accepts_automatic_messages = data.optIn as boolean
				}
				if ("nickname" in data) {
					client.nickname = data.nickname as string
				}
				if ("tags" in data) {
					client.tags = data.tags
				}
			}

			return client
		})
		const updatedClientCatalog = {
			...clientCatalog,
			rows: updatedClients
		} as ClientCatalogPagination

		setClientCatalog(updatedClientCatalog)
	}
	const getAndUpdateCustomerTags = async () => {
		const data = await TagService.getTags()

		if (data) {
			setCustomerTags(data)
		}
	}
	const handleWhereDataChange = (newData: Partial<ClientCatalogWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}
	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getAndUpdateClientCatalogData({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getAndUpdateClientCatalogData({ rowsPerPage })
	}

	useDebounce(
		async () => getAndUpdateClientCatalogData({ search: whereData?.search }),
		whereData.search,
		2250
	)

	useDidMount(() => {
		getAndUpdateClientCatalogData()
		getAndUpdateCustomerTags()
	})
	const classes = useStyles()
	const customClasses = useCustomStyles()
	return (
		<Portlet
			elevation={1}
		>
			<ClientManageContactsHeader
				selectedContactsFilter={selectedContactsFilter}
				setClientCatalog={setClientCatalog} />

			<Divider size={3} orientation="horizontal" />

			<ClientManageContacts
				setWhereData={setWhereData}
				whereData={whereData}
				getAndUpdateClientCatalogData={getAndUpdateClientCatalogData}
				setClientCatalog={setClientCatalog}
				setSelectedContactsFilter={setSelectedContactsFilter}
				selectedContactsFilter={selectedContactsFilter} />

			<Grid item xs={12}>
				<Loading
					customLoadingElement={<ClientCatalogTableSkeleton />}
					loading={loadingClientCatalog}
				>
					{
						clientCatalog.count > 0 ? (
							<LoadingOverlay
								loading={loadingClientCatalog}
							>
								<Paper className={classes.paper}>
									<TableContainer>
										<Table stickyHeader size="small">
											<TableHead>
												<TableRow>
													<TableCell
														width="300px"
													>
														Cliente
													</TableCell>

													<TableCell
														width="200px"
													>
														WhatsApp
													</TableCell>

													<TableCell
														width="185px"
													>
														Tags
													</TableCell>
													<TableCell>
														Permissão
													</TableCell>
													<TableCell
														width="185px"
													/>
												</TableRow>
											</TableHead>

											<TableBody
												className={customClasses.reportTableBodyText}
											>
												{clientCatalog.rows.map(client => {
													return (
														<TableRow key={client.id} tabIndex={-1}>
															<TableCell>
																<Grid container justify="flex-start" alignItems="center" >
																	<Grid item>
																		<Avatar src={client.picture_url} />
																	</Grid>

																	<Divider size={isSmallScreen ? 1 : 2.5} orientation={isSmallScreen ? "horizontal" : "vertical"} />

																	<Grid item xs>
																		<TypographyListGroup>
																			<Typography variant="h4">
																				{client.nickname}
																			</Typography>
																			<Typography style={{ color: "#818285", fontWeight: 400 }}>
																				~{client.name}
																			</Typography>
																		</TypographyListGroup>
																	</Grid>
																</Grid>
															</TableCell>

															<TableCell width="185px">
																<Typography style={{ fontSize: 16 }}>
																	{formatPhoneNumber(client.contacts?.find(contact => contact.channel_type === "whatsapp")?.data?.phone)}
																</Typography>
															</TableCell>

															<TableCell width={174}>
																<Grid container direction="row" wrap="wrap" spacing={1}>
																	{client.tags?.map(tag => {
																		return (
																			<Grid item key={tag.id}>
																				<Tooltip title={tag.name}>
																					<Chip
																						style={{
																							backgroundColor: convertRGBAObjectToString(tag.color),
																							height: 12,
																							width: 64
																						}}
																						size="small"
																					/>
																				</Tooltip>
																			</Grid>
																		)
																	})}
																</Grid>
															</TableCell>

															<TableCell>
																<Grid item>
																	<Box className={classes.clientAutomaticMessages} style={{ backgroundColor: client.accepts_automatic_messages ? "#DCF5D9" : "#F8DAD3" }}>
																		{client.accepts_automatic_messages ? "Opt-in" : "Opt-out"}
																	</Box>
																</Grid>

															</TableCell>
															<TableCell>
																<Grid container justify="flex-end" alignItems="center">
																	<Grid item>
																		<ClientProfileInfo customerTags={customerTags} onDataChange={onDataChange} clientId={client.id}>
																			<IconButton>
																				<Person />
																			</IconButton>
																		</ClientProfileInfo>
																	</Grid>
																</Grid>
															</TableCell>
														</TableRow>
													)
												})}
											</TableBody>

										</Table>
									</TableContainer>

									<TablePagination
										rowsPerPageOptions={[20, 50, 100, 200]}
										component="div"
										count={clientCatalog?.count}
										rowsPerPage={whereData.rowsPerPage}
										page={whereData.page}
										onPageChange={handlePageChange}
										onRowsPerPageChange={({ target }) => {
											handlePageChange(target, 0)
											handleChangeRowsPerPage(+target.value)
										}}
										labelRowsPerPage={"Resultados por página:"}
										labelDisplayedRows={tableData => getRowsLabel(tableData, whereData.rowsPerPage)}
									/>
								</Paper>
							</LoadingOverlay>
						) : (
							<>
								<Divider size={3} orientation="horizontal" />
								<Typography align="center" variant="h2">
									Nenhum resultado encontrado
								</Typography>
							</>
						)
					}
				</Loading>
			</Grid>
		</Portlet >
	)
}

export default NewClientCatalogPage
