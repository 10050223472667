import { RefObject } from "react"

import useDidMount from "@/hooks/useDidMount"
import useAsyncState from "@/hooks/useAsyncState"
import useCustomMemo from "@/hooks/useCustomMemo"

type ElementSize = {
	width: number
	height: number
}

function useElementSize<T extends HTMLElement = HTMLDivElement> (elementRef: RefObject<T>) {
	const [size, setSize] = useAsyncState<ElementSize>({
		width: 0,
		height: 0
	})

	const updateSize = () => {
		requestAnimationFrame(() => {
			const node = elementRef?.current

			if (node) {
				const widthChanged = node.offsetWidth !== size.current.width
				const heightChanged = node.offsetHeight !== size.current.height

				if (widthChanged || heightChanged) {
					setSize(() => ({
						width: node.offsetWidth || 0,
						height: node.offsetHeight || 0
					}))
				}
			}

			updateSize()
		})
	}

	useDidMount(() => {
		updateSize()
	})

	return useCustomMemo(() => {
		return size
	}, [size.current.height, size.current.width])
}

export default useElementSize
