import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	variableBox: {
		marginBottom: 16,
		display: "flex",
		alignItems: "center",
		"& svg": {
			width: 13,
			height: 13,
			margin: "2.5px 0px 0px 4px;"
		}
	},
	bracketRight: {
		border: "1px solid",
		borderRadius: 8,
		borderRight: "inherit",
		padding: "18px 20px",
		borderColor: colors.grayScale[3],
		backgroundColor: colors.grayScale[12],
		color: colors.grayScale[3],
		fontWeight: 400,
		fontSize: 16
	},
	bracketLeft: {
		borderRadius: 8,
		borderLeft: "inherit",
		padding: "18px 20px",
		borderColor: colors.grayScale[3],
		backgroundColor: colors.grayScale[12],
		border: "1px solid",
		color: colors.grayScale[3],
		fontWeight: 400,
		fontSize: 16
	},
	inputResetPadding: {
		"& div": {
			padding: 0,
			maxWidth: 553,
			borderRadius: 8
		},
		"& input": {
			borderRadius: 8,
			paddingLeft: 12
		},
		maxWidth: 584
	},
	labelOutlinedStyle: {
		backgroundColor: colors.palette.background,
		padding: "0px 4px"
	},
	alterationAlert: {
		display: "contents",
		"& .alert-content": {
			margin: "0px 10px"
		}
	},
	selectedGroupPlaceholder: {
		color: colors.grayScale[6]
	}
}))

export default useStyles
