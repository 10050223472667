import React, { useState } from "react"
import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"
import useValidation from "@/hooks/useValidation"
import Loading from "@/components/Loading"
import EditableTextSkeleton from "@/skeletons/EditableTextSkeleton"

type EditableTextProps = {
	code: string,
	errorText?: string | undefined
	skeletonHeight?: number
	skeletonWidth?: number
}

const EditableText: React.FC<EditableTextProps> = (props) => {
	const {
		code,
		errorText,
		skeletonHeight,
		skeletonWidth
	} = props

	const {
		triggerValidation
	} = useValidation()

	const defaultReturn = errorText || "..."
	const languageSufix = "ptbr"
	const textKey = `text_${languageSufix}`

	const [text, setText] = useState<string>("")

	const [loading, setLoading] = useState<boolean>(false)

	const getText = async (code: string) => {
		setLoading(true)
		try {
			const { data } = await ApiService.get("inbox/editable-platform-text-by-code", {
				params: {
					editablePlatformTextCode: code
				}
			})

			setText(data[textKey])
		} catch (error) {
			triggerValidation(error)

			setText(defaultReturn)
		}
		setLoading(false)
	}

	useDidMount(() => {
		if (!code) {
			setText(defaultReturn)
		} else {
			getText(code)
		}
	})

	return (
		<Loading
			loading={loading}
			customLoadingElement={<EditableTextSkeleton height={skeletonHeight} width={skeletonWidth} />}
		>
			{/* Replaces space for &nbsp to let easier to put double space in text */}
			<p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: text.replaceAll(" ", "&nbsp") }}></p>
		</Loading>
	)
}

export default EditableText
