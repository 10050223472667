import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	breadcrumb: {
		textTransform: "uppercase",
		display: "flex",
		alignItems: "center"
	},
	logoImg: {
		maxHeight: 50
	},
	chatTabs: {
		position: "relative",
		"&::before": {
			content: "close-quote",
			position: "absolute",
			width: "100%",
			height: "1px",
			bottom: 0,
			left: 0,
			backgroundColor: "#DADADA"
		}
	},
	chatTab: {
		color: `${colors.grayScale[4]} !important`,
		opacity: 1,
		fontSize: 14
	},
	chatTabIndicator: {
		backgroundColor: colors.palette.primary
	},
	explanationLink: {
		cursor: "pointer"
	},
	explanationText: {
		color: "#8F8F8F"
	},
	categoryTitle: {
		color: "#8F8F8F"
	}
})

export default useStyles
