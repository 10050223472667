import React from "react"
import { Grid } from "@material-ui/core"

const BlockBodyContainer: React.FC = (props) => {
	return (
		<Grid
			container
			spacing={4}
			direction="column"
		>
			{props.children}
		</Grid>
	)
}

export default BlockBodyContainer
