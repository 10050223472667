import React, { useState, useRef } from "react"
import { Grid, Popover, IconButton, Typography } from "@material-ui/core"
import {
	Cancel as CancelIcon,
	CheckCircle as FinishIcon,
	Mic as VoiceIcon
} from "@material-ui/icons"

import Skeleton from "@material-ui/lab/Skeleton"

import AudioService from "@/services/Audio"
import MediaService, { Media } from "@/services/Media"

import { Divider } from "@/components"

import useStyles from "@/pages/Attendance/Chat/ConversationPanel/Input/VoiceInput/styles"
import useCounter from "@/hooks/useCounter"

type VoiceInputProps = {
	onMedia: (audio: Media) => void
	disabled?: boolean
}

const VoiceInput: React.FC<VoiceInputProps> = (props) => {
	const { onMedia, disabled } = props

	const [isRecording, setIsRecording] = useState(false)
	const recordingCounter = useCounter({ direction: "up", startFromSeconds: 0 })

	const childrenRef = useRef()
	const classes = useStyles()

	const handleStartRecording = async () => {
		await AudioService.startRecording()

		recordingCounter.reset()
		setIsRecording(true)

		recordingCounter.start()
	}

	const handleClearRecording = () => {
		recordingCounter.reset()

		setIsRecording(false)
	}

	const handleCancelRecording = () => {
		handleClearRecording()

		AudioService.finishRecording()
	}

	const handleFinishRecording = async () => {
		handleClearRecording()

		const audio = await AudioService.finishRecording()

		const audioFile = MediaService.turnBlobIntoFile(audio, `${Date.now()}.mp3`)

		const audioMedia = MediaService.adaptFile(audioFile)

		audioMedia.type = "audio"

		onMedia(audioMedia)
	}

	return (
		<>
			<Popover
				open={isRecording}
				anchorEl={childrenRef.current}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center"
				}}
			>
				<Grid
					container
					alignItems="center"
					justify="space-between"
				>
					<IconButton
						onClick={handleCancelRecording}
					>
						<CancelIcon
							className={classes.cancelIcon}
						/>
					</IconButton>

					<Divider orientation="vertical" size={2} />

					<Grid
						container
						alignItems="center"
						justify="center"
						className={classes.recordingInfoContainer}
					>
						<Skeleton
							variant="circle"
							className={classes.recodingAnimatedIcon}
						/>

						<Divider orientation="vertical" size={1} />

						<Typography
							variant="body1"
							color="textPrimary"
						>
							{recordingCounter.formattedElapsedSeconds}
						</Typography>
					</Grid>

					<Divider orientation="vertical" size={2} />

					<IconButton
						onClick={handleFinishRecording}
					>
						<FinishIcon
							className={classes.finishIcon}
						/>
					</IconButton>
				</Grid>
			</Popover>

			<Grid
				innerRef={childrenRef}
			>
				<IconButton
					disabled={disabled}
					onClick={handleStartRecording}
				>
					<VoiceIcon />
				</IconButton>
			</Grid>
		</>
	)
}

export default VoiceInput
