import { makeStyles, fade } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles((theme) => ({
	inputBase: {
		padding: 10,
		width: "100%",
		borderBottom: "1px solid #dfe2e5",
		"& input": {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create(["border-color", "box-shadow"]),
			border: "1px solid #ced4da",
			fontSize: 14,
			"&:focus": {
				boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main
			}
		}
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18
	},
	text: {
		flexGrow: 1
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2
	},
	closeIcon: {
		float: "right",
		"& svg": {
			fontSize: "1rem"
		}
	},
	tagsCard: {
		marginTop: theme.spacing(1),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(3)
	},
	color: {
		width: 14,
		height: 14,
		flexShrink: 0,
		borderRadius: 3,
		marginRight: 8,
		marginTop: 2
	},
	popperDisablePortal: {
		position: "relative"
	},
	option: {
		minHeight: "auto",
		alignItems: "flex-start",
		padding: 8,
		"&[aria-selected='true']": {
			backgroundColor: "transparent"
		},
		"&[data-focus='true']": {
			backgroundColor: theme.palette.action.hover
		}
	},
	paper: {
		boxShadow: "none",
		margin: 0,
		color: "#586069",
		fontSize: 13
	},
	popper: {
		border: "1px solid rgba(27,31,35,.15)",
		boxShadow: "0 3px 12px rgba(27,31,35,.15)",
		borderRadius: 3,
		width: 300,
		zIndex: 9999999,
		fontSize: 13,
		color: "#586069",
		backgroundColor: "#f6f8fa"
	},
	tagCard: {
		borderRadius: theme.spacing(0.5),
		height: theme.spacing(3),
		width: "100%",
		textAlign: "center",
		fontSize: 15,
		paddingTop: 2,
		marginBottom: theme.spacing(1),
		color: "white"
	},
	loadingTagAction: {
		color: colors.grayScale[11]
	},
	pluginFeatureIconCircle: {
		float: "left",
		width: "19px",
		height: "19px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.grey[300],
		borderRadius: "50%",
		marginLeft: "2px",
		marginTop: "1px"
	},
	pluginFeatureIcon: {
		width: "16px",
		height: "16px"
	},
	notesCard: {
		marginTop: theme.spacing(1),
		paddingBottom: theme.spacing(3)
	},
	notesCardTitle: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1)
	},
	note: {
		padding: 0,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(3)
	},
	noteContent: {
		fontSize: "15px",
		padding: "0px",
		marginTop: "0px"
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "340px",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 3,
		wordWrap: "break-word"
	},
	button: {
		color: colors.grayScale[1],
		paddingBottom: 0
	},
	iconButton: {
		padding: "0",
		margin: "0",
		minHeight: "20px"
	}
}))

export default useStyles
