import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	inputContainer: {
		flex: 1
	},
	associationIconContainer: {
		width: theme.spacing(8)
	},
	associationDescription: {
		backgroundColor: "#E7E7E7",
		fontWeight: "bold",
		fontSize: "15px",
		color: colors.grayScale[4],
		borderRadius: theme.spacing(0.5),
		padding: "18.5px 14px",
		border: "1px solid #CBCBCB"
	}
}))

export default useStyles
