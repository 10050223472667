import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	name: {
		paddingLeft: theme.spacing(0.5)
	},
	nickname: {}
}))

export default useStyles
